<ts-header
    [dateRangeObservable]="dateRangeObservable"
    [userDaysOff]="userDaysOff"
    [tasksInTotal]="tasksInTotal"
    [userSealDate]="userSealDate"
    [userId]="userId"
    [currentUserId]="currentUserId"
    class="o-ts-row o-ts-row--header"
>
</ts-header>
<ts-project
    *ngFor="let project of timesheet.projects; let idx = index"
    [project]="project"
    [userId]="userId"
    [onTaskEdit]="onTaskEdit"
    [userHolidaysDays]="userHolidaysDays"
    [userSealDate]="userSealDate"
    [firstProject]="isFirstProject(idx)"
    [lastProject]="isLastProject(idx)"
    [focusedActivityObservable]="focusedActivityObservable"
    [timerObservable]="timerObservable"
    [timerSeconds]="timerSeconds"
    class="o-ts-row o-ts-row--project"
    #projectComponent
    (navigateDown)="navigateDownOf(projectComponent, $event)"
    (navigateUp)="navigateUpOf(projectComponent, $event)"
>
</ts-project>
<div class="c-timesheet-table__footer">
    <add-task-row
        *ngIf="timesheet && shouldDisplayAddTaskRow()"
        [userId]="userId"
        [userIdObservable]="userIdObservable"
        [userSealDate]="userSealDate"
        [userHolidaysDays]="userHolidaysDays"
        [timesheetObservable]="timesheetSubject"
        [timesheet]="timesheet"
        [dropdownOpensUpwards]="dropdownOpensUpwards"
    ></add-task-row>
    <add-task-row-explanation
        *ngIf="shouldDisplayExplanation"
        [isCurrentWeek]="isCurrentWeek"
    ></add-task-row-explanation>
    <ts-footer
        [dateRangeObservable]="dateRangeObservable"
        [timeSummariesByDay]="timesheet.timeSummariesByDay"
        [totalTime]="timesheet.totalTime"
        [showAddTaskSubject]="showAddTaskSubject"
        [fixedAddBtnObservable]="fixedAddBtnObservable"
        [timerObservable]="timerObservable"
        [timerSeconds]="timerSeconds"
        class="o-ts-row o-ts-row--footer"
    >
    </ts-footer>
</div>
<add-task-modal
    *ngIf="addTaskModalVisible"
    [showStream]="showAddTaskSubject"
    [userId]="userId"
></add-task-modal>
<edit-task-modal
    *ngIf="editTaskModalVisible"
    [showStream]="showEditTaskSubject"
    [task]="editedTask"
    [initialProject]="editedTasksProject"
></edit-task-modal>
