import { profileEditI18NDe } from "./profile-edit-i18n-de";
import { profileEditI18NPl } from "./profile-edit-i18n-pl";

const profileEditI18NEn = {
    editYourProfile: "Edit Your Profile",
    changePicture: "Change profile picture",
    firstName: "First name",
    lastName: "Last name",
    required: "Required",
    saveChanges: "Save changes",
    uploadPicture: "Upload picture",
    changeProfilePicture: "Change profile picture",
    save: "Save",
    wantToStart: "Want to start using Timepot right away?",
    skipNow: "Skip now",
};

export const profileEditI18N = {
    de: profileEditI18NDe,
    en: profileEditI18NEn,
    pl: profileEditI18NPl,
};
