import { Component, Input, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { CompanyService } from "services/company/CompanyService";
import { DateService } from "services/date/DateService";
import { newCompanyI18n } from "timepot/components/newcompany/i18n/new-company-i18n";
import { UserService } from "services/user/UserService";
import { Router } from "@angular/router";
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    flatMap,
} from "rxjs/operators";
import {
    Action,
    OpenModalToken,
} from "timepot/components/genericmodal/GenericModalComponent";
import { Token } from "models/token/Token";
import { CompanyPickerService } from "services/currentcompanyuser/CompanyPickerService";

export const MINIMAL_SEARCH_LENGTH = 1;
export const SUBDOMAIN_NAME_REGEX = /^([a-z0-9][a-z0-9-]*)?[a-z0-9]$/i;

@Component({
    selector: "new-company-modal",
    templateUrl: "new-company-modal.html",
})
export class NewCompanyModalComponent implements OnInit {
    public i18n: (typeof newCompanyI18n)["en"];
    public signUpDisabledObservable = new BehaviorSubject<boolean>(true);
    public isNameEmpty = true;
    public isNameValid = false;
    public isNameAvailable = false;
    public isNameValidAndAvailable = false;
    public companyName: string;
    public signUpForm: FormGroup = null;
    public showModalStream = new Subject<boolean>();
    public okEnabled: Subject<boolean> = new BehaviorSubject<boolean>(false);
    public actionStream: Subject<Action> = new ReplaySubject<Action>(1);

    @Input() public showStream: Subject<Token>;
    @Input() public openModalStream: Subject<OpenModalToken> =
        new ReplaySubject<OpenModalToken>(1);

    constructor(
        private companyService: CompanyService,
        private i18nService: I18NService,
        private dateService: DateService,
        private userService: UserService,
        private router: Router,
        private formBuilder: FormBuilder,
        private companyPickerService: CompanyPickerService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(newCompanyI18n);
        this.signUpForm = this.formBuilder.group({
            companyName: ["", Validators.required],
        });

        this.signUpForm.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => {
                const key = "companyName";
                if (this.getControl(key).value !== undefined) {
                    this.onCompanyNameInputChange(this.getControl(key).value);
                    this.getControl(key).markAsTouched();
                }
            });
    }

    public ngOnInit() {
        this.showStream.subscribe(() => this.showModalStream.next(true));
        this.actionStream
            .pipe(
                filter((action) => action === Action.OK && this.isFormValid()),
                flatMap(() =>
                    this.companyService.createCompany(
                        this.signUpForm.value.companyName
                    )
                )
            )
            .subscribe((user) => this.companyPickerService.pickCompany(user));
    }

    private companyNameStatusUpdate(
        empty: boolean,
        valid: boolean,
        available?: boolean
    ) {
        this.isNameValid = valid;
        this.isNameValidAndAvailable = valid && available;
        this.isNameEmpty = empty;

        if (available !== undefined && this.isNameAvailable !== available) {
            this.isNameAvailable = available;
            this.signUpDisabledObservable.next(!this.isNameAvailable);
        }
    }

    public onCompanyNameInputChange(searchText: string) {
        const isEmpty = searchText === null || searchText.length === 0;
        const isValid =
            searchText !== null &&
            searchText.length >= MINIMAL_SEARCH_LENGTH &&
            SUBDOMAIN_NAME_REGEX.test(searchText);

        if (!isEmpty && isValid) {
            this.companyService
                .getCompanyNameAvailability(searchText)
                .subscribe((available: boolean) =>
                    this.companyNameStatusUpdate(isEmpty, isValid, available)
                );
        } else {
            this.companyNameStatusUpdate(isEmpty, isValid, false);
        }
    }

    public getControl(name: string): AbstractControl {
        return this.signUpForm.controls[name];
    }

    public onInput(name: string): void {
        this.getControl(name).markAsUntouched();
    }

    public touched(name: string): boolean {
        return this.getControl(name).touched;
    }

    public isFormValid(): boolean {
        this.signUpForm.updateValueAndValidity();
        return this.signUpForm.valid;
    }
}
