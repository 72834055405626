import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { ResetPasswordService } from "services/password/ResetPasswordService";
import { BACKEND_ERROR_CODES } from "constants/BackendErrorCode";
import { I18NService } from "services/i18n/I18NService";
import { passwordI18N } from "./i18n/password-i18n";
import { SignupPasswordComponent } from "../signup/SignupPasswordComponent";

@Component({
    selector: "reset-password",
    templateUrl: "reset-password.html",
    host: { "[class.c-reset-password]": "true" },
})
export class ResetPasswordComponent implements OnInit {
    public i18n;
    public token: string;
    public passwordVisibility: boolean;
    public passwordSet: boolean;
    public passwordInvalid: boolean;

    @ViewChild(SignupPasswordComponent)
    private newPassword: SignupPasswordComponent;

    constructor(
        private passwordService: ResetPasswordService,
        i18nService: I18NService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.i18n = i18nService.extractCurrentTranslation(passwordI18N);
    }

    public ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            /* eslint-disable @typescript-eslint/dot-notation */
            this.token = params["token"];
            /* eslint-enable @typescript-eslint/dot-notation */
        });
    }

    public submit(event) {
        event.preventDefault();
        const password = this.newPassword.value;
        this.passwordService.resetPassword(password, this.token).subscribe(
            () => (this.passwordSet = true),
            (err: HttpErrorResponse) => {
                const json = err.error;
                if (
                    json &&
                    json.errorCode === BACKEND_ERROR_CODES.VALIDATION_ERROR
                ) {
                    this.passwordInvalid = true;
                } else {
                    throw err;
                }
            }
        );
    }

    public gotoLogin(event) {
        event.preventDefault();
        this.router.navigate(["/login"]);
    }

    public canSubmit() {
        return this.token && this.newPassword.isValid();
    }

    public togglePasswordVisibility() {
        this.passwordVisibility = !this.passwordVisibility;
    }
}
