import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { DaysOff } from "../../models/daysOff/DaysOff";
import { DateService } from "../date/DateService";
import { ConfigService } from "../config";
import { UserDaysOff } from "../../models/daysOff/UserDaysOff";
import { DaysOffRange } from "../../models/daysOff/DaysOffRange";
import { DayOffType } from "../../models/daysOff/DayOffType";
import { User } from "../../models/users/User";
import { DateRange } from "../../models/daterange/DateRange";
import { concat, flatMap, map } from "rxjs/operators";

@Injectable()
export class DaysOffService {
    private refreshSubject = new Subject<RefreshToken>();

    constructor(
        private http: HttpClient,
        private dateService: DateService,
        private configService: ConfigService
    ) {}

    private static isUserDaysOff(
        user: User,
        userHolidays: UserDaysOff
    ): boolean {
        return userHolidays.user.id === user.id;
    }

    public getHolidaysForYear(
        year: number,
        type: DayOffType
    ): Observable<DaysOff> {
        const getHolidaysObservable = () =>
            this.http.get(this.configService.daysOffEndpoint(year, type)).pipe(
                map((res) => {
                    return DaysOff.parse(res);
                })
            );
        const refreshedActivities = this.refreshSubject.pipe(
            flatMap(() => getHolidaysObservable())
        );
        return getHolidaysObservable().pipe(concat(refreshedActivities));
    }

    public getUserDaysOffInRange(
        userId,
        range: DateRange,
        type: DayOffType
    ): Observable<UserDaysOff> {
        return this.http
            .get(
                this.configService.userDaysOffInRangeEndpoint(
                    userId,
                    range.from,
                    range.to,
                    type
                )
            )
            .pipe(map((res) => UserDaysOff.parse(res)));
    }

    public addDaysOff(
        userId: string,
        start: Date,
        end: Date,
        type: DayOffType
    ) {
        const payload = {
            begin: DateService.toISOString(start),
            end: DateService.toISOString(end),
            type: type,
        };
        this.http
            .post(this.configService.userDaysOffEndpoint(userId), payload)
            .subscribe(() => this.refreshSubject.next(RefreshToken.REFRESH));
    }

    public deleteDaysOff(user: User, start: Date, end: Date) {
        const endpoint = this.configService.userDaysOffEndpoint(user.id);
        this.http
            .delete(
                endpoint +
                    "?begin=" +
                    DateService.toISOString(start) +
                    "&end=" +
                    DateService.toISOString(end)
            )
            .subscribe(() => this.refreshSubject.next(RefreshToken.REFRESH));
    }

    /**
     * @param daysOff
     * @param currentUser
     * @param dateRange
     * @returns {boolean} true if the provided dateRange is included as whole in one holiday period of the user or does not overlap with any holiday period of the user
     */
    public isHomogeneousRange(
        daysOff: DaysOff,
        currentUser: User,
        dateRange: DateRange
    ) {
        const currentUserHolidays: UserDaysOff = daysOff.daysOff.find(
            (userHolidays) =>
                DaysOffService.isUserDaysOff(currentUser, userHolidays)
        );
        const transitioningHolidays: DaysOffRange[] =
            currentUserHolidays.daysOff.filter(
                (holiday) =>
                    this.dateService.isInDateRange(holiday.begin, dateRange) ||
                    this.dateService.isInDateRange(holiday.end, dateRange)
            );
        if (transitioningHolidays.length > 1) {
            return false;
        } else if (transitioningHolidays.length === 0) {
            return true;
        } else {
            const onlyTransitioningHoliday = transitioningHolidays[0];
            return (
                onlyTransitioningHoliday.begin <= dateRange.from &&
                dateRange.to <= onlyTransitioningHoliday.end
            );
        }
    }
}

enum RefreshToken {
    REFRESH,
}
