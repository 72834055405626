<div (document:mousedown)="onClick($event)">
    <div
        (mousedown)="openIfAllowed()"
        class="c-generic-dropdown__activator"
        [ngClass]="{
         'u-hidden': !isGenericDropdownActivatorAllowed(),
         'c-generic-dropdown__activator--disabled': disabled}"
        tabindex="0"
        (keydown)="onDropdownActivatorKeyDown($event)"
    >
        <span
            *ngIf="multiSelect || !currentSelection || currentSelection.length === 0"
            class="c-generic-dropdown__activator-placeholder"
            >{{ selectMessage }}</span
        >
        <span *ngIf="!multiSelect && !!currentSelection">
            <span
                *ngFor="let selectedItem of currentSelection"
                class="c-generic-dropdown__activator-selected {{ getStyle(selectedItem) }}"
                showOverflownTitle
            >
                {{selectedItem.name}}
            </span>
        </span>
        <i
            *ngIf="!disabled"
            class="u-icon u-icon-angle-down c-generic-dropdown__activator-icon"
        ></i>
    </div>

    <div
        class="c-generic-dropdown__dropdown"
        [ngClass]="{ 'u-hidden': isGenericDropdownActivatorAllowed() }"
    >
        <input
            class="c-generic-dropdown__input input-search"
            type="text"
            autocomplete="off"
            placeholder="{{searchPlaceholder}}"
            #search
            (input)="onInput(search.value)"
            (keydown)="onInputKeyDown(search.value, $event)"
            (blur)="onInputBlur()"
        />
        <span class="c-generic-dropdown__close" (click)="closeList()"></span>

        <div
            class="c-generic-dropdown__list-container"
            *ngIf="isListOpened() || optionalItems"
        >
            <div
                class="c-generic-dropdown__toggle"
                *ngIf="optionalItems && optionalItems.length > 0"
            >
                <div
                    class="c-generic-dropdown__toggle-item"
                    [ngClass]="{'c-generic-dropdown__toggle-item--selected': isItemSelected(originalItems) }"
                    (click)="selectItems(originalItems)"
                >
                    {{itemsName}}
                </div>
                <div
                    class="c-generic-dropdown__toggle-item"
                    [ngClass]="{'c-generic-dropdown__toggle-item--selected': isItemSelected(optionalItems) }"
                    (click)="selectItems(optionalItems)"
                >
                    {{optionalItemsName}}
                </div>
            </div>

            <ul class="c-generic-dropdown__list ps-child">
                <li
                    *ngFor="let item of items"
                    (mousedown)="select(item, $event)"
                    class="{{ getStyle(item) }}"
                >
                    <a
                        *ngIf="matchesFilter(item)"
                        showOverflownTitle
                        class="c-generic-dropdown__option"
                        [ngClass]="{ 'c-generic-dropdown__option--selected': isSelected(item) }"
                        (keydown)="onListKeyDown(item, $event)"
                        tabindex="0"
                        >{{item.name}}</a
                    >
                </li>
            </ul>
        </div>
    </div>
</div>
