export const signupI18NDe = {
    createAccount: "Konto erstellen",
    toJoin: (invitingCompanyName) => "zur Anmeldung " + invitingCompanyName,
    signUpWithGoogle: "Mit Google anmelden",
    withGoogle: "mit Google",
    with: "mit",
    signUpWithEmail: "Mit E-Mail anmelden",
    withEmail: "mit E-Mail",
    email: "email",
    signUp: "Anmelden",
    alreadyHaveAnAccount: "Sie haben schon ein Konto?",
    logInNow: "Jetzt anmelden",
    yourEmail: "Ihre E-Mail",
    password: "Passwort",
    invalidEmailAddress: "Ungültige E-Mail",
    weakPassword: "Schwaches Passwort",
    required: "Erforderlich",
    passwordLoginFailed:
        "Anmeldung fehlgeschlagen Bitte versuchen Sie es erneut oder melden Sie sich mit Google an",
    enterPromoCode: "Aktionscode eingeben",
    placeholderPromoCode: "Aktionscode",
    bySigningUpYouAreAgreeTo:
        "Durch Ihre Anmeldung geben Sie Ihre Zustimmung zu",
    thePrivacyPolicy: "den Datenschutzrichtlinien",
    and: "und",
    termsOfUse: "Nutzungsbedingungen",
    privacyUrl: "https://www.timepot.de/privacy.html",
    termsUrl: "https://www.timepot.de/terms.html",
    firstProjectName: "Default",
};
