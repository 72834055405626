import { Pipe, PipeTransform } from "@angular/core";
import { DateService } from "../services/date/DateService";
import { I18NService } from "../services/i18n/I18NService";
import { pipesI18N } from "./i18n/pipes-i18n";

@Pipe({ name: "timeDistancePipe" })
export class TimeDistancePipe implements PipeTransform {
    private i18n;

    constructor(
        private dateService: DateService,
        private i18nService: I18NService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(pipesI18N);
    }

    public transform(date: Date): string {
        if (this.dateService.isToday(date)) {
            return this.i18n.today;
        } else if (this.dateService.isYesterday(date)) {
            return this.i18n.yesterday;
        } else {
            return this.dateService.prettyDate(date);
        }
    }
}
