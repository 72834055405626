import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import {
    Action,
    OpenModalToken,
} from "../../genericmodal/GenericModalComponent";
import { I18NService } from "services/i18n/I18NService";
import { inviteI18N } from "../../invite/i18n/invite-i18n";

@Component({
    selector: "invite-users-modal",
    templateUrl: "invite-users-modal.html",
})
export class InviteUsersModalComponent implements OnInit {
    @Input() public showStream: Subject<boolean>;
    @Input() public actionStream: Subject<Action> = new ReplaySubject<Action>(
        1
    );
    @Input() public openModalStream: Subject<OpenModalToken> =
        new ReplaySubject<OpenModalToken>(1);

    public okEnabled: Subject<boolean> = new ReplaySubject<boolean>(1);
    public isBtnOkEnabled: boolean;
    public i18n;

    constructor(private i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(inviteI18N);

        this.okEnabled.subscribe((enable) => {
            this.isBtnOkEnabled = enable;
        });
    }

    public ngOnInit() {
        this.actionStream.subscribe(() => this.showStream.next(false));
    }
}
