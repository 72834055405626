import { Injectable } from "@angular/core";

@Injectable()
export class ChartScaleService {
    public getScale(maxValue: number, numberOfScales: number): number {
        const intervalSize = Math.ceil(maxValue / numberOfScales);
        const largestPowerOfTenInsideInterval =
            this.findLargestPowerOfTenSmallerOrEqualToNumber(intervalSize);
        return this.findSmallestMultipleLargerOrEqualToNumber(
            largestPowerOfTenInsideInterval,
            intervalSize
        );
    }

    private findSmallestMultipleLargerOrEqualToNumber(
        base: number,
        num: number
    ) {
        return Math.ceil(num / base) * base;
    }

    private findLargestPowerOfTenSmallerOrEqualToNumber(num: number) {
        const exponent = Math.floor(Math.log10(num));
        return Math.pow(10, exponent);
    }
}
