<div class="c-ts-week-picker__btn" (click)="setOpen(true)">
    <svg><use xlink:href="images/menu/all.svg#datepicker"></use></svg>
</div>

<div class="c-ts-week-picker__flyout" *ngIf="isOpen">
    <date-picker-body
        [preselected]="preselected"
        [selection]="selectedDate"
        [startRange]="startRange"
        [endRange]="endRange"
    ></date-picker-body>
</div>
