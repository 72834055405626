<div class="c-configurator-user-entry__photo">
    <img
        class="c-configurator-user-entry__photo-image"
        src="{{getAvatar()}}"
        alt=""
    />
</div>
<div class="c-configurator-user-entry__name">
    {{currentUser ? i18n.you : user.name}}
</div>
<div class="c-configurator-user-entry__badge">{{usedHolidayDays}}</div>
