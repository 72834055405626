import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ProjectIncomeAndExpenseReport } from "models/financialreport/ProjectIncomeAndExpenseReport";
import { I18NService } from "services/i18n/I18NService";
import { financialReportsI18N } from "../i18n/financial-reports-i18n";

@Component({
    selector: "project-summary",
    templateUrl: "project-summary.html",
})
export class ProjectSummaryComponent implements OnInit {
    @Input() public reportObservable: Observable<ProjectIncomeAndExpenseReport>;
    public totalIncome: number;
    public totalExpenses: number;
    public totalBalance: number;
    public currency = "";

    public i18n;

    constructor(i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(financialReportsI18N);
    }

    public ngOnInit() {
        this.reportObservable.subscribe((report) => {
            this.currency = report.currency;
            this.totalIncome = Math.round(
                report.incomesAndExpenses.reduce(
                    (acc, iAndE) => acc + iAndE.income,
                    0
                )
            );

            this.totalExpenses = Math.round(
                report.incomesAndExpenses.reduce(
                    (acc, iAndE) => acc + iAndE.expense,
                    0
                )
            );

            this.totalBalance = this.totalIncome - this.totalExpenses;
        });
    }
}
