export const projectModalI18NPl = {
    addProjectTitle: "Dodaj projekt do Timepota",
    chooseColor: "Wybierz kolor",
    editProjectTitle: "Edytuj projekt",
    projectNamePlaceholder: "Nazwa projektu w Timepocie",
    add: "Dodaj",
    save: "Zapisz",
    defaultTasksBillability: "Zadania domyślnie opłacane",
    billable: "Opłacany",
    nonBillable: "Nie opłacany",
};
