import { AfterViewInit, Component, Input, OnDestroy } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import * as _ from "lodash";
import { I18NService } from "services/i18n/I18NService";
import { barChartHorizontalI18N } from "./i18n/barchart-horizontal-i18n";
import { ChartEntry } from "../common/ChartEntry";

const MINUTES_IN_HOUR = 60;

@Component({
    selector: "barchart-horizontal",
    templateUrl: "barchart-horizontal.html",
    host: { "[class.c-barchart-horizontal]": "true" },
})
export class BarChartHorizontalComponent<T>
    implements AfterViewInit, OnDestroy
{
    @Input() public dataObservable: Observable<Array<ChartEntry<T>>>;
    @Input() public selectedItemStream: Subject<T>;
    @Input() public visible = false;
    @Input() public keyI18N: string;

    public i18n;
    public items: ChartEntry<T>[] = [];

    private hoverElementId: string = null;
    private longestDuration: number = null;

    private currentSubscription: Subscription;

    constructor(private i18nService: I18NService) {
        this.i18n = this.i18nService.extractCurrentTranslation(
            barChartHorizontalI18N
        );
    }

    public ngAfterViewInit() {
        this.currentSubscription = this.dataObservable.subscribe(
            (data: ChartEntry<T>[]) => {
                this.items = _.sortBy(
                    data,
                    (entry: ChartEntry<T>) => entry.minutes
                ).reverse();
                this.longestDuration = _.max(
                    _.map(this.items, (entry: ChartEntry<T>) => entry.minutes)
                );
            }
        );
    }

    public ngOnDestroy(): void {
        this.currentSubscription.unsubscribe();
    }

    public releaseHover(): void {
        this.hoverElementId = null;
    }

    public setHover(id: string): void {
        this.hoverElementId = id;
    }

    public isOnHover(id: string): boolean {
        return !!this.hoverElementId && this.hoverElementId === id;
    }

    public getBarWidthPercentage(minutes: number): string {
        return this.getWidthPercentage(minutes) + "%";
    }

    public getDuration(duration: number): string {
        const hours: number = Math.floor(duration / MINUTES_IN_HOUR);
        const minutes: number = duration - hours * MINUTES_IN_HOUR;

        return `${hours}h ${minutes}min`;
    }

    public getLabelStyle(item: ChartEntry<T>) {
        return this.isOnHover(item.id) ? { color: item.colorHash } : {};
    }

    public selectEntry(data: T) {
        this.selectedItemStream.next(data);
    }

    private getWidthPercentage(minutes: number): number {
        return (100 * minutes) / this.longestDuration;
    }
}
