<div class="u-flex-row">
    <div class="o-form__group u-1/2">
        <div class="o-form__input-group">
            <generic-dropdown
                [items]="users"
                [selection]="selectedUsers"
                [multiSelect]="false"
                [initialSelectionStream]="initialSelectionStream"
                [selectMessage]="i18n.user"
                [selectOnSwitch]="false"
                [searchPlaceholder]="i18n.user"
            >
            </generic-dropdown>
        </div>
    </div>

    <div class="o-form__group u-1/2">
        <div class="o-form__input-group">
            <input
                class="o-form__input"
                [ngModel]="override.rate"
                #rate
                (keyup)="setRate(rate.value)"
                name="rate"
            />
            <div
                class="c-user-rate-override__btn-delete"
                (click)="delete.emit(override)"
            ></div>
        </div>
    </div>
</div>
