import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

const SCROLLBAR_UPDATE_INTERVAL = 1000; //ms
const ELEMENT_REF = "timepot";
const CLASSES_PREVENTING_INITIALIZATION = [
    "ps-container",
    "c-timepot--navbar-open",
];

@Injectable()
export class PerfectScrollbarService {
    private perfectScrollbar: PerfectScrollbar;
    private timepot: HTMLInputElement = <HTMLInputElement>(
        document.getElementsByTagName(ELEMENT_REF)[0]
    );

    private containerHasPreventingClass(): boolean {
        const containerClassName = this.timepot.className.split(" ");
        return CLASSES_PREVENTING_INITIALIZATION.some(
            (elem) => containerClassName.indexOf(elem) > -1
        );
    }

    constructor(private router: Router) {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        this.perfectScrollbar = (<any>window).Ps;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    public initScrollbar() {
        if (!this.containerHasPreventingClass()) {
            this.perfectScrollbar.initialize(this.timepot);

            setInterval(
                () => this.updateScrollbar(),
                SCROLLBAR_UPDATE_INTERVAL
            );

            this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe(() => this.scrollToTheTop());
        }
    }

    public scrollToTheTop() {
        this.timepot.scrollTop = 0;
    }

    public updateScrollbar() {
        this.perfectScrollbar.update(this.timepot);
    }

    public destroyScrollbar() {
        this.perfectScrollbar.destroy(this.timepot);
    }
}

/* start copied form perfect-scrollbar.d.ts */
interface PerfectScrollbar {
    initialize(container: HTMLElement, options?: PerfectScrollbarOptions): void;
    update(container: HTMLElement): void;
    destroy(container: HTMLElement): void;
}

interface PerfectScrollbarOptions {
    wheelSpeed?: number;
    wheelPropagation?: boolean;
    swipePropagation?: boolean;
    minScrollbarLength?: number;
    maxScrollbarLength?: number;
    useBothWheelAxes?: boolean;
    useKeyboard?: boolean;
    suppressScrollX?: boolean;
    suppressScrollY?: boolean;
    scrollXMarginOffset?: number;
    scrollYMarginOffset?: number;
}
/* end copied form perfect-scrollbar.d.ts */
