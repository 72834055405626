import { Component, Input, OnInit } from "@angular/core";
import { combineLatest, Observable, ReplaySubject, Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { DateRange } from "models/daterange/DateRange";
import { DateService } from "services/date/DateService";
import { UserDaysOff } from "models/daysOff/UserDaysOff";
import { DaysOff } from "models/daysOff/DaysOff";
import { DayOffType } from "models/daysOff/DayOffType";
import { Calendar } from "models/calendar/Calendar";
import { CalendarViewMode } from "models/calendar/CalendarViewMode";
import { User } from "models/users/User";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { calendarI18N } from "./i18n/calendar-i18n";
import { map } from "rxjs/operators";

@Component({
    selector: "calendar",
    templateUrl: "calendar.html",
    host: { "[class.c-calendar]": "true" },
})
export class CalendarComponent implements OnInit {
    public year: number;
    public month: number;
    public monday: Date;
    public viewMode: CalendarViewMode;

    @Input() public selectedYearSubject: ReplaySubject<number>;
    @Input() public selectedUsersObservable: Observable<string[]>;
    @Input() public currentSelection: DateRange;
    @Input() public daysOffObservable: Observable<DaysOff>;
    public monthObservable = new Subject<number>();
    public calendarStateObservable = new Subject<Calendar>();

    public calendarViewMode = CalendarViewMode;

    public daysOff: DaysOff;
    public currentUser: User;
    public usedDaysObservable: Observable<{}>;

    public dayOffTypes = {
        holiday: "HOLIDAY",
        sickLeave: "SICK_LEAVE",
    };

    public dayOffTypeSubject = new ReplaySubject<string[]>();

    public dayOffType = [this.dayOffTypes.holiday, this.dayOffTypes.sickLeave];

    public i18n;

    constructor(
        private dateService: DateService,
        currentCompanyUserViewService: CurrentCompanyUserViewService,
        i18nService: I18NService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(calendarI18N);
        this.currentUser = currentCompanyUserViewService.companyUser;
    }

    public ngOnInit() {
        this.dayOffTypeSubject.next(this.dayOffType);
        this.selectedYearSubject.subscribe((v) => (this.year = v));
        this.selectedYearSubject.next(new Date().getFullYear());

        combineLatest([
            this.daysOffObservable,
            this.selectedUsersObservable,
            this.dayOffTypeSubject,
        ]).subscribe(
            ([daysOff, usersToShow, dayOffType]: [
                DaysOff,
                string[],
                string[]
            ]) => {
                const daysOffByUsers = daysOff.daysOff.filter(
                    (userDaysOff) =>
                        usersToShow.indexOf(userDaysOff.user.id) !== -1
                );

                const daysOffByTypes = daysOffByUsers.map(
                    (userDaysOff): UserDaysOff => {
                        return {
                            ...userDaysOff,
                            daysOff: userDaysOff.daysOff.filter(
                                (range) => dayOffType.indexOf(range.type) !== -1
                            ),
                        };
                    }
                );

                this.daysOff = { daysOff: daysOffByTypes };
            }
        );

        this.usedDaysObservable = this.daysOffObservable.pipe(
            map((daysOff: DaysOff) => {
                const currentUserDaysOff = daysOff.daysOff.find(
                    (userDaysOff) => userDaysOff.user.id === this.currentUser.id
                );
                return {
                    holidays: currentUserDaysOff
                        ? currentUserDaysOff.totals.holidays
                        : 0,
                    sickLeave: currentUserDaysOff
                        ? currentUserDaysOff.totals.sickLeave
                        : 0,
                };
            })
        );

        this.monthObservable.subscribe((month) => {
            this.calendarStateObservable.next({
                year: this.year,
                month: month,
                monday: this.monday,
                viewMode: CalendarViewMode.MONTH,
            });
        });

        this.calendarStateObservable.subscribe((calendarState) => {
            if (this.year !== calendarState.year) {
                this.year = calendarState.year;
                this.selectedYearSubject.next(this.year);
            }
            if (this.month !== calendarState.month) {
                this.month = calendarState.month;
            }
            if (this.monday !== calendarState.monday) {
                this.monday = calendarState.monday;
            }
            if (this.viewMode !== calendarState.viewMode) {
                this.viewMode = calendarState.viewMode;
            }
        });

        this.calendarStateObservable.next({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            monday: this.dateService.getMondayOfWeek(new Date()),
            viewMode: CalendarViewMode.YEAR,
        });
    }

    public next() {
        switch (this.viewMode) {
            case CalendarViewMode.YEAR:
                this.changeYear(this.year, ChangeDirection.NEXT);
                break;

            case CalendarViewMode.MONTH:
                this.changeMonth(this.year, this.month, ChangeDirection.NEXT);
                break;

            case CalendarViewMode.WEEK:
                this.changeWeek(this.monday, ChangeDirection.NEXT);
                break;

            default:
                break;
        }
    }

    public prev() {
        switch (this.viewMode) {
            case CalendarViewMode.YEAR:
                this.changeYear(this.year, ChangeDirection.PREV);
                break;

            case CalendarViewMode.MONTH:
                this.changeMonth(this.year, this.month, ChangeDirection.PREV);
                break;

            case CalendarViewMode.WEEK:
                this.changeWeek(this.monday, ChangeDirection.PREV);
                break;

            default:
                break;
        }
    }

    public switchCalendarView(mode: CalendarViewMode) {
        this.calendarStateObservable.next({
            year: this.year,
            month: this.month,
            monday: this.monday,
            viewMode: mode,
        });
    }

    public selectDayOffTypes(type: string) {
        const index = this.dayOffType.indexOf(type);
        if (index >= 0) {
            this.dayOffType.splice(index, 1);
        } else {
            this.dayOffType.push(type);
        }

        this.dayOffTypeSubject.next(this.dayOffType);
    }

    public isSelected(type: DayOffType) {
        return this.dayOffType.indexOf(type) >= 0;
    }

    private changeYear(year: number, direction: ChangeDirection) {
        let newYear = year;
        const newMonth = 0;
        const newMonday = this.dateService.getMondayOfWeek(
            new Date(newYear, newMonth, 1)
        );

        direction === ChangeDirection.NEXT ? newYear++ : newYear--;

        this.calendarStateObservable.next({
            year: newYear,
            month: newMonth,
            monday: newMonday,
            viewMode: this.viewMode,
        });
    }

    private changeMonth(
        year: number,
        month: number,
        direction: ChangeDirection
    ) {
        let newYear = year;
        let newMonth = month;
        const diff = direction === ChangeDirection.NEXT ? 1 : -1;

        if (newMonth + diff >= 0 && newMonth + diff <= 11) {
            newMonth = newMonth + diff;
        } else if (newMonth + diff < 0) {
            newMonth = 11;
            newYear--;
        } else if (newMonth + diff > 11) {
            newMonth = 0;
            newYear++;
        }

        const newMonday = this.dateService.getMondayOfWeek(
            new Date(newYear, newMonth, 1)
        );

        this.calendarStateObservable.next({
            year: newYear,
            month: newMonth,
            monday: newMonday,
            viewMode: this.viewMode,
        });
    }

    private changeWeek(monday: Date, direction: ChangeDirection) {
        let newMonday = monday;
        const diff = direction === ChangeDirection.NEXT ? 7 : -7;

        newMonday = new Date(newMonday.getTime());
        newMonday.setDate(newMonday.getDate() + diff);

        const newYear = newMonday.getFullYear(),
            newMonth = newMonday.getMonth();

        this.calendarStateObservable.next({
            year: newYear,
            month: newMonth,
            monday: newMonday,
            viewMode: this.viewMode,
        });
    }
}

enum ChangeDirection {
    PREV,
    NEXT,
}
