const datepicker = {
    ranges: [
        "Heute",
        "Diese Woche",
        "Diesen Monat",
        "Dieses Jahr",
        "Gestern",
        "Letzte Woche",
        "Letzten Monat",
        "Letztes Jahr",
    ],
    datepickerNames: ["Von", "An"],
    dayInitials: {
        sundayInitial: "So",
        mondayInitial: "Mo",
        tuesdayInitial: "Di",
        wednesdayInitial: "Mi",
        thursdayInitial: "Do",
        fridayInitial: "Fr",
        saturdayInitial: "Sa",
    },
};

export const reportFilterI18NDe = {
    currency: "Währung",
    from: "Von",
    to: "Bis",
    keywordInputPlaceholder: "Stichwort",
    users: "Benutzer / Gruppen",
    projects: "Projekte / Gruppen",
    projectsSwitch: "Projekte",
    usersSwitch: "Benutzer",
    teamsSwitch: "Gruppen",
    teamDisplay: "Gruppe: ",
    groupsSwitch: "Gruppen",
    groupDisplay: "Gruppe: ",
    findUserPlaceholder: "Benutzer finden",
    findUserTeamPlaceholder: "Gruppen / Benutzer finden",
    findProjectPlaceholder: "Projekt finden",
    findProjectGroupPlaceholder: "Projekte / Gruppen finden",
    monthNames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ],
    monthShortNames: [
        "Jan",
        "Feb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
    ],
    datepicker: datepicker,
    yes: "Ja",
    no: "Nein",
    holidays: "Urlaub",
    holidaysIncluded: "Inklusive Urlaubskosten",
    holidaysExcluded: "Exklusive Urlaubskosten",
    noFilters: "Keine Filter ausgewählt",
    billability: "Abrechenbarkeit",
    billable: "Abrechenbar",
    nonBillable: "Nicht abrechenbar",
};
