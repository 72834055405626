import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const TaskMinutes = z.object({
    minutes: z.number(),
    name: z.string(),
    taskCode: z.string(),
});

export type TaskMinutes = z.infer<typeof TaskMinutes>;
