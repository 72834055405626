import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { timesheetI18N } from "timepot/components/timesheet/i18n/timesheet-i18n";
import { CompanyService } from "services/company/CompanyService";
import { CompanySettings } from "models/company/CompanySettings";

@Component({
    selector: "timepot-sealer",
    templateUrl: "timepot-sealer.html",
    host: { "[class.c-timepot-sealer]": "true" },
})
export class TimepotSealerComponent implements OnInit {
    @Input() public userId: string;

    public i18n;
    public enableSealing = false;
    public flyoutState = new ReplaySubject<boolean>();
    public isDatePickerOpened = false;

    constructor(
        private i18nService: I18NService,
        private companyService: CompanyService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(timesheetI18N);
    }

    public ngOnInit(): void {
        this.companyService
            .getCompanySettings()
            .subscribe((settings: CompanySettings) => {
                this.enableSealing = settings.timepotSealed;
            });

        this.flyoutState.subscribe(
            (state) => (this.isDatePickerOpened = state)
        );
    }

    public openDatePicker(event: Event) {
        event.stopPropagation();
        this.flyoutState.next(true);
    }
}
