import { callToActionI18NDe } from "./call-to-action-i18n-de";
import { callToActionI18NPl } from "./call-to-action-i18n-pl";

const callToActionI18NEn = {
    enjoyedUsingTimepot: "Enjoyed using timepot?",
    createAccount: "Create your own account now",
    createAccountShort: "Create account",
    or: "or",
    sendFeedback: "send us feedback",
    mailSubject: "<Give us short summary of your feedback>",
    mailBody: "<Put here details of your feedback>",
    signUpNow: "Sign up now!",
};

export const callToActionI18N = {
    de: callToActionI18NDe,
    en: callToActionI18NEn,
    pl: callToActionI18NPl,
};
