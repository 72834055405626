import * as _ from "lodash";
import { FilterSettings } from "../../models/reports/filters/FilterSettings";
import {
    FilterSetting,
    FilterType,
} from "../../models/reports/filters/FilterSetting";
import { Injectable } from "@angular/core";

@Injectable()
export class FilterSettingsQueryHelper {
    public getQueryString(
        filterSettings: FilterSettings,
        appendToParams = false
    ): string {
        let queryStr = "";
        let dateFrom: string,
            dateTo: string,
            firstMonth: string,
            lastMonth: string;
        _.forEach(filterSettings.settings, (fs: FilterSetting) => {
            switch (fs.filterType) {
                case FilterType.KEYWORD:
                    queryStr +=
                        "keywords=" + encodeURIComponent(fs.filterValue) + "&";
                    break;
                case FilterType.PROJECT:
                    queryStr += "projectIds=" + fs.filterValue + "&";
                    break;
                case FilterType.PROJECT_TEAMS:
                    queryStr += "projectTeamCodes=" + fs.filterValue + "&";
                    break;
                case FilterType.USER:
                    queryStr += "userIds=" + fs.filterValue + "&";
                    break;
                case FilterType.USER_TEAMS:
                    queryStr += "userTeamCodes=" + fs.filterValue + "&";
                    break;
                case FilterType.IGNORED:
                    queryStr += "ignoredIds=" + fs.filterValue + "&";
                    break;
                case FilterType.DATE:
                    [dateFrom, dateTo] = fs.filterValue.split(" ");
                    queryStr +=
                        "dateFrom=" + dateFrom + "&dateTo=" + dateTo + "&";
                    break;
                case FilterType.YEAR_MONTH_PERIOD:
                    [firstMonth, lastMonth] = fs.filterValue.split(" ");
                    queryStr +=
                        "firstMonth=" +
                        firstMonth +
                        "&lastMonth=" +
                        lastMonth +
                        "&";
                    break;
                case FilterType.HOLIDAYS_INCLUDED:
                    queryStr += "includeHolidays=" + fs.filterValue + "&";
                    break;
                case FilterType.CURRENCY:
                    queryStr += "reportCurrency=" + fs.filterValue + "&";
                    break;
                case FilterType.BILLABLE:
                    queryStr += "billable=" + fs.filterValue + "&";
                    break;
                default:
                    break;
            }
        });
        if (queryStr !== "") {
            queryStr = ((appendToParams ? "&" : "?") + queryStr).substring(
                0,
                queryStr.length
            ); // remove last "&";
        }
        return queryStr;
    }

    public appendFilterDisplays(filterSettings: FilterSettings): string {
        let queryStr = "";
        _.forEach(filterSettings.settings, (fs: FilterSetting) => {
            queryStr +=
                "filterDisplays=" + encodeURIComponent(fs.filterDisplay) + "&";
        });
        return queryStr;
    }
}
