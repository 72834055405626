<generic-modal
    [showStream]="showStream"
    [actionStream]="actionStream"
    [openModalStream]="openModalStream"
    [title]="i18n.title"
    [subtitle]="i18n.tip"
    [confirmLabel]="i18n.add"
    [okEnabled]="isBtnOkEnabled"
>
    <invitation-form
        [actionStream]="actionStream"
        [openModalStream]="openModalStream"
        [okEnabled]="okEnabled"
        [hideButton]="true"
    ></invitation-form>
</generic-modal>
