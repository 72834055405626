<generic-modal
    [showStream]="showModalStream"
    [actionStream]="actionStream"
    [openModalStream]="openModalStream"
    [title]="i18n.editYourProfile"
    [confirmLabel]="i18n.save"
    [okEnabled]="isBtnOkEnabled"
>
    <profile-edit-form
        [actionStream]="actionStream"
        [showStream]="showStream"
        [isInModal]="true"
        [okEnabled]="okEnabled"
    ></profile-edit-form>
</generic-modal>
