import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AuthService } from "./AuthService";
import { CurrentCompanyUserViewService } from "../currentcompanyuser/CurrentCompanyUserViewService";
import { CurrentCompanyUserService } from "services/currentcompanyuser/CurrentCompanyUserService";
import { firstValueFrom } from "rxjs";
import { flatMap } from "rxjs/operators";
import { UserService } from "services/user/UserService";

@Injectable()
export class ProfileEditGuard implements CanActivate {
    constructor(
        private router: Router,
        private currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {}

    public canActivate() {
        if (!this.currentCompanyUserViewService.shouldAskForDetails) {
            return this.router.createUrlTree([""]);
        }
        return true;
    }
}

@Injectable()
export class NotAuthenticatedGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    public canActivate() {
        if (this.authService.isAuthenticated()) {
            return this.router.createUrlTree([""]);
        }
        return true;
    }
}

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(
        private router: Router,
        private currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {}

    public canActivate() {
        if (!this.currentCompanyUserViewService.isCompanyAdmin) {
            return this.router.createUrlTree([""]);
        }
        return true;
    }
}

@Injectable()
export class ManagerGuard implements CanActivate {
    constructor(
        private router: Router,
        private currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {}

    public canActivate() {
        if (!this.currentCompanyUserViewService.isCompanyManager) {
            return this.router.createUrlTree([""]);
        }
        return true;
    }
}

@Injectable()
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router,
        private currentCompanyUserService: CurrentCompanyUserService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService,
        private userService: UserService
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot) {
        if (!this.authService.isAuthenticated()) {
            return this.router.createUrlTree(["/login"]);
        }
        if (!this.currentCompanyUserService.companyUser) {
            await firstValueFrom(
                this.currentCompanyUserService
                    .fetchCurrentCompanyUser()
                    .pipe(
                        flatMap((companyUser) =>
                            this.userService.activateUserIfNeeded(companyUser)
                        )
                    )
            );
        }
        if (
            this.currentCompanyUserViewService.shouldAskForDetails &&
            !this.isProfileEdit(route)
        ) {
            return this.router.createUrlTree(["auth", "profile"]);
        }
        return true;
    }

    private isProfileEdit(route: ActivatedRouteSnapshot): boolean {
        return (
            route &&
            route.firstChild &&
            route.firstChild.routeConfig.path === "profile"
        );
    }
}

@Injectable()
export class TimesheetAccessGuard implements CanActivate {
    private static USER_PARAM = "userId";

    constructor(
        private router: Router,
        private currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot) {
        const isCurrentUserManager =
            this.currentCompanyUserViewService.isCompanyManager;
        const currentUserId = this.currentCompanyUserViewService.userId;
        const requestedUserId = route.params[TimesheetAccessGuard.USER_PARAM];
        if (
            requestedUserId &&
            requestedUserId !== currentUserId &&
            !isCurrentUserManager
        ) {
            return this.router.createUrlTree([
                "auth",
                "ts",
                { userId: currentUserId },
            ]);
        }
        return true;
    }
}
