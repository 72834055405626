import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import * as _ from "lodash";
import { DateService } from "services/date/DateService";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import {
    FilterSetting,
    FilterType,
    YearMonthFilterSetting,
} from "models/reports/filters/FilterSetting";

@Component({
    selector: "double-year-month-picker",
    templateUrl: "double-year-month-picker.html",
    host: { "[class.c-double-year-month-picker]": "true" },
})
export class DoubleYearMonthPickerComponent implements OnInit {
    @Input() public filterSubject: Subject<FilterSettings>;
    public firstYearMonthChanges = new Subject<Date>();
    public lastYearMonthChanges = new Subject<Date>();

    public firstYearMonthSelected: Date;
    public lastYearMonthSelected: Date;

    private filterSettings: FilterSettings;

    constructor(private dateService: DateService) {}

    public ngOnInit() {
        this.filterSubject.subscribe((fs) => {
            this.filterSettings = fs;
            [this.firstYearMonthSelected, this.lastYearMonthSelected] =
                this.getYearMonthRange(fs);
        });

        this.firstYearMonthChanges.subscribe((fromYearMonth) => {
            let [, toYearMonth] = this.getYearMonthRange(this.filterSettings);
            if (
                fromYearMonth &&
                toYearMonth &&
                this.dateService.isBefore(toYearMonth, fromYearMonth)
            ) {
                toYearMonth = fromYearMonth;
            }
            this.setYearMonthFilter(fromYearMonth, toYearMonth);
        });

        this.lastYearMonthChanges.subscribe((toYearMonth) => {
            let [fromYearMonth] = this.getYearMonthRange(this.filterSettings);
            if (
                fromYearMonth &&
                toYearMonth &&
                this.dateService.isAfter(fromYearMonth, toYearMonth)
            ) {
                fromYearMonth = toYearMonth;
            }
            this.setYearMonthFilter(fromYearMonth, toYearMonth);
        });
    }

    private getYearMonthRange(fs: FilterSettings) {
        const yearMonthSetting = <YearMonthFilterSetting>(
            fs.settings.find(
                (setting) => setting.filterType === FilterType.YEAR_MONTH_PERIOD
            )
        );
        if (yearMonthSetting) {
            return [yearMonthSetting.from, yearMonthSetting.to];
        }
        return [undefined, undefined];
    }

    private setYearMonthFilter(from: Date, to: Date) {
        _.remove(
            this.filterSettings.settings,
            (setting: FilterSetting) =>
                setting.filterType === FilterType.YEAR_MONTH_PERIOD
        );
        this.filterSettings.settings.push(new YearMonthFilterSetting(from, to));
        this.filterSubject.next(this.filterSettings);
    }
}
