<div class="c-timepot-sealer__title">{{i18n.sealDateTitle}}</div>
<date-picker-body
    [preselected]="preselected"
    [selection]="selectedDate"
    [sealDate]="sealDate"
>
</date-picker-body>
<div class="c-timepot-sealer__button-group">
    <button class="o-btn o-btn--large o-btn--default" (click)="cancel()">
        {{i18n.cancel}}
    </button>
    <button class="o-btn o-btn--large o-btn--success" (click)="seal()">
        {{i18n.seal}}
    </button>
</div>
<div class="c-timepot-sealer__explanation">{{i18n.sealDateExplanation}}</div>
