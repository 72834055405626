<div class="o-date-picker__month-header">
    <div class="o-date-picker__month-header-day">{{i18n.mondayInitial}}</div>
    <div class="o-date-picker__month-header-day">{{i18n.tuesdayInitial}}</div>
    <div class="o-date-picker__month-header-day">{{i18n.wednesdayInitial}}</div>
    <div class="o-date-picker__month-header-day">{{i18n.thursdayInitial}}</div>
    <div class="o-date-picker__month-header-day">{{i18n.fridayInitial}}</div>
    <div class="o-date-picker__month-header-day">{{i18n.saturdayInitial}}</div>
    <div class="o-date-picker__month-header-day">{{i18n.sundayInitial}}</div>
</div>

<date-picker-week
    *ngFor="let monday of mondays; let i = index"
    [month]="month"
    [year]="year"
    [startDate]="monday"
    [last]="i === mondays.length"
    [first]="i === 0"
    [preselected]="preselected"
    [selection]="selection"
    [startRange]="startRange"
    [endRange]="endRange"
    [sealDate]="sealDate"
    class="o-date-picker__month-week"
>
</date-picker-week>
