import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Token } from "../../models/token/Token";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "../config";
import { map } from "rxjs/operators";

@Injectable()
export class FeedbackService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) {}

    public sendFeedback(feedback: string): Observable<Token> {
        return this.http
            .post(this.configService.feedbackEnpoint(), { feedback: feedback })
            .pipe(map(() => Token.TOKEN));
    }
}
