import { Injectable } from "@angular/core";
import { DateService } from "./date/DateService";
import { CompanyUserStatus } from "../models/companyusers/CompanyUserStatus";
import { Email } from "./invitation/InvitationService";
import { DateRange } from "../models/daterange/DateRange";
import { DayOffType } from "../models/daysOff/DayOffType";
import { Environment } from "../models/environment/Environment";

@Injectable()
export class ConfigService {
    constructor(protected environment: Environment) {}

    public apiVersion = 3;
    public versionParamName = "version";
    public signupHref = (domainExt = "io") =>
        `https://auth.timepot.${domainExt}/timepot/#/signup;ref=demo`;
    public apiKeyEndpoint = () => this.encodeUrl("/apiKey");
    public timesheetEndpoint = (userId: string, dateRange: DateRange) =>
        this.encodeUrl(`/users/${userId}/timesheet`, {
            from: DateService.toISOString(dateRange.from),
            to: DateService.toISOString(dateRange.to),
        });
    public taskActivitiesEndpoint = (taskId: string) =>
        this.encodeUrl(`/tasks/${taskId}/activities`);
    public activityEndpoint = (activityId: string) =>
        this.encodeUrl(`/activities/${activityId}`);
    public userDayTaskActivitiesEndpoint = () =>
        this.encodeUrl("/usersDayTaskActivities");
    public publicUserDayTaskActivitiesEndpoint = (reportId: string) =>
        this.encodeUrl(`/public/usersDayTaskActivities/${reportId}`);
    public projectsEndpoint = (active?: boolean) =>
        this.encodeUrl(`/projects`, { active: active });
    public projectEndpoint = (projectId: string) =>
        this.encodeUrl(`/projects/${projectId}`);
    public companyUsersEndpoint = (status = CompanyUserStatus.ACTIVE) =>
        this.encodeUrl(`/companyUsers`, { status: status });
    public companyUserEndpoint = (
        userId: string,
        status = CompanyUserStatus.ACTIVE
    ) => this.encodeUrl(`/companyUsers/${userId}`, { status: status });
    public currentCompanyEndpoint = () =>
        this.encodeUrl(`/users/currentCompany`);
    public userEndpoint = (userId: string) =>
        this.encodeUrl(`/users/${userId}`);
    public userCompaniesEndpoint = (
        userId: string,
        statuses = [CompanyUserStatus.ACTIVE]
    ) => this.encodeUrl(`/users/${userId}/companies`, { statuses: statuses });
    public invitationsEndpoint = (email: Email) =>
        this.encodeUrl(`/invitations`, { email: email.address });
    public invitationEndpoint = (userId: string) =>
        this.encodeUrl(`/invitations/${userId}`);
    public profileEndpoint = () => this.encodeUrl("/profile");
    public timezoneEndpoint = () => this.encodeUrl("/profile/timezone");
    public avatarEndpoint = (userId: string, withRandomParam = false) =>
        withRandomParam
            ? this.encodeUrl(
                  `/users/${userId}/avatar?randomParam=${Math.random()}&${
                      this.versionParamName
                  }=${this.apiVersion}`
              )
            : this.encodeUrl(
                  `/users/${userId}/avatar?${this.versionParamName}=${this.apiVersion}`
              );
    public daysOffEndpoint = (year: number, type: DayOffType) => {
        if (type) {
            return this.encodeUrl(`/daysOff`, { year: year, type: type });
        } else {
            return this.encodeUrl(`/daysOff`, { year: year });
        }
    };
    public userDaysOffEndpoint = (userId: string) =>
        this.encodeUrl(`/users/${userId}/daysOff`);
    public userDaysOffInRangeEndpoint = (
        userId: string,
        from: Date,
        to: Date,
        type: DayOffType
    ) => {
        if (type) {
            return this.encodeUrl(`/users/${userId}/daysOff`, {
                from: DateService.toISOString(from),
                to: DateService.toISOString(to),
                type: type,
            });
        } else {
            return this.encodeUrl(`/users/${userId}/daysOff`, {
                from: DateService.toISOString(from),
                to: DateService.toISOString(to),
            });
        }
    };
    public userSealingEndpoint = (userId: string) =>
        this.encodeUrl(`/users/${userId}/seal`);
    public taskEndpoint = (taskId: string) =>
        this.encodeUrl(`/tasks/${taskId}`);
    public tasksReportEndpoint = () => this.encodeUrl("/tasksReport");
    public publicTasksReportEndpoint = (reportId: string) =>
        this.encodeUrl(`/public/tasksReport/${reportId}`);
    public tasksPublicReportFiltersEndpoint = (reportId: string) =>
        this.encodeUrl(`/public/report/${reportId}/filters`);
    public reportShareEndpoint = () => this.encodeUrl("/tasksShareReport");
    public tasksReportCSVEndpoint = () =>
        this.encodeUrl(
            `/tasksCSVReport?${this.versionParamName}=${this.apiVersion}`
        );
    public tasksDailyDurationsReportEndpoint = () =>
        this.encodeUrl("/tasksDailyDurationsReport");
    public publicTasksDailyDurationsReportEndpoint = (reportId: string) =>
        this.encodeUrl(`/public/tasksDailyDurationsReport/${reportId}`);
    public userActivitiesDurationsEndpoint = () =>
        this.encodeUrl("/userActivitiesDurations");
    public publicUserActivitiesDurationsEndpoint = (reportId: string) =>
        this.encodeUrl(`/public/userActivitiesDurations/${reportId}`);
    public projectActivitiesDurationsEndpoint = () =>
        this.encodeUrl("/projectActivitiesDurations");
    public publicProjectActivitiesDurationsEndpoint = (reportId: string) =>
        this.encodeUrl(`/public/projectActivitiesDurations/${reportId}`);
    public projectTasksEndpoint = (projectId: string) =>
        this.encodeUrl(`/projects/${projectId}/tasks`);
    public tasksEndpoint = (
        projectId?: string,
        userId?: string,
        query?: string,
        offset?: number,
        limit?: number
    ) =>
        this.encodeUrl(`/tasks`, {
            project: projectId,
            user: userId,
            query: query,
            offset: offset,
            limit: limit,
        });
    public tasksCountEndpoint = (
        projectId?: string,
        userId?: string,
        query?: string
    ) =>
        this.encodeUrl(`/tasksCount`, {
            project: projectId,
            user: userId,
            query: query,
        });
    public tasksDurationEndpoint = (
        projectId?: string,
        userId?: string,
        query?: string
    ) =>
        this.encodeUrl(`/tasksDuration`, {
            project: projectId,
            user: userId,
            query: query,
        });
    public mostRecentProjectEndpoint = (userId: string) =>
        this.encodeUrl(`/users/${userId}/mostRecentProject`);
    public mostRecentProjectsEndpoint = (
        userId: string,
        includeImported: boolean
    ) =>
        this.encodeUrl(`/users/${userId}/mostRecentProjects`, {
            includeImported: includeImported,
        });
    public companySettingsEndpoint = () => this.encodeUrl(`/companySettings`);
    public countriesEndpoint = (
        query?: string,
        offset?: number,
        limit?: number
    ) =>
        this.encodeUrl(`/countries`, {
            query: query,
            offset: offset,
            limit: limit,
        });
    public countriesCountEndpoint = (query?: string) =>
        this.encodeUrl(`/countriesCount`, { query: query });
    public isEuropeanCountryEndpont = (countryCode: string) =>
        this.encodeUrl("/countries/european", { countryCode: countryCode });
    public signOutEndpoint = () => this.encodeUrl("/authToken");
    public signInEndpoint = () => this.encodeUrl("/authToken");
    public invitationCompaniesEndpoint = (invitationToken: string) =>
        this.encodeUrl("/invitations/companies", {
            invitationToken: invitationToken,
        });
    public companiesEndpoint = () => this.encodeUrl("/companies");
    signUpWithPasswordEndpoint = () => this.encodeUrl("/signupWithPassword");
    public signUpCompaniesEndpoint = (companyName: string) =>
        this.encodeUrl("/companies", { name: companyName });
    public signUpWithInvitationEndpoint = () =>
        this.encodeUrl("/signupWithPasswordAndInvitation");
    public sendResetPasswordEmailEndpoint = () =>
        this.encodeUrl("/sendResetPasswordEmail");
    public resetPasswordEndpoint = () => this.encodeUrl(`/resetPassword`);
    public notificationsEndpoint = () => this.encodeUrl(`/websocket`);
    public timerStartEndpoint = (userId: string) =>
        this.encodeUrl(`/users/${userId}/timer`);
    public monthlyBalanceEndpoint = () =>
        this.encodeUrl(`/financialReport/monthlyBalance`);
    public projectIncomeAndExpensesEndpoint = () =>
        this.encodeUrl(`/financialReport/projectIncomesAndExpenses`);
    public userPayment = (userId: string, date: string) =>
        this.encodeUrl(`/users/${userId}/payment`, { date: date });
    public clearUserPayment = (userId: string) =>
        this.encodeUrl(`/users/${userId}/payment`);
    public userPaymentHistory = (userId: string) =>
        this.encodeUrl(`/users/${userId}/paymentHistory`);
    public userHourlyRate = (userId: string) =>
        this.encodeUrl(`/users/${userId}/payment/hourlyRate`);
    public userHourlyRatePeriods = (userId: string) =>
        this.encodeUrl(`/users/${userId}/payment/hourlyRate/periods`);
    public userMonthlyRate = (userId: string) =>
        this.encodeUrl(`/users/${userId}/payment/monthlyRate`);
    public userMonthlyRatePeriods = (userId: string) =>
        this.encodeUrl(`/users/${userId}/payment/monthlyRate/periods`);
    public projectPricing = (projectCode: string, date: string) =>
        this.encodeUrl(`/projects/${projectCode}/pricing`, { date: date });
    public clearProjectPricing = (projectCode: string) =>
        this.encodeUrl(`/projects/${projectCode}/pricing`);
    public projectFixedPrice = (projectCode: string) =>
        this.encodeUrl(`/projects/${projectCode}/pricing/fixedPrice`);
    public projectHourlyRate = (projectCode: string) =>
        this.encodeUrl(`/projects/${projectCode}/pricing/hourlyRate`);
    public projectHourlyRatePeriod = (projectCode: string) =>
        this.encodeUrl(`/projects/${projectCode}/pricing/hourlyRate/periods`);
    public projectMonthlyRate = (projectCode: string) =>
        this.encodeUrl(`/projects/${projectCode}/pricing/monthlyRate`);
    public projectMonthlyRatePeriod = (projectCode: string) =>
        this.encodeUrl(`/projects/${projectCode}/pricing/monthlyRate/periods`);
    public projectPricingHistory = (projectCode: string) =>
        this.encodeUrl(`/projects/${projectCode}/pricingHistory`);
    public feedbackEnpoint = () => this.encodeUrl(`/feedback`);
    public activitiesTopic = (userCode: string) =>
        `/topic/activity/${userCode}`;
    public pdfReportEndpoint = () =>
        this.encodeUrl(
            `/pdfReport?${this.versionParamName}=${this.apiVersion}`
        );
    public logEndpoint = () => this.encodeUrl("/log/event");
    public teamsEndpoint = () => this.encodeUrl("/teams/");
    public teamEndpoint = (code: string) => this.encodeUrl(`/teams/${code}`);
    public companiesByFeature = {
        experimental: ["sparkbit", "localhost"],
    };
    public isProdMode() {
        return this.environment.production;
    }
    public isDesktop() {
        return this.environment.desktop;
    }

    protected encodeUrlQuery(queryObject, prefix?: string): string {
        const segments = [];

        for (const property in queryObject) {
            if (Object.prototype.hasOwnProperty.call(queryObject, property)) {
                const param = prefix ? prefix + "[" + property + "]" : property,
                    arg = queryObject[property];
                if (arg !== undefined && arg != null && arg !== "") {
                    const isArray = arg instanceof Array;
                    const encodedParam = encodeURIComponent(param);
                    const encodedArg = isArray
                        ? (arg as Array<string>)
                              .map(encodeURIComponent)
                              .join(",")
                        : encodeURIComponent(arg);
                    segments.push(
                        typeof arg === "object" && !isArray
                            ? this.encodeUrlQuery(arg, param)
                            : `${encodedParam}=${encodedArg}`
                    );
                }
            }
        }

        return segments.join("&");
    }

    protected encodeUrl(path: string, queryObject?): string {
        const query = this.encodeUrlQuery(queryObject);
        return (
            this.environment.backendUrl +
            path +
            (query.length > 0 ? "?" + query : "")
        );
    }
}
