import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import * as _ from "lodash";
import { Project } from "models/projects/Project";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { Action } from "../../genericmodal/GenericModalComponent";
import { CreateOrAddProjectRateComponent } from "./createoraddprojectrate/CreateOrAddProjectRateComponent";
import { ProjectPricingService } from "services/projects/ProjectPricingService";
import {
    emptyProjectPricing,
    ProjectPricing,
} from "models/projects/ProjectPricing";
import { ProjectPricingHistory } from "models/projects/ProjectPricingHistory";
import { ProjectPricingType } from "models/projects/ProjectPricingType";
import { I18NService } from "services/i18n/I18NService";
import { UserService } from "services/user/UserService";
import { projectsI18N } from "../i18n/projects-i18n";
import { filter, flatMap, map, tap } from "rxjs/operators";

@Component({
    selector: "project-pricing-modal",
    templateUrl: "project-pricing-modal.html",
    host: { "[class.c-project-pricing-modal]": "true" },
})
export class ProjectPricingModalComponent implements OnInit {
    @Input() public projectStream: Observable<Project>;
    @Input() public showStream: Subject<boolean>;
    @ViewChild(CreateOrAddProjectRateComponent)
    public createOrAddRateComponent: CreateOrAddProjectRateComponent;
    public actionStream = new Subject<Action>();
    public currentPricing: ProjectPricing;
    public noInitialPricing: boolean;
    public pricingHistory: ProjectPricingHistory;
    public isShowingHistory: boolean;
    public isHistoryTabAvailable: boolean;
    public ready: boolean;
    public users: CompanyUser[] = [];
    public i18n;
    public project: Project;

    public get isValid() {
        return (
            this.createOrAddRateComponent &&
            this.createOrAddRateComponent.isValid
        );
    }

    constructor(
        i18nService: I18NService,
        private projectPricingService: ProjectPricingService,
        private userService: UserService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectsI18N);
    }

    public ngOnInit() {
        this.projectStream
            .pipe(
                filter(_.identity),
                tap(() => (this.ready = false)),
                flatMap((p) => {
                    this.isShowingHistory = false;
                    this.project = p;
                    this.showStream.next(true);
                    return this.projectPricingService.getProjectPricing(
                        this.project.id
                    );
                }),
                flatMap((pricing) => {
                    if (
                        pricing &&
                        pricing.type !== ProjectPricingType.FIXED_PRICE
                    ) {
                        return this.projectPricingService
                            .getProjectPricingHistory(this.project.id)
                            .pipe(map((history) => [pricing, history]));
                    } else {
                        return of([pricing]);
                    }
                })
            )
            .subscribe(
                ([pricing, history]: [
                    ProjectPricing,
                    ProjectPricingHistory
                ]) => {
                    const projectPricing = pricing;
                    this.currentPricing =
                        projectPricing === undefined
                            ? emptyProjectPricing()
                            : projectPricing;
                    this.noInitialPricing = !pricing;
                    this.pricingHistory = history;
                    this.isHistoryTabAvailable =
                        projectPricing &&
                        projectPricing.type !== ProjectPricingType.FIXED_PRICE;
                    this.ready = true;
                }
            );
        this.userService
            .getUsersForCompany()
            .subscribe((u) => (this.users = u.companyUsers));
        this.actionStream.subscribe((action) => {
            if (action === Action.OK) {
                this.createOrAddRateComponent.save();
            } else if (action === Action.RESET) {
                this.projectPricingService
                    .clearProjectPricing(this.project.id)
                    .subscribe(() => {
                        this.currentPricing = emptyProjectPricing();
                        this.noInitialPricing = true;
                        this.isHistoryTabAvailable = false;
                    });
            }
            this.project = undefined;
        });
    }

    public onHistoryEdit = () => {
        this.projectPricingService
            .getProjectPricingHistory(this.project.id)
            .subscribe(
                (pricingHistory) => (this.pricingHistory = pricingHistory)
            );
    };
}
