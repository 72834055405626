import { loginI18NDe } from "./login-i18n-de";
import { loginI18NPl } from "./login-i18n-pl";
const loginI18NEn = {
    logIn: "Log in",
    withGoogle: "with Google",
    or: "or",
    yourEmail: "Your e-mail",
    password: "Password",
    passwordLoginFailed:
        "Log in failed. Please try again or log in with Google",
    forgotPassword: "Forgot password?",
    invalidEmailAddress: "Invalid email",
    required: "Required",
    unknownGoogleAccount:
        "Unknown Google account. Please try again with another account or log in with e-mail and password.",
};

export const loginI18N = {
    de: loginI18NDe,
    en: loginI18NEn,
    pl: loginI18NPl,
};
