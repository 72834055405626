export const dateI18NDe = {
    dayNames: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    monthNames: [
        "Jan",
        "Feb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
    ],
};
