import { ProjectActivitiesDuration } from "./ProjectActivitiesDuration";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const ProjectActivitiesDurations = z.object({
    durations: ProjectActivitiesDuration.array(),
});

export type ProjectActivitiesDurations = z.infer<
    typeof ProjectActivitiesDurations
>;
