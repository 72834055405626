export const tasksI18NPl = {
    pageTitle: "Zadania",
    project: "Projekt",
    task: "Zadanie",
    time: "Czas",
    users: "Użytkownicy",
    updated: "Aktualizacja",
    userSeparator: " + ",
    twoPeople(first, second) {
        return first + this.userSeparator + second;
    },
    manyPeople: (count) => count + " osoby",
    showMore: "Pokaż więcej",
    negated: (name) => "¬ " + name,
};
