import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class MessageService {
    private messageStream = new Subject<Message>();

    public addMessage(message: Message) {
        this.messageStream.next(message);
    }

    get messages(): Observable<Message> {
        return this.messageStream;
    }
}

export interface Message {
    text: string;
    type: MessageType;
}

export enum MessageType {
    SERVER_ERROR,
    CLIENT_ERROR,
}

export function createServerErrorMessage(message: string): Message {
    return { text: message, type: MessageType.SERVER_ERROR };
}

export function createClientErrorMessage(message: string): Message {
    return { text: message, type: MessageType.CLIENT_ERROR };
}
