import { taskDropdownI18nDe } from "./task-dropdown-i18n-de";
import { taskDropdownI18nPl } from "./task-dropdown-i18n-pl";
const taskDropdownI18nEn = {
    enterTaskPlaceholder: "Enter Task",
    tasks: "Tasks",
};

export const taskDropdownI18n = {
    de: taskDropdownI18nDe,
    pl: taskDropdownI18nPl,
    en: taskDropdownI18nEn,
};
