import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
    selector: "[showOverflownTitle]",
})
export class ShowOverflownTitleDirective implements OnInit {
    public static DEFAULT_TARGET_ATTR = "title";
    @Input() public showOverflownTitle: string | undefined = undefined;
    @Input() public titleForSelector: string;
    private elmt: HTMLElement;

    constructor(elmtRef: ElementRef) {
        this.elmt = <HTMLElement>elmtRef.nativeElement;
    }

    public ngOnInit() {
        const elmtWithText = this.getElementWithText();
        if (elmtWithText) {
            let target: string;
            if (
                this.showOverflownTitle &&
                this.showOverflownTitle.trim().length
            ) {
                target = this.showOverflownTitle;
            } else {
                target = ShowOverflownTitleDirective.DEFAULT_TARGET_ATTR;
            }
            this.elmt.addEventListener("mouseover", () => {
                const isOverflown =
                    elmtWithText.offsetWidth < elmtWithText.scrollWidth;
                if (isOverflown) {
                    const text =
                        elmtWithText.innerText || elmtWithText.textContent;
                    if (text && text.trim().length) {
                        this.elmt.setAttribute(target, text);
                    } else {
                        this.elmt.removeAttribute(target);
                    }
                }
            });
        }
    }

    private getElementWithText() {
        if (this.titleForSelector && this.titleForSelector.trim().length) {
            const elmtWithText = <HTMLElement>(
                this.elmt.querySelector(this.titleForSelector)
            );
            return elmtWithText;
        }
        return this.elmt;
    }
}
