import { Component, Input, OnChanges } from "@angular/core";
import { Subject } from "rxjs";
import { DateService } from "services/date/DateService";
import { I18NService } from "services/i18n/I18NService";
import { DateRange } from "models/daterange/DateRange";
import { DaysOff } from "models/daysOff/DaysOff";
import { User } from "models/users/User";
import { calendarI18N } from "../i18n/calendar-i18n";
import { Calendar } from "models/calendar/Calendar";
import { CalendarViewMode } from "models/calendar/CalendarViewMode";

@Component({
    selector: "month",
    templateUrl: "month.html",
    host: { "[class.c-month]": "true" },
})
export class MonthComponent implements OnChanges {
    @Input() public month: number; // 0-based
    @Input() public year: number;
    public mondays: Date[] = [];
    @Input() public daysOff: DaysOff;
    @Input() public currentUser: User;
    @Input() public currentSelection: DateRange;
    @Input() public calendarStateObservable: Subject<Calendar>;
    public i18n;

    constructor(private dateService: DateService, i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(calendarI18N);
    }
    public ngOnChanges() {
        const first = new Date(this.year, this.month, 1);
        let monday = this.dateService.getMondayOfWeek(first);
        this.mondays = [];
        do {
            this.mondays.push(monday);
            monday = new Date(monday.getTime());
            monday.setDate(monday.getDate() + 7);
        } while (this.isCurrentMonth(monday));
    }
    public isCurrentMonth(date: Date) {
        return date.getMonth() === this.month;
    }

    public showMonth() {
        this.calendarStateObservable.next({
            year: this.year,
            month: this.month,
            monday: this.dateService.getMondayOfWeek(
                new Date(this.year, this.month, 1)
            ),
            viewMode: CalendarViewMode.MONTH,
        });
    }
}
