<div class="c-calendar holidays-calendar" id="holidays-calendar">
    <usage-indicator
        [year]="year"
        [usedDaysObservable]="usedDaysObservable"
    ></usage-indicator>

    <div class="c-calendar__header">
        <div class="c-calendar__header-mode-switch">
            <selector
                [switches]="[
                    {text: i18n.week, label: calendarViewMode.WEEK, selected: viewMode === calendarViewMode.WEEK },
                    {text: i18n.month, label: calendarViewMode.MONTH, selected: viewMode === calendarViewMode.MONTH },
                    {text: i18n.year, label: calendarViewMode.YEAR, selected: viewMode === calendarViewMode.YEAR }
                ]"
                (selection)="switchCalendarView($event)"
                [inline]="true"
            ></selector>
        </div>

        <div class="c-calendar__header-title">
            <calendar-header
                [calendarStateObservable]="calendarStateObservable"
            ></calendar-header>
        </div>
        <div class="c-calendar__header-nav">
            <div class="c-pagination__wrapper-button">
                <div
                    class="c-pagination__button c-pagination__button--prev"
                    (click)="prev()"
                >
                    <svg>
                        <use
                            [attr.xlink:href]="'images/menu/all.svg#arrow'"
                        ></use></svg
                    >{{i18n.prev}}
                </div>
                <div
                    class="c-pagination__button c-pagination__button--next"
                    (click)="next()"
                >
                    {{i18n.next}}<svg>
                        <use
                            [attr.xlink:href]="'images/menu/all.svg#arrow'"
                        ></use>
                    </svg>
                </div>
            </div>
        </div>
    </div>

    <year
        *ngIf="viewMode === calendarViewMode.YEAR"
        [year]="year"
        [daysOff]="daysOff"
        [currentUser]="currentUser"
        [currentSelection]="currentSelection"
        [calendarStateObservable]="calendarStateObservable"
    ></year>

    <month-full
        *ngIf="viewMode === calendarViewMode.MONTH"
        [month]="month"
        [year]="year"
        [calendarStateObservable]="calendarStateObservable"
        [daysOff]="daysOff"
        [currentUser]="currentUser"
    ></month-full>

    <week-full
        *ngIf="viewMode === calendarViewMode.WEEK"
        [monday]="monday"
        [month]="month"
        [year]="year"
        [calendarStateObservable]="calendarStateObservable"
        [daysOff]="daysOff"
        [currentUser]="currentUser"
    ></week-full>

    <div class="c-calendar-configurator__day-off-types">
        <div
            class="c-calendar-configurator__day-off-type c-calendar-configurator__day-off-type--holiday"
            [ngClass]="{'c-calendar-configurator__day-off-type--active': isSelected('HOLIDAY')}"
            (click)="selectDayOffTypes(dayOffTypes.holiday)"
        >
            <span class="c-calendar-configurator__day-off-type-show"
                >{{i18n.showHolidays}}</span
            >
            <span class="c-calendar-configurator__day-off-type-hide"
                >{{i18n.hideHolidays}}</span
            >
        </div>
        <div
            class="c-calendar-configurator__day-off-type c-calendar-configurator__day-off-type--sick-day"
            [ngClass]="{'c-calendar-configurator__day-off-type--active': isSelected('SICK_LEAVE')}"
            (click)="selectDayOffTypes(dayOffTypes.sickLeave)"
        >
            <span class="c-calendar-configurator__day-off-type-show"
                >{{i18n.showSickDays}}</span
            >
            <span class="c-calendar-configurator__day-off-type-hide"
                >{{i18n.hideSickDays}}</span
            >
        </div>
    </div>
</div>
