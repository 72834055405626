import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { UserPaymentSnapshot } from "models/users/UserPaymentSnapshot";
import { UserPaymentType } from "models/users/UserPaymentType";
import { PricingDisplayService } from "services/pricingdisplay/PricingDisplayService";
import { UserPaymentService } from "services/user/UserPaymentService";
import { UserPaymentHistory } from "models/users/UserPaymentHistory";
import { I18NService } from "services/i18n/I18NService";
import { userPaymentI18N } from "../i18n/user-payment-i18n";

@Component({
    selector: "user-payment-history-entry",
    templateUrl: "user-payment-history-entry.html",
    host: { "[class.c-user-payment-history-entry]": "true" },
})
export class UserPaymentHistoryEntryComponent implements OnInit {
    @Input() public history: UserPaymentHistory;
    @Input() public snapshot: UserPaymentSnapshot;
    @Input() public currency: string;
    @Input() public paymentType: UserPaymentType;
    @Input() public userId: string;
    @Input() public workingHoursInDay: number;
    @Output() public save = new EventEmitter<void>();
    public i18n;
    public ready: boolean;
    public isInEditMode: boolean;
    public snapshotCopy: UserPaymentSnapshot;

    public selectedStartDateStream = new ReplaySubject<Date>();
    public selectedEndDateStream = new ReplaySubject<Date>();
    public startSubject = new ReplaySubject<Date>();
    public endSubject = new ReplaySubject<Date>();

    constructor(
        private pricingDisplayService: PricingDisplayService,
        private userPaymentService: UserPaymentService,
        i18nService: I18NService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(userPaymentI18N);
    }

    public ngOnInit() {
        this.snapshotCopy = { ...this.snapshot };

        if (this.snapshot.from) {
            this.selectedStartDateStream.next(this.snapshot.from);
        }
        if (this.snapshot.to) {
            this.selectedEndDateStream.next(this.snapshot.to);
        }

        this.startSubject.subscribe((from) => {
            this.snapshotCopy.from = from;
        });

        this.endSubject.subscribe((to) => {
            this.snapshotCopy.to = to;
        });
    }

    public getFormattedAmount(amount: number) {
        return this.pricingDisplayService.getFormattedAmount(
            this.paymentType === UserPaymentType.HOURLY_RATE,
            amount,
            this.currency
        );
    }

    public getFormattedDate(date: Date) {
        return this.pricingDisplayService.getFormattedDate(
            this.paymentType === UserPaymentType.HOURLY_RATE,
            date
        );
    }

    public editSnapshot() {
        this.isInEditMode = !this.isInEditMode;
    }

    public saveSnapshot() {
        this.isInEditMode = !this.isInEditMode;
        if (this.isSnapshotChanged(this.snapshot, this.snapshotCopy)) {
            let observable: Observable<void>;
            if (this.paymentType === UserPaymentType.HOURLY_RATE) {
                observable = this.userPaymentService.addHourlyRatePeriod(
                    this.userId,
                    this.snapshotCopy.amount,
                    this.snapshotCopy.from,
                    this.snapshotCopy.to
                );
            } else if (this.paymentType === UserPaymentType.MONTHLY_RATE) {
                observable = this.userPaymentService.addMonthlyRatePeriod(
                    this.userId,
                    this.snapshotCopy.amount,
                    this.workingHoursInDay,
                    this.snapshotCopy.from,
                    this.snapshotCopy.to
                );
            }
            observable.subscribe(() => {
                this.save.emit(null);
                this.snapshotCopy = { ...this.snapshot };
            });
            this.snapshotCopy = { ...this.snapshot };
        }
    }

    private isSnapshotChanged(snapshot, copy) {
        return snapshot.from !== copy.from || snapshot.to !== copy.to;
    }
}
