import { CurrentCompanyUserService } from "./CurrentCompanyUserService";
import { Injectable } from "@angular/core";
import { CompanyUser } from "../../models/companyusers/CompanyUser";

@Injectable()
export class CurrentCompanyUserViewService {
    constructor(private currentCompanyUserService: CurrentCompanyUserService) {}

    public get companyUser(): CompanyUser {
        return this.currentCompanyUserService.companyUser;
    }

    public get userId(): string {
        return this.companyUser && this.companyUser.userId;
    }

    public get isCompanyAdmin(): boolean {
        return this.companyUser && this.companyUser.admin;
    }

    public get isCompanyManager(): boolean {
        return this.companyUser && this.companyUser.manager;
    }

    public get shouldAskForDetails(): boolean {
        return this.companyUser && this.companyUser.askForDetails;
    }
}
