import { Component, Input } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { inviteI18N } from "./i18n/invite-i18n";
import { Action } from "../genericmodal/GenericModalComponent";

@Component({
    selector: "invite",
    templateUrl: "invite.html",
})
export class InviteComponent {
    @Input() public actionStream: Subject<Action> = new ReplaySubject<Action>(
        1
    );
    public i18n;

    constructor(private i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(inviteI18N);
    }
}
