import { pricingI18NDe } from "./pricing-i18n-de";
import { pricingI18NPl } from "./pricing-i18n-pl";

const pricingI18NEn = {
    hourlyRateFormat: (rate: number, currency: string) =>
        `${currency} ${rate}/h`,
    monthlyRateFormat: (rate: number, currency: string) =>
        `${currency} ${rate}/month`,
    noValuePlaceholder: "-",
};

export const pricingI18N = {
    de: pricingI18NDe,
    pl: pricingI18NPl,
    en: pricingI18NEn,
};
