import { ProjectPricingType } from "./ProjectPricingType";
import { UserRateOverride } from "./UserRateOverride";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const ProjectPricing = z.object({
    type: z.string().transform((typeStr) => ProjectPricingType[typeStr]),
    rate: z.number().transform((value) => value / 100),
    currency: z.string(),
    expectedWorkload: z.number().optional(),
    userOverrides: UserRateOverride.array().optional(),
    //
    //
    // public static copyOf(other: ProjectPricing) {
    //     return {
    //         type: other.type,
    //         rate: other.rate,
    //         expectedWorkload: other.expectedWorkload,
    //         currency: other.currency,
    //         userOverrides: other.userOverrides.slice()
    //     }
    // }
});

export type ProjectPricing = z.infer<typeof ProjectPricing>;

export function emptyProjectPricing(): ProjectPricing {
    return {
        type: undefined,
        rate: undefined,
        expectedWorkload: undefined,
        currency: undefined,
        userOverrides: [],
    };
}
