import { Component } from "@angular/core";
import { I18NService } from "services/i18n/I18NService";
import { Logger } from "services/logging/Logger";
import { ConsoleAppender } from "services/logging/ConsoleAppender";
import { BackendAppender } from "services/logging/BackendAppender";
import { IssueAgent, TimepotExceptionHandler } from "TimepotExceptionHandler";
import { PerfectScrollbarService } from "services/perfectscrollbar/PerfectScrollbarService";

@Component({
    selector: "timepot",
    template: "<router-outlet></router-outlet>",
    host: { "[class.c-timepot]": "true" },
})
export class TimepotComponent {
    constructor(
        private i18nService: I18NService,
        consoleAppender: ConsoleAppender,
        agent: IssueAgent,
        backendAppender: BackendAppender,
        private perfectScrollbar: PerfectScrollbarService
    ) {
        const availableLanguages = ["en", "pl", "de"];
        const defaultLanguage = "en";
        let preferredLanguage = i18nService.getPreferredLanguage();

        if (preferredLanguage === null) {
            preferredLanguage = defaultLanguage;
        }

        TimepotExceptionHandler.registerAgent(agent);
        Logger.configure([consoleAppender, backendAppender]);

        this.i18nService.configure({
            availableLanguages: availableLanguages,
            defaultLanguage: defaultLanguage,
            preferredLanguage: preferredLanguage,
        });

        this.perfectScrollbar.initScrollbar();
    }
}
