import { Component, Input, OnInit } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { Project } from "models/projects/Project";
import { Team } from "models/teams/Team";
import { I18NService } from "services/i18n/I18NService";
import { projectsI18N } from "../i18n/projects-i18n";
import { TimesheetReportService } from "services/timesheetreport/TimesheetReportService";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import { map } from "rxjs/operators";

@Component({
    selector: "projects-list",
    templateUrl: "projects-list.html",
    host: { "[class.c-projects-list]": "true" },
})
export class ProjectsListComponent implements OnInit {
    @Input() public projectModalStream: Subject<Project>;
    @Input() public projectsStream: Observable<Project[]>;
    @Input() public teamsStream: Observable<Team[]>;

    public projectActivitiesDurations: Map<string, number> = new Map<
        string,
        number
    >();
    public i18n;
    public projectForPricingStream = new ReplaySubject<Project>(1);
    public pricingModalShowStream = new ReplaySubject<boolean>(1);
    public page: Project[] = [];

    constructor(
        private reportService: TimesheetReportService,
        private i18nService: I18NService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectsI18N);
    }

    public onSelectedForPricing(project: Project) {
        this.projectForPricingStream.next(project);
    }

    public ngOnInit() {
        this.reportService
            .getProjectActivitiesDurations(new FilterSettings([]))
            .pipe(map((durations) => durations.durations))
            .subscribe((durations) => {
                for (const duration of durations) {
                    this.projectActivitiesDurations.set(
                        duration.code,
                        duration.duration
                    );
                }
            });
    }

    public onPageChanged(page: Project[]) {
        this.page = page;
    }
}
