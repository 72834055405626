import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { ClickService } from "services/click/ClickService";
import { I18NService } from "services/i18n/I18NService";
import { reportFilterI18N } from "../../../i18n/reportfilter-i18n";

@Component({
    selector: "year-month-picker-popdown",
    templateUrl: "year-month-picker-popdown.html",
})
export class YearMonthPickerPopdownComponent implements OnInit, OnDestroy {
    @Input() public selectedYearMonth: Date;
    @Input() public selectedYearMonthSubject: Subject<Date>;
    @Input() public rangeStart: boolean;
    public i18n;
    public open: boolean;

    private outsideClicksSubscription: Subscription;

    constructor(
        i18nService: I18NService,
        private elmtRef: ElementRef,
        private clickService: ClickService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(reportFilterI18N);
    }

    public ngOnInit() {
        this.outsideClicksSubscription = this.clickService
            .getOutsideNodeClicksStream(<Node>this.elmtRef.nativeElement)
            .subscribe(() => (this.open = false));
    }

    public ngOnDestroy() {
        if (this.outsideClicksSubscription) {
            this.outsideClicksSubscription.unsubscribe();
        }
    }
    public get selectedYearMonthString(): string {
        if (this.selectedYearMonth) {
            return (
                this.i18n.monthShortNames[this.selectedYearMonth.getMonth()] +
                " " +
                this.selectedYearMonth.getFullYear()
            );
        }
        return "";
    }

    @HostListener("click")
    public doOpen() {
        this.open = true;
    }
}
