import { userBreakdownReportI18NPl } from "./userBreakdownReport-i18n-pl";
import { userBreakdownReportI18NDe } from "./userBreakdownReport-i18n-de";

const userBreakdownReportI18NEn = {
    user: "User",
    date: "Date",
    time: "Time",
    tasks: "Tasks",
    total: (num: number) => {
        return num + " total";
    },
};

export const userBreakdownReportI18N = {
    de: userBreakdownReportI18NDe,
    en: userBreakdownReportI18NEn,
    pl: userBreakdownReportI18NPl,
};
