import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    ReplaySubject,
    Subject,
} from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { TimesheetReportService } from "services/timesheetreport/TimesheetReportService";
import { TimeUnitConversionService } from "services/timeunitconversion/TimeUnitConversionService";
import { timesheetReportsI18N } from "./i18n/timesheet-reports-i18n";
import { BarChartConfig } from "../charts/barchart/BarChartComponent";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import { TasksReport } from "models/reports/tasks/TasksReport";
import { BarChartData } from "models/charts/BarChartData";
import { DailyTimeReport } from "models/reports/dailytime/DailyTimeReport";
import { ChartEntry } from "../charts/common/ChartEntry";
import { BarchartDataService } from "../../services/barchart/BarchartDataService";
import {
    DateFilterSetting,
    FilterSetting,
    FilterType,
} from "models/reports/filters/FilterSetting";
import { ProjectService } from "services/projects/ProjectService";
import { Project } from "models/projects/Project";
import { UserActivitiesDuration } from "models/reports/useractivities/UserActivitiesDuration";
import { ProjectActivitiesDuration } from "models/reports/projectactivities/ProjectActivitiesDuration";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { PdfReportService } from "services/pdfreport/PdfReportService";
import { ReportLink } from "models/reports/link/ReportLink";
import {
    filter,
    flatMap,
    map,
    merge,
    publishReplay,
    refCount,
} from "rxjs/operators";

export const PARAMETER_PROJECT = "project";
export const PARAMETER_USER = "user";

@Component({
    selector: "reports",
    templateUrl: "timesheet-reports.html",
    host: { "[class.c-reports]": "true" },
})
export class TimesheetReportsComponent implements OnInit {
    private static CHART_COLORS = [
        "#41264e",
        "#26eca5",
        "#813ea6",
        "#2c1bbe",
        "#2ccc22",
        "#2c96de",
        "#00bc9b",
        "#1fce6d",
        "#f2c500",
        "#e87e04",
        "#ea4b35",
        "#ea4b35",
        "#ea4b35",
    ];

    private static PROJECT_COLORS = {
        red: "#EA4B35",
        orange: "#E87E04",
        yellow: "#F2C500",
        violet: "#9C56B9",
        green: "#1FCE6D",
        turquoise: "#00BC9B",
        blue: "#2C96DE",
    };

    public donutChartDisplayed = true;
    public i18n;
    public showLinkStream = new Subject<boolean>();
    public reportShareLink: string;
    public userBreakdownDisplayed = false;
    private numberOfTasks = 0;

    private displayedContent: DisplayedContent =
        DisplayedContent.PROJECTS_DIAGRAM;

    //used in template
    public displayedContentEnum = DisplayedContent;
    public filterSubject: ReplaySubject<FilterSettings>;
    public tasksListObservable: Observable<TasksReport>;

    public dailyTimeObservable: Observable<BarChartData<FilterSetting>>;
    private displayedContentChanged: Subject<void>;

    private chartDataObservable: Observable<ChartEntry<FilterSetting>[]>;
    private filterSettingsStream = new Subject<FilterSetting>();
    private filterSettings: FilterSettings;

    public chartConfig;
    public chartTotalHours: string;
    public chartVisible = true;
    public isChartEmpty: boolean;
    public publicReportId: string;

    public keysI18N: { [chartKey: string]: string } = {
        donutchartKey: "projectLabel",
        barchartKey: "projectName",
    };

    private routeProject: string;
    private isCompanyAdmin: boolean;
    private isCompanyManager: boolean;

    private static createUserChartEntry(
        userActivitiesDuration: UserActivitiesDuration,
        index: number
    ): ChartEntry<FilterSetting> {
        return {
            id: index.toString(),
            label: userActivitiesDuration.name,
            minutes: userActivitiesDuration.duration,
            colorHash: TimesheetReportsComponent.getChartColorForIndex(index),
            data: new FilterSetting(
                FilterType.USER,
                userActivitiesDuration.code,
                userActivitiesDuration.name
            ),
        };
    }

    private static createProjectChartEntry(
        projectActivitiesDuration: ProjectActivitiesDuration,
        index: number
    ): ChartEntry<FilterSetting> {
        return {
            id: index.toString(),
            label: projectActivitiesDuration.projectName,
            minutes: projectActivitiesDuration.duration,
            colorHash: TimesheetReportsComponent.getProjectColor(
                projectActivitiesDuration.projectColor,
                index
            ),
            data: new FilterSetting(
                FilterType.PROJECT,
                projectActivitiesDuration.code,
                projectActivitiesDuration.projectName
            ),
        };
    }

    private static getChartColorForIndex(index: number): string {
        const chartColorsCount = TimesheetReportsComponent.CHART_COLORS.length;
        return TimesheetReportsComponent.CHART_COLORS[index % chartColorsCount];
    }

    private static getProjectColor(name: string, index: number): string {
        if (TimesheetReportsComponent.PROJECT_COLORS[name.toLowerCase()]) {
            return TimesheetReportsComponent.PROJECT_COLORS[name.toLowerCase()];
        } else {
            return TimesheetReportsComponent.getChartColorForIndex(index);
        }
    }

    public shareReport() {
        this.reportService
            .shareReport(this.filterSettings)
            .subscribe((link: ReportLink) => {
                this.reportShareLink = link.url;
                this.showLinkStream.next(true);
            });
    }

    constructor(
        private barchartDataService: BarchartDataService,
        private i18nService: I18NService,
        private timeUnitConversionService: TimeUnitConversionService,
        private projectService: ProjectService,
        private reportService: TimesheetReportService,
        private router: Router,
        private route: ActivatedRoute,
        private pdfReportService: PdfReportService,
        currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {
        this.publicReportId = this.route.snapshot.paramMap.get("reportId");
        if (!this.publicReportId) {
            this.isCompanyAdmin =
                currentCompanyUserViewService.companyUser.admin;
            this.isCompanyManager =
                currentCompanyUserViewService.companyUser.manager;
        }
        this.i18n =
            this.i18nService.extractCurrentTranslation(timesheetReportsI18N);
        this.filterSubject = new ReplaySubject<FilterSettings>(1);
        this.displayedContentChanged = new BehaviorSubject<void>(null);
        this.chartConfig = new BarChartConfig(
            (value) => value + this.i18n.hours,
            (value) => value / 60,
            (value) =>
                this.timeUnitConversionService.minutesToFormattedText(value)
        );

        const buildChartDataObservable: ChartDataObservableBuilder = <
            T extends ActivitiesDuration
        >(
            handledContent: DisplayedContent,
            dataProvider: DurationsDataProvider<T>,
            mapper: ChartEntryMapper<T>
        ) => {
            return combineLatest([
                this.filterSubject,
                this.displayedContentChanged,
            ]).pipe(
                filter(() => this.displayedContent === handledContent),
                flatMap(([filterSettings]: [FilterSettings, void]) =>
                    dataProvider(
                        this.publicReportId
                            ? this.publicReportId
                            : filterSettings
                    )
                ),
                map((data: DurationsData<T>) => data.durations.map(mapper))
            );
        };

        let dailyReportsObservable: Observable<DailyTimeReport>;
        let userActivitiesChartObservable: Observable<
            ChartEntry<FilterSetting>[]
        >;
        let projectActivitiesChartObservable: Observable<
            ChartEntry<FilterSetting>[]
        >;

        if (this.publicReportId) {
            this.tasksListObservable = this.filterSubject.pipe(
                flatMap(() =>
                    reportService.getPublicTaskReport(this.publicReportId)
                ),
                publishReplay(1),
                refCount()
            );
            dailyReportsObservable = this.filterSubject.pipe(
                flatMap(() =>
                    reportService.getPublicDailyTimeReport(this.publicReportId)
                ),
                publishReplay(1),
                refCount()
            );
            userActivitiesChartObservable = buildChartDataObservable(
                DisplayedContent.USERS_DIAGRAM,
                this.reportService.getPublicUserActivitiesDurations.bind(
                    this.reportService
                ),
                TimesheetReportsComponent.createUserChartEntry
            );
            projectActivitiesChartObservable = buildChartDataObservable(
                DisplayedContent.PROJECTS_DIAGRAM,
                this.reportService.getPublicProjectActivitiesDurations.bind(
                    this.reportService
                ),
                TimesheetReportsComponent.createProjectChartEntry
            );
        } else {
            this.tasksListObservable = this.filterSubject.pipe(
                flatMap((filterSettings: FilterSettings) =>
                    reportService.getTasksReport(filterSettings)
                ),
                publishReplay(1),
                refCount()
            );
            dailyReportsObservable = this.filterSubject.pipe(
                flatMap((filterSettings: FilterSettings) =>
                    reportService.getDailyTimeReport(filterSettings)
                ),
                publishReplay(1),
                refCount()
            );
            userActivitiesChartObservable = buildChartDataObservable(
                DisplayedContent.USERS_DIAGRAM,
                this.reportService.getUserActivitiesDurations.bind(
                    this.reportService
                ),
                TimesheetReportsComponent.createUserChartEntry
            );
            projectActivitiesChartObservable = buildChartDataObservable(
                DisplayedContent.PROJECTS_DIAGRAM,
                this.reportService.getProjectActivitiesDurations.bind(
                    this.reportService
                ),
                TimesheetReportsComponent.createProjectChartEntry
            );
        }
        this.dailyTimeObservable = dailyReportsObservable.pipe(
            map(this.mapDailyReport)
        );

        dailyReportsObservable.subscribe((dailyReports) => {
            this.isChartEmpty = !dailyReports.dailyDurations.length;
            const totalMinutes = dailyReports.dailyDurations.reduce(
                (acc, d) => acc + d.duration,
                0
            );
            this.chartTotalHours =
                this.chartConfig.valueFormatter(totalMinutes);
        });

        this.chartDataObservable = userActivitiesChartObservable.pipe(
            merge(projectActivitiesChartObservable)
        );
        this.handleFilters();

        this.tasksListObservable.subscribe((tasksReport) => {
            this.numberOfTasks = tasksReport.tasks.length;
        });
    }

    private mapDailyReport = (
        dailyTimeReport: DailyTimeReport
    ): BarChartData<FilterSetting> => {
        return new BarChartData<FilterSetting>(
            this.barchartDataService.aggregateBasedOnRange(
                dailyTimeReport.dailyDurations,
                (d) => d.date,
                (d) => d.duration,
                (d1, d2) => new DateFilterSetting(d1, d2)
            )
        );
    };

    private handleFilters() {
        this.filterSubject.subscribe((f) => {
            this.filterSettings = f;
        });
        this.filterSettingsStream.subscribe((newSetting) => {
            if (!this.publicReportId) {
                const newSettings = this.filterSettings.settings.filter(
                    (setting) => setting.filterType !== newSetting.filterType
                );
                newSettings.push(newSetting);
                this.filterSubject.next(new FilterSettings(newSettings));
            }
        });
    }

    public ngOnInit() {
        if (this.publicReportId) {
            this.reportService
                .getPublicReportTaskFilterView(this.publicReportId)
                .subscribe((filterView) => {
                    const fs: FilterSetting[] = filterView.filterDisplays.map(
                        (display) => FilterSetting.displayOnly(display)
                    );
                    this.filterSubject.next(new FilterSettings(fs));
                });
        } else {
            this.route.params.subscribe((params) => {
                const newProject = params[PARAMETER_PROJECT];
                if (newProject) {
                    this.routeProject = newProject;
                    this.initProjectFilterSettings(this.routeProject);
                    this.router.navigate(["auth", "reports"]);
                } else if (!this.routeProject && !newProject) {
                    this.filterSubject.next(
                        this.reportService.getDefaultFilterSettings()
                    );
                }
            });
        }
    }

    private initProjectFilterSettings(projectName: string) {
        const filterSettings = this.reportService.getDefaultFilterSettings();
        this.projectService
            .getProject(projectName)
            .subscribe((project: Project) => {
                if (project) {
                    filterSettings.settings = this.isCompanyManager
                        ? []
                        : filterSettings.settings.filter(
                              (setting) =>
                                  setting.filterType === FilterType.USER
                          );
                    filterSettings.settings.push(
                        new FilterSetting(
                            FilterType.PROJECT,
                            project.id,
                            project.name
                        )
                    );
                }
                this.filterSubject.next(filterSettings);
            });
    }

    public switchToUsers(): void {
        this.setDisplayedContent(DisplayedContent.USERS_DIAGRAM);

        /* eslint-disable @typescript-eslint/dot-notation */
        this.keysI18N["donutchartKey"] = "userLabel";
        this.keysI18N["barchartKey"] = "username";
        /* eslint-enable @typescript-eslint/dot-notation */
    }

    public switchToProjects(): void {
        this.setDisplayedContent(DisplayedContent.PROJECTS_DIAGRAM);
        this.userBreakdownDisplayed = false;

        /* eslint-disable @typescript-eslint/dot-notation */
        this.keysI18N["donutchartKey"] = "projectLabel";
        this.keysI18N["barchartKey"] = "projectName";
        /* eslint-enable @typescript-eslint/dot-notation */
    }

    public switchToTasks(): void {
        this.setDisplayedContent(DisplayedContent.TASKS_LIST);
        this.userBreakdownDisplayed = false;
    }

    public displayDonutChart(): void {
        this.donutChartDisplayed = true;
        this.userBreakdownDisplayed = false;
    }

    public displayUserBreakdown(): void {
        this.userBreakdownDisplayed = true;
        this.donutChartDisplayed = false;
    }

    public displayBarChartHorizontal(): void {
        this.donutChartDisplayed = false;
        this.userBreakdownDisplayed = false;
    }

    private setDisplayedContent(newDisplayedContent: DisplayedContent) {
        const changeOccurred = this.displayedContent !== newDisplayedContent;
        this.displayedContent = newDisplayedContent;

        if (changeOccurred) {
            this.displayedContentChanged.next(null);
        }
    }

    public hasTasks(): boolean {
        return this.numberOfTasks > 0;
    }

    public toggleGraph(): void {
        this.chartVisible = !this.chartVisible;
    }

    public getCSVReportLink(): string {
        return this.reportService.getCSVTaskReportLink(this.filterSettings);
    }

    public getPdfReportLink() {
        return this.pdfReportService.getPdfReportLink(this.filterSettings);
    }
}

export enum DisplayedContent {
    USERS_DIAGRAM,
    PROJECTS_DIAGRAM,
    TASKS_LIST,
}

type ActivitiesDuration = ProjectActivitiesDuration | UserActivitiesDuration;

interface DurationsData<T extends ActivitiesDuration> {
    durations: T[];
}

type DurationsDataProvider<T extends ActivitiesDuration> = (
    filter: FilterSettings | string
) => Observable<DurationsData<T>>;

type ChartEntryMapper<T> = (t: T, index: number) => ChartEntry<FilterSetting>;

type ChartDataObservableBuilder = <T extends ActivitiesDuration>(
    handledContent: DisplayedContent,
    dataProvider: DurationsDataProvider<T>,
    mapper: ChartEntryMapper<T>
) => Observable<ChartEntry<FilterSetting>[]>;
