import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";
import { DateRange } from "models/daterange/DateRange";
import { DaysOff } from "models/daysOff/DaysOff";
import { User } from "models/users/User";
import { Calendar } from "models/calendar/Calendar";

@Component({
    selector: "year",
    templateUrl: "year.html",
    host: { "[class.c-year]": "true" },
})
export class YearComponent {
    @Input() public year: number;
    @Input() public daysOff: DaysOff;
    @Input() public currentUser: User;
    @Input() public currentSelection: DateRange;
    @Input() public calendarStateObservable: Subject<Calendar>;
}
