export const inviteI18NDe = {
    firstEmailAddressPlaceholder: "E-Mail-Adresse eingeben",
    nextEmailAddressPlaceholder: "Nächste E-Mail-Adresse eingeben",
    invalidEmailAddress: "Ungültige E-Mail",
    usedEmailAddress: "Bereits eingeladen",
    required: "Erforderlich",
    nonUnique: "E-Mail doppelt vorhanden",
    sendInvitations: "Einladungen senden",
    tip: "E-Mail-Adresse jeder Person eingeben",
    title: "Benutzer zu Ihrem Timepot hinzufügen",
    ok: "Einladungen senden",
    add: "Hinzufügen",
};
