export const inviteI18NPl = {
    firstEmailAddressPlaceholder: "Wpisz adres e-mail",
    nextEmailAddressPlaceholder: "Wpisz kolejny adres e-mail",
    invalidEmailAddress: "Niepoprawny e-mail",
    usedEmailAddress: "Wykorzystany e-mail",
    required: "Wymagane pole",
    nonUnique: "Powtórzony e-mail",
    sendInvitations: "wyślij zaproszenia",
    tip: "Wpisz adres e-mail każdej z osób:",
    title: "Dodaj użytkowników do Timepota",
    ok: "wyślij zaproszenia",
    add: "Dodaj",
};
