<div *ngIf="filterSettings">
    <span
        *ngFor="let filterSetting of filterSettings.settings"
        class="c-filter-display-field__filter"
        [ngClass]="{'c-filter-display-field__filter--readonly': filterSetting.readOnly}"
    >
        {{filterSetting.filterDisplay}}
        <a
            *ngIf="!filterSetting.readOnly"
            (click)="deleteFilterSetting(filterSetting)"
            class="c-filter-display-field__filter-cancel"
        ></a>
    </span>
    <div
        *ngIf="filterSettings.settings.length === 0"
        class="c-filter-display-field__message"
    >
        {{i18n.noFilters}}
    </div>
</div>
