export const timesheetReportsI18NDe = {
    pageTitle: "Berichte",
    projects: "Projekte",
    users: "Benutzer",
    tasks: "Aufgaben",
    noResults: "Keine Ergebnisse für aktuelle Filter",
    hideGraph: "Diagramm ausblenden",
    hours: "Std.",
    noData: "Keine Daten",
    showGraph: "Diagramm anzeigen",
    total: (time: string) => `Gesamt: ${time}`,
    csv: "CSV",
    pdf: "PDF",
    share: "Öffentlicher Link",
    yourLink: "Öffentlicher Link des Berichts:",
    copyToClipboard: "in die Zwischenablage kopieren",
    all: "Alles",
    billable: "Abrechenbar",
    nonBillable: "Nicht abrechenbar",
    publicLinkDesc: "Nur der abrechenbare Teil der Daten wird exportiert.",
};
