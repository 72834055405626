import { Component, Input, OnInit } from "@angular/core";
import { combineLatest, Observable, of, ReplaySubject, Subject } from "rxjs";
import * as _ from "lodash";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { Team } from "models/teams/Team";
import { Action } from "../../genericmodal/GenericModalComponent";
import { TeamService } from "services/teams/TeamService";
import { filter, flatMap, tap } from "rxjs/operators";

@Component({
    selector: "user-teams-modal",
    templateUrl: "user-teams-modal.html",
    host: {
        "[class.c-user-teams-modal]": "true",
    },
})
export class UserTeamsModalComponent implements OnInit {
    @Input() public user: CompanyUser;
    @Input() public showStream: Subject<boolean>;

    public actionStream = new Subject<Action>();
    public noInitialPaymentType: boolean;
    public ready = false;
    public i18n;
    public isShowingHistory: boolean;
    public isHistoryTabAvailable: boolean;
    public userTeamsStream: Observable<Team[]>;
    public teamsStream = new ReplaySubject<Team[]>(1);
    private selectedTeams: Team[] = [];
    private allTeams: Team[] = [];

    constructor(private teamService: TeamService) {}

    public ngOnInit(): void {
        this.showStream
            .pipe(
                filter(_.identity),
                tap(() => (this.ready = false)),
                flatMap(() => this.teamService.getTeams())
            )
            .subscribe((teams) => {
                this.selectedTeams = this.user.teams.slice();
                this.userTeamsStream = of(this.selectedTeams);
                this.allTeams = teams;
                this.teamsStream.next(teams);
                this.ready = true;
            });

        this.actionStream.subscribe((action) => {
            if (action === Action.OK) {
                const updatedTeams =
                    this.teamService.calculateUpdatedTeamsForUser(
                        this.user.id,
                        this.user.teams.map((t) => t.code),
                        this.selectedTeams.map((t) => t.code),
                        this.allTeams
                    );
                combineLatest(
                    updatedTeams.map((team) =>
                        this.teamService.updateTeam(team)
                    )
                ).subscribe(() => {
                    this.user.teams = this.selectedTeams;
                    this.showStream.next(false);
                });
            }
        });
    }
    public removeFromSelection(team: Team): void {
        const idx = this.selectedTeams.findIndex((t) => t.code === team.code);
        this.selectedTeams.splice(idx, 1);
    }

    public onNewTeams(teams: Team[]) {
        this.selectedTeams = teams;
    }
}
