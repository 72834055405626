import { Injectable } from "@angular/core";
import { dateI18N } from "./i18n/date-i18n";
import { I18NService } from "../i18n/I18NService";
import { DateRange } from "../../models/daterange/DateRange";

@Injectable()
export class DateService {
    private i18n;
    private DAY_NAMES;
    private MONTH_NAMES;

    constructor(private i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(dateI18N);
        this.DAY_NAMES = this.i18n.dayNames;
        this.MONTH_NAMES = this.i18n.monthNames;
    }

    public static toDate(dateString: string): Date {
        try {
            const parts = dateString.split("-");
            return new Date(
                parseInt(parts[0], 10),
                parseInt(parts[1], 10) - 1,
                parseInt(parts[2], 10)
            );
        } catch (e) {
            return undefined;
        }
    }
    public static toISOString(date: Date): string {
        const yearStr = "" + date.getFullYear();
        const month = date.getMonth() + 1; //months are 0-based
        const monthStr = month < 10 ? "0" + month : "" + month;
        const day = date.getDate();
        const dayStr = day < 10 ? "0" + day : "" + day;
        return yearStr + "-" + monthStr + "-" + dayStr;
    }
    public static toYearMonthString(date: Date): string {
        const yearStr = "" + date.getFullYear();
        const month = date.getMonth() + 1; //months are 0-based
        const monthStr = month < 10 ? "0" + month : "" + month;
        return yearStr + "-" + monthStr;
    }
    public prettyDate(date: Date): string {
        const dateString =
            this.MONTH_NAMES[date.getMonth()] + " " + date.getDate();
        const dayName = this.DAY_NAMES[date.getDay()];
        return dateString + " " + dayName;
    }
    public prettyMonth(date: Date): string {
        return this.MONTH_NAMES[date.getMonth()];
    }
    public prettyMonthAndYear(date: Date): string {
        return this.prettyMonth(date) + " " + date.getFullYear();
    }
    public isWeekDay(date: Date): boolean {
        return !(date.getDay() === 6 || date.getDay() === 0);
    }
    public isToday(date: Date): boolean {
        return this.isEqual(new Date(), date);
    }
    public isEqual(date1: Date, date2: Date): boolean {
        return (
            this.stripTime(date1).getTime() === this.stripTime(date2).getTime()
        );
    }
    public isYesterday(date: Date): boolean {
        return this.isEqual(new Date(), this.addDays(date, 1));
    }
    public getDateSpan(from: Date, to: Date): Date[] {
        let strippedFrom = this.stripTime(from);
        const strippedTo = this.stripTime(to);
        const days: Date[] = [];

        while (!this.isAfter(strippedFrom, strippedTo)) {
            days.push(new Date(strippedFrom.getTime()));
            strippedFrom = this.addDays(strippedFrom, 1);
        }

        return days;
    }
    public getWeek(from: Date): Date[] {
        const to = this.stripTime(new Date(from.getTime()));
        to.setDate(to.getDate() + 6);
        return this.getDateSpan(from, to);
    }
    public getWeekStartingOnMonday(date: Date): Date[] {
        return this.getWeek(this.getMondayOfWeek(date));
    }
    public getMondayOfWeek(date: Date): Date {
        const dayOfWeek = date.getDay(); // 0 is sunday
        const offset = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
        const tmp = new Date(date.getTime());
        tmp.setDate(tmp.getDate() - offset);
        return tmp;
    }
    public getSundayOfWeek(date: Date): Date {
        const tmp = this.getMondayOfWeek(date);
        tmp.setDate(tmp.getDate() + 6);
        return tmp;
    }
    public getMondayOfLastWeek(date: Date): Date {
        const tmpDay = new Date(date.getTime());
        tmpDay.setDate(tmpDay.getDate() - 7);
        return this.getMondayOfWeek(tmpDay);
    }
    public getDayDifferenceRoundedUp(start: Date, end: Date) {
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        const timeDiff = Math.abs(end.getTime() - start.getTime());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }

    public isInDateRange(date: Date, dateRange: DateRange): boolean {
        return this.isInRange(date, dateRange.from, dateRange.to);
    }

    public stripTime(date: Date): Date {
        if (date) {
            const strippedDate = new Date(date.getTime());
            strippedDate.setHours(0, 0, 0, 0);
            return strippedDate;
        } else {
            return date;
        }
    }

    public isInRange(date: Date, start: Date, end: Date): boolean {
        const strippedDate = this.stripTime(date);
        const strippedStart = this.stripTime(start);
        const strippedEnd = this.stripTime(end);

        return strippedStart <= strippedDate && strippedDate <= strippedEnd;
    }

    public isBefore(date1: Date, date2: Date): boolean {
        return date1.getTime() < date2.getTime();
    }

    public isAfter(date1: Date, date2: Date): boolean {
        return date1.getTime() > date2.getTime();
    }

    public min(date1: Date, date2: Date): Date {
        return this.isBefore(date1, date2) ? date1 : date2;
    }

    public max(date1: Date, date2: Date): Date {
        return this.isAfter(date1, date2) ? date1 : date2;
    }

    public convertToRange(startStr: string, endStr: string): DateRange {
        return new DateRange(
            DateService.toDate(startStr),
            DateService.toDate(endStr)
        );
    }

    public isValidWeekRange(range: DateRange): boolean {
        if (!range || !range.from || !range.to) {
            return false;
        }
        const [from, to] = [
            this.stripTime(range.from),
            this.stripTime(range.to),
        ];
        const week = this.getWeekStartingOnMonday(from);
        return this.isEqual(from, week[0]) && this.isEqual(to, week[6]);
    }
    public isValidRange(range: DateRange, difference: number): boolean {
        return (
            range &&
            range.from &&
            range.to &&
            this.getDayDifferenceRoundedUp(range.from, range.to) === difference
        );
    }
    public getNextDay(day: Date): Date {
        return this.addDays(day, 1);
    }
    public getPreviousDay(day: Date): Date {
        return this.addDays(day, -1);
    }

    public addDays(day: Date, daysCount: number): Date {
        const result = new Date(day.getTime());
        result.setDate(result.getDate() + daysCount);

        return result;
    }

    public getFirstDayOfMonth(date: Date): Date {
        const month = date.getMonth();
        const year = date.getFullYear();

        return new Date(year, month, 1);
    }
    public getLastDayOfMonth(date: Date): Date {
        const month = date.getMonth();
        const year = date.getFullYear();

        return new Date(year, month + 1, 0);
    }
    public getDayFromWeekAgoOf(day: Date): Date {
        const dayWeekAgo = new Date(day.getTime());
        dayWeekAgo.setDate(dayWeekAgo.getDate() - 7);

        return dayWeekAgo;
    }
    public getDayFromNextWeekOf(day: Date): Date {
        const dayNextWeek = new Date(day.getTime());
        dayNextWeek.setDate(dayNextWeek.getDate() + 7);

        return dayNextWeek;
    }
    public equalMonthOfYear(date1: Date, date2: Date): boolean {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth()
        );
    }
    public getISOStringWeekBoundaries(): [string, string] {
        const now = new Date();
        const monday = DateService.toISOString(this.getMondayOfWeek(now));
        const sunday = DateService.toISOString(this.getSundayOfWeek(now));
        return [monday, sunday];
    }
}
