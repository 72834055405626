import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import * as _ from "lodash";
import { Token } from "../../models/token/Token";
import { KEY_CODE } from "../../constants/KeyCode";
import { DomSanitizer } from "@angular/platform-browser";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

export const DEFAULT_MINIMAL_SEARCH_LENGTH = 1;
export const DEFAULT_SEARCH_DELAY = 300;

/** Keys that might select value: Enter, Return, Tab
 * @type KEY_CODE[] */
export const KEYS_SELECTING = [
    KEY_CODE.KEY_ENTER,
    KEY_CODE.KEY_RETURN,
    KEY_CODE.KEY_TAB,
];
/** Keys that might accept value: Enter, Return
 * @type KEY_CODE[] */
export const KEYS_ACCEPTING = [KEY_CODE.KEY_ENTER, KEY_CODE.KEY_RETURN];
/** Keys that might discard value: Escape
 * @type KEY_CODE[] */
export const KEYS_DISCARDING = [KEY_CODE.KEY_ESCAPE];
/** Keys that might change page: Page Up, Page Down
 * @type KEY_CODE[] */
export const KEYS_PAGING = [KEY_CODE.KEY_PAGE_DOWN, KEY_CODE.KEY_PAGE_UP];
/** Keys that might change element: Up, Down
 * @type KEY_CODE[] */
export const KEYS_CHANGING = [KEY_CODE.KEY_UP, KEY_CODE.KEY_DOWN];
/** Keys that might switch to another component: Tab
 * @type KEY_CODE[] */
export const KEYS_SWITCHING = [KEY_CODE.KEY_TAB];
/** Keys that might navigate through elements: Page Up, Page Down, Up, Down, Home, End
 * @type KEY_CODE[] */
export const KEYS_NAVIGATING = [
    KEY_CODE.KEY_HOME,
    KEY_CODE.KEY_END,
    KEY_CODE.KEY_PAGE_DOWN,
    KEY_CODE.KEY_PAGE_UP,
    KEY_CODE.KEY_UP,
    KEY_CODE.KEY_DOWN,
];

@Component({
    selector: "generic-dropdown",
    templateUrl: "generic-dropdown.html",
    host: { "[class.c-generic-dropdown]": "true" },
})
export class GenericDropdownComponent implements OnInit, OnChanges {
    /** Controls if component allows selection of multiple values */
    @Input() public multiSelect = false;
    /** Accepts items for listeners after {@link GenericDropdownComponent#select select} (see {@link GenericDropdownComponent#commit commit});
     * values of {@link #currentSelection currentSelection} are used (see {@link #selectCurrent selectCurrent} and {@link #deselectCurrent deselectCurrent}) */
    @Input() public selection = new ReplaySubject<Item[]>(1);

    @Input() public uncommittedSelection: Subject<Item[]>;
    /** Contains items currently stored in component (see {@link #updateItems updateItems}); some might be {@link #getFilteredItems filtered out} for presentation purposes */
    @Input() public items: Item[] = [];
    /** Name to be displayed in selector of the first column if two item columns given */
    @Input() public itemsName = "";
    /** Contains items stored second column (see {@link #updateItems updateItems}); some might be {@link #getFilteredItems filtered out} for presentation purposes */
    @Input() public optionalItems: Item[];
    /** Name to be displayed in selector of the second column if two item columns given */
    @Input() public optionalItemsName = "";
    /** Callback for style to apply on list item by its name */
    @Input() public getItemStyle: (itemName: ItemName) => string;
    /** Placeholder text for unfocused and empty input (‟dropdown activator”) */
    @Input() public selectMessage: string;
    /** Placeholder text for focused and empty input */
    @Input() public searchPlaceholder: string;
    /** Contains initial selection of {@link GenericDropdownComponent#items items} (see {@link #updateItems updateItems}) */
    @Input() public initialSelectionStream: Observable<Item[]>;
    /** Controls if component allows adding new items */
    @Input() public allowNewValues = false;
    /** Controls if component allows selecting “no value” */
    @Input() public allowNoValue = true;
    /** Controls if text from input should be passed to listeners immediately (“on-the-fly”;
     * see {@link #resetAfterCommit resetAfterCommit} and {@link GenericDropdownComponent#commit commit}) */
    @Input() public autocommit = false;
    /** Controls if component is {@link GenericDropdownComponent#reset reset} after {@link GenericDropdownComponent#commit commit} (see {@link #autocommit autocommit}) */
    @Input() public resetAfterCommit = false;
    /** Controls if empty input text should be converted into “null” or left as empty string */
    @Input() public emptyIsNull = true;
    /** Controls if leaving component without explicit acceptance or rejection of current value should be treated as acceptance */
    @Input() public selectOnSwitch = true;
    /** Controls if search event on open should issue empty value or rather {@link #selectCurrent current selection} (if exists) */
    @Input() public globalSearchOnOpen = true;
    /** Controls if component is disabled */
    @Input() public disabled = false;
    /** Controls delay for invoking search after last key press in input */
    @Input() public delay: number = DEFAULT_SEARCH_DELAY;
    /** Controls minimal length of input text to invoke search (see {@link #parseSearchText parseSearchText}) */
    @Input() public searchThreshold: number = DEFAULT_MINIMAL_SEARCH_LENGTH;
    /** Controls if matching items with input text should be case-insensitive (see {@link #findItem findItem}) */
    @Input() public caseInsensitiveSearch = true;
    /** Listens for component activation request */
    @Input() public activateStream: Observable<Token>;
    /** Listens for reset component request, i.e. {@link #discard selection discard} */
    @Input() public resetSearchText: Observable<ResetSearchTextToken> = null;
    /** Emits search text change (see {@link #searchText searchText}) */
    @Output() public searchTextChange = new EventEmitter<string>();
    /** Emits blur event (see {@link GenericDropdownComponent#blurSearchInput blurSearchInput}) */
    @Output() public blur = new EventEmitter<void>();

    /** Contains current selection to {@link GenericDropdownComponent#commit commit} or {@link #discard discard} (see {@link GenericDropdownComponent#selection selection});
     * should be generally changed by {@link GenericDropdownComponent#select select}, {@link #selectCurrent selectCurrent} and {@link #deselectCurrent deselectCurrent} */
    @Input() public currentSelection: Item[] = [];

    @Input() public dropdownOpensUpwards = false;
    /** Accepts {@link searchText search text} for listeners that might {@link #updateItems update items} to reflect requested items change */
    public searchEvent = new ReplaySubject<string>(1);
    /** Contains current search text for {@link #searchTextChange listeners’ notification}; in most cases contains current input text might this isn’t a rule */
    public searchText = "";

    /** Contains copy of original items given as @Input*/
    public originalItems: Item[];

    private searchInput: HTMLInputElement;
    private dropdownActivator: HTMLElement;
    private open = false;

    private isInputFocused = false;

    constructor(
        protected elementRef: ElementRef,
        protected sanitizer: DomSanitizer
    ) {}

    public ngOnInit(): void {
        this.searchInput =
            this.elementRef.nativeElement.getElementsByTagName("input")[0];
        this.dropdownActivator =
            this.elementRef.nativeElement.getElementsByClassName(
                "c-generic-dropdown__activator"
            )[0];

        this.selection.subscribe((items) => (this.currentSelection = items));

        if (this.resetSearchText) {
            this.resetSearchText.subscribe(() => {
                this.discard();
                this.searchEvent.next("");
            });
        }

        if (this.initialSelectionStream) {
            this.initialSelectionStream.subscribe(
                (initial) => {
                    this.currentSelection = initial;

                    if (this.multiSelect) {
                        this.resetSearchInputValue();
                    } else if (initial.length > 0) {
                        this.updateSearchInputValue(initial[0].name);
                        this.searchText = initial[0].name;
                    }
                },
                () => {
                    /* ignore if there’s nothing in initial selection stream */
                }
            );
        }

        if (this.activateStream) {
            this.activateStream.subscribe(() => this.focusDropdownActivator());
        }

        this.searchEvent
            .pipe(
                distinctUntilChanged(),
                map((searchText) => this.parseSearchText(searchText)),
                debounceTime(this.delay),
                distinctUntilChanged()
            )
            .subscribe((searchText) => this.doSearch(searchText));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public ngOnChanges(changes: SimpleChanges): void {
        this.originalItems = this.items;
    }

    /**
     * Issues {@link #searchTextChange search event} updating {@link #searchText search text} beforehand on {@link #searchEvent request}.
     * @see #beforeSearch beforeSearch
     */
    private doSearch(searchText: string) {
        if (searchText !== null && searchText !== undefined) {
            this.searchText = searchText;
        }
        this.beforeSearch();
        this.searchTextChange.emit(this.searchText);
    }

    /**
     * Parses text for {@link #searchEvent search event}; by default empties text is shorter than {@link GenericDropdownComponent#searchThreshold search threshold}.
     *
     * @param searchText
     * @returns {string}
     */
    protected parseSearchText(searchText: string): string {
        const hasApprovedLength =
            searchText != null && searchText.length >= this.searchThreshold;

        return hasApprovedLength ? searchText : "";
    }

    public getStyle(item: ItemName): string {
        return this.getItemStyle ? this.getItemStyle(item) : "";
    }

    /**
     * Checks if key in event is one of the provided ones.
     *
     * @param keys to check
     * @param event to check
     * @returns {boolean}
     */
    protected keyDetected(keys: KEY_CODE[], event: KeyboardEvent): boolean {
        return event != null && _.includes(keys, event.keyCode);
    }

    /**
     * Opens list if not {@link GenericDropdownComponent#disabled disabled}; {@link #updateSearchInputValue updates search input} with
     * {@link #selectCurrent current selection} (if exists) and {@link #doSearch initiates search} with it or
     * empty string if {@link #globalSearchOnOpen requested}.
     */
    public openIfAllowed(): void {
        if (!this.disabled) {
            const currentItem = _.last(this.currentSelection);
            const current = currentItem && currentItem.name;

            this.doSearch(this.globalSearchOnOpen ? "" : current);
            this.updateSearchInputValue((!this.multiSelect && current) || "");

            this.openList();
            this.focusOnNextBestFit();
        }
    }

    /**
     * {@link GenericDropdownComponent#selectCurrent Adds item to current selection} and {@link GenericDropdownComponent#commit commits} it.
     *
     * @param item to add
     * @param event to {@link #gobbleEvent gobble}
     */
    public select(item: Item, event?: Event): void {
        this.selectCurrent(item);
        this.commit(event);
    }

    /**
     * {@link GenericDropdownComponent#selection Notifies listeners} about current selection and
     * {@link GenericDropdownComponent#reset resets}/{@link GenericDropdownComponent#dismiss dismisses} {@link GenericDropdownComponent#resetAfterCommit when needed}.
     *
     * @param event to {@link #gobbleEvent gobble}
     * @see #selectCurrent selectCurrent
     * @see #discard discard
     */
    public commit(event?: Event): void {
        this.selection.next(this.currentSelection);

        if (this.resetAfterCommit) {
            this.reset(event);
        } else {
            this.dismiss(event);
        }
    }

    /**
     * {@link GenericDropdownComponent#reset Resets} current selection and {@link GenericDropdownComponent#selection notifies listeners} about it.
     *
     * @param event to {@link #gobbleEvent gobble}
     * @see #selectCurrent selectCurrent
     * @see GenericDropdownComponent#commit commit
     */
    public discard(event?: Event): void {
        this.reset(event, true);
        if (this.uncommittedSelection) {
            this.uncommittedSelection.next(this.currentSelection);
        }
        this.selection.next(this.currentSelection);
    }

    /**
     * {@link GenericDropdownComponent#deselectCurrent Clears current selection} and empties search text
     * for non-{@link GenericDropdownComponent#multiSelect multiselect} components {@link #dismiss dismissing} selection afterwards.
     *
     * @param event to {@link #gobbleEvent gobble}
     */
    public reset(event?: Event, forceListClose = false): void {
        if (!this.multiSelect) {
            this.deselectCurrent();
            this.searchText = "";
        }

        this.dismiss(event, forceListClose);
    }

    /**
     * {@link #closeList Closes list} if allowed.
     *
     * @param event to {@link #gobbleEvent gobble}
     * @param forceListClose to ensure that list will be closed
     */
    private dismiss(event?: Event, forceListClose = false): void {
        if (forceListClose || !this.multiSelect) {
            this.closeList();
        }

        this.gobbleEvent(event);
    }

    /**
     * Sets item as {@link #currentSelection current selection} if non-{@link #multiSelect multiselect} and {@link #toggleCurrent toggles} it in other case.
     *
     * @param item to be set
     * @see #deselectCurrent deselectCurrent
     * @see GenericDropdownComponent#select select
     */
    private selectCurrent(item: Item): void {
        if (this.multiSelect) {
            this.toggleCurrent(item);
        } else {
            this.currentSelection = [item];
        }
        if (this.uncommittedSelection) {
            this.uncommittedSelection.next(this.currentSelection);
        }
    }

    /**
     * Adds item to {@link #currentSelection current selection} if selection does not contain item and removes it from selection in opposite case.
     *
     * @param item
     * @see #selectCurrent selectCurrent
     */
    private toggleCurrent(item: Item): void {
        const idx = this.currentSelection.findIndex((it) => it.id === item.id);

        if (idx !== -1) {
            this.currentSelection.splice(idx, 1);
        } else {
            this.currentSelection.push(item);
        }
    }

    /**
     * Clears {@link #currentSelection current selection} if {@link #allowNoValue allowed}.
     *
     * @see #selectCurrent selectCurrent
     */
    private deselectCurrent(): void {
        if (this.allowNoValue) {
            this.currentSelection = [];
        }
        if (this.uncommittedSelection) {
            this.uncommittedSelection.next(this.currentSelection);
        }
    }

    /**
     * {@link #selectCurrent Selects current item} if it exists adding it beforehand if non-empty and {@link #allowNewValues allowed}
     * and {@link #deselectCurrent clears current selection} if empty, {@link #allowNoValue allowed} and non-{@link #multiSelect multiselect}.
     *
     * @param text of new selection
     */
    private selectText(text: string): void {
        text = text ? text : "";
        this.searchText = text !== "" || !this.emptyIsNull ? text : null;

        const item = this.allowNewValues
            ? this.findItem(text)
            : this.findFirstItemContainingText(text);

        if (item) {
            this.selectCurrent(item);
        } else if (this.allowNewValues && !!text) {
            const newItem = { id: null, name: text };
            this.selectCurrent(newItem);
        } else if (!this.multiSelect && !text) {
            this.deselectCurrent();
        }
    }

    /**
     * Finds item with given name; uses {@link #caseInsensitiveSearch case sensitivity setting}.
     *
     * @param text to use
     * @returns {Item}
     */
    protected findItem(text: string): Item {
        const searchPredicate = this.caseInsensitiveSearch
            ? (item) =>
                  item.name.toLocaleLowerCase() === text.toLocaleLowerCase()
            : (item) => item.name === text;
        return !!text && _.find(this.items, searchPredicate);
    }

    private findFirstItemContainingText(text: string): Item {
        if (!text) {
            return null;
        }
        const searchPredicate = this.caseInsensitiveSearch
            ? (item) =>
                  item.name
                      .toLocaleLowerCase()
                      .indexOf(text.toLocaleLowerCase()) > -1
            : (item) => item.name.indexOf(text) > -1;
        return !!text && _.find(this.items, searchPredicate);
    }

    /**
     * Matches given item name against filter text or {@link #searchText search text}; uses {@link #caseInsensitiveSearch case sensitivity setting}.
     *
     * @param item to check
     * @param filterText to use
     * @returns {boolean}
     */
    public matchesFilter(item: ItemName, filterText?: string): boolean {
        const pattern = filterText || this.searchText;
        return (
            !pattern ||
            (this.caseInsensitiveSearch
                ? item.name
                      .toLocaleLowerCase()
                      .indexOf(pattern.toLocaleLowerCase()) !== -1
                : item.name.indexOf(pattern) !== -1)
        );
    }

    public onInputKeyDown(searchText: string, event: KeyboardEvent): void {
        if (this.keyDetected(KEYS_CHANGING, event)) {
            if (event.keyCode === KEY_CODE.KEY_DOWN) {
                this.openList();
                this.focusOnNextBestFit(Direction.DOWNWARD, searchText);
            }
            if (
                this.dropdownOpensUpwards &&
                event.keyCode === KEY_CODE.KEY_UP
            ) {
                this.openList();
                this.focusOnNextBestFit(Direction.UPWARD, searchText);
            }
            this.gobbleEvent(event);
        } else if (this.keyDetected(KEYS_PAGING, event)) {
            this.gobbleEvent(event);
        } else if (this.open && this.keyDetected(KEYS_DISCARDING, event)) {
            this.discard(event);
        } else if (
            this.keyDetected(KEYS_ACCEPTING, event) ||
            (this.keyDetected(KEYS_SWITCHING, event) && this.selectOnSwitch)
        ) {
            this.selectText(searchText);
            this.commit();
        } else if (
            this.keyDetected(KEYS_SWITCHING, event) &&
            !this.selectOnSwitch
        ) {
            this.selectText(searchText);
            this.closeList();
            this.blurSearchInput();
        }
    }

    /**
     * Issues search event for given text and {@link #selectText selects} it when non-{@link #multiSelect multiselect};
     * notifies listeners of new selection if {@link #autocommit autocommit} is enabled.
     *
     * @param searchText from input
     * @see #selectCurrent selectCurrent
     */
    public onInput(searchText: string): void {
        this.searchEvent.next(searchText);

        if (!this.multiSelect) {
            this.selectText(searchText);
        }

        if (this.autocommit) {
            this.selection.next(this.currentSelection);
        }
    }

    public onDropdownActivatorKeyDown(event: KeyboardEvent): void {
        if (this.keyDetected(KEYS_CHANGING, event)) {
            this.openIfAllowed();
            this.focusOnNextBestFit();
            event.preventDefault();
        } else if (
            (!event.key && event.keyCode !== KEY_CODE.KEY_TAB) ||
            (event.key && event.key.length === 1)
        ) {
            this.openIfAllowed();
            if (event.key) {
                this.updateSearchInputValue(this.searchInput.value + event.key);
            }
            setTimeout(() => this.focusSearchInput());
        } else {
            event.preventDefault();
        }
    }

    public isGenericDropdownActivatorAllowed(): boolean {
        return !this.open && !this.isInputFocused;
    }

    /**
     * Opens list.
     *
     * @see #closeList closeList
     * @see GenericDropdownComponent#isListOpened isListOpened
     */
    public openList(): void {
        this.open = true;
    }

    /**
     * Closes list and {@link #resetSearchInputValue resets input} with {@link #blurSearchInput blur}.
     *
     * @see #openList openList
     * @see GenericDropdownComponent#isListOpened isListOpened
     */
    public closeList(): void {
        this.open = false;

        this.resetSearchInputValue();
        this.blurSearchInput();
    }

    /**
     * Checks if list is opened (and {@link #hasUnfilteredItems has unfiltered items}).
     *
     * @returns {boolean}
     * @see #openList openList
     * @see #closeList closeList
     */
    public isListOpened(): boolean {
        return this.open && this.hasUnfilteredItems();
    }

    /**
     * Checks if list is not completely filtered out (see {@link GenericDropdownComponent#matchesFilter matchesFilter}).
     *
     * @returns {boolean}
     */
    public hasUnfilteredItems(): boolean {
        const filteredItems = this.items.filter((item) =>
            this.matchesFilter(item)
        );
        return filteredItems.length > 0;
    }

    /**
     * Checks if given item is in {@link #currentSelection current selection}.
     *
     * @param item to check
     * @returns {boolean}
     */
    public isSelected(item: Item): boolean {
        return (
            !!item &&
            this.currentSelection.find((it) => it.id === item.id) !== undefined
        );
    }

    /**
     * Prevents event's default action.
     *
     * @param event to change
     */
    protected gobbleEvent(event?: Event): void {
        if (event) {
            event.preventDefault();
        }
    }

    /**
     * Handles clicks {@link #isAncestorElement outside} component: selects current item (non-{@link #multiSelect multiselect}) or {@link #searchText search text}
     * and {@link #commit commits} if {@link #selectOnSwitch requested} or just {@link #dismiss dismisses}.
     * @param event
     */
    public onClick(event: Event): void {
        const clickedComponent: Node = <Node>event.target;

        if (this.open && !this.isAncestorElement(clickedComponent)) {
            if (!this.multiSelect) {
                this.selectText(this.searchInput.value);
            }

            if (this.selectOnSwitch) {
                this.commit();
            } else {
                this.dismiss(null, true);
            }
        }
    }

    /**
     * Checks this component’s DOM element contains given node.
     *
     * @param node to be checked
     * @returns {boolean}
     */
    private isAncestorElement(node: Node): boolean {
        const ancestor = this.elementRef.nativeElement;

        do {
            if (node === ancestor) {
                return true;
            }

            node = node.parentNode;
        } while (node);

        return false;
    }

    public onListKeyDown(item: Item, event: KeyboardEvent): void {
        if (this.multiSelect) {
            return;
        }

        if (this.keyDetected(KEYS_SELECTING, event)) {
            const itemName = item.name;

            if (itemName !== "" || this.allowNoValue) {
                this.searchText = itemName;
                this.updateSearchInputValue(itemName);

                if (
                    this.keyDetected(KEYS_ACCEPTING, event) ||
                    (this.keyDetected(KEYS_SWITCHING, event) &&
                        this.selectOnSwitch)
                ) {
                    this.select(item, event);
                } else {
                    this.closeList();
                }
            } else {
                this.focusSearchInput();
            }
        } else if (this.keyDetected(KEYS_NAVIGATING, event)) {
            this.handleArrowKeyNavigation(item, event);
        } else if (this.keyDetected(KEYS_DISCARDING, event)) {
            this.discard(event);
        } else {
            this.focusSearchInput();
        }
    }

    /**
     * Gets filters items out of list {@link GenericDropdownComponent#items items} if they {@link GenericDropdownComponent#matchesFilter match}
     * filter text or {@link #searchText search text}.
     *
     * @param filterText to use
     * @returns {Item[]} filtered items
     */
    protected getFilteredItems(filterText: string = null): Item[] {
        const pattern: string = filterText || this.searchText;

        return (
            pattern
                ? _.filter(this.items, (item) =>
                      this.matchesFilter(item, pattern)
                  )
                : this.items
        ).slice();
    }

    protected getIndexInItems(item: Item): number {
        return _.findIndex(this.items, (collectionItem) =>
            _.isEqual(item, collectionItem)
        );
    }

    /**
     * Focuses on list item asynchronously; {@link #updateSearchInputValue updates search input} and {@link #selectCurrent selects it} it
     * if non-{@link #multiSelect multiselect}.
     *
     * @param item to focus on
     */
    protected focusItem(item: Item): void {
        setTimeout(() => {
            const index = this.getIndexInItems(item);
            const listItems =
                this.elementRef.nativeElement.querySelectorAll("ul > li");

            if (0 <= index && index < listItems.length) {
                const anchor = listItems[index].getElementsByTagName("a")[0];
                anchor.focus();

                if (!this.multiSelect) {
                    this.updateSearchInputValue(item.name);
                    this.selectCurrent(item);
                }
            } else {
                this.focusSearchInput();
            }
        });
    }

    /**
     * Gets item with given index from {@link #getFilteredItems filtered items}.
     *
     * @param itemIndex to use
     * @param wrap interpreting negative indices as counted from end
     * @returns {Item}
     */
    private getItem(itemIndex: number, wrap = false): Item {
        const filteredItems: Item[] = this.getFilteredItems();
        if (wrap && itemIndex < 0) {
            itemIndex += filteredItems.length;
        }
        return 0 <= itemIndex && itemIndex <= filteredItems.length - 1
            ? _.find(this.items, (item) =>
                  _.isEqual(item, filteredItems[itemIndex])
              )
            : null;
    }

    /**
     * {@link GenericDropdownComponent#getItem Gets item} after one with given index.
     *
     * @param itemIndex to use
     * @returns {Item}
     */
    protected getNextItem(itemIndex: number): Item {
        return this.getItem(itemIndex + 1);
    }

    /**
     * {@link GenericDropdownComponent#getItem Gets item} before one with given index.
     *
     * @param itemIndex to use
     * @returns {Item}
     */
    protected getPreviousItem(itemIndex: number): Item {
        return this.getItem(itemIndex - 1);
    }

    /**
     * {@link GenericDropdownComponent#getItem Gets first item}.
     *
     * @returns {Item}
     */
    protected getFirstItem(): Item {
        return this.getItem(0);
    }

    /**
     * {@link GenericDropdownComponent#getItem Gets last item}.
     *
     * @returns {Item}
     */
    protected getLastItem(): Item {
        return this.getItem(-1, true);
    }

    protected handleArrowKeyNavigation(
        eventItem: Item,
        event: KeyboardEvent
    ): void {
        const filteredItems: Item[] = this.getFilteredItems();
        const eventItemIndex: number = _.findIndex(
            filteredItems,
            (item) => item.name === eventItem.name
        );

        let nextItem: Item = null;

        switch (event.keyCode) {
            case KEY_CODE.KEY_DOWN:
                nextItem = this.getNextItem(eventItemIndex);
                break;
            case KEY_CODE.KEY_UP:
                nextItem = this.getPreviousItem(eventItemIndex);
                break;
            case KEY_CODE.KEY_HOME:
                nextItem = this.getFirstItem();
                break;
            case KEY_CODE.KEY_END:
                nextItem = this.getLastItem();
                break;
            default:
                break;
        }

        if (!nextItem && event.keyCode === KEY_CODE.KEY_UP) {
            this.focusSearchInput();
            this.deselectCurrent();
        } else if (nextItem) {
            this.focusItem(nextItem);
        }

        event.preventDefault();
    }

    /**
     * Focuses search input immediately.
     *
     * @see GenericDropdownComponent#blurSearchInput blurSearchInput
     */
    protected focusSearchInput(): void {
        this.isInputFocused = true;
        this.searchInput.focus();
    }

    /**
     * Blurs search input immediately notifying listeners.
     *
     * @see GenericDropdownComponent#focusSearchInput focusSearchInput
     * @see GenericDropdownComponent#blur blur
     */
    protected blurSearchInput(): void {
        this.isInputFocused = false;
        this.searchInput.blur();
    }

    /**
     * Listens for blur event on input emitting another, plain event to listeners.
     *
     * @see GenericDropdownComponent#blurSearchInput blurSearchInput
     * @see GenericDropdownComponent#blur blur
     */
    public onInputBlur(): void {
        this.blur.emit();
    }

    /**
     * Focuses dropdown activator immediately.
     */
    private focusDropdownActivator(): void {
        this.dropdownActivator.focus();
    }

    /**
     * {@link #focusItem Focuses} on best fitted item from {@link #getFilteredItems filtered items} with
     * {@link GenericDropdownComponent#matchesFilter matching} name in given direction preference.
     *
     * @param direction preferred during search
     * @param filterText to use in matching
     */
    protected focusOnNextBestFit(
        direction: Direction = null,
        filterText: string = null
    ): void {
        const filteredItems = this.getFilteredItems(filterText);

        if (filteredItems.length > 0) {
            const getItem = (checkedItem: Item): Item => {
                if (direction === null || filteredItems.length === 0) {
                    return checkedItem;
                } else {
                    const index = _.findIndex(filteredItems, (filteredItem) =>
                        _.isEqual(filteredItem, checkedItem)
                    );
                    if (index >= 0) {
                        const offset = this.isSelected(checkedItem)
                            ? direction
                            : 0;
                        const nextIndex = index + offset;
                        return 0 <= nextIndex &&
                            nextIndex <= filteredItems.length - 1
                            ? filteredItems[nextIndex]
                            : checkedItem;
                    } else {
                        return filteredItems[0];
                    }
                }
            };

            let initialItem: Item = null;
            const last: Item = this.currentSelection
                ? _.last(this.currentSelection)
                : null;

            if (
                !!filterText &&
                !!last &&
                this.matchesFilter(last, filterText)
            ) {
                initialItem = getItem(last);
            } else if (!filterText && !!last) {
                const currentlySelectedItem: Item = _.find(
                    filteredItems,
                    (item) => _.isEqual(item, _.last(this.currentSelection))
                );
                initialItem = getItem(currentlySelectedItem);
            } else if (direction !== null) {
                initialItem = filteredItems[0];
            }

            this.focusItem(initialItem);
        } else {
            setTimeout(() => this.focusSearchInput());
        }
    }

    /**
     * Updates search input with given text.
     *
     * @param text
     */
    private updateSearchInputValue(text: string): void {
        this.searchInput.value = text;
    }

    /**
     * Resets search input content.
     */
    private resetSearchInputValue(): void {
        this.updateSearchInputValue("");
    }

    /**
     * Updates list {@link GenericDropdownComponent#items items} with given.
     *
     * @param items to use
     */
    protected updateItems(items: Item[]): void {
        this.items = items;
    }

    /**
     * Injection just before change.
     */
    protected beforeSearch(): void {
        // use in descendants
    }

    /**
     * Replaces currently displayed items in drop down menu. Used when {@link GenericDropdownComponent#optionalItems optionalItems} are provided.
     * @param {Item[]} items to be displayed
     */
    public selectItems(items: Item[]): void {
        this.items = items;
    }

    public isItemSelected(i: Item[]): boolean {
        return i === this.items;
    }
}

/** String id with string name */
export type Item = { id: string; name: string };
/** String name */
export type ItemName = { name: string };
/** Direction on list: UPWARD (−1) and DOWNWARD (+1) */
export enum Direction {
    UPWARD = -1,
    DOWNWARD = 1,
}
export enum ResetSearchTextToken {
    RESET,
}
