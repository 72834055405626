import { Component, OnInit } from "@angular/core";
import { I18NService } from "services/i18n/I18NService";
import { settingsI18N } from "./i18n/settings-i18n";
import { CompanySettings } from "models/company/CompanySettings";
import { CompanyService } from "services/company/CompanyService";

@Component({
    selector: "settings",
    templateUrl: "settings.html",
    host: { "[class.c-settings]": "true" },
})
export class SettingsComponent implements OnInit {
    public i18n;
    public settingsReady = false;
    public apiKey: string;
    public ready = false;

    private currentSettings: CompanySettings;

    constructor(
        private i18nService: I18NService,
        private companyService: CompanyService
    ) {
        this.i18n = this.i18nService.extractCurrentTranslation(settingsI18N);
    }

    public ngOnInit(): void {
        this.companyService
            .getCompanySettings()
            .subscribe((settings: CompanySettings) => {
                this.currentSettings = settings;
                this.settingsReady = true;
            });
        this.companyService.getApiKey().subscribe((key) => {
            this.apiKey = key;
        });
    }

    public setHolidayNotification(event: string) {
        this.currentSettings.holidayNotifications = event;
        this.updateSettings();
    }

    public isHolidayNotificationSelected(label: string): boolean {
        return this.currentSettings.holidayNotifications === label;
    }

    public setTimesheetReminders(event: string) {
        this.currentSettings.timesheetsReminders = event;
        this.updateSettings();
    }

    public isTimesheetRemindersSelected(label: string) {
        return this.currentSettings.timesheetsReminders === label;
    }

    public setTimepotSealing(event: string) {
        this.currentSettings.timepotSealed = event === "ON";
        this.updateSettings();
    }

    public isTimepotSealingSelected(label: string): boolean {
        return this.currentSettings.timepotSealed === (label === "ON");
    }

    private updateSettings() {
        this.companyService
            .updateCompanySettings(this.currentSettings)
            .subscribe();
    }
}
