<table
    class="o-calendar__week-row"
    [ngClass]="{'c-week__row--first': first, 'c-week__row--last': last}"
>
    <thead>
        <tr>
            <td
                *ngFor="let day of days; let i = index"
                class="c-week__day {{dayClassNames[i]}}"
                [ngClass]="{'c-week__day--today': isToday(day)}"
                data-id="calendar-day"
                [attr.data-month]="month"
                [attr.data-year]="year"
                [attr.data-day]="day.getDate()"
            >
                <div
                    *ngIf="!isOtherMonth(day)"
                    #div
                    [ngClass]="getItemClass(i)"
                >
                    <span class="c-week__day-label">{{day.getDate()}}</span>
                </div>
            </td>
        </tr>
    </thead>
</table>
