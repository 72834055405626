import { UserPaymentType } from "./UserPaymentType";
import { UserPaymentSnapshot } from "./UserPaymentSnapshot";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const UserPaymentHistory = z.object({
    type: z.string().transform((typeStr) => UserPaymentType[typeStr]),
    snapshots: UserPaymentSnapshot.array(),
});

export type UserPaymentHistory = z.infer<typeof UserPaymentHistory>;
