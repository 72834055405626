import { MonthlyBalance } from "./MonthlyBalance";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const MonthlyBalanceReport = z.object({
    monthlyBalances: MonthlyBalance.array(),
    currency: z.string(),
});

export type MonthlyBalanceReport = z.infer<typeof MonthlyBalanceReport>;
