export const timesheetI18NDe = {
    cancel: "Abbrechen",
    youAreAtTimepotOf: (name) => "Sie sind in" + name + "s Timepot",
    newTaskTitle: "Aufgabe zu einem Projekt hinzufügen",
    editTaskTitle: "Aufgabe bearbeiten",
    taskNamePlaceholder: "Name der Aufgabe",
    noTasksInProject: "Keine Aufgaben im aktuellen Projekt",
    add: "Hinzufügen",
    save: "Speichern",
    pageTitle: "Ihre Woche",
    findOrAddTask: "Aufgabe finden oder hinzufügen",
    selectTaskFromList: "Aufgabe auswählen",
    chooseTaskFirst: "Aufgabe zuerst auswählen",
    selectProject: "Projekt auswählen",
    enterTask: "Aufgabe eingeben",
    startTimer: "Zeituhr starten..",
    orLogTimeManually: "...oder Zeit manuell erfassen",
    logYourTime: "Zeit erfassen",
    watchTutorial: "Anleitung anschauen",
    taskSealed: "Aufgabe bearbeiten ist deaktiviert",
    sealTimepot: "Siegelzeit",
    seal: "Siegel",
    timepot: "Timepot",
    sealDateTitle: "Wählen Sie ein Datum aus, um diesen Timepot zu versiegeln",
    sealDateExplanation:
        "Hinweis: Dieser Benutzer kann keine Aktivitäten vor dem von Ihnen ausgewählten Datum aktualisieren. " +
        "Dies beinhaltet das Umbenennen und Entfernen von Aufgaben.",
    billable: "Abrechenbar",
    nonBillable: "Nicht abrechenbar",
    nonBillableTask: "Nicht abrechenbare Aufgabe",
};
