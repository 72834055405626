import { feedbackI18NDe } from "./feedback-i18n-de";
import { feedbackI18NPl } from "./feedback-i18n-pl";

const feedbackI18NEn = {
    feedback: `Leave your feedback`,
    send: `Send`,
};

export const feedbackI18N = {
    de: feedbackI18NDe,
    pl: feedbackI18NPl,
    en: feedbackI18NEn,
};
