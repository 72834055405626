import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { DateService } from "services/date/DateService";
import { DateRange } from "models/daterange/DateRange";
import { FeatureToggleService } from "services/featuretoggle/FeatureToggleService";
import { DisplayService } from "services/display/DisplayService";
import { Timer } from "models/timesheet/Timer";

@Component({
    selector: "ts-footer",
    templateUrl: "ts-footer.html",
    host: { "[class.c-ts-footer]": "true" },
})
export class TSFooterComponent implements OnInit {
    public from: Date;
    public to: Date;
    public fixedBtn: boolean;
    @Input() public timeSummariesByDay: object;
    @Input() public totalTime: number;
    @Input() public dateRangeObservable: Observable<DateRange>;
    @Input() public showAddTaskSubject: BehaviorSubject<boolean>;
    @Input() public fixedAddBtnObservable: Subject<number>;
    @Input() public timerObservable: Observable<Timer>;
    @Input() public timerSeconds: number;

    public days: Date[] = [];
    public isOnTimer = false;

    constructor(
        private dateService: DateService,
        private featureToggleService: FeatureToggleService,
        public displayService: DisplayService
    ) {}

    public ngOnInit() {
        this.dateRangeObservable.subscribe((dr: DateRange) => {
            this.to = dr.to;
            this.from = dr.from;
            this.refreshFooter();
        });
        this.fixedAddBtnObservable.subscribe((state) => {
            this.fixedBtn = state + 50 > window.innerHeight;
        });

        this.timerObservable.subscribe((timer) => {
            this.isOnTimer =
                timer && this.days.some((d) => this.dateService.isToday(d));
        });
    }

    public getSecondsWithTimer() {
        return this.totalTime * 60 + this.timerSeconds;
    }

    public handleClick(event: MouseEvent) {
        this.showAddTaskSubject.next(true);
        event.stopPropagation();
    }
    private refreshFooter() {
        this.days = this.dateService.getDateSpan(this.from, this.to);
    }
    public isWeekend(day: Date) {
        return !this.dateService.isWeekDay(day);
    }
}
