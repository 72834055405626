<ng-container *ngIf="!isOnTimer">
    <span
        *ngIf="!!totalActivitiesTime"
        [innerHTML]="totalActivitiesTime | minutesViewPipe"
        >&nbsp;</span
    >
    <span *ngIf="!totalActivitiesTime">0:00</span>
</ng-container>

<ng-container *ngIf="isOnTimer">
    <span>{{getSecondsWithTimer() | secondsViewPipe }}</span>
</ng-container>
