import { z } from "zod";
import { TimepotDate } from "models/TimepotDate";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const DaysOffRange = z.object({
    begin: TimepotDate,
    end: TimepotDate,
    type: z.string(),
});

export type DaysOffRange = z.infer<typeof DaysOffRange>;
