<div class="c-project-pricing-history__row">
    <div class="c-project-pricing-history__period" *ngIf="!isInEditMode">
        <div>{{i18n.from}}: {{getFormattedDate(snapshot.from)}}</div>
        <div>{{i18n.to}}: {{getFormattedDate(snapshot.to)}}</div>
    </div>
    <div class="c-project-pricing-history__period-edit" *ngIf="isInEditMode">
        <div class="u-flex-row">
            <div class="u-1/1 u-1/2@sm">
                <date-picker
                    [selection]="startSubject"
                    [preselected]="selectedStartDateStream"
                    [position]="'opensright'"
                    [iconClass]="'c-date-picker__input--start'"
                    [disabled]="!snapshot.from"
                ></date-picker>
            </div>
            <div class="u-1/1 u-1/2@sm">
                <date-picker
                    [selection]="endSubject"
                    [preselected]="selectedEndDateStream"
                    [position]="'opensright'"
                    [iconClass]="'c-date-picker__input--end'"
                    [disabled]="!snapshot.to"
                ></date-picker>
            </div>
        </div>
    </div>
    <div
        class="c-project-pricing-history__overrides"
        *ngIf="snapshot.userOverrides && snapshot.userOverrides.length"
        (click)="toggleExpanded()"
    >
        <div>
            <svg height="14px" width="14px">
                <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="images/menu/all.svg#users"
                ></use>
            </svg>
            {{snapshot.userOverrides.length}}
        </div>
    </div>
    <div
        class="c-project-pricing-history__amount"
        [ngClass]="{'c-project-pricing-history__amount--wider' : !snapshot.userOverrides}"
    >
        {{getFormattedAmount(snapshot.amount)}}
    </div>
    <div
        class="c-project-pricing-history__action c-project-pricing-history__action--edit"
        *ngIf="!isInEditMode"
        (click)="editSnapshot()"
    ></div>
    <div
        class="c-project-pricing-history__action c-project-pricing-history__action--save"
        *ngIf="isInEditMode"
        (click)="saveSnapshot()"
    ></div>
</div>
<div
    class="c-project-pricing-history__container"
    *ngIf="expanded && snapshot.userOverrides && snapshot.userOverrides.length"
>
    <div
        *ngFor="let override of snapshot.userOverrides"
        class="c-project-pricing-history__sub-row"
    >
        <div class="c-project-pricing-history__photo">
            <img
                class="c-project-pricing-history__photo-image"
                src="{{getUserAvatar(override.userCode)}}"
                alt=""
            />
        </div>
        <div class="c-project-pricing-history__username">
            {{getUsername(override.userCode)}}
        </div>
        <div class="c-project-pricing-history__amount">
            {{getFormattedAmount(override.rate)}}
        </div>
    </div>
</div>
