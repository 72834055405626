<div *ngIf="!!project">
    <generic-modal
        [showStream]="showStream"
        [actionStream]="actionStream"
        [okEnabled]="ready && isValid"
        [buttonsVisible]="!isShowingHistory"
        [showReset]="!noInitialPricing"
    >
        <div class="c-project-pricing-modal__header">
            <span class="c-project-pricing-modal__header-name"
                >{{project.name}}</span
            >
        </div>
        <div *ngIf="!ready" class="u-loader u-loader--relative"></div>
        <ng-container *ngIf="ready">
            <ul
                class="c-project-pricing-modal__tabs"
                *ngIf="isHistoryTabAvailable"
            >
                <li
                    class="c-project-pricing-modal__tab"
                    [ngClass]="{'c-project-pricing-modal__tab--active': !isShowingHistory}"
                    (click)="isShowingHistory = false"
                >
                    {{i18n.currentRate}}
                </li>
                <li
                    class="c-project-pricing-modal__tab"
                    [ngClass]="{'c-project-pricing-modal__tab--active': isShowingHistory}"
                    (click)="isShowingHistory = true"
                >
                    {{i18n.history}}
                </li>
            </ul>
            <create-or-add-project-rate
                [ngClass]="{'u-hidden': isShowingHistory}"
                [projectCode]="project.id"
                [users]="users"
                [noInitialPricing]="noInitialPricing"
                [currentPricing]="currentPricing"
            ></create-or-add-project-rate>
            <project-pricing-history
                *ngIf="isHistoryTabAvailable"
                [currency]="currentPricing ? currentPricing.currency: 'USD'"
                [ngClass]="{'u-hidden': !isShowingHistory}"
                [history]="pricingHistory"
                (editHistory)="onHistoryEdit()"
                [users]="users"
                [projectCode]="project.id"
            ></project-pricing-history>
        </ng-container>
    </generic-modal>
</div>
