import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ConfigService } from "../config";
import { UserPayment } from "../../models/users/UserPayment";
import { Observable, of } from "rxjs";
import { DateService } from "../date/DateService";
import { UserPaymentHistory } from "../../models/users/UserPaymentHistory";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class UserPaymentService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) {}

    public getUserPayment(userId: string): Observable<UserPayment> {
        const date = DateService.toISOString(new Date());
        return this.http.get(this.configService.userPayment(userId, date)).pipe(
            map((response) => UserPayment.parse(response)),
            catchError((e) => {
                if (e instanceof HttpErrorResponse && e.status === 404) {
                    return of(undefined);
                } else {
                    throw e;
                }
            })
        );
    }

    public getUserPaymentHistory(
        userId: string
    ): Observable<UserPaymentHistory> {
        return this.http
            .get(this.configService.userPaymentHistory(userId))
            .pipe(
                map((response) => UserPaymentHistory.parse(response)),
                catchError((e) => {
                    if (e instanceof HttpErrorResponse && e.status === 404) {
                        return of(undefined);
                    } else {
                        throw e;
                    }
                })
            );
    }

    public createHourlyRate(
        userId: string,
        rate: number,
        currency: string
    ): Observable<void> {
        const payload = {
            rate: UserPaymentService.toCents(rate),
            currency: currency,
        };
        return this.http
            .post(this.configService.userHourlyRate(userId), payload)
            .pipe(map(() => undefined));
    }

    public createMonthlyRate(
        userId: string,
        rate: number,
        workingHoursInDay: number,
        currency: string
    ): Observable<void> {
        const payload = {
            rate: UserPaymentService.toCents(rate),
            workingHoursInDay: workingHoursInDay,
            currency: currency,
        };
        return this.http
            .post(this.configService.userMonthlyRate(userId), payload)
            .pipe(map(() => undefined));
    }

    public addHourlyRatePeriod(
        userId: string,
        rate: number,
        firstDay: Date = undefined,
        lastDay: Date = undefined
    ): Observable<void> {
        const payload = UserPaymentService.newHourlyRatePeriodPayload(
            rate,
            firstDay,
            lastDay
        );
        return this.http
            .post(this.configService.userHourlyRatePeriods(userId), payload)
            .pipe(map(() => undefined));
    }

    public addMonthlyRatePeriod(
        userId: string,
        rate: number,
        workingHoursInDay: number,
        firstMonth: Date = undefined,
        lastMonth: Date = undefined
    ): Observable<void> {
        const payload = UserPaymentService.newMonthlyRatePeriodPayload(
            rate,
            workingHoursInDay,
            firstMonth,
            lastMonth
        );
        return this.http
            .post(this.configService.userMonthlyRatePeriods(userId), payload)
            .pipe(map(() => undefined));
    }

    public resetPayment(userId: string): Observable<void> {
        return this.http
            .delete(this.configService.clearUserPayment(userId))
            .pipe(map(() => undefined));
    }

    private static newHourlyRatePeriodPayload(
        rate: number,
        firstDay: Date,
        lastDay: Date
    ) {
        return {
            rate: UserPaymentService.toCents(rate),
            firstDay: firstDay ? DateService.toISOString(firstDay) : undefined,
            lastDay: lastDay ? DateService.toISOString(lastDay) : undefined,
        };
    }

    private static newMonthlyRatePeriodPayload(
        rate: number,
        workingHoursInDay: number,
        firstMonth: Date,
        lastMonth: Date
    ) {
        return {
            rate: UserPaymentService.toCents(rate),
            firstMonth: firstMonth
                ? DateService.toYearMonthString(firstMonth)
                : undefined,
            lastMonth: lastMonth
                ? DateService.toYearMonthString(lastMonth)
                : undefined,
            workingHoursInDay: workingHoursInDay,
        };
    }

    private static toCents(amount: number): number {
        return amount * 100;
    }
}
