import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { DateService } from "services/date/DateService";
import { Observable } from "rxjs";
import { Timer } from "models/timesheet/Timer";

@Component({
    selector: "day-footer",
    templateUrl: "day-footer.html",
    host: { "[class.c-day-footer]": "true" },
})
export class DayFooterComponent implements OnInit, OnChanges {
    @Input() public day: Date;
    @Input() public timeSummariesByDay: object;
    @Input() public timerObservable: Observable<Timer>;
    @Input() public timerSeconds: number;

    public isOnTimer = false;
    public totalActivitiesTime: number;

    constructor(private dateService: DateService) {}

    public ngOnInit(): void {
        if (this.timerObservable) {
            this.timerObservable.subscribe((timer) => {
                this.isOnTimer = timer && this.dateService.isToday(this.day);
            });
        }
    }

    public getSecondsWithTimer() {
        return this.totalActivitiesTime * 60 + this.timerSeconds;
    }

    public ngOnChanges() {
        this.totalActivitiesTime =
            this.timeSummariesByDay[DateService.toISOString(this.day)];
    }
}
