import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { DateService } from "services/date/DateService";
import { I18NService } from "services/i18n/I18NService";
import { dayHeaderI18N } from "./i18n/day-header-i18n";

@Component({
    selector: "day-header",
    templateUrl: "day-header.html",
    host: { "[class.c-day-header]": "true" },
})
export class DayHeaderComponent implements OnInit {
    @Input() public day: Date;
    @Input() public tasksInTotal: number;

    public monthName: string;
    public dayNumber: number;
    public dayName: string;
    private i18n;

    constructor(
        private dateService: DateService,
        private elemRef: ElementRef,
        private i18nService: I18NService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(dayHeaderI18N);
    }

    public ngOnInit() {
        this.monthName = this.i18n.monthNames[this.day.getMonth()];
        this.dayNumber = this.day.getDate();
        this.dayName = this.i18n.dayNames[this.day.getDay()];
    }
}
