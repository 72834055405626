export const projectModalI18NDe = {
    addProjectTitle: "Projekt Ihrem Timepot hinzufügen",
    chooseColor: "Farbe auswählen",
    editProjectTitle: "Projekt bearbeiten",
    projectNamePlaceholder: "Projektname",
    add: "Hinzufügen",
    save: "Speichern",
    defaultTasksBillability: "Default tasks billability",
    billable: "Billable",
    nonBillable: "Non-billable",
};
