import { dateI18NDe } from "./date-i18n-de";
import { dateI18NPl } from "./date-i18n-pl";

const dateI18NEn = {
    dayNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ],
};

export const dateI18N = {
    de: dateI18NDe,
    pl: dateI18NPl,
    en: dateI18NEn,
};
