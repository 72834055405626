<div (document:mousedown)="onClick($event)">
    <div
        (mousedown)="openIfAllowed()"
        class="c-generic-dropdown__activator c-generic-dropdown__activator--paged"
        [ngClass]="{ 'u-hidden': !isGenericDropdownActivatorAllowed(), 'c-generic-dropdown__activator--disabled': disabled }"
        tabindex="0"
        (keydown)="onDropdownActivatorKeyDown($event)"
    >
        <i
            *ngIf="!disabled"
            class="u-icon u-icon-angle-down c-generic-dropdown__activator-icon"
        ></i>
        <span
            *ngIf="multiSelect || !currentSelection || currentSelection.length === 0"
            class="c-generic-dropdown__activator-placeholder"
            >{{ selectMessage }}</span
        >
        <span
            *ngIf="!multiSelect && !!currentSelection"
            class="c-generic-dropdown__activator-selected {{ getStyle(selectedItem) }}"
        >
            <span
                *ngFor="let selectedItem of currentSelection"
                showOverflownTitle
            >
                {{selectedItem.name}}
            </span>
        </span>
    </div>

    <div
        class="c-generic-dropdown__dropdown"
        [ngClass]="{'u-hidden': isGenericDropdownActivatorAllowed()}"
    >
        <input
            class="c-generic-dropdown__input"
            type="text"
            placeholder="{{searchPlaceholder}}"
            #search
            (input)="onInput(search.value)"
            (keydown)="onInputKeyDown(search.value, $event)"
            [ngClass]="{'c-generic-dropdown__input--error' : !items.length && !allowNewValues }"
        />
        <span
            class="c-generic-dropdown__loader"
            [ngClass]="{'u-hidden': !searching}"
        ></span>
        <span
            class="c-generic-dropdown__close"
            [ngClass]="{'u-hidden': searching}"
            (click)="closeList()"
        ></span>
        <div class="c-generic-dropdown__list-container" *ngIf="isListOpened()">
            <div
                class="c-generic-dropdown__pagination c-generic-dropdown__pagination--top popdown-list-top"
                *ngIf="isListOpened() && hasMultiplePages"
            >
                <div
                    (mousedown)="previousPage($event)"
                    class="c-generic-dropdown__pagination-btn"
                    *ngIf="hasPreviousPage"
                >
                    ▲
                </div>
                <div
                    class="c-generic-dropdown__pagination-btn c-generic-dropdown__pagination-btn--inactive"
                    *ngIf="!hasPreviousPage"
                >
                    ▲
                </div>
            </div>
            <ul
                class="c-generic-dropdown__list c-generic-dropdown__list--paged"
            >
                <li
                    *ngFor="let item of items"
                    (mousedown)="select(item, $event)"
                    class="{{getStyle(item)}}"
                >
                    <a
                        *ngIf="matchesFilter(item)"
                        showOverflownTitle
                        class="c-generic-dropdown__option"
                        [ngClass]="{ 'c-generic-dropdown__option--selected': isSelected(item) }"
                        (keydown)="onListKeyDown(item, $event)"
                        tabindex="0"
                        >{{item.name}}</a
                    >
                </li>
                <li
                    *ngFor="let pad of padding"
                    class="paged-dropdown-option-dummy"
                >
                    &nbsp;
                </li>
            </ul>
            <div
                class="c-generic-dropdown__pagination"
                *ngIf="isListOpened() && (hasMultiplePages || listMessage !== null)"
            >
                <span
                    class="c-generic-dropdown__pagination-desc c-generic-dropdown__pagination-desc--left"
                    *ngIf="hasMultiplePages"
                    >{{ i18n.page(currentPage + 1, pagesTotal) }}</span
                >
                <span
                    class="c-generic-dropdown__pagination-desc c-generic-dropdown__pagination-desc--right"
                    *ngIf="hasMultiplePages"
                    >{{ i18n.items(firstItemOnPage + 1, lastItemOnPage + 1,
                    totalCount) }}</span
                >
                <div
                    (mousedown)="nextPage($event)"
                    class="c-generic-dropdown__pagination-btn"
                    *ngIf="hasNextPage && hasMultiplePages"
                >
                    ▼
                </div>
                <div
                    class="c-generic-dropdown__pagination-btn c-generic-dropdown__pagination-btn--inactive"
                    *ngIf="!hasNextPage && hasMultiplePages"
                >
                    ▼
                </div>
                <div class="popdown-list-message" *ngIf="listMessage !== null">
                    {{ listMessage }}
                </div>
            </div>
        </div>
    </div>
</div>
