<generic-dropdown
    [items]="users"
    [itemsName]="i18n.usersSwitch"
    [optionalItems]="teams"
    [optionalItemsName]="i18n.teamsSwitch"
    [selection]="selectedItems"
    [multiSelect]="true"
    [initialSelectionStream]="preselected"
    [selectOnSwitch]="false"
    [selectMessage]="teams.length > 0 ? i18n.users : i18n.usersSwitch"
    [searchPlaceholder]="teams.length > 0 ? i18n.findUserTeamPlaceholder : i18n.findUserPlaceholder"
    [disabled]="!isManager()"
    class="c-generic-dropdown--contained"
>
</generic-dropdown>
