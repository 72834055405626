import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";
import { Token } from "models/token/Token";
import { timesheetI18N } from "../i18n/timesheet-i18n";
import { I18NService } from "services/i18n/I18NService";

@Component({
    selector: "add-task-row-explanation",
    templateUrl: "add-task-row-explanation.html",
    host: {
        "[class.c-add-task-row-explanation]": "true",
    },
})
export class AddTaskRowExplanationComponent {
    public i18n;
    public showVideoStream = new Subject<Token>();

    @Input() public isCurrentWeek = true;

    constructor(public i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(timesheetI18N);
    }

    public showVideo(): void {
        this.showVideoStream.next(Token.TOKEN);
    }
}
