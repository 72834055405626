<paged-dropdown
    [itemSource]="taskSource"
    [page]="page"
    [totalItems]="tasksTotal"
    [pageSize]="pageSize"
    [selection]="selectedTasks"
    [initialSelectionStream]="preselected"
    [resetSearchText]="resetSearchText"
    (searchTextChange)="inputSearch($event)"
    [disabled]="disabled"
    [allowNoValue]="true"
    [selectOnSwitch]="selectOnSwitch"
    [globalSearchOnOpen]="false"
    [searchThreshold]="searchThreshold"
    [getItemStyle]="getTaskCSS"
    [selectMessage]="placeholder || i18n.tasks"
    [searchPlaceholder]="i18n.enterTaskPlaceholder"
    [allowNewValues]="allowAddingNewTasks"
    [activateStream]="activateStream"
    [dropdownOpensUpwards]="dropdownOpensUpwards"
    [uncommittedSelection]="uncommittedSelectedTasks"
    [ngClass]="{'c-generic-dropdown--contained': !needsProject}"
    class="basic-dropdown"
>
</paged-dropdown>
