export const userPaymentI18NDe = {
    hourlyRate: "Stundensatz",
    monthlyRate: "Monatlicher Satz",
    fullTime: "Vollzeit",
    partTime: "Teilzeit",
    ratePlaceholder: "Wert",
    invalid: "Ungültig",
    currency: "Währung",
    currentRate: "Aktueller Satz",
    payment: "Satz",
    history: "Verlauf",
    from: "Von",
    to: "Bis",
};
