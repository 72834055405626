import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import * as _ from "lodash";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import { I18NService } from "services/i18n/I18NService";
import {
    FilterSetting,
    FilterType,
} from "models/reports/filters/FilterSetting";
import { reportFilterI18N } from "../i18n/reportfilter-i18n";

@Component({
    selector: "holidays-included-filter",
    templateUrl: "holidays-included-filter.html",
})
export class HolidaysIncludedFilterComponent implements OnInit {
    @Input() public filterSubject: Subject<FilterSettings>;
    public holidaysIncludedOptions: Array<HolidaysIncludedOption>;
    private filterSettings: FilterSettings;
    public i18n;
    public selected = new ReplaySubject<HolidaysIncludedOption[]>(1);
    public preselected = new ReplaySubject<HolidaysIncludedOption[]>(1);

    constructor(private i18nService: I18NService) {
        this.i18n =
            this.i18nService.extractCurrentTranslation(reportFilterI18N);
        this.holidaysIncludedOptions = [
            { name: this.i18n.holidaysIncluded, id: "true" },
            { name: this.i18n.holidaysExcluded, id: "false" },
        ];
    }

    public ngOnInit(): void {
        this.filterSubject.subscribe(
            (filterSettings) => (this.filterSettings = filterSettings)
        );
        this.filterSubject.subscribe((filterSettings) => {
            this.filterSettings = filterSettings;
            const preselected = _.filter(
                filterSettings.settings,
                (fs) => fs.filterType === FilterType.HOLIDAYS_INCLUDED
            ).map((fs) => {
                return { id: fs.filterValue, name: fs.filterDisplay };
            });
            this.preselected.next(preselected);
        });

        this.selected.subscribe((selectedOptions) => {
            _.remove(
                this.filterSettings.settings,
                (fs) => fs.filterType === FilterType.HOLIDAYS_INCLUDED
            );
            if (selectedOptions.length) {
                const option = selectedOptions[0];
                const newSetting =
                    option.id === "true"
                        ? new FilterSetting(
                              FilterType.HOLIDAYS_INCLUDED,
                              "true",
                              this.i18n.holidaysIncluded,
                              true
                          )
                        : new FilterSetting(
                              FilterType.HOLIDAYS_INCLUDED,
                              "false",
                              this.i18n.holidaysExcluded,
                              true
                          );
                this.filterSettings.settings.push(newSetting);
            }
            this.filterSubject.next(this.filterSettings);
        });
    }
}

export type HolidaysIncludedOption = { name: string; id: string };
