<span *ngIf="users.length === 1" class="task-users" showOverflownTitle>
    {{ usersNames() }}
</span>
<span
    *ngIf="users.length > 1"
    class="task-users task-users--title-available"
    [attr.title]="usersNames()"
    #elementWithText
>
    {{ i18n.manyPeople(users.length) }}
</span>
