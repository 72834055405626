import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const Team = z
    .object({
        code: z.string(),
        name: z.string(),
        readOnly: z.boolean(),
        userCodes: z.string().array().default([]),
        projectCodes: z.string().array().default([]),
    })
    .transform((values) => ({
        id: values.code,
        ...values,
    }));

export type Team = z.infer<typeof Team>;
