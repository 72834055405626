type MatchFn<T> = (a: T, b: T) => boolean;
type MergeFn<T> = (a: T, b: T) => T;

export interface MergeFunctions<T> {
    match: MatchFn<T>;
    merge: MergeFn<T>;
}

export class Merger {
    public static mergeArrays<T>(
        arr1: T[],
        arr2: T[],
        { match, merge }: MergeFunctions<T>
    ): T[] {
        return arr2.map((element2) => {
            const matchingEl = arr1.find((element1) =>
                match(element1, element2)
            );
            if (matchingEl !== undefined) {
                return merge(matchingEl, element2);
            }
            return element2;
        });
    }
}
