import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { TimesheetService } from "services/timesheet/TimesheetService";
import { I18NService } from "services/i18n/I18NService";
import { tsActivityI18N } from "./tsactivity-i18n";
import { Logger } from "services/logging/Logger";
import { TimeUnitConversionService } from "services/timeunitconversion/TimeUnitConversionService";
import { TSActivity } from "models/timesheet/TSActivity";
import { TSTask } from "models/timesheet/TSTask";
import { Observable, BehaviorSubject } from "rxjs";
import { DateService } from "services/date/DateService";
import { Timer } from "models/timesheet/Timer";
import { TimerStopComponent } from "components/timer/timerstop/TimerStopComponent";
import { MessageService, MessageType } from "services/message/MessageService";

const HOURS_IN_DAY = 24;
const MINUTES_IN_DAY = HOURS_IN_DAY * 60;

@Component({
    selector: "ts-activity",
    templateUrl: "ts-activity.html",
    host: { "[class.c-ts-activity]": "true" },
})
export class TSActivityComponent implements OnInit {
    @Input() public userId: string;
    @Input() public activity: TSActivity;
    @Input() public task: TSTask;
    @Input() public activitiesAllowed = false;
    @Input() public timerObservable: Observable<Timer>;
    @Input() public focusedActivityObservable: BehaviorSubject<{
        taskId: string;
        date: Date;
    }>;
    @Input() public timerSeconds: number;
    @Input() public projectId: string;

    public open = false;
    public isOnTimer = false;
    public timer: Observable<number>;

    @ViewChild(TimerStopComponent)
    private timerStopComponent: TimerStopComponent;

    private i18n;

    constructor(
        private element: ElementRef,
        private logger: Logger,
        private timesheetService: TimesheetService,
        private timeUnitConversionService: TimeUnitConversionService,
        private dateService: DateService,
        i18nService: I18NService,
        private messageService: MessageService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(tsActivityI18N);
    }

    public ngOnInit(): void {
        if (this.timerObservable) {
            this.timerObservable.subscribe((timer) => {
                this.isOnTimer =
                    timer &&
                    timer.taskCode === this.task.id &&
                    this.dateService.isToday(this.activity.date);
            });
        }
        if (this.focusedActivityObservable) {
            this.focusedActivityObservable.subscribe((focusedActivity) => {
                const { date, taskId } = focusedActivity;
                if (
                    !this.open &&
                    this.activity.date === date &&
                    this.task.id === taskId
                ) {
                    this.keepFocus();
                }
            });
        }
    }

    public getSecondsWithTimer() {
        if (this.activity && this.activity.minutes) {
            return this.timerSeconds + this.activity.minutes * 60;
        } else {
            return this.timerSeconds;
        }
    }

    public setOpen(val: boolean) {
        if (!this.task.readOnly && this.activitiesAllowed && !this.isOnTimer) {
            this.open = val;
            if (this.open) {
                this.getInput().value =
                    this.timeUnitConversionService.minutesToFormattedTextWithDefault(
                        this.activity.minutes,
                        ""
                    );
                this.focusOnInput();
                this.focusedActivityObservable?.next({
                    date: this.activity.date,
                    taskId: this.task.id,
                });
            } else {
                this.focusedActivityObservable?.next({
                    date: undefined,
                    taskId: undefined,
                });
            }
        } else if (this.isOnTimer && val) {
            this.timerStopComponent.focus();
        }
    }

    public keepFocus() {
        if (!this.task.readOnly && this.activitiesAllowed && !this.isOnTimer) {
            this.open = true;
            setTimeout(
                () =>
                    (this.getInput().value =
                        this.timeUnitConversionService.minutesToFormattedTextWithDefault(
                            this.activity.minutes,
                            ""
                        ))
            );
            this.focusOnInput();
        }
    }

    public getStyles() {
        if (this.task && this.task.readOnly) {
            return { cursor: "not-allowed" };
        }
        if (this.activitiesAllowed) {
            return { cursor: "pointer" };
        }
        return { cursor: "default" };
    }

    public getInputDisplay() {
        if (this.open) {
            return { display: "block" };
        }
        return { display: "none" };
    }

    public saveDurationAndClose() {
        if (this.open) {
            this.saveNewDuration();
            this.setOpen(false);
        }
    }
    private saveNewDuration() {
        const newDurationStr = this.getInput().value,
            newDuration =
                this.timeUnitConversionService.userInputToMinutes(
                    newDurationStr
                );
        const isNewActivity =
                newDuration && _.isUndefined(this.activity.minutes),
            isDurationChanged =
                !_.isUndefined(this.activity.minutes) &&
                this.activity.minutes !== newDuration;

        if (isNewActivity || isDurationChanged) {
            if (0 <= newDuration && newDuration <= MINUTES_IN_DAY) {
                this.acceptActivity(newDuration);
            } else {
                this.rejectActivity(newDurationStr);
            }
        }
    }

    private acceptActivity(newDuration: number): void {
        this.activity.minutes = newDuration;

        const persistActivity = () => {
            if (!this.activity.id) {
                this.timesheetService.addUserActivity(
                    this.userId,
                    this.task.id,
                    this.activity.date,
                    newDuration
                );
            } else if (newDuration > 0) {
                this.timesheetService.updateUserActivity(
                    this.activity,
                    newDuration
                );
            } else {
                this.timesheetService.deleteUserActivity(this.activity);
            }
        };

        if (!this.task.id) {
            this.timesheetService
                .createTask(this.projectId, this.task.name)
                .subscribe((task) => {
                    this.task.id = task.id;
                    persistActivity();
                });
        } else {
            persistActivity();
        }
    }

    private rejectActivity(wrongValue: string) {
        const value = +wrongValue;
        if (value > HOURS_IN_DAY && value < MINUTES_IN_DAY) {
            const suggested =
                this.timeUnitConversionService.minutesToFormattedText(value);
            this.messageService.addMessage({
                text: this.i18n.wrongActivityTimeValueSuggested(
                    wrongValue,
                    suggested
                ),
                type: MessageType.CLIENT_ERROR,
            });
        } else {
            this.messageService.addMessage({
                text: this.i18n.wrongActivityTimeValue(wrongValue),
                type: MessageType.CLIENT_ERROR,
            });
        }
    }

    private focusOnInput() {
        setTimeout(() => this.getInput().select());
    }

    private getInput() {
        return <HTMLInputElement>(
            this.element.nativeElement.getElementsByTagName("input")[0]
        );
    }
}
