import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../config";
import { TasksReport } from "../../models/reports/tasks/TasksReport";
import { FilterSettings } from "../../models/reports/filters/FilterSettings";
import {
    FilterSetting,
    FilterType,
} from "../../models/reports/filters/FilterSetting";
import { DailyTimeReport } from "../../models/reports/dailytime/DailyTimeReport";
import { UserActivitiesDurations } from "../../models/reports/useractivities/UserActivitiesDurations";
import { ProjectActivitiesDurations } from "../../models/reports/projectactivities/ProjectActivitiesDurations";
import { CurrentCompanyUserViewService } from "../currentcompanyuser/CurrentCompanyUserViewService";
import { FilterSettingsQueryHelper } from "../report/FilterSettingsQueryHelper";
import { ReportLink } from "../../models/reports/link/ReportLink";
import { TaskFilterView } from "../../models/reports/link/TaskFilterView";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class TimesheetReportService {
    constructor(
        private http: HttpClient,
        private currentCompanyUserViewService: CurrentCompanyUserViewService,
        private filterSettingQueryHelper: FilterSettingsQueryHelper,
        private configService: ConfigService
    ) {}

    public getTasksReport(
        filterSettings: FilterSettings
    ): Observable<TasksReport> {
        return this.http
            .get(
                this.configService.tasksReportEndpoint() +
                    this.filterSettingQueryHelper.getQueryString(filterSettings)
            )
            .pipe(
                map((res) => {
                    return TasksReport.parse(res);
                })
            );
    }

    public getPublicTaskReport(
        publicReportId: string
    ): Observable<TasksReport> {
        return this.http
            .get(this.configService.publicTasksReportEndpoint(publicReportId))
            .pipe(
                map((res) => {
                    return TasksReport.parse(res);
                })
            );
    }

    public getPublicReportTaskFilterView(
        reportId: string
    ): Observable<TaskFilterView> {
        return this.http
            .get(this.configService.tasksPublicReportFiltersEndpoint(reportId))
            .pipe(
                map((res) => {
                    return TaskFilterView.parse(res);
                })
            );
    }

    public shareReport(filterSettings: FilterSettings): Observable<ReportLink> {
        let url =
            this.configService.reportShareEndpoint() +
            this.filterSettingQueryHelper.getQueryString(filterSettings);
        if (filterSettings.settings.length > 0) {
            url =
                url +
                "&" +
                this.filterSettingQueryHelper.appendFilterDisplays(
                    filterSettings
                );
        }
        return this.http.get(url).pipe(
            map((res) => {
                return ReportLink.parse(res);
            })
        );
    }

    public getCSVTaskReportLink(filterSettings: FilterSettings): string {
        return (
            this.configService.tasksReportCSVEndpoint() +
            this.filterSettingQueryHelper.getQueryString(filterSettings, true)
        );
    }

    public getDailyTimeReport(
        filterSettings: FilterSettings
    ): Observable<DailyTimeReport> {
        return this.http
            .get(
                this.configService.tasksDailyDurationsReportEndpoint() +
                    this.filterSettingQueryHelper.getQueryString(filterSettings)
            )
            .pipe(
                map((res) => {
                    return DailyTimeReport.parse(res);
                })
            );
    }

    public getPublicDailyTimeReport(
        publicReportId: string
    ): Observable<DailyTimeReport> {
        return this.http
            .get(
                this.configService.publicTasksDailyDurationsReportEndpoint(
                    publicReportId
                )
            )
            .pipe(
                map((res) => {
                    return DailyTimeReport.parse(res);
                })
            );
    }

    public getDefaultFilterSettings(): FilterSettings {
        const companyUser = this.currentCompanyUserViewService.companyUser;
        const defaultFilterSettings = FilterSettings.defaultSettings();
        defaultFilterSettings.settings.push(
            new FilterSetting(
                FilterType.USER,
                companyUser.id,
                companyUser.name,
                !companyUser.manager
            )
        );
        return defaultFilterSettings;
    }

    public getUserActivitiesDurations(
        filterSettings: FilterSettings
    ): Observable<UserActivitiesDurations> {
        return this.http
            .get(
                this.configService.userActivitiesDurationsEndpoint() +
                    this.filterSettingQueryHelper.getQueryString(filterSettings)
            )
            .pipe(
                map((res) => {
                    return UserActivitiesDurations.parse(res);
                })
            );
    }

    public getPublicUserActivitiesDurations(
        publicReportId: string
    ): Observable<UserActivitiesDurations> {
        return this.http
            .get(
                this.configService.publicUserActivitiesDurationsEndpoint(
                    publicReportId
                )
            )
            .pipe(
                map((res) => {
                    return UserActivitiesDurations.parse(res);
                })
            );
    }

    public getProjectActivitiesDurations(
        filterSettings: FilterSettings
    ): Observable<ProjectActivitiesDurations> {
        return this.http
            .get(
                this.configService.projectActivitiesDurationsEndpoint() +
                    this.filterSettingQueryHelper.getQueryString(filterSettings)
            )
            .pipe(
                map((res) => {
                    return ProjectActivitiesDurations.parse(res);
                })
            );
    }

    public getPublicProjectActivitiesDurations(
        publicReportId?: string
    ): Observable<ProjectActivitiesDurations> {
        return this.http
            .get(
                this.configService.publicProjectActivitiesDurationsEndpoint(
                    publicReportId
                )
            )
            .pipe(
                map((res) => {
                    return ProjectActivitiesDurations.parse(res);
                })
            );
    }
}
