<div *ngFor="let snapshot of history.snapshots; let idx = index">
    <project-pricing-history-entry
        [users]="users"
        [snapshot]="snapshot"
        [pricingType]="history.type"
        [projectCode]="projectCode"
        [currency]="currency"
        (save)="onSave()"
    >
    </project-pricing-history-entry>
</div>
