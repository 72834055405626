<form>
    <div class="c-user-payment-modal__form-container">
        <ng-container *ngIf="noInitialPaymentType">
            <div class="o-form__group o-form__group--inline">
                <label for="hourly" class="o-form__radio">
                    <input
                        class="o-form__radio-input"
                        type="radio"
                        id="hourly"
                        name="paymentType"
                        (change)="setHourlyRate()"
                        [checked]="isHourlyRate()"
                    />
                    <span class="o-form__radio-label">{{i18n.hourlyRate}}</span>
                </label>
                <label for="monthly" class="o-form__radio">
                    <input
                        class="o-form__radio-input"
                        type="radio"
                        id="monthly"
                        name="paymentType"
                        (change)="setMonthlyRate()"
                        [checked]="isMonthlyRate()"
                    />
                    <span class="o-form__radio-label"
                        >{{i18n.monthlyRate}}</span
                    >
                </label>
            </div>
        </ng-container>

        <ng-container *ngIf="!noInitialPaymentType">
            <div class="c-user-payment-modal__selected-payment-type">
                {{isMonthlyRate() ? i18n.monthlyRate : i18n.hourlyRate}}
            </div>
        </ng-container>
    </div>

    <div class="c-user-payment-modal__form">
        <div class="c-user-payment-modal__form-container">
            <ng-container *ngIf="isMonthlyRate()">
                <div class="o-form__group o-form__group--inline">
                    <label class="o-form__radio" for="fullTime">
                        <input
                            class="o-form__radio-input"
                            type="radio"
                            id="fullTime"
                            name="workload"
                            (change)="setFullTime()"
                            [checked]="isFullTime()"
                        />
                        <span class="o-form__radio-label"
                            >{{i18n.fullTime}}</span
                        >
                    </label>
                    <label class="o-form__radio" for="partTime">
                        <input
                            class="o-form__radio-input"
                            type="radio"
                            id="partTime"
                            name="workload"
                            (change)="setPartTime()"
                            [checked]="isPartTime()"
                        />
                        <span class="o-form__radio-label"
                            >{{i18n.partTime}}</span
                        >
                    </label>
                </div>
            </ng-container>
            <div class="o-form__group">
                <span class="o-form__label">{{i18n.currency}}</span>
                <div class="o-form__input-group">
                    <generic-dropdown
                        [items]="currenciesArray"
                        [selection]="selected"
                        [disabled]="!noInitialPaymentType"
                        [initialSelectionStream]="preselected"
                        [selectMessage]="''"
                        [selectOnSwitch]="false"
                        [searchPlaceholder]="i18n.selectCurrency"
                    >
                    </generic-dropdown>
                </div>
            </div>
            <div class="c-user-payment-modal__form-rate">
                <div class="o-form__group">
                    <span class="o-form__label">{{i18n.ratePlaceholder}}</span>
                    <div class="o-form__input-group">
                        <input
                            class="o-form__input"
                            [ngModel]="oldRate"
                            #rate
                            id="rate"
                            (keyup)="setRate(rate.value)"
                            name="rate"
                        />
                        <label
                            for="rate"
                            class="o-form__validation"
                            [class.u-hidden]="rateValid"
                        >
                            <span
                                class="o-form__validation-msg o-form__validation-msg--error"
                                [ngClass]="{'u-hidden': rateValid}"
                            >
                                {{i18n.invalid}}
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
