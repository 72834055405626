import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { UserService } from "services/user/UserService";
import { User } from "models/users/User";
import { configuratorUserEntryI18N } from "./i18n/configurator-user-entry-i18n";

@Component({
    selector: "configurator-user-entry",
    templateUrl: "configurator-user-entry.html",
    host: {
        "(click)": "toggleSelection()",
        "[class.c-configurator-user-entry]": "true",
        "[class.c-configurator-user-entry--current]": "currentUser",
        "[class.c-configurator-user-entry--visible]": "selected",
    },
})
export class ConfiguratorUserEntryComponent implements OnInit {
    @Input() public user: User;
    @Input() public currentUser: boolean;
    @Input() public selectedSubject: Subject<boolean>;
    @Input() public usedHolidayDays: number;
    public i18n;
    private selected: boolean;

    constructor(i18nService: I18NService, private userService: UserService) {
        this.i18n = i18nService.extractCurrentTranslation(
            configuratorUserEntryI18N
        );
    }

    public ngOnInit() {
        this.selectedSubject.subscribe((v) => (this.selected = v));
    }

    public toggleSelection() {
        this.selectedSubject.next(!this.selected);
    }

    public getAvatar() {
        return this.userService.getAvatarForUser(this.user);
    }
}
