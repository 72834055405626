import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
} from "@angular/core";
import { Subject } from "rxjs";

@Component({
    selector: "tooltip",
    templateUrl: "tooltip.html",
})
export class TooltipComponent implements OnInit {
    @Input() public element: HTMLElement;
    @Input() public showStream: Subject<boolean>;
    public show = false;

    constructor(
        private elementRef: ElementRef,
        private ref: ChangeDetectorRef
    ) {}

    public ngOnInit() {
        this.showStream.subscribe((v) => {
            this.show = v;
        });
    }
    public cancel() {
        this.showStream.next(false);
    }
    public onMouseUp(event: MouseEvent) {
        this.onMouseEvent(event);
    }
    public onMouseDown(event: MouseEvent) {
        this.onMouseEvent(event);
    }
    public onScroll() {
        if (this.show) {
            this.ref.detectChanges();
        }
    }
    private onMouseEvent(event: MouseEvent) {
        const tooltip =
            this.elementRef.nativeElement.getElementsByClassName("tooltip")[0];

        if (
            this.isInside(event, tooltip) ||
            this.isInside(event, this.element)
        ) {
            return;
        }

        this.cancel();
    }
    private isInside(event: Event, element) {
        let clickedComponent: Node = <Node>event.target;

        do {
            if (clickedComponent === element) {
                return true;
            }
            clickedComponent = clickedComponent.parentNode;
        } while (clickedComponent);
        return false;
    }
    public get left() {
        if (this.element) {
            const tooltipRect = this.elementRef.nativeElement
                .getElementsByClassName("tooltip")[0]
                .getBoundingClientRect();
            const elemRect = this.element.getBoundingClientRect();
            return elemRect.left + elemRect.width / 2 - tooltipRect.width / 2;
        }
        return 0;
    }
    public get top() {
        if (this.element) {
            const tooltip =
                this.elementRef.nativeElement.getElementsByClassName(
                    "tooltip"
                )[0];
            return (
                this.element.getBoundingClientRect().top -
                tooltip.getBoundingClientRect().height
            );
        }
        return 0;
    }
}

export enum MouseEventType {
    MOUSEUP,
    MOUSEDOWN,
}
