export const profileEditI18NDe = {
    editYourProfile: "Ihr Profil bearbeiten",
    changePicture: "Profilbild ändern",
    firstName: "Vorname",
    lastName: "Nachname",
    required: "Erforderlich",
    saveChanges: "Änderungen speichern",
    uploadPicture: "Bild hochladen",
    changeProfilePicture: "Bild hochladen",
    save: "Speichern",
    wantToStart: "Möchten Sie sofort Timepot nutzen?",
    skipNow: "Überspringen",
};
