<div class="c-date-picker-range__group c-date-picker-range__group--start">
    <label class="c-date-picker-range__group-label">{{i18n.startDate}}</label>
    <input
        class="c-date-picker-range__group-input"
        type="text"
        [ngModel]="startDateString"
        (focus)="showDatepicker(dateRangeInputType.START)"
        readonly
    />
</div>
<div class="c-date-picker-range__group c-date-picker-range__group--end">
    <label class="c-date-picker-range__group-label">{{i18n.endDate}}</label>
    <input
        class="c-date-picker-range__group-input"
        type="text"
        [ngModel]="endDateString"
        (focus)="showDatepicker(dateRangeInputType.END)"
        readonly
    />
</div>

<div
    class="c-date-picker-range__flyout"
    *ngIf="isVisibleDatepicker"
    [ngClass]="currentDateRangeInputType === 0 ? 'c-date-picker-range__flyout--align-left' : 'c-date-picker-range__flyout--align-right'"
>
    <date-picker-body
        [preselected]="preselected"
        [selection]="selectedDate"
        [startRange]="startRange"
        [endRange]="endRange"
    ></date-picker-body>

    <div class="c-date-picker-range__predefined-actions" *ngIf="report">
        <div
            class="c-date-picker-range__predefined-action"
            (click)="setToday()"
        >
            {{i18n.today}}
        </div>
        <div
            class="c-date-picker-range__predefined-action"
            (click)="setThisWeek()"
        >
            {{i18n.thisWeek}}
        </div>
        <div
            class="c-date-picker-range__predefined-action"
            (click)="setThisMonth()"
        >
            {{i18n.thisMonth}}
        </div>
        <div
            class="c-date-picker-range__predefined-action"
            (click)="setThisYear()"
        >
            {{i18n.thisYear}}
        </div>
        <div
            class="c-date-picker-range__predefined-action"
            (click)="setYesterday()"
        >
            {{i18n.yesterday}}
        </div>
        <div
            class="c-date-picker-range__predefined-action"
            (click)="setLastWeek()"
        >
            {{i18n.lastWeek}}
        </div>
        <div
            class="c-date-picker-range__predefined-action"
            (click)="setLastMonth()"
        >
            {{i18n.lastMonth}}
        </div>
        <div
            class="c-date-picker-range__predefined-action"
            (click)="setLastYear()"
        >
            {{i18n.lastYear}}
        </div>
    </div>
</div>
<div
    class="c-date-picker-range__flyout-overlay"
    (click)="hideDatepicker()"
    *ngIf="isVisibleDatepicker"
></div>
