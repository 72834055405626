import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import * as _ from "lodash";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import {
    DateFilterSetting,
    FilterSetting,
    FilterType,
} from "models/reports/filters/FilterSetting";
import { DateRange } from "models/daterange/DateRange";

@Component({
    selector: "report-date-picker-range",
    templateUrl: "report-date-picker-range.html",
})
export class ReportDatePickerRangeComponent implements OnInit {
    @Input() public filterSubject: Subject<FilterSettings>;

    public dateRangeObservable = new ReplaySubject<DateRange>();
    public dateRangeSubject = new ReplaySubject<DateRange>();

    private filterSettings: FilterSettings;

    public ngOnInit() {
        this.filterSubject.subscribe((fs: FilterSettings) => {
            const dateFilterSettings = <DateFilterSetting>(
                fs.settings.find((s) => s.filterType === FilterType.DATE)
            );
            this.dateRangeObservable.next({
                from: dateFilterSettings ? dateFilterSettings.from : null,
                to: dateFilterSettings ? dateFilterSettings.to : null,
            });
            this.filterSettings = fs;
        });

        this.dateRangeSubject.subscribe((range) =>
            this.setDateFilter(range.from, range.to)
        );
    }

    private setDateFilter(from: Date, to: Date): void {
        if (from && to) {
            _.remove(
                this.filterSettings.settings,
                (setting: FilterSetting) =>
                    setting.filterType === FilterType.DATE
            );
            this.filterSettings.settings.push(new DateFilterSetting(from, to));
            this.filterSubject.next(this.filterSettings);
        }
    }
}
