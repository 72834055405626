import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnDestroy,
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import * as _ from "lodash";
import * as c3 from "c3";
import { I18NService } from "services/i18n/I18NService";
import { donutChartI18N } from "./i18n/donutchart-i18n";
import { ChartEntry } from "../common/ChartEntry";

const MINUTES_IN_HOUR = 60;
const MAX_PROJECT_NAME_LABELS_ON_PAGE = 10;
const MAX_PROJECTS_DONUT_CHART = 20;
const OTHERS_INDEX = "-1";
const OTHERS_COLOR = "#9c9c9c";

@Component({
    selector: "donutchart",
    templateUrl: "donutchart.html",
    host: { "[class.c-donutchart]": "true" },
})
export class DonutChartComponent<T>
    implements OnChanges, AfterViewInit, OnDestroy
{
    @Input() public dataObservable: Observable<Array<ChartEntry<T>>>;
    @Input() public selectedItemStream: Subject<T>;
    @Input() public keyI18N: string;

    public i18n;
    public selectedLabel: string;
    public duration: number = null;
    public itemsOnTheLeft: Array<ChartEntry<T>> = null;
    public itemsOnTheRight: Array<ChartEntry<T>> = null;

    private defaultLabel: string;
    private othersLabel: string;
    private totalMinutes: number = null;
    private itemsProperties: {
        [id: number]: { minutes: number; label: string };
    } = {};
    private chart = null;
    private currentSubscription: Subscription;

    constructor(private i18nService: I18NService) {
        this.i18n = this.i18nService.extractCurrentTranslation(donutChartI18N);
    }

    public ngOnChanges(): void {
        this.defaultLabel = this.i18n[this.keyI18N];
        this.othersLabel = this.i18n.othersLabel;
        this.selectedLabel = this.defaultLabel;
    }
    public ngAfterViewInit(): void {
        this.currentSubscription = this.dataObservable.subscribe(
            (entries: ChartEntry<T>[]) => {
                if (this.chart) {
                    this.chart.destroy();
                }

                this.totalMinutes = _.sum(
                    entries.map((entry: ChartEntry<T>) => entry.minutes)
                );
                this.duration = this.totalMinutes;

                const donutData: [Array<Column>, ColorHashDict] =
                    this.buildDonutData(entries);

                const labelledProjectNames = _.take(
                    _.sortBy(
                        entries,
                        (entry: ChartEntry<T>) => entry.minutes
                    ).reverse(),
                    MAX_PROJECT_NAME_LABELS_ON_PAGE
                );
                [this.itemsOnTheLeft, this.itemsOnTheRight] = _.chunk(
                    labelledProjectNames,
                    Math.ceil(labelledProjectNames.length / 2)
                );

                this.chart = c3.generate({
                    bindto: "#donutchart-container",
                    data: {
                        columns: donutData[0],
                        colors: donutData[1],
                        type: "donut",
                        labels: true,
                        onclick: (object: DonutChartEventObject) => {
                            if (object.id !== OTHERS_INDEX) {
                                const selectedEntry = entries.find(
                                    (entry) => entry.id === object.id
                                );
                                this.selectEntry(selectedEntry.data);
                            }
                        },
                        onmouseover: (object: DonutChartEventObject) => {
                            this.duration =
                                this.itemsProperties[object.id].minutes;
                            this.selectedLabel =
                                this.itemsProperties[object.id].label;
                        },
                        onmouseout: () => {
                            this.duration = this.totalMinutes;
                            this.selectedLabel = this.defaultLabel;
                        },
                    },
                    tooltip: {
                        show: false,
                    },
                    donut: {
                        title: "",
                        label: {
                            show: false,
                        },
                        width: 40,
                    },
                    legend: {
                        show: false,
                    },
                    zoom: {
                        rescale: true,
                        extent: [10, 100], // enable more zooming
                    },
                });
            }
        );
    }

    public buildDonutData(
        entries: ChartEntry<T>[]
    ): [Array<Column>, ColorHashDict] {
        const columns: Array<Column> = new Array<Column>();
        const colors: ColorHashDict = {};

        const sortedEntries: ChartEntry<T>[] = _.sortBy(
            entries,
            (entry: ChartEntry<T>) => entry.minutes
        ).reverse();
        const visible: ChartEntry<T>[] = sortedEntries.slice(
            0,
            MAX_PROJECTS_DONUT_CHART - 1
        );
        const others: ChartEntry<T>[] = sortedEntries.slice(
            MAX_PROJECTS_DONUT_CHART,
            sortedEntries.length
        );

        visible.forEach((entry: ChartEntry<T>) => {
            this.addVisibleItem(entry, columns, colors);
        });
        if (others.length === 1) {
            this.addVisibleItem(others[0], columns, colors);
        } else if (others.length > 0) {
            const otherMinutes: number = _.sum(
                others.map((entry: ChartEntry<T>) => entry.minutes)
            );
            columns.push([OTHERS_INDEX, otherMinutes]);
            this.itemsProperties[OTHERS_INDEX] = {
                minutes: otherMinutes,
                label: this.othersLabel,
            };
            colors[OTHERS_INDEX] = OTHERS_COLOR;
        }

        return [columns, colors];
    }

    private addVisibleItem(
        entry: ChartEntry<T>,
        columns: Array<Column>,
        colors: ColorHashDict
    ) {
        this.itemsProperties[entry.id] = {
            minutes: entry.minutes,
            label: entry.label,
        };
        columns.push([entry.id, entry.minutes]);
        colors[entry.id] = entry.colorHash;
    }

    public ngOnDestroy(): void {
        this.currentSubscription.unsubscribe();
    }

    public getDuration(duration: number): string {
        const hours: number = Math.floor(duration / MINUTES_IN_HOUR);
        const minutes: number = duration - hours * MINUTES_IN_HOUR;

        return `${hours}h ${minutes}min`;
    }

    public getCurrentLabel(): string {
        return this.selectedLabel;
    }

    public selectEntry(data: T) {
        this.selectedItemStream.next(data);
    }
}

type Column = [string, number];
type ColorHashDict = { [id: string]: string };

interface DonutChartEventObject {
    id: string;
    value: number;
    ratio: number;
    index: number;
    name: string;
}
