<div (keydown)="onKeyDown($event)">
    <generic-modal
        [showStream]="showStream"
        [actionStream]="actionStream"
        [title]="i18n.feedback"
        [okEnabled]="feedbackNonEmpty()"
        [confirmLabel]="i18n.send"
    >
        <textarea
            [(ngModel)]="feedback"
            class="c-feedback-modal__input"
        ></textarea>
    </generic-modal>
</div>
