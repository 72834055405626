import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { CurrentCompanyUserViewService } from "../currentcompanyuser/CurrentCompanyUserViewService";
import { ConfigService } from "../config";
import { CookieService } from "../cookie/CookieService";

@Injectable()
export class FeatureToggleService {
    private companiesByFeature = new Map<string, Set<string>>();

    private hasTimepotDevCookie = false;

    private hasDemoCookie = false;

    constructor(
        private currentCompanyUserService: CurrentCompanyUserViewService,
        cookieService: CookieService,
        configService: ConfigService
    ) {
        const features = _.keys(configService.companiesByFeature);
        features.forEach((feature) => {
            const companies = new Set<string>(
                configService.companiesByFeature[feature].map((c) =>
                    c.toLowerCase()
                )
            );
            this.companiesByFeature.set(feature, companies);
        });

        this.hasTimepotDevCookie =
            !!cookieService.getCookie("timepotDevCookie");
        this.hasDemoCookie = !!cookieService.getCookie("timepot.demo");
    }

    public shouldDisplayExperimentalFeature() {
        return this.shouldDisplayFeature("experimental");
    }

    public shouldDisplayDemoFeature() {
        return this.hasDemoCookie;
    }

    private shouldDisplayFeature(feature: string) {
        const company =
            this.currentCompanyUserService.companyUser.companyName.toLowerCase();
        const companiesWithFeature = this.companiesByFeature.get(feature);
        return companiesWithFeature && companiesWithFeature.has(company);
    }

    public shouldDisplayDevOnlyFeature() {
        return (
            this.hasTimepotDevCookie ||
            this.currentCompanyUserService.companyUser.companyName.toLowerCase() ===
                "localhost"
        );
    }
}
