import { Component, Input, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DateService } from "services/date/DateService";
import { I18NService } from "services/i18n/I18NService";
import { datePickerI18N } from "../i18n/datepicker-i18n";

@Component({
    selector: "date-picker-month",
    templateUrl: "date-picker-month.html",
    host: { "[class.o-date-picker__month]": "true" },
})
export class DatePickerMonthComponent implements OnInit {
    @Input() public selectedMonthSubject: Subject<{
        month: number;
        year: number;
    }>;
    @Input() public selection: Subject<Date>;
    @Input() public sealDate: Subject<Date>;
    @Input() public startRange: Subject<Date>;
    @Input() public endRange: Subject<Date>;
    @Input() public preselected: Observable<Date>;
    public mondays: Date[] = [];
    public i18n;
    private month: number;
    private year: number;
    constructor(private dateService: DateService, i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(datePickerI18N);
    }

    public ngOnInit() {
        this.selectedMonthSubject.subscribe(({ month, year }) => {
            const first = new Date(year, month, 1);
            let monday = this.dateService.getMondayOfWeek(first);
            this.mondays = [];
            this.month = month;
            this.year = year;
            do {
                this.mondays.push(monday);
                monday = new Date(monday.getTime());
                monday.setDate(monday.getDate() + 7);
            } while (this.isCurrentMonth(monday));
        });
    }
    public isCurrentMonth(date: Date) {
        return date.getMonth() === this.month;
    }
}
