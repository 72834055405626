export const dateI18NPl = {
    dayNames: ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
    monthNames: [
        "STY",
        "LUT",
        "MAR",
        "KWI",
        "MAJ",
        "CZE",
        "LIP",
        "SIE",
        "WRZ",
        "PAŹ",
        "LIS",
        "GRU",
    ],
};
