export const holidaysI18NPl = {
    pageTitle: "Dni wolne",
    addHolidayTitle: "Dodaj urlop",
    addHolidays: "Dodaj urlop",
    usersField: "Użytkownicy",
    findUser: "Znajdź użytkownika",
    startDate: "Start: ",
    endDate: "Koniec: ",
    usedIn: "Wykorzystano",
    days: "dni w",
    add: "Dodaj",
    addSelected: "Dodaj zaznaczone",
    deleteSelected: "Usuń zaznaczone dni",
    deleteWhole: "Usuń cały urlop",
    holidays: "Urlop",
    sickDays: "Choroba",
    selectType: "Wybierz typ",
};
