import { Component, OnInit } from "@angular/core";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { Observable, ReplaySubject, Subject } from "rxjs";
import * as _ from "lodash";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import {
    FilterSetting,
    FilterType,
    YearMonthFilterSetting,
} from "models/reports/filters/FilterSetting";
import { I18NService } from "services/i18n/I18NService";
import { CurrencyService } from "services/currency/CurrencyService";
import { financialReportsI18N } from "../i18n/financial-reports-i18n";
import { reportFilterI18N } from "../../reportfilter/i18n/reportfilter-i18n";
import { FinancialReportService } from "services/financialreport/FinancialReportService";
import { BarChartData } from "models/charts/BarChartData";
import { BarChartConfig } from "../../charts/barchart/BarChartComponent";
import { BarchartDataService } from "../../../services/barchart/BarchartDataService";
import { ProjectIncomeAndExpenseReport } from "models/financialreport/ProjectIncomeAndExpenseReport";
import { flatMap, map, publishReplay, refCount, tap } from "rxjs/operators";

@Component({
    selector: "financial-reports",
    templateUrl: "financial-reports.html",
})
export class FinancialReportsComponent implements OnInit {
    public filterSubject: Subject<FilterSettings>;
    public monthlyBalanceObservable: Observable<BarChartData<FilterSetting>>;
    public monthlyBalanceChartConfig: BarChartConfig;
    public projectIncomeAndExpenseObservable: Observable<ProjectIncomeAndExpenseReport>;
    public monthlyBalanceChartLoading = false;
    public totalIncome: number;
    public totalExpenses: number;
    public filterSettingsStream = new Subject<FilterSetting>();
    private filterSettings: FilterSettings;
    private currency = "USD";
    public i18n;

    constructor(
        i18nService: I18NService,
        private financialReportService: FinancialReportService,
        private datePipe: DatePipe,
        private barchartDataService: BarchartDataService,
        private currencyService: CurrencyService,
        private currencyPipe: CurrencyPipe
    ) {
        this.i18n = i18nService.extractCurrentTranslation(financialReportsI18N);
        this.filterSubject = new ReplaySubject<FilterSettings>(1);

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const filterI18N = <any>(
            i18nService.extractCurrentTranslation(reportFilterI18N)
        );
        /* eslint-enable  @typescript-eslint/no-explicit-any */

        const today = new Date();
        const initialFilterSettings = new FilterSettings([
            new FilterSetting(
                FilterType.HOLIDAYS_INCLUDED,
                "true",
                filterI18N.holidaysIncluded,
                true
            ),
            new FilterSetting(
                FilterType.CURRENCY,
                this.currencyService.getDefaultCurrency(),
                filterI18N.currency +
                    ": " +
                    this.currencyService.getDefaultCurrency(),
                true
            ),
            new YearMonthFilterSetting(
                new Date(today.getFullYear() - 1, today.getMonth() - 1),
                new Date(today.getFullYear(), today.getMonth() - 1)
            ),
        ]);
        this.filterSubject.next(initialFilterSettings);
    }

    public ngOnInit() {
        this.monthlyBalanceChartConfig = new BarChartConfig(
            (value) =>
                this.currencyPipe.transform(
                    value,
                    this.currency,
                    true,
                    "1.0-2"
                ),
            _.identity,
            (value) =>
                this.currencyPipe.transform(value, this.currency, true, "1.0-2")
        );

        this.monthlyBalanceObservable = this.filterSubject.pipe(
            tap(() => {
                this.monthlyBalanceChartLoading = true;
            }),
            flatMap((filterSettings) =>
                this.financialReportService.getMonthlyBalanceReport(
                    filterSettings
                )
            ),
            map((monthlyBalanceReport) => {
                this.currency = monthlyBalanceReport.currency;
                this.monthlyBalanceChartConfig = new BarChartConfig(
                    (value) =>
                        this.currencyPipe.transform(
                            value,
                            this.currency,
                            true,
                            "1.0-2"
                        ),
                    _.identity,
                    (value) =>
                        this.currencyPipe.transform(
                            value,
                            this.currency,
                            true,
                            "1.0-2"
                        )
                );
                this.monthlyBalanceChartLoading = false;
                return new BarChartData<FilterSetting>(
                    this.barchartDataService.aggregateBasedOnRange(
                        monthlyBalanceReport.monthlyBalances,
                        (mb) => mb.yearMonth,
                        (mb) => mb.balance,
                        (d1, d2) => new YearMonthFilterSetting(d1, d2)
                    )
                );
            })
        );
        this.projectIncomeAndExpenseObservable = this.filterSubject.pipe(
            flatMap((filterSettings) =>
                this.financialReportService.getProjectIncomeAndExpenseReport(
                    filterSettings
                )
            ),
            publishReplay(1),
            refCount()
        );

        this.projectIncomeAndExpenseObservable.subscribe((report) => {
            this.totalIncome = Math.round(
                report.incomesAndExpenses.reduce(
                    (acc, iAndE) => acc + iAndE.income,
                    0
                )
            );
            this.totalExpenses = Math.round(
                report.incomesAndExpenses.reduce(
                    (acc, iAndE) => acc + iAndE.expense,
                    0
                )
            );
        });
        this.handleFilters();
    }

    public isEmptyReport() {
        return this.totalIncome === 0 && this.totalExpenses === 0;
    }

    private handleFilters() {
        this.filterSubject.subscribe((f) => (this.filterSettings = f));
        this.filterSettingsStream.subscribe((newSetting) => {
            const newSettings = this.filterSettings.settings.filter(
                (setting) => setting.filterType !== newSetting.filterType
            );
            newSettings.push(newSetting);
            this.filterSubject.next(new FilterSettings(newSettings));
        });
    }
}
