import { teamDropdownI18nDe } from "./team-dropdown-i18n-de";
import { teamDropdownI18nPl } from "./team-dropdown-i18n-pl";
const teamDropdownI18nEn = {
    teams: "Teams",
    findTeamPlaceholder: "Find Team",
};

export const teamDropdownI18n = {
    de: teamDropdownI18nDe,
    pl: teamDropdownI18nPl,
    en: teamDropdownI18nEn,
};
