import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CompanyUser } from "../../models/companyusers/CompanyUser";
import { ConfigService } from "../config";
import { map, tap } from "rxjs/operators";

@Injectable()
export class CurrentCompanyUserService {
    private currentCompanyUser: CompanyUser;

    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) {}

    public set companyUser(companyUser: CompanyUser) {
        this.currentCompanyUser = companyUser;
    }

    public get companyUser(): CompanyUser {
        return this.currentCompanyUser;
    }

    public fetchCurrentCompanyUser(): Observable<CompanyUser> {
        return this.http.get(this.configService.currentCompanyEndpoint()).pipe(
            map((res) => (res ? CompanyUser.parse(res) : null)),
            tap((companyUser) => (this.companyUser = companyUser))
        );
    }

    public reloadCurrentCompanyUser(): Observable<CompanyUser> {
        return this.http
            .get(this.configService.companyUserEndpoint(this.companyUser.id))
            .pipe(
                map((res) => (res ? CompanyUser.parse(res) : null)),
                tap((companyUser) => (this.companyUser = companyUser))
            );
    }
}
