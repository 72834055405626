export const signupI18NPl = {
    createAccount: "Załóż konto",
    toJoin: (invitingCompanyName) => "żeby dołączyć do " + invitingCompanyName,
    signUpWithGoogle: "Załóż konto z Google",
    withGoogle: "z Google",
    with: "z",
    signUpWithEmail: "Załóż konto z emailem",
    withEmail: "z emailem",
    email: "emailem",
    signUp: "Załóż konto",
    alreadyHaveAnAccount: "Masz już konto?",
    logInNow: "Zaloguj się",
    yourEmail: "Twój e-mail",
    password: "Hasło",
    invalidEmailAddress: "Niepoprawny e-mail",
    weakPassword: "Słabe hasło",
    required: "Wymagane pole",
    passwordLoginFailed:
        "Logowanie nie powiodło się. Spróbuj ponownie lub zaloguj się z Google",
    enterPromoCode: "Wprowadź kod promocyjny",
    placeholderPromoCode: "Kod promocyjny",
    bySigningUpYouAreAgreeTo: "Oświadczam, że zapoznałem/am się i akceptuję",
    thePrivacyPolicy: "Politykę Prywatności",
    and: "i",
    termsOfUse: "Regulamin Serwisu internetowego www.timepot.pl",
    privacyUrl: "https://www.timepot.pl/privacy.html",
    termsUrl: "https://www.timepot.pl/terms.html",
    firstProjectName: "Twój projekt",
};
