<main-header [pageTitle]="i18n.pageTitle"></main-header>
<div
    class="c-reports__container"
    [ngClass]="{'c-reports__container--empty': !hasTasks()}"
>
    <timesheet-report-filter
        [filterSubject]="filterSubject"
        *ngIf="!publicReportId"
    ></timesheet-report-filter>
    <filter-display-field
        [filterSubject]="filterSubject"
    ></filter-display-field>
    <div *ngIf="hasTasks()" class="c-reports__bar-chart-container">
        <div class="c-reports__bar-chart-header">
            <div class="c-reports__bar-chart-header-actions">
                <div
                    class="c-reports__bar-chart-report-download-container"
                    *ngIf="!publicReportId"
                >
                    <a
                        [href]="getCSVReportLink()"
                        class="c-reports__bar-chart-report-download-link"
                        >{{i18n.csv}}</a
                    >
                    <a
                        [href]="getPdfReportLink()"
                        *ngIf="isCompanyAdmin"
                        class="c-reports__bar-chart-report-download-link"
                        >{{i18n.pdf}}</a
                    >
                    <label
                        (click)="shareReport()"
                        *ngIf="isCompanyAdmin"
                        class="c-reports__bar-chart-report-download-link"
                        >{{i18n.share}}</label
                    >
                </div>
            </div>
            <div
                *ngIf="!isEmpty && chartTotalHours !== undefined"
                class="c-reports__bar-chart-total"
            >
                {{ i18n.total(chartTotalHours) }}
            </div>
            <a
                *ngIf="!isEmpty"
                class="c-reports__bar-chart-visibility-trigger"
                [ngClass]="{ 'c-reports__bar-chart-visibility-trigger--visible': chartVisible }"
                (click)="toggleGraph()"
            >
                <i
                    class="c-reports__bar-chart-visibility-trigger-icon u-icon u-icon-angle-up"
                ></i>
                <span *ngIf="chartVisible">{{i18n.hideGraph}}</span>
                <span *ngIf="!chartVisible">{{i18n.showGraph}}</span>
            </a>
        </div>
        <barchart
            [dataItemsObservable]="dailyTimeObservable"
            [selectedItemStream]="filterSettingsStream"
            [config]="chartConfig"
            [visible]="chartVisible"
            [filterSubject]="filterSubject"
        ></barchart>
    </div>
    <div class="c-reports__graphs" *ngIf="hasTasks()">
        <div class="c-reports__graphs-tabs">
            <!-- <div class="c-reports__graphs-tabs-option">
                <a (click)="switchToTasks()" class="c-reports__graphs-tabs-option-link"
                   [ngClass]="{ active: displayedContent === displayedContentEnum.TASKS_LIST}">{{i18n.tasks}}</a>
            </div> -->
            <div class="c-reports__graphs-tabs-option">
                <a
                    (click)="switchToProjects()"
                    class="c-reports__graphs-tabs-option-link"
                    [ngClass]="{ active: displayedContent === displayedContentEnum.PROJECTS_DIAGRAM }"
                    >{{i18n.projects}}</a
                >
            </div>
            <div
                *ngIf="isCompanyManager || publicReportId"
                class="c-reports__graphs-tabs-option"
            >
                <a
                    (click)="switchToUsers()"
                    class="c-reports__graphs-tabs-option-link"
                    [ngClass]="{ active: displayedContent === displayedContentEnum.USERS_DIAGRAM }"
                    >{{i18n.users}}</a
                >
            </div>
        </div>
        <div
            *ngIf="displayedContent !== displayedContentEnum.TASKS_LIST"
            class="c-reports__graphs-type-picker"
        >
            <!-- <a *ngIf="displayedContent === displayedContentEnum.USERS_DIAGRAM"
               (click)="displayUserBreakdown()" class="c-reports__graphs-type-picker-link c-reports__graphs-type-picker-link--users"
               [ngClass]="{ 'c-reports__graphs-type-picker-link--active': userBreakdownDisplayed }"></a> -->
            <a
                (click)="displayDonutChart()"
                class="c-reports__graphs-type-picker-link c-reports__graphs-type-picker-link--donut"
                [ngClass]="{ 'c-reports__graphs-type-picker-link--active': donutChartDisplayed }"
            ></a>
            <a
                (click)="displayBarChartHorizontal()"
                class="c-reports__graphs-type-picker-link c-reports__graphs-type-picker-link--bar"
                [ngClass]="{ 'c-reports__graphs-type-picker-link--active': !donutChartDisplayed && !userBreakdownDisplayed}"
            ></a>
        </div>
        <div *ngIf="displayedContent !== displayedContentEnum.TASKS_LIST">
            <donutchart
                *ngIf="donutChartDisplayed"
                [dataObservable]="chartDataObservable"
                [selectedItemStream]="filterSettingsStream"
                [keyI18N]="keysI18N.donutchartKey"
            ></donutchart>
            <barchart-horizontal
                *ngIf="!donutChartDisplayed && !userBreakdownDisplayed"
                [dataObservable]="chartDataObservable"
                [selectedItemStream]="filterSettingsStream"
                [keyI18N]="keysI18N.barchartKey"
            ></barchart-horizontal>
        </div>
        <tasks-list
            [hidden]="displayedContent !== displayedContentEnum.TASKS_LIST"
            [tasksListObservable]="tasksListObservable"
            [filterSubject]="filterSubject"
        ></tasks-list>
        <user-breakdown-report
            *ngIf="userBreakdownDisplayed"
            [filterSubject]="filterSubject"
            [publicReportId]="publicReportId"
        ></user-breakdown-report>
    </div>

    <div class="c-reports__alert c-reports__alert--info" *ngIf="!hasTasks()">
        {{i18n.noResults}}
    </div>
</div>
<div>
    <report-share-link-modal
        [showLinkStream]="showLinkStream"
        [currentLink]="reportShareLink"
    >
        {{reportShareLink}}
    </report-share-link-modal>
</div>
