import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { AuthService } from "services/auth/AuthService";
import { DateService } from "services/date/DateService";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { I18NService } from "services/i18n/I18NService";
import { navbarI18N } from "./i18n/navbar-i18n";
import { Token } from "models/token/Token";
import { PerfectScrollbarService } from "services/perfectscrollbar/PerfectScrollbarService";
import { DisplayService } from "services/display/DisplayService";
import { FeatureToggleService } from "services/featuretoggle/FeatureToggleService";

@Component({
    host: {
        "[class.c-navbar]": "true",
        "[class.c-navbar--active]": "navbarOpen",
        "(window:resize)": "onResize($event)",
    },
    selector: "navbar",
    templateUrl: "navbar.html",
})
export class NavbarComponent implements OnInit {
    public showEditProfileStream = new Subject<Token>();
    public showNewCompanyModalStream = new Subject<Token>();
    public showFeedbackStream = new Subject<boolean>();
    public isAdmin = false;
    public isManager = false;
    public userId: string;
    public navbarOpen = false;
    public lastInnerWidth = window.innerWidth;
    public i18n: (typeof navbarI18N)["en"];
    private timepotContainer = document.getElementsByClassName("c-timepot")[0];

    public isWidthChangedAndBiggerThanBreakpoint(
        currentWidth,
        lastWidth
    ): boolean {
        this.lastInnerWidth = currentWidth;
        return (
            currentWidth > lastWidth &&
            lastWidth < DisplayService.VIEW_BREAKPOINT
        );
    }

    constructor(
        private authService: AuthService,
        private dateService: DateService,
        private router: Router,
        i18nService: I18NService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService,
        private perfectScrollbar: PerfectScrollbarService,
        private featureToggleService: FeatureToggleService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(navbarI18N);
    }

    public ngOnInit() {
        if (this.authService.isAuthenticated()) {
            const companyUser = this.currentCompanyUserViewService.companyUser;
            this.userId = companyUser.id;
            this.isAdmin = companyUser.admin;
            this.isManager = companyUser.manager;
        }
    }
    public get currMonday() {
        const currWeek = this.dateService.getWeekStartingOnMonday(new Date());
        return DateService.toISOString(currWeek[0]);
    }
    public get currSunday() {
        const currWeek = this.dateService.getWeekStartingOnMonday(new Date());
        return DateService.toISOString(currWeek[6]);
    }

    public signOut() {
        this.authService
            .signOut()
            .subscribe(() => this.router.navigate(["/login"]));
    }

    public openNavbar(addClassToBody = true) {
        this.navbarOpen = !this.navbarOpen;

        if (this.navbarOpen && addClassToBody) {
            this.perfectScrollbar.destroyScrollbar();
            this.timepotContainer.classList.add("c-timepot--navbar-open");
        } else {
            this.timepotContainer.classList.remove("c-timepot--navbar-open");
            this.perfectScrollbar.initScrollbar();
        }
    }

    public onResize(event) {
        if (
            this.isWidthChangedAndBiggerThanBreakpoint(
                event.target.innerWidth,
                this.lastInnerWidth
            ) &&
            this.navbarOpen
        ) {
            this.navbarOpen = false;
            this.timepotContainer.classList.remove("c-timepot--navbar-open");
            this.perfectScrollbar.initScrollbar();
        }
    }

    public showFeedback() {
        this.showFeedbackStream.next(true);
    }
}
