import { Component, Input, OnInit } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { DaysOffService } from "services/daysOff/DaysOffService";
import { UserService } from "services/user/UserService";
import { DateService } from "services/date/DateService";
import { DateRange } from "models/daterange/DateRange";
import { DayOffType } from "models/daysOff/DayOffType";
import { Action } from "../genericmodal/GenericModalComponent";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { I18NService } from "services/i18n/I18NService";
import { holidaysI18N } from "./i18n/holidays-i18n";

@Component({
    selector: "add-holidays-modal",
    templateUrl: "add-holidays-modal.html",
    host: { "[class.c-add-holidays-modal]": "true" },
})
export class AddHolidaysModalComponent implements OnInit {
    @Input() public showStream: Subject<boolean>;
    @Input() public selectedDateRangeObservable: Subject<DateRange>;

    public selectedStartDateStream: Subject<Date>;
    public selectedEndDateStream: Observable<Date>;
    public actionStream = new Subject<Action>();
    public selectedUsers = new ReplaySubject<{ id: string; name: string }[]>(1);
    public isCompanyManager: boolean;
    public dayOffTypes = {
        holiday: <DayOffType>"HOLIDAY",
        sickLeave: <DayOffType>"SICK_LEAVE",
    };
    public dayOffType: DayOffType = this.dayOffTypes.holiday;
    private start: Date;
    private end: Date;
    private user: { id: string; name: string };
    private currentUser: { id: string; name: string };
    private users: { id: string; name: string; manager: boolean }[];
    private currUserStream = new ReplaySubject<{ id: string; name: string }[]>(
        1
    );

    public i18n;

    constructor(
        private userService: UserService,
        private dateService: DateService,
        private daysOffService: DaysOffService,
        private i18nService: I18NService,
        currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(holidaysI18N);

        const companyUser = currentCompanyUserViewService.companyUser;
        this.currentUser = {
            id: companyUser.id,
            name: companyUser.firstName + " " + companyUser.lastName,
        };
        this.currUserStream.next([this.currentUser]);

        this.isCompanyManager = companyUser.manager;
        if (companyUser.manager) {
            this.userService.getUsersForCompany().subscribe(
                (companyUsers) =>
                    (this.users = companyUsers.companyUsers.map((cu) => {
                        return {
                            manager: cu.manager,
                            id: cu.id,
                            name: cu.name,
                        };
                    }))
            );
        }

        this.actionStream.subscribe((action) => {
            if (action === Action.OK) {
                this.add();
            }
            this.clear();
        });
        this.selectedUsers.subscribe((u) => (this.user = u[0]));
    }
    public ngOnInit() {
        this.selectedDateRangeObservable.subscribe((dateRange: DateRange) => {
            this.start = dateRange.from;
            this.end = dateRange.to;
        });
    }
    public add() {
        //a manager can select user, for which he adds holidays. Other users can add only for themselves
        const user = this.user || this.currentUser;
        this.daysOffService.addDaysOff(
            user.id,
            this.start,
            this.end,
            this.dayOffType
        );
        this.clear();
    }
    public isAddEnabled() {
        return this.start && this.end;
    }
    public clear() {
        this.start = null;
        this.end = null;
        this.dayOffType = this.dayOffTypes.holiday;
    }

    public switchDayOffType(type) {
        this.dayOffType = type;
    }

    public noop() {
        return "";
    }
}
