<div *ngIf="ready" [runTour]="tourName">
    <ts-user-header
        [userId]="userId"
        *ngIf="userId !== currentUserId"
    ></ts-user-header>
    <timesheet-table
        [timesheetParametersObservable]="timesheetParametersObservable"
        [currentUserId]="currentUserId"
        [userIdObservable]="userIdObservable"
        [fixedAddBtnObservable]="fixedAddBtnObservable"
        [dropdownOpensUpwards]="isNoPlaceForDropdown"
    ></timesheet-table>
</div>
