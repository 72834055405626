import { Component } from "@angular/core";
import { Message, MessageService } from "services/message/MessageService";

@Component({
    selector: "message-header",
    templateUrl: "message-header.html",
    host: {
        "[class.c-message-header]": "true",
        "[class.c-message-header--fade-in]": "this.accepted",
        "[class.c-message-header--fade-out]": "this.dismissed",
    },
})
export class MessageHeaderComponent {
    private text;

    constructor(messageService: MessageService) {
        messageService.messages.subscribe((message) => this.accept(message));
    }

    public accept(message: Message): void {
        setTimeout(
            () =>
                (this.text = message ? cutTextBetweenWords(message.text) : null)
        );
    }

    public close(): void {
        this.accept(null);
    }

    public get message(): string {
        return this.text;
    }

    public get accepted(): boolean {
        return !!this.text;
    }

    public get dismissed(): boolean {
        return this.text === null;
    }
}

function cutTextBetweenWords(
    text: string,
    [cutSpanLeft, cutSpanRight] = [140, 150]
): string {
    if (text && text.length > cutSpanLeft) {
        const cut = Math.min(text.indexOf(" ", cutSpanLeft), cutSpanRight);
        text = text.substring(0, cut) + " […]";
    }

    return text;
}
