<generic-dropdown
    [items]="currencies"
    [selection]="selectedCurrency"
    [multiSelect]="false"
    [allowNoValue]="false"
    [initialSelectionStream]="preselected"
    [selectMessage]="i18n.users"
    [selectOnSwitch]="false"
    [searchPlaceholder]="i18n.findUserPlaceholder"
    class="c-generic-dropdown--contained"
>
</generic-dropdown>
