import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from "@angular/core";
import {
    fromEvent,
    Observable,
    ReplaySubject,
    Subject,
    Subscription,
} from "rxjs";
import { debounceTime, filter, withLatestFrom } from "rxjs/operators";
import * as _ from "lodash";
import { Token } from "models/token/Token";
import { Project } from "models/projects/Project";
import { ResetSearchTextToken } from "components/genericdropdown/GenericDropdownComponent";
import { Task } from "models/timesheet/Task";
import { ProjectService } from "services/projects/ProjectService";
import {
    TSActivity,
    tsActivityEmpty,
    tsActivityMergeFunctions,
} from "models/timesheet/TSActivity";
import { DateService } from "services/date/DateService";
import { TimesheetService } from "services/timesheet/TimesheetService";
import { getTimesheetTaskWithId, Timesheet } from "models/timesheet/Timesheet";
import { TSActivityComponent } from "../activity/TSActivityComponent";
import { NavigableRowOfActivities } from "../navigation/NavigableRowOfActivities";
import { KEY_CODE } from "constants/KeyCode";
import { timesheetI18N } from "../i18n/timesheet-i18n";
import { I18NService } from "services/i18n/I18NService";
import { FeatureToggleService } from "services/featuretoggle/FeatureToggleService";

const FORM_INPUTS =
    ".c-ts-project-selector__input, " +
    ".c-generic-dropdown__activator, .c-open-activity__input, " +
    ".c-add-task-row__btn--add, " +
    ".c-add-task-row__btn--clear";

@Component({
    selector: "add-task-row",
    templateUrl: "add-task-row.html",
    host: {
        "[class.o-ts-row]": "true",
        "[class.c-add-task-row]": "true",
        "[class.c-add-task-row--focus]": "showProjectIndicator()",
        "(document:keydown)": "onKeyDown($event)",
    },
})
export class AddTaskRowComponent
    extends NavigableRowOfActivities
    implements OnInit, OnDestroy
{
    @Input() public userId: string;
    @Input() public userHolidaysDays: Date[];
    @Input() public userSealDate: Date;
    @Input() public timesheetObservable: Observable<Timesheet>;
    @Input() public userIdObservable: ReplaySubject<string>;
    @Input() public dropdownOpensUpwards = false;

    public i18n;

    public activateProjectsStream = new Subject<Token>();
    public projectSubject: Subject<Project> = new ReplaySubject<Project>(1);
    public mostRecentProjectStream: Subject<Project> =
        new ReplaySubject<Project>(1);

    public activateTasksStream = new Subject<Token>();
    public taskSubject = new ReplaySubject<Task>(1);
    public candidateTaskSubject = new ReplaySubject<Task>(1);
    public resetTaskNameSubject: Subject<ResetSearchTextToken> =
        new Subject<ResetSearchTextToken>();

    @ViewChildren(TSActivityComponent)
    public activityComponents: QueryList<TSActivityComponent>;

    public project: Project = null;

    private selectedTask: { id: string; name: string } = null;

    private candidateTask: { id: string; name: string } = null;

    private activities: TSActivity[];

    private focusOutSubscription: Subscription;

    private focused = false;

    private cellToBeFocusedIdx: number = undefined;

    private shouldFocusCell = true;

    private taskInputValue: string;

    private displayDisabledCellTooltip = false;

    constructor(
        private projectService: ProjectService,
        private dateService: DateService,
        private timesheetService: TimesheetService,
        protected elementRef: ElementRef,
        public i18nService: I18NService,
        public featureToggleService: FeatureToggleService
    ) {
        super();

        this.i18n = i18nService.extractCurrentTranslation(timesheetI18N);
    }

    public ngOnInit() {
        this.timesheetObservable.subscribe((t) => {
            this.recalculateActivities(t);
            this.userIdObservable.next(this.userId);
        });

        this.projectSubject.subscribe((project: Project) => {
            if (!project || !this.isTheSameProjectName(project.name)) {
                this.resetTaskName();
            }
            this.project = project;
        });

        this.candidateTaskSubject.subscribe(
            (task) =>
                (this.candidateTask = !task
                    ? null
                    : { id: task.id, name: task.name })
        );

        this.taskSubject
            .pipe(withLatestFrom(this.timesheetObservable))
            .subscribe(([task, timesheet]) => {
                this.selectedTask = !task
                    ? null
                    : { id: task.id, name: task.name };
                this.recalculateActivities(timesheet);
                if (task !== null && this.shouldFocusCell) {
                    setTimeout(() => {
                        if (this.cellToBeFocusedIdx === undefined) {
                            this.focusTodayOrFirstActivity();
                        } else {
                            this.focus(
                                this.getActivities()[this.cellToBeFocusedIdx]
                            );
                        }
                    });
                }
            });

        if (this.userId) {
            this.projectService
                .getMostRecentProjectForUser(this.userId)
                .subscribe((project) => {
                    this.resetTaskName();

                    this.projectSubject.next(project);
                    this.mostRecentProjectStream.next(project);
                });
        }

        this.focusOutSubscription = fromEvent(
            this.elementRef.nativeElement,
            "focusout"
        )
            .pipe(
                debounceTime(200),
                filter(() => !this.containsActiveElement())
            )
            .subscribe(() => {
                this.focused = false;
                this.reset();
            });

        fromEvent(this.elementRef.nativeElement, "mouseover")
            .pipe(debounceTime(800))
            .subscribe(() => (this.displayDisabledCellTooltip = true));

        fromEvent(this.elementRef.nativeElement, "mouseout").subscribe(
            () => (this.displayDisabledCellTooltip = false)
        );
    }

    public setCellToBeFocusedIdx(idx: number) {
        this.cellToBeFocusedIdx = idx;
        this.shouldFocusCell = true;
    }

    private containsActiveElement(): boolean {
        return this.elementRef.nativeElement.contains(document.activeElement);
    }

    public ngOnDestroy() {
        this.focusOutSubscription.unsubscribe();
    }

    private recalculateActivities(timesheet: Timesheet) {
        const newActivities =
            this.getActivitiesForSelectedTask(timesheet) ||
            this.createEmptyActivitiesSequence(timesheet);

        if (
            this.activities &&
            tsActivityMergeFunctions.match(this.activities[0], newActivities[0])
        ) {
            this.activities = this.activities.map((originalActivity, index) => {
                const newActivity = newActivities[index];
                return tsActivityMergeFunctions.merge(
                    originalActivity,
                    newActivity
                );
            });
        } else {
            this.activities = newActivities;
        }
    }

    private getActivitiesForSelectedTask(timesheet: Timesheet) {
        const task = getTimesheetTaskWithId(
            timesheet,
            this.selectedTask && this.selectedTask.id
        );
        return task && task.activities;
    }

    private createEmptyActivitiesSequence(timesheet: Timesheet) {
        return this.dateService
            .getDateSpan(timesheet.displayRange.from, timesheet.displayRange.to)
            .map((date: Date) => tsActivityEmpty(date));
    }

    private isTheSameProjectName(projectName: string): boolean {
        return !!this.project && this.project.name === projectName;
    }

    public isTodaySealed(): boolean {
        return (
            this.userSealDate != null &&
            (this.dateService.isAfter(this.userSealDate, new Date()) ||
                this.dateService.isToday(this.userSealDate))
        );
    }

    public isHolidaysActivity(activity: TSActivity): boolean {
        return this.timesheetService.isHolidaysActivity(
            this.userHolidaysDays,
            activity
        );
    }

    public isSealedActivity(activity: TSActivity): boolean {
        return (
            this.userSealDate != null &&
            (this.dateService.isBefore(activity.date, this.userSealDate) ||
                this.dateService.isEqual(activity.date, this.userSealDate))
        );
    }

    public reset() {
        this.resetTaskName();
    }

    public onKeyDown(event: KeyboardEvent): void {
        if (
            ((event.which || event.keyCode) === KEY_CODE.KEY_TAB ||
                (event.which || event.keyCode) === KEY_CODE.KEY_RETURN) &&
            !this.isEventTargetButton(event.target)
        ) {
            event.stopPropagation();
            event.preventDefault();

            const inputsList =
                this.elementRef.nativeElement.querySelectorAll(FORM_INPUTS);
            const currentIdx = this.getCurrentIdx(event.target, inputsList);

            inputsList[
                this.getNextInputIdx(
                    event.shiftKey,
                    currentIdx,
                    inputsList.length
                )
            ].focus();
        }
    }

    private isEventTargetButton(target: EventTarget) {
        return target instanceof HTMLElement && target.tagName === "BUTTON";
    }

    private getNextInputIdx(shiftKey, currentIdx, listLength): number {
        const [firstIdx, lastIdx] = [0, listLength - 1];
        let nextIdx;

        if (shiftKey) {
            const prevElement = currentIdx - 1;

            if (prevElement < 0) {
                nextIdx = lastIdx;
            } else {
                nextIdx = prevElement;
            }
        } else {
            const nextElement = currentIdx + 1;

            if (nextElement > lastIdx) {
                nextIdx = firstIdx;
            } else {
                nextIdx = nextElement;
            }
        }

        return nextIdx;
    }

    private getCurrentIdx(target, inputsList): number {
        const targetInput: HTMLInputElement = <HTMLInputElement>target;
        const targetInputParentName = targetInput.parentNode.nodeName;
        let currentIdx = Array.from(inputsList).indexOf(targetInput);

        if (targetInputParentName === "LI" && currentIdx === -1) {
            currentIdx = 1;
        }

        return currentIdx;
    }

    private resetTaskName() {
        this.resetTaskNameSubject.next(ResetSearchTextToken.RESET);
        this.taskInputValue = undefined;
    }

    protected getActivities(): TSActivityComponent[] {
        return this.activityComponents.toArray();
    }

    public focusTodayOrFirstActivityIfTaskSelected(event?): void {
        if (event) {
            event.stopPropagation();
        }
        if (this.isTaskSelected()) {
            this.focusTodayOrFirstActivity();
        }
    }

    private focusTodayOrFirstActivity() {
        const activityForToday = this.getActivities().find(
            (activityComponent) =>
                this.dateService.isToday(activityComponent.activity.date)
        );
        if (
            activityForToday &&
            !this.isSealedActivity(activityForToday.activity)
        ) {
            this.focus(activityForToday);
        } else {
            const firstActivity = this.getActivities().filter(
                (ac) => !this.isSealedActivity(ac.activity)
            )[0];
            if (firstActivity != null) {
                this.focus(firstActivity);
            }
        }
    }

    public focusLastActivity(): void {
        this.focus(this.getActivities()[6]);
    }

    private focus(activityComponent: TSActivityComponent) {
        if (
            !this.isSealedActivity(activityComponent.activity) ||
            (this.userSealDate != null &&
                this.dateService.isAfter(
                    activityComponent.activity.date,
                    this.userSealDate
                ))
        ) {
            activityComponent.setOpen(true);
        }
    }

    public navigateOutOf(
        activityComponent: TSActivityComponent,
        event: KeyboardEvent
    ) {
        activityComponent.saveDurationAndClose();
        event.stopPropagation();
    }

    @HostListener("focusin")
    public focusin() {
        this.focused = true;
    }

    public showProjectIndicator(): boolean {
        return this.focused || this.isDirty();
    }

    public isTaskSelected(): boolean {
        return (
            !!this.candidateTask ||
            (this.isTaskInputDirty() && this.isCreatingTasksInProjectAllowed())
        );
    }

    public isCreatingTasksInProjectAllowed() {
        return this.project && !this.project.imported;
    }

    public isCurrentWeek(): boolean {
        return (
            this.activities &&
            this.activities.some((activity) =>
                this.dateService.isToday(activity.date)
            )
        );
    }

    public taskInputChange(newValue: string) {
        this.taskInputValue = newValue;
    }

    public isTaskInputDirty() {
        return !!this.taskInputValue;
    }

    public preventCellFocus() {
        this.shouldFocusCell = false;
    }

    public get disabledCellTooltip() {
        if (this.displayDisabledCellTooltip) {
            return this.i18n.chooseTaskFirst;
        } else {
            return undefined;
        }
    }

    private isDirty(): boolean {
        return (
            this.selectedTask !== null ||
            (this.activities &&
                this.activities.some(
                    (activity) =>
                        !_.isUndefined(activity.minutes) &&
                        activity.minutes !== 0
                ))
        );
    }
}
