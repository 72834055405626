import { userPaymentI18NDe } from "./user-payment-i18n-de";
import { userPaymentI18NPl } from "./user-payment-i18n-pl";

const userPaymentI18NEn = {
    hourlyRate: "Hourly rate",
    monthlyRate: "Monthly rate",
    fullTime: "Full-time",
    partTime: "Part-time",
    ratePlaceholder: "Value",
    invalid: "Invalid",
    currency: "Currency",
    currentRate: "Current rate",
    payment: "Payment",
    history: "History",
    from: "From",
    to: "To",
};

export const userPaymentI18N = {
    de: userPaymentI18NDe,
    en: userPaymentI18NEn,
    pl: userPaymentI18NPl,
};
