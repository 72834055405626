<message-header></message-header>
<modal-core>
    <div class="c-reset-password__header">
        <h1 class="c-reset-password__header-title">{{i18n.newPassword}}</h1>
    </div>

    <p class="u-form-group">{{i18n.passwordValidationRequirements}}</p>

    <form class="c-reset-password__form" (ngSubmit)="submit($event)">
        <signup-password></signup-password>
        <div
            *ngIf="passwordSet"
            class="c-send-reset-password-email__valid u-form-group"
        >
            {{i18n.passwordHasBeenReset}}
        </div>
        <div
            *ngIf="passwordInvalid"
            class="c-send-reset-password-email__invalid u-form-group"
        >
            {{i18n.invalidPassword}}
        </div>
        <div class="u-form-group">
            <button
                *ngIf="!passwordSet"
                class="o-btn o-btn--large o-btn--primary c-reset-password__form-btn"
                [disabled]="!canSubmit()"
                type="submit"
            >
                {{i18n.save}}
            </button>
            <button
                *ngIf="passwordSet"
                class="o-btn o-btn--large o-btn--primary"
                (click)="gotoLogin($event)"
            >
                {{i18n.gotoLogin}}
            </button>
        </div>
    </form>
</modal-core>
