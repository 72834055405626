import { ProjectIncomeAndExpense } from "./ProjectIncomeAndExpense";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const ProjectIncomeAndExpenseReport = z.object({
    incomesAndExpenses: ProjectIncomeAndExpense.array(),
    currency: z.string(),
});

export type ProjectIncomeAndExpenseReport = z.infer<
    typeof ProjectIncomeAndExpenseReport
>;

export function incomeAndExpenseReportMaxValue(
    report: ProjectIncomeAndExpenseReport
) {
    return report.incomesAndExpenses.reduce(
        (acc, iAndE) => Math.max(acc, iAndE.income, iAndE.expense),
        0
    );
}
