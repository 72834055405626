import { reportFilterI18NDe } from "./reportfilter-i18n-de";
import { reportFilterI18NPl } from "./reportfilter-i18n-pl";

const datepicker = {
    ranges: [
        "Today",
        "This Week",
        "This Month",
        "This Year",
        "Yesterday",
        "Last Week",
        "Last Month",
        "Last Year",
    ],
    datepickerNames: ["From", "To"],
    dayInitials: {
        sundayInitial: "Sun",
        mondayInitial: "Mon",
        tuesdayInitial: "Tue",
        wednesdayInitial: "Wed",
        thursdayInitial: "Thu",
        fridayInitial: "Fri",
        saturdayInitial: "Sat",
    },
};

const reportFilterI18NEn = {
    currency: "Currency",
    from: "From",
    to: "To",
    keywordInputPlaceholder: "Keyword (i.e. task name)",
    users: "Users / Teams",
    projects: "Projects / Groups",
    projectsSwitch: "Projects",
    usersSwitch: "Users",
    teamsSwitch: "Teams",
    teamDisplay: "Team: ",
    groupsSwitch: "Groups",
    groupDisplay: "Group: ",
    findUserPlaceholder: "Find User",
    findUserTeamPlaceholder: "Find User / Team",
    findProjectPlaceholder: "Find Project",
    findProjectGroupPlaceholder: "Find Project / Group",
    monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    monthShortNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ],
    holidays: "Holidays",
    holidaysIncluded: "Include holiday costs",
    holidaysExcluded: "Exclude holiday costs",
    yes: "Yes",
    no: "No",
    datepicker: datepicker,
    noFilters: "No filters selected",
    billability: "Billability",
    billable: "Billable",
    nonBillable: "Non-billable",
};

export const reportFilterI18N = {
    de: reportFilterI18NDe,
    pl: reportFilterI18NPl,
    en: reportFilterI18NEn,
};
