import { Component, ElementRef, Input, OnChanges } from "@angular/core";
import * as _ from "lodash";
import { DateService } from "services/date/DateService";
import { DaysOffService } from "services/daysOff/DaysOffService";
import { DateRange } from "models/daterange/DateRange";
import { DaysOff } from "models/daysOff/DaysOff";
import { User } from "models/users/User";

@Component({
    selector: "week",
    templateUrl: "week.html",
    host: { "[class.c-week]": "true" },
})
export class WeekComponent implements OnChanges {
    @Input() public month: number; // 0-based
    @Input() public year: number;
    @Input() public startDate: Date; // monday of the week
    @Input() public last: boolean;
    @Input() public first: boolean;
    @Input() public currentUser: User;
    @Input() public daysOff: DaysOff;
    @Input() public currentSelection: DateRange;
    private prevSelection = null;
    private prevDaysOff: DaysOff = null;
    public dayClassNames = [
        "c-week__day--mon",
        "c-week__day--tue",
        "c-week__day--wed",
        "c-week__day--thu",
        "c-week__day--fri",
        "c-week__day--sat",
        "c-week__day--sun",
    ];

    public days: Date[];
    private dayClasses: string[] = [];

    constructor(
        private dateService: DateService,
        private daysOffService: DaysOffService,
        private elemRef: ElementRef
    ) {}

    public ngOnChanges() {
        this.days = this.dateService.getWeek(this.startDate);
        if (
            this.prevDaysOff !== this.daysOff ||
            this.prevSelection !== this.currentSelection
        ) {
            for (let i = 0; i < this.days.length; i++) {
                this.dayClasses[i] =
                    this.holidayClass(this.days[i]) +
                    this.getPreselectionClass(this.days[i]);
            }
        }
        this.prevDaysOff = this.daysOff;
        this.prevSelection = this.currentSelection;
    }

    public isPrevMonth(date: Date) {
        return date.getFullYear() < this.year || date.getMonth() < this.month;
    }
    public isNextMonth(date: Date) {
        return date.getFullYear() > this.year || date.getMonth() > this.month;
    }
    public isOtherMonth(date: Date) {
        return this.isPrevMonth(date) || this.isNextMonth(date);
    }
    public isToday(date: Date) {
        return this.dateService.isToday(date);
    }
    public holidayClass(date: Date) {
        if (!this.daysOff || !this.currentUser) {
            return " ";
        }
        let dayHolidays: {
            userId: string;
            begin: Date;
            end: Date;
            type: string;
        }[] = [];
        for (let i = 0; i < this.daysOff.daysOff.length; i++) {
            const userHolidaysForDay = this.daysOff.daysOff[i].daysOff.filter(
                (dayOff) =>
                    this.dateService.isInRange(date, dayOff.begin, dayOff.end)
            );
            dayHolidays = dayHolidays.concat(
                userHolidaysForDay.map((h) => {
                    return {
                        userId: this.daysOff.daysOff[i].user.id,
                        begin: h.begin,
                        end: h.end,
                        type: h.type,
                    };
                })
            );
        }

        const beginHolidays = dayHolidays.filter((holiday) =>
            this.dateService.isEqual(holiday.begin, date)
        );
        const endHolidays = dayHolidays.filter((holiday) =>
            this.dateService.isEqual(holiday.end, date)
        );
        const ownerHolidays = dayHolidays.filter(
            (holiday) =>
                holiday.userId === this.currentUser.id &&
                holiday.type === "HOLIDAY"
        );
        const otherHolidays = dayHolidays.filter(
            (holiday) =>
                holiday.userId !== this.currentUser.id &&
                holiday.type === "HOLIDAY"
        );
        const ownerSickDays = dayHolidays.filter(
            (sickDay) =>
                sickDay.userId === this.currentUser.id &&
                sickDay.type === "SICK_LEAVE"
        );
        const otherSickDays = dayHolidays.filter(
            (sickDay) =>
                sickDay.userId !== this.currentUser.id &&
                sickDay.type === "SICK_LEAVE"
        );
        let ret = "";
        if (
            ownerHolidays.length &&
            !otherHolidays.length &&
            !otherSickDays.length
        ) {
            ret = " c-week__day--current-user-holiday";
        } else if (
            ownerSickDays.length &&
            !otherHolidays.length &&
            !otherSickDays.length
        ) {
            ret = " c-week__day--current-user-sick-day";
        } else if (
            !ownerSickDays.length &&
            !ownerHolidays.length &&
            !otherSickDays.length &&
            otherHolidays.length
        ) {
            ret = " c-week__day--holiday";
        } else if (
            !ownerHolidays.length &&
            !otherHolidays.length &&
            otherSickDays.length
        ) {
            ret = " c-week__day--sick-day";
        } else if (
            !ownerHolidays.length &&
            otherHolidays.length &&
            (otherSickDays.length || ownerSickDays.length)
        ) {
            ret = " c-week__day--overlapping-holiday-sick-day";
        } else if (
            ownerHolidays.length &&
            otherHolidays.length &&
            otherSickDays.length
        ) {
            ret = " c-week__day--overlapping-user-holiday-sick-day";
        } else if (
            ownerHolidays.length &&
            otherHolidays.length &&
            !otherSickDays.length
        ) {
            ret = " c-week__day--overlapping-holidays";
        } else if (ownerHolidays.length && otherSickDays.length) {
            ret = " c-week__day--overlapping-sick-day";
        }

        if (
            beginHolidays.length &&
            beginHolidays.length === dayHolidays.length &&
            endHolidays.length &&
            endHolidays.length === dayHolidays.length
        ) {
            ret += " c-week__day--one-day-holiday";
        } else {
            if (
                beginHolidays.length &&
                beginHolidays.length === dayHolidays.length
            ) {
                ret += " c-week__day--holiday-start";
            }
            if (
                endHolidays.length &&
                endHolidays.length === dayHolidays.length
            ) {
                ret += " c-week__day--holiday-end";
            }
        }
        return ret;
    }
    public getItemClass(dayIdx) {
        return this.dayClasses[dayIdx] || "";
    }
    private getPreselectionClass(day) {
        let ret = "";

        if (this.isInPreselectedRange(day)) {
            const range = this.currentSelection;

            const isFirstSelectedDay = this.dateService.isEqual(
                range.from,
                day
            );
            const isLastSelectedDay = this.dateService.isEqual(range.to, day);

            const isHolidaysDay = (date: Date) => {
                const currentUserDaysOff = this.daysOff.daysOff.find(
                    (userDaysOff) => userDaysOff.user.id === this.currentUser.id
                );

                return (
                    currentUserDaysOff &&
                    _.some(currentUserDaysOff.daysOff, (daysOff) =>
                        this.dateService.isInRange(
                            date,
                            daysOff.begin,
                            daysOff.end
                        )
                    )
                );
            };

            const previousDay = this.dateService.getPreviousDay(day);
            const nextDay = this.dateService.getNextDay(day);
            const bookedHolidaysDayBefore = isHolidaysDay(previousDay);
            const bookedHolidaysDayAfter = isHolidaysDay(nextDay);
            const isFirstDayOfWeek = this.dateService.isEqual(
                day,
                this.dateService.getMondayOfWeek(day)
            );
            const isLastDayOfWeek = this.dateService.isEqual(
                day,
                this.dateService.getSundayOfWeek(day)
            );
            const isFirstDayOfMonth = this.dateService.isEqual(
                day,
                this.dateService.getFirstDayOfMonth(day)
            );
            const isLastDayOfMonth = this.dateService.isEqual(
                day,
                this.dateService.getLastDayOfMonth(day)
            );
            const isFirstDayOfWeekOfMonth =
                isFirstDayOfMonth || isFirstDayOfWeek;
            const isLastDayOfWeekOfMonth = isLastDayOfMonth || isLastDayOfWeek;
            const isExtremeDayOfWeekOfMonth =
                isFirstDayOfWeekOfMonth || isLastDayOfWeekOfMonth;
            const bookedHolidaysDayBeforeAndDayIsNotFirstDayOfWeekOfMonth =
                bookedHolidaysDayBefore && !isFirstDayOfWeekOfMonth;
            const bookedHolidaysDayAfterAndDayIsNotLastDayOfWeekOfMonth =
                bookedHolidaysDayAfter && !isLastDayOfWeekOfMonth;

            if (
                bookedHolidaysDayBefore &&
                bookedHolidaysDayAfter &&
                !isExtremeDayOfWeekOfMonth
            ) {
                ret = " c-week__day--one-day-holiday-preselect-between";
            } else if (isFirstSelectedDay && isLastSelectedDay) {
                if (
                    (!bookedHolidaysDayAfter && !bookedHolidaysDayBefore) ||
                    (bookedHolidaysDayBefore &&
                        !bookedHolidaysDayAfter &&
                        !this.dateService.equalMonthOfYear(previousDay, day)) ||
                    (!bookedHolidaysDayBefore &&
                        bookedHolidaysDayAfter &&
                        !this.dateService.equalMonthOfYear(nextDay, day))
                ) {
                    ret = " c-week__day--one-day-holiday-preselect";
                } else if (bookedHolidaysDayBefore) {
                    ret = " c-week__day--holiday-end-preselect-expanded";
                } else if (bookedHolidaysDayAfter) {
                    ret = " c-week__day--holiday-start-preselect-expanded";
                }
            } else if (isFirstSelectedDay) {
                if (bookedHolidaysDayBeforeAndDayIsNotFirstDayOfWeekOfMonth) {
                    ret =
                        " c-week__day--current-user-holiday-preselect-expanded-left";
                } else if (
                    bookedHolidaysDayAfterAndDayIsNotLastDayOfWeekOfMonth
                ) {
                    ret = " c-week__day--holiday-start-preselect-expanded";
                } else {
                    ret = " c-week__day--holiday-start-preselect";
                }
            } else if (isLastSelectedDay) {
                if (bookedHolidaysDayAfterAndDayIsNotLastDayOfWeekOfMonth) {
                    ret =
                        " c-week__day--current-user-holiday-preselect-expanded-right";
                } else if (
                    bookedHolidaysDayBeforeAndDayIsNotFirstDayOfWeekOfMonth
                ) {
                    ret = " c-week__day--holiday-end-preselect-expanded";
                } else {
                    ret = " c-week__day--holiday-end-preselect";
                }
            } else {
                if (bookedHolidaysDayBeforeAndDayIsNotFirstDayOfWeekOfMonth) {
                    ret =
                        " c-week__day--current-user-holiday-preselect-expanded-left";
                } else if (
                    bookedHolidaysDayAfterAndDayIsNotLastDayOfWeekOfMonth
                ) {
                    ret =
                        " c-week__day--current-user-holiday-preselect-expanded-right";
                } else {
                    ret = " c-week__day--current-user-holiday-preselect";
                }
            }
        }

        return ret;
    }
    private isInPreselectedRange(day: Date) {
        if (!!this.daysOff && !!this.currentSelection) {
            return this.dateService.isInRange(
                day,
                this.currentSelection.from,
                this.currentSelection.to
            );
        } else {
            return false;
        }
    }
}
