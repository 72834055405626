import { ProjectPricingSnapshot } from "./ProjectPricingSnapshot";
import { ProjectPricingType } from "./ProjectPricingType";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const ProjectPricingHistory = z.object({
    type: z.string().transform((str) => ProjectPricingType[str]),
    snapshots: ProjectPricingSnapshot.array(),
});

export type ProjectPricingHistory = z.infer<typeof ProjectPricingHistory>;
