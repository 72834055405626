<ul class="c-project-report__container">
    <li class="c-project-report__header-row">
        <div class="c-project-report__project-label-column">
            {{i18n.project}}
        </div>
        <div class="c-project-report__chart-scale-head-row">
            <div
                *ngFor="let scale of scaleObservable | async"
                class="c-project-report__chart-scale-head-column"
            >
                {{scale | currency: currency:true:'1.0-2'}}
            </div>
            <div
                class="c-project-report__chart-scale-head-column c-project-report__chart-scale-head-column--last"
            ></div>
        </div>
    </li>

    <li></li>
    <li
        *ngFor="let project of chartDataObservable | async"
        class="c-project-report__chart-row c-project-report__chart-row--{{project.color.toLowerCase()}}"
        (click)="onProjectSelected(project)"
    >
        <div
            class="c-project-report__project-label-column c-project-report__project-label-column--project"
            showOverflownTitle
        >
            {{project.name}}
        </div>
        <div class="c-project-report__chart-scale-grid">
            <div
                *ngFor="let scaleUnit of scaleObservable | async"
                class="c-project-report__chart-scale-column"
            ></div>
            <div
                class="c-project-report__chart-scale-column c-project-report__chart-scale-column--last"
            ></div>
            <div
                [ngStyle]="{'width': project.incomeBarLength + '%'}"
                class="u-tooltip--absolute u-tooltip--right c-project-report__project-bar c-project-report__project-bar--positive"
                [attr.data-tooltip]="project.income| currency: currency:true:'1.0-2'"
            ></div>
            <div
                [ngStyle]="{'width': project.expenseBarLength + '%'}"
                class="u-tooltip--absolute u-tooltip--right c-project-report__project-bar c-project-report__project-bar--negative"
                [attr.data-tooltip]="project.expense | currency: currency:true:'1.0-2'"
            ></div>
        </div>
    </li>
</ul>

<table class="c-project-report__mobile">
    <thead>
        <tr
            class="c-project-report__mobile-row c-project-report__mobile-row--head"
        >
            <th class="c-project-report__mobile-name">{{i18n.project}}</th>
            <th class="c-project-report__mobile-income">{{i18n.income}}</th>
            <th class="c-project-report__mobile-expanses">{{i18n.expenses}}</th>
        </tr>
    </thead>
    <tbody>
        <tr
            *ngFor="let project of chartDataObservable | async"
            class="c-project-report__mobile-row c-project-report__mobile-row--{{project.color.toLowerCase()}}"
        >
            <td class="c-project-report__mobile-name">{{project.name}}</td>
            <td class="c-project-report__mobile-income">
                {{project.income| currency: currency:true:'1.0-2'}}
            </td>
            <td class="c-project-report__mobile-expanses">
                {{project.expense | currency: currency:true:'1.0-2'}}
            </td>
        </tr>
    </tbody>
</table>
