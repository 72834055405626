import { Injectable } from "@angular/core";
import * as _ from "lodash";

@Injectable()
export class ClientIdService {
    private static NUMBER_OF_LETTERS = 26;
    private static LOWER_CASE_LETTERS_START = 97;
    private static ID_LENGTH = 10;

    private clientId: string;

    constructor() {
        this.clientId = this.generateRandomString(ClientIdService.ID_LENGTH);
    }

    public getClientId() {
        return this.clientId;
    }

    private generateRandomString(length: number) {
        const randomLetter = () => {
            const asciiCode =
                Math.floor(Math.random() * ClientIdService.NUMBER_OF_LETTERS) +
                ClientIdService.LOWER_CASE_LETTERS_START;
            return String.fromCharCode(asciiCode);
        };

        return _.range(0, length).reduce((acc) => acc + randomLetter(), "");
    }
}
