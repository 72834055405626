import { projectModalI18NDe } from "./projectmodal-i18n-de";
import { projectModalI18NPl } from "./projectmodal-i18n-pl";

const projectModalI18NEn = {
    addProjectTitle: "Add project to your Timepot",
    chooseColor: "Choose color",
    editProjectTitle: "Edit project",
    projectNamePlaceholder: "Your Timepot project name",
    add: "Add",
    save: "Save",
    defaultTasksBillability: "Default tasks billability",
    billable: "Billable",
    nonBillable: "Non-billable",
};

export const projectModalI18N = {
    de: projectModalI18NDe,
    en: projectModalI18NEn,
    pl: projectModalI18NPl,
};
