import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import { FilterSetting } from "models/reports/filters/FilterSetting";
import { I18NService } from "services/i18n/I18NService";
import { reportFilterI18N } from "../i18n/reportfilter-i18n";

@Component({
    selector: "filter-display-field",
    templateUrl: "filter-display-field.html",
    host: { "[class.c-filter-display-field]": "true" },
})
export class FilterDisplayFieldComponent implements OnInit {
    @Input() public filterSubject: Subject<FilterSettings>;

    public filterSettings: FilterSettings;
    public i18n;

    constructor(private i18nService: I18NService) {
        this.i18n =
            this.i18nService.extractCurrentTranslation(reportFilterI18N);
    }

    public deleteFilterSetting(filterSetting: FilterSetting): void {
        this.filterSettings.settings.splice(
            this.filterSettings.settings.indexOf(filterSetting),
            1
        );
        this.filterSubject.next(this.filterSettings);
    }

    public ngOnInit() {
        this.filterSubject.subscribe((fs) => {
            this.filterSettings = fs;
        });
    }
}
