import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { Team } from "models/teams/Team";
import { TeamService } from "services/teams/TeamService";
import { I18NService } from "services/i18n/I18NService";

import { Action } from "../../genericmodal/GenericModalComponent";
import { teamsMenuI18N } from "../i18n/teams-menu-i18n";

@Component({
    selector: "remove-team-modal",
    templateUrl: "remove-team-modal.html",
    host: {
        "[class.c-remove-team-modal]": "true",
    },
})
export class RemoveTeamModalComponent implements OnInit {
    @Input() public team: Team;
    @Input() public showStream: Subject<boolean>;

    public actionStream = new Subject<Action>();
    public ready = false;
    public i18n;

    constructor(private teamService: TeamService, i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(teamsMenuI18N);
    }

    public ngOnInit(): void {
        this.actionStream.subscribe((action) => {
            if (action === Action.OK) {
                this.teamService.deleteTeam(this.team.code).subscribe(() => {
                    this.showStream.next(false);
                });
            }
        });
    }
}
