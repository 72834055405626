import { Component } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { I18NService } from "services/i18n/I18NService";
import { PasswordValidator } from "services/validators/PasswordValidator";
import { signupI18N } from "./i18n/signup-i18n";

@Component({
    selector: "signup-password",
    templateUrl: "signup-password.html",
})
export class SignupPasswordComponent {
    private static FIELD_NAME = "password";

    public i18n: (typeof signupI18N)["en"];
    public passwordForm: FormGroup = null;
    public passwordVisibility = false;

    constructor(
        private i18nService: I18NService,
        private formBuilder: FormBuilder
    ) {
        this.i18n = i18nService.extractCurrentTranslation(signupI18N);
        this.passwordForm = this.formBuilder.group({
            password: [
                "",
                Validators.compose([
                    Validators.required,
                    PasswordValidator.invalid,
                ]),
            ],
        });
    }

    public get value(): string {
        return this.getControl().value;
    }

    public getControl(): AbstractControl {
        return this.passwordForm.controls[SignupPasswordComponent.FIELD_NAME];
    }

    public onInput(): void {
        this.getControl().markAsUntouched();
    }

    public isValidPassword(): boolean {
        return !this.getControl().hasError("invalid");
    }

    public isEmpty(): boolean {
        return !this.getControl().value;
    }

    public isValid(): boolean {
        const valid =
            !this.pending() && !this.isEmpty() && this.isValidPassword();
        if (valid) {
            this.getControl().markAsPristine();
            this.getControl().markAsUntouched();
        }
        return valid;
    }

    public touched(): boolean {
        return this.getControl().touched;
    }

    public pending(): boolean {
        return this.getControl().pending;
    }

    public togglePasswordVisibility(): void {
        this.passwordVisibility = !this.passwordVisibility;
    }
}
