import { DateFilterSetting, FilterSetting } from "./FilterSetting";

export class FilterSettings {
    public static defaultSettings(): FilterSettings {
        const defaultSettings = [];
        const today = new Date();
        const firstDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
        );
        defaultSettings.push(new DateFilterSetting(firstDayOfMonth, today));
        return new FilterSettings(defaultSettings);
    }
    constructor(public settings: FilterSetting[]) {}
}
