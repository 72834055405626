import { calendarI18NDe } from "./calendar-i18n-de";
import { calendarI18NPl } from "./calendar-i18n-pl";
const calendarI18NEn = {
    sundayInitial: "S",
    mondayInitial: "M",
    tuesdayInitial: "T",
    wednesdayInitial: "W",
    thursdayInitial: "T",
    fridayInitial: "F",
    saturdayInitial: "S",
    prev: "prev",
    next: "next",
    year: "Year",
    month: "Month",
    week: "Week",
    showHolidays: "Show holidays",
    hideHolidays: "Hide holidays",
    showSickDays: "Show sick days",
    hideSickDays: "Hide sick days",
    daysShort: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
};

export const calendarI18N = {
    de: calendarI18NDe,
    en: calendarI18NEn,
    pl: calendarI18NPl,
};
