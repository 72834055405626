import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import {
    APP_BASE_HREF,
    CommonModule,
    CurrencyPipe,
    DatePipe,
} from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ResetPasswordService } from "services/password/ResetPasswordService";
import { TimesheetService } from "services/timesheet/TimesheetService";
import { ProjectService } from "services/projects/ProjectService";
import { DateService } from "services/date/DateService";
import { UserService } from "services/user/UserService";
import { CompanyService } from "services/company/CompanyService";
import { Logger } from "services/logging/Logger";
import { ConsoleAppender } from "services/logging/ConsoleAppender";
import { BackendAppender } from "services/logging/BackendAppender";
import { I18NService } from "services/i18n/I18NService";
import { CookieService } from "services/cookie/CookieService";
import { TimepotHttpInterceptor } from "services/http/HttpInterceptor";
import { TimeUnitConversionService } from "services/timeunitconversion/TimeUnitConversionService";
import { TimesheetReportService } from "services/timesheetreport/TimesheetReportService";
import { DaysOffService } from "services/daysOff/DaysOffService";
import { AuthService } from "services/auth/AuthService";
import { EmailValidator } from "services/validators/EmailValidator";
import { MessageService } from "services/message/MessageService";
import { IssueAgent, TimepotExceptionHandler } from "TimepotExceptionHandler";
import { UnauthenticatedIssueAgent } from "services/auth/UnauthenticatedIssueAgent";
import { LoggingIssueAgent } from "services/logging/LoggingIssueAgent";
import { MessagingIssueAgent } from "services/issue/MessagingIssueAgent";
import { InvitationService } from "services/invitation/InvitationService";
import { CurrentCompanyUserService } from "services/currentcompanyuser/CurrentCompanyUserService";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { CompanyPickerService } from "services/currentcompanyuser/CompanyPickerService";
import { TimeZoneService } from "services/timezone/TimeZoneService";
import {
    AuthenticatedGuard,
    NotAuthenticatedGuard,
    AdminGuard,
    ManagerGuard,
    ProfileEditGuard,
    TimesheetAccessGuard,
} from "services/auth/ActivationGuards";
import { CountryService } from "services/country/CountryService";
import { TourService } from "services/tour/TourService";
import { ClientIdService } from "services/clientid/ClientIdService";
import { WebSocketNotificationService } from "services/websocketnotification/WebSocketNotificationService";
import { TeamService } from "services/teams/TeamService";
import {
    MinutesEditPipe,
    MinutesViewPipe,
    SecondsViewPipe,
} from "pipes/TimeUnitPipes";
import { TimeDistancePipe } from "pipes/DatePipes";

import { ModalCoreComponent } from "components/common/modal-core/ModalCoreComponent";
import { GoogleAuthButtonComponent } from "components/googleauthbutton/GoogleAuthButtonComponent";
import { LoginComponent } from "components/login/LoginComponent";
import { TaskDropdownComponent } from "components/tasks/taskdropdown/TaskDropdownComponent";
import { GenericDropdownComponent } from "components/genericdropdown/GenericDropdownComponent";
import { PagedDropdownComponent } from "components/pageddropdown/PagedDropdownComponent";
import { ProjectDropdownComponent } from "components/projects/projectdropdown/ProjectDropdownComponent";
import { TeamDropdownComponent } from "components/teams/teamdropdown/TeamDropdownComponent";
import { TimerStartComponent } from "components/timer/timerstart/TimerStartComponent";
import { TimerStopComponent } from "components/timer/timerstop/TimerStopComponent";
import { MessageHeaderComponent } from "components/messageheader/MessageHeaderComponent";
import { HeaderComponent } from "./components/common/header/HeaderComponent";
import { BarChartHorizontalComponent } from "./components/charts/barchart-horizontal/BarChartHorizontalComponent";
import { BarChartComponent } from "./components/charts/barchart/BarChartComponent";
import { DonutChartComponent } from "./components/charts/donutchart/DonutChartComponent";
import { DatepickerHeaderComponent } from "./components/datepicker/datepickerheader/DatepickerHeaderComponent";
import { DatePickerWeekComponent } from "./components/datepicker/datepickermonth/datepickerweek/DatePickerWeekComponent";
import { DatePickerMonthComponent } from "./components/datepicker/datepickermonth/DatePickerMonthComponent";
import { DatePickerComponent } from "./components/datepicker/DatePickerComponent";
import { DatePickerBodyComponent } from "./components/datepicker/datepickerbody/DatePickerBodyComponent";
import { DatePickerRangeComponent } from "./components/datepicker/datepickerrange/DatePickerRangeComponent";
import { GenericBackdropComponent } from "./components/genericbackdrop/GenericBackdropComponent";
import { GenericModalComponent } from "./components/genericmodal/GenericModalComponent";
import { MonthFullComponent } from "./components/holidays/calendar/monthfull/MonthFullComponent";
import { WeekFullComponent } from "./components/holidays/calendar/weekfull/WeekFullComponent";
import { MonthComponent } from "./components/holidays/calendar/month/MonthComponent";
import { WeekComponent } from "./components/holidays/calendar/week/WeekComponent";
import { YearComponent } from "./components/holidays/calendar/year/YearComponent";
import { CalendarHeaderComponent } from "./components/holidays/calendar/header/HeaderComponent";
import { CalendarComponent } from "./components/holidays/calendar/CalendarComponent";
import { ConfiguratorUserEntryComponent } from "./components/holidays/calendarconfigurator/configuratoruserentry/ConfiguratorUserEntryComponent";
import { CalendarConfiguratorComponent } from "./components/holidays/calendarconfigurator/CalendarConfiguratorComponent";
import { UsageIndicatorComponent } from "./components/holidays/usageindicator/UsageIndicatorComponent";
import { HolidaysComponent } from "./components/holidays/HolidaysComponent";
import { AddHolidaysModalComponent } from "./components/holidays/AddHolidaysModalComponent";
import { InviteComponent } from "./components/invite/InviteComponent";
import { InvitationFormComponent } from "./components/invite/InvitationFormComponent";
import { SendResetPasswordEmailComponent } from "./components/password/SendResetPasswordEmailComponent";
import { ResetPasswordComponent } from "./components/password/ResetPasswordComponent";
import { ProfileEditComponent } from "./components/profileedit/ProfileEditComponent";
import { ProfileEditFormComponent } from "./components/profileedit/ProfileEditFormComponent";
import { ProfileEditModalComponent } from "./components/profileedit/ProfileEditModalComponent";
import { MenuEntryComponent } from "./components/navbar/menu-entry/MenuEntryComponent";
import { UserMenuComponent } from "./components/navbar/usermenu/UserMenuComponent";
import { NavbarComponent } from "./components/navbar/NavbarComponent";
import { ProjectEntryComponent } from "./components/projects/project-entry/ProjectEntryComponent";
import { ColorPickerComponent } from "./components/projects/projectmodal/colorpicker/ColorPickerComponent";
import { ProjectModalComponent } from "./components/projects/projectmodal/ProjectModalComponent";
import { ProjectsListComponent } from "./components/projects/projects-list/ProjectsListComponent";
import { ProjectsComponent } from "./components/projects/ProjectsComponent";
import { PaginationComponent } from "./components/pagination/PaginationComponent";
import { SettingsComponent } from "./components/settings/SettingsComponent";

import { ReportDatePickerRangeComponent } from "./components/reportfilter/filterfields/reportdatepickerrange/ReportDatePickerRangeComponent";
import { BillableFilterComponent } from "./components/reportfilter/filterfields/BillableFilterComponent";
import { UsersFilterComponent } from "./components/reportfilter/filterfields/UsersFilterComponent";
import { CurrencyPickerFilterComponent } from "./components/reportfilter/filterfields/CurrencyPickerFilterComponent";
import { FilterDisplayFieldComponent } from "./components/reportfilter/filterfields/FilterDisplayFieldComponent";
import { ProjectsFilterComponent } from "./components/reportfilter/filterfields/ProjectsFilterComponent";
import { TaskFilterFieldComponent } from "./components/reportfilter/filterfields/TaskFilterFieldComponent";
import { HolidaysIncludedFilterComponent } from "./components/reportfilter/filterfields/HolidaysIncludedFilterComponent";
import { TimesheetReportFilterComponent } from "./components/timesheetreports/filter/TimesheetReportFilterComponent";
import { TimesheetReportsComponent } from "./components/timesheetreports/TimesheetReportsComponent";
import { FinancialReportsComponent } from "./components/finances/financialreports/FinancialReportsComponent";
import { FinancialReportFilterComponent } from "./components/finances/financialreports/filter/FinancialReportFilterComponent";
import { ProjectSummaryComponent } from "./components/finances/projectsummary/ProjectSummaryComponent";
import { ProjectReportComponent } from "./components/finances/projectreport/ProjectReportComponent";
import { YearMonthPickerComponent } from "./components/reportfilter/filterfields/doubleyearmonthpicker/yearmonthpicker/YearMonthPickerComponent";
import { YearMonthPickerPopdownComponent } from "./components/reportfilter/filterfields/doubleyearmonthpicker/yearmonthpickerpopdown/YearMonthPickerPopdownComponent";
import { DoubleYearMonthPickerComponent } from "./components/reportfilter/filterfields/doubleyearmonthpicker/DoubleYearMonthPickerComponent";
import { SignupInvitationComponent } from "./components/signup/SignupInvitationComponent";
import { SignupPasswordComponent } from "./components/signup/SignupPasswordComponent";
import { CountryDropdownComponent } from "./components/countrydropdown/CountryDropdownComponent";
import { TaskUsersComponent } from "./components/tasks/taskslist/taskusers/TaskUsersComponent";
import { TasksListComponent } from "./components/tasks/taskslist/TasksListComponent";
import { TSActivityComponent } from "./components/timesheet/activity/TSActivityComponent";
import { AddTaskModalComponent } from "./components/timesheet/addtask/AddTaskModalComponent";
import { EditTaskModalComponent } from "./components/timesheet/edittask/EditTaskModalComponent";
import { TSProjectComponent } from "./components/timesheet/project/TSProjectComponent";
import { TSTaskComponent } from "./components/timesheet/task/TSTaskComponent";
import { TimesheetTableComponent } from "./components/timesheet/timesheet-table/TimesheetTableComponent";
import { DayFooterComponent } from "./components/timesheet/tsfooter/dayfooter/DayFooterComponent";
import { TSFooterComponent } from "./components/timesheet/tsfooter/TSFooterComponent";
import { DayHeaderComponent } from "./components/timesheet/tsheader/dayheader/DayHeaderComponent";
import { TSWeekChangerComponent } from "./components/timesheet/tsheader/tsweekchanger/TSWeekChangerComponent";
import { TSDayChangerComponent } from "./components/timesheet/tsheader/tsdaychanger/TSDayChangerComponent";
import { TSWeekPickerComponent } from "./components/timesheet/tsheader/tsweekpicker/TSWeekPickerComponent";
import { TSHeaderComponent } from "./components/timesheet/tsheader/TSHeaderComponent";
import { TSUserHeaderComponent } from "timepot/components/timesheet/tsuserheader/TSUserHeaderComponent";
import { TimesheetComponent } from "./components/timesheet/TimesheetComponent";
import { TSProjectSelectorComponent } from "./components/timesheet/tsprojectselector/TSProjectSelectorComponent";
import { TooltipComponent } from "./components/tooltip/TooltipComponent";
import { InviteUsersModalComponent } from "./components/users/inviteusers/InviteUsersModalComponent";
import { UserComponent } from "./components/users/user-list/user/UserComponent";
import { UserListComponent } from "./components/users/user-list/UserListComponent";
import { UsersComponent } from "./components/users/UsersComponent";
import { RunTourDirective } from "./components/tour/RunTourDirective";
import { ShowOverflownTitleDirective } from "./components/showoverflowntitle/ShowOverflownTitleDirective";
import { FeedbackModalComponent } from "./components/feedbackmodal/FeedbackModalComponent";
import { TimepotComponent } from "./TimepotComponent";
import { AuthComponent } from "./AuthComponent";
import { routing } from "./timepot.routing";
import { tourI18N } from "./services/tour/i18n/tour-i18n";
import { TourDefinitions } from "services/tour/TourDefinitions";
import { TimepotTours } from "./services/tour/TimepotTours";
import { CallToActionComponent } from "./components/demo/calltoaction/CallToActionComponent";
import { BarchartDataService } from "./services/barchart/BarchartDataService";
import { DemoService } from "./services/demo/DemoService";
import { PerfectScrollbarService } from "services/perfectscrollbar/PerfectScrollbarService";
import { FeatureToggleService } from "services/featuretoggle/FeatureToggleService";
import { DisplayService } from "services/display/DisplayService";
import { AddTaskRowComponent } from "./components/timesheet/addtaskrow/AddTaskRowComponent";
import { AddTaskRowExplanationComponent } from "./components/timesheet/addtaskrowexplanation/AddTaskRowExplanationComponent";
import { VideoModalComponent } from "./components/videomodalcomponent/VideoModalComponent";
import { FilterSettingsQueryHelper } from "services/report/FilterSettingsQueryHelper";
import { FinancialReportService } from "services/financialreport/FinancialReportService";
import { ChartScaleService } from "services/chartscale/ChartScaleService";
import { UserAvatarComponent } from "components/user/UserAvatarComponent";
import { UserPaymentModalComponent } from "./components/users/userpayment/UserPaymentModalComponent";
import { UserTeamsModalComponent } from "./components/users/userteams/UserTeamsModalComponent";
import { TeamsMenuComponent } from "./components/teamsmenu/TeamsMenuComponent";
import { RemoveTeamModalComponent } from "./components/teamsmenu/removeteammodal/RemoveTeamModalComponent";
import { SearchComponent } from "./components/search/SearchComponent";

import { ProjectTeamsModalComponent } from "./components/projects/projectteams/ProjectTeamsModalComponent";
import { UserPaymentHistoryComponent } from "./components/users/userpayment/userpaymenthistory/UserPaymentHistoryComponent";
import { UserPaymentHistoryEntryComponent } from "./components/users/userpayment/userpaymenthistory/UserPaymentHistoryEntryComponent";
import { CreateOrAddUserPaymentComponent } from "./components/users/userpayment/createoradduserpayment/CreateOrAddUserPaymentComponent";
import { UserPaymentService } from "services/user/UserPaymentService";
import { CurrencyService } from "services/currency/CurrencyService";
import { ClickService } from "services/click/ClickService";
import { ProjectPricingModalComponent } from "./components/projects/projectpricing/ProjectPricingModalComponent";
import { ProjectFixedRatePricingComponent } from "./components/projects/projectpricing/fixedprice/ProjectFixedRatePricingComponent";
import { ProjectHourlyRatePricingComponent } from "./components/projects/projectpricing/hourlyrate/ProjectHourlyRatePricingComponent";
import { UserRateOverrideComponent } from "./components/projects/projectpricing/hourlyrate/useroverrides/UserRateOverrideComponent";
import { UserRateOverridesComponent } from "./components/projects/projectpricing/hourlyrate/useroverrides/UserRateOverridesComponent";
import { ProjectMonthlyRatePricingComponent } from "./components/projects/projectpricing/monthlyrate/ProjectMonthlyRatePricingComponent";
import { ProjectPricingService } from "services/projects/ProjectPricingService";
import { ProjectPricingHistoryComponent } from "./components/projects/projectpricinghistory/ProjectPricingHistoryComponent";
import { ProjectPricingHistoryEntryComponent } from "./components/projects/projectpricinghistory/ProjectPricingHistoryEntryComponent";
import { CreateOrAddProjectRateComponent } from "./components/projects/projectpricing/createoraddprojectrate/CreateOrAddProjectRateComponent";
import { PricingDisplayService } from "services/pricingdisplay/PricingDisplayService";
import { FeedbackService } from "services/feedback/FeedbackService";
import { PdfReportService } from "services/pdfreport/PdfReportService";
import { ConfigService } from "services/config";
import { Environment } from "models/environment/Environment";
import { environment } from "../environments/environment";
import { SelectorComponent } from "components/selector/SelectorComponent";
import { TimepotSealerComponent } from "./components/timesheet/timepotsealer/TimepotSealerComponent";
import { SealerFlyoutComponent } from "./components/timesheet/timepotsealer/sealerflyout/SealerFlyoutComponent";
import { ReportShareLinkModalComponent } from "timepot/components/timesheetreports/ReportShareLinkModalComponent";
import { UserBreakdownReportComponent } from "timepot/components/userbreakdownreports/UserBreakdownReportComponent";
import { DayActivityDetailComponent } from "./components/userbreakdownreports/DayActivityDetailComponent";
import { UserDayActivityDetailsComponent } from "./components/userbreakdownreports/UserDayActivityDetailsComponent";
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from "@angular/common/http";
import {
    GoogleLoginProvider,
    SocialAuthServiceConfig,
    SocialLoginModule,
} from "@abacritt/angularx-social-login";
import { NewCompanyModalComponent } from "timepot/components/newcompany/NewCompanyModalComponent";

const appBaseHrefProvider = { provide: APP_BASE_HREF, useValue: "/" };
const errorHandlerProvider = {
    provide: ErrorHandler,
    useClass: TimepotExceptionHandler,
};
const issueAgentProviders = [
    { provide: IssueAgent, multi: true, useClass: LoggingIssueAgent },
    { provide: IssueAgent, multi: true, useClass: UnauthenticatedIssueAgent },
    { provide: IssueAgent, multi: true, useClass: MessagingIssueAgent },
];
const tourConfigProvider = { provide: TourDefinitions, useClass: TimepotTours };
const tourTranslationsProvider = {
    provide: "tourTranslations",
    useValue: tourI18N,
};
const envProvider = { provide: Environment, useValue: environment };

export function backendAppenderFactory(
    http,
    log: Logger,
    config: ConfigService
) {
    return new BackendAppender(http, log, config.logEndpoint(), "web");
}

@NgModule({
    imports: [
        HttpClientModule,
        BrowserAnimationsModule,
        CommonModule,
        routing,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        SocialLoginModule,
    ],
    providers: [
        DatePipe,
        CurrencyPipe,
        TimepotExceptionHandler,
        envProvider,
        ConfigService,
        AuthenticatedGuard,
        NotAuthenticatedGuard,
        AuthService,
        AdminGuard,
        ManagerGuard,
        CompanyPickerService,
        CompanyService,
        ConsoleAppender,
        CookieService,
        CountryService,
        CurrentCompanyUserService,
        CurrentCompanyUserViewService,
        DateService,
        EmailValidator,
        DaysOffService,
        I18NService,
        InvitationService,
        Logger,
        LoggingIssueAgent,
        MessageService,
        MessagingIssueAgent,
        TimeUnitConversionService,
        ProfileEditGuard,
        ProjectService,
        TimesheetReportService,
        TimesheetAccessGuard,
        TimesheetService,
        UnauthenticatedIssueAgent,
        UserService,
        appBaseHrefProvider,
        errorHandlerProvider,
        issueAgentProviders[0],
        issueAgentProviders[1],
        issueAgentProviders[2],
        tourConfigProvider,
        tourTranslationsProvider,
        TourService,
        PerfectScrollbarService,
        DemoService,
        ResetPasswordService,
        ClientIdService,
        WebSocketNotificationService,
        FeatureToggleService,
        DisplayService,
        TimeZoneService,
        FilterSettingsQueryHelper,
        FinancialReportService,
        TeamService,
        ChartScaleService,
        UserPaymentService,
        ClickService,
        CurrencyService,
        ProjectPricingService,
        PricingDisplayService,
        BarchartDataService,
        FeedbackService,
        PdfReportService,
        TimepotHttpInterceptor,
        {
            provide: BackendAppender,
            deps: [HttpClient, Logger, ConfigService],
            useFactory: backendAppenderFactory,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TimepotHttpInterceptor,
            multi: true,
        },
        {
            provide: "SocialAuthServiceConfig",
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            "961973070941-i1h1m5dtjmu4f08bufng2hmcm5hkv205.apps.googleusercontent.com",
                            {
                                oneTapEnabled: false,
                            }
                        ),
                    },
                ],
                onError: (err) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
    ],
    declarations: [
        AddHolidaysModalComponent,
        AddTaskModalComponent,
        AuthComponent,
        TeamsMenuComponent,
        BarChartComponent,
        BarChartHorizontalComponent,
        CalendarComponent,
        CalendarConfiguratorComponent,
        ColorPickerComponent,
        ConfiguratorUserEntryComponent,
        VideoModalComponent,
        CountryDropdownComponent,
        DatePickerBodyComponent,
        DatePickerComponent,
        DatePickerMonthComponent,
        DatePickerWeekComponent,
        DatePickerRangeComponent,
        DatepickerHeaderComponent,
        DayFooterComponent,
        DayHeaderComponent,
        DonutChartComponent,
        EditTaskModalComponent,
        FilterDisplayFieldComponent,
        GenericBackdropComponent,
        GenericDropdownComponent,
        GenericModalComponent,
        GoogleAuthButtonComponent,
        HeaderComponent,
        HolidaysComponent,
        InvitationFormComponent,
        InviteComponent,
        InviteUsersModalComponent,
        LoginComponent,
        MenuEntryComponent,
        MessageHeaderComponent,
        MinutesEditPipe,
        MinutesViewPipe,
        SecondsViewPipe,
        ModalCoreComponent,
        MonthComponent,
        NavbarComponent,
        PagedDropdownComponent,
        ProfileEditComponent,
        ProfileEditFormComponent,
        ProfileEditModalComponent,
        ProjectDropdownComponent,
        ProjectEntryComponent,
        ProjectModalComponent,
        ProjectsComponent,
        ProjectsFilterComponent,
        ProjectsListComponent,
        BillableFilterComponent,
        TimesheetReportFilterComponent,
        TimesheetReportsComponent,
        NewCompanyModalComponent,
        SignupInvitationComponent,
        SignupPasswordComponent,
        TSActivityComponent,
        TSDayChangerComponent,
        TSFooterComponent,
        TSHeaderComponent,
        TSProjectComponent,
        TSTaskComponent,
        TSUserHeaderComponent,
        TSWeekChangerComponent,
        TSWeekPickerComponent,
        TaskDropdownComponent,
        TaskFilterFieldComponent,
        TaskUsersComponent,
        TasksListComponent,
        TeamDropdownComponent,
        TimeDistancePipe,
        TimepotComponent,
        TimesheetComponent,
        TimesheetTableComponent,
        TooltipComponent,
        UsageIndicatorComponent,
        UserComponent,
        UserListComponent,
        UserMenuComponent,
        UsersComponent,
        UsersFilterComponent,
        WeekComponent,
        YearComponent,
        ShowOverflownTitleDirective,
        RunTourDirective,
        CallToActionComponent,
        SendResetPasswordEmailComponent,
        ResetPasswordComponent,
        AddTaskRowComponent,
        TSProjectSelectorComponent,
        TimerStopComponent,
        TimerStartComponent,
        FinancialReportsComponent,
        FinancialReportFilterComponent,
        HolidaysIncludedFilterComponent,
        UserAvatarComponent,
        ProjectSummaryComponent,
        ProjectReportComponent,
        UserPaymentModalComponent,
        UserTeamsModalComponent,
        YearMonthPickerComponent,
        YearMonthPickerPopdownComponent,
        DoubleYearMonthPickerComponent,
        ProjectPricingModalComponent,
        ProjectFixedRatePricingComponent,
        ProjectHourlyRatePricingComponent,
        ProjectMonthlyRatePricingComponent,
        ProjectPricingHistoryComponent,
        CreateOrAddProjectRateComponent,
        CreateOrAddUserPaymentComponent,
        UserPaymentHistoryComponent,
        UserPaymentHistoryEntryComponent,
        AddTaskRowExplanationComponent,
        FeedbackModalComponent,
        CurrencyPickerFilterComponent,
        UserRateOverrideComponent,
        UserRateOverridesComponent,
        ProjectPricingHistoryEntryComponent,
        ProjectTeamsModalComponent,
        SearchComponent,
        PaginationComponent,
        RemoveTeamModalComponent,
        SettingsComponent,
        SelectorComponent,
        TimepotSealerComponent,
        MonthFullComponent,
        WeekFullComponent,
        CalendarHeaderComponent,
        SealerFlyoutComponent,
        ReportDatePickerRangeComponent,
        UserBreakdownReportComponent,
        DayActivityDetailComponent,
        UserDayActivityDetailsComponent,
        ReportShareLinkModalComponent,
    ],
    bootstrap: [TimepotComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TimepotModule {}
