import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../config";
import { ClientIdService } from "../clientid/ClientIdService";
import { AuthService } from "../auth/AuthService";

//todo override 'resource' method
@Injectable()
export class TimepotHttpInterceptor implements HttpInterceptor {
    constructor(
        private configService: ConfigService,
        private clientIdService: ClientIdService,
        private authService: AuthService
    ) {}

    public intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const headers = {
            "X-Timepot-Client-Id": this.clientIdService.getClientId(),
            ...(this.authService.googleIdToken === null
                ? {}
                : {
                      Authorization: "Bearer " + this.authService.googleIdToken,
                  }),
        };
        this.authService.googleIdToken = null;
        return next.handle(
            req.clone({
                setHeaders: headers,
                setParams: {
                    [this.configService.versionParamName]:
                        "" + this.configService.apiVersion,
                },
                withCredentials: true,
            })
        );
    }
}
