import { Project } from "../projects/Project";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const FullTask = z.object({
    id: z.string(),
    name: z.string(),
    project: Project,
});

export type FullTask = z.infer<typeof FullTask>;
