import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { DateRange } from "models/daterange/DateRange";
import { DateService } from "services/date/DateService";

@Component({
    selector: "ts-week-changer",
    templateUrl: "ts-week-changer.html",
    host: { "[class.c-ts-week-changer]": "true" },
})
export class TSWeekChangerComponent implements OnInit {
    @Input() public isNextWeekChanger: boolean;
    @Input() public userId: string;
    @Input() public dateRangeObservable: Observable<DateRange>;
    private to: Date;
    private from: Date;

    constructor(private router: Router) {}

    public ngOnInit() {
        this.dateRangeObservable.subscribe((dr: DateRange) => {
            this.to = dr.to;
            this.from = dr.from;
        });
    }

    public handleChange(isNextWeekChanger: boolean) {
        if (isNextWeekChanger) {
            this.from.setDate(this.from.getDate() + 7);
        } else {
            this.from.setDate(this.from.getDate() - 7);
        }
        this.to.setTime(this.from.getTime());
        this.to.setDate(this.from.getDate() + 6);

        const dateParams = {
            userId: this.userId,
            from: DateService.toISOString(this.from),
            to: DateService.toISOString(this.to),
        };

        this.router.navigate(["auth", "ts", dateParams]);
    }
}
