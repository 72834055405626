<table class="c-tasks-list__table" id="report-tasks-list">
    <thead>
        <tr>
            <th class="c-tasks-list__table-th c-tasks-list__table-th--project">
                {{i18n.project}}
            </th>
            <th class="c-tasks-list__table-th c-tasks-list__table-th--task">
                {{i18n.task}}
            </th>
            <th class="c-tasks-list__table-th c-tasks-list__table-th--time">
                {{i18n.time}}
            </th>
            <th class="c-tasks-list__table-th c-tasks-list__table-th--users">
                {{i18n.users}}
            </th>
            <th class="c-tasks-list__table-th c-tasks-list__table-th--updated">
                {{i18n.updated}}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr
            class="c-tasks-list__table-tr c-tasks-list__table-tr--{{task.project.color.toLowerCase()}}"
            *ngFor="let task of tasks"
        >
            <td
                class="c-tasks-list__table-td c-tasks-list__table-td--project"
                showOverflownTitle
            >
                {{task.project.name}}
            </td>
            <td
                class="c-tasks-list__table-td c-tasks-list__table-td--task"
                showOverflownTitle
            >
                {{task.name}}
            </td>
            <td class="c-tasks-list__table-td c-tasks-list__table-td--time">
                <span class="c-tasks-list__table-td-label"
                    >{{i18n.time}}:
                </span>
                {{task.totalDuration | minutesViewPipe}}
            </td>
            <td class="c-tasks-list__table-td c-tasks-list__table-td--users">
                <span class="c-tasks-list__table-td-label"
                    >{{i18n.users}}:
                </span>
                <task-users [users]="task.users"></task-users>
            </td>
            <td class="c-tasks-list__table-td c-tasks-list__table-td--updated">
                <span class="c-tasks-list__table-td-label"
                    >{{i18n.updated}}:
                </span>
                {{task.lastUpdated | timeDistancePipe}}
            </td>
        </tr>
    </tbody>
</table>
<div (click)="showMore()" class="c-tasks-list__show-more" *ngIf="hasMore()">
    {{i18n.showMore}}
</div>
