import { Injectable } from "@angular/core";

@Injectable()
export class CurrencyService {
    private currencyFormat = /^\d(\d|\s)*(\.\d\d)?$/;

    public isFormatValid(value: string) {
        return this.currencyFormat.test(value);
    }

    public convertToNumber(valueStr: string) {
        if (valueStr !== undefined && valueStr !== null) {
            valueStr = valueStr.split(" ").join("");
            const num = parseFloat(valueStr);
            if (isNaN(num)) {
                return undefined;
            }
            return num;
        }
    }

    public getDefaultCurrency() {
        return "USD";
    }

    public getSupportedCurrencies() {
        return ["CHF", "EUR", "GBP", "PLN", "USD", "ARS"];
    }
}
