import { FormControl } from "@angular/forms";
import {
    createEmail,
    InvitationService,
} from "../invitation/InvitationService";

export const DEFAULT_SEARCH_DELAY = 16; // 16ms ≈ 60 polls/s

export class InvitedEmailValidator {
    public static unused(
        invitationService: InvitationService,
        delay = DEFAULT_SEARCH_DELAY
    ) {
        let emailValidatorTimeout;

        return (control: FormControl) => {
            clearTimeout(emailValidatorTimeout);

            return new Promise((resolve) => {
                emailValidatorTimeout = setTimeout(() => {
                    invitationService
                        .isInvited(createEmail(control.value))
                        .subscribe(
                            (invited) =>
                                invited
                                    ? resolve({ invited: true })
                                    : resolve(null),
                            () => resolve(null)
                        );
                }, delay);
            });
        };
    }
}
