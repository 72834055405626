import { Component, OnInit } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import * as _ from "lodash";
import { I18NService } from "services/i18n/I18NService";
import { DaysOffService } from "services/daysOff/DaysOffService";
import { DateService } from "services/date/DateService";
import { holidaysI18N } from "./i18n/holidays-i18n";
import { DateRange } from "models/daterange/DateRange";
import { DaysOff } from "models/daysOff/DaysOff";
import { User } from "models/users/User";
import { CurrentCompanyUserService } from "services/currentcompanyuser/CurrentCompanyUserService";
import { publishReplay, refCount, switchMap } from "rxjs/operators";

@Component({
    selector: "holidays",
    templateUrl: "holidays.html",
    host: { "[class.c-holidays]": "true" },
})
export class HolidaysComponent implements OnInit {
    public i18n;
    public selectedUsersSubject = new ReplaySubject<string[]>(1);
    public daysOffObservable: Observable<DaysOff>;
    public showAddHolidayObservable = new ReplaySubject<boolean>(1);
    public showTooltipObservable = new ReplaySubject<boolean>(1);
    public daysOffSelectedDateRange = new ReplaySubject<DateRange>(1);
    public selectedYearSubject = new ReplaySubject<number>(1);
    public clickedElement: HTMLElement = null;
    public currentSelection: DateRange;

    private selectedDateRange: DateRange;
    private selectedUsersIds = new Array<string>();
    private daysOff: DaysOff;
    private user: User;
    private selectionStart: Date;
    private selectionEnd: Date;

    constructor(
        private dateService: DateService,
        private daysOffService: DaysOffService,
        private i18nService: I18NService,
        currentCompanyUserViewService: CurrentCompanyUserService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(holidaysI18N);
        this.user = currentCompanyUserViewService.companyUser;
        this.showAddHolidayObservable.next(false);
        this.showTooltipObservable.subscribe((v) => {
            if (!v) {
                this.currentSelection = null;
            }
        });
    }
    public ngOnInit() {
        this.daysOffObservable = this.selectedYearSubject.pipe(
            switchMap((year) => {
                return this.daysOffService.getHolidaysForYear(year, null);
            }),
            publishReplay(),
            refCount()
        );

        this.daysOffObservable.subscribe((h) => (this.daysOff = h));
        this.selectedUsersSubject.subscribe(
            (selectedUsersIds) => (this.selectedUsersIds = selectedUsersIds)
        );
    }
    public handleSelectedDateRange(dateRange: DateRange, element: HTMLElement) {
        this.selectedDateRange = dateRange;
        this.clickedElement = element;
        this.daysOffSelectedDateRange.next(dateRange);
        this.showTooltipObservable.next(true);
    }
    public openAddHolidayDialog() {
        const date: Date = new Date();
        this.daysOffSelectedDateRange.next(new DateRange(date, date));
        this.showAddHolidayObservable.next(true);
    }
    public openAddHolidayDialogForSelectedRange() {
        this.currentSelection = null;
        this.showTooltipObservable.next(false);
        this.showAddHolidayObservable.next(true);
    }
    public deleteHolidaysForSelectedRange() {
        this.deleteHoliday(HolidayDeletionMode.SELECTED_RANGE);
    }

    public deleteWholeHoliday() {
        this.deleteHoliday(HolidayDeletionMode.WHOLE);
    }

    private deleteHoliday(deletionMode: HolidayDeletionMode) {
        if (this.user && this.selectedDateRange) {
            if (deletionMode === HolidayDeletionMode.WHOLE) {
                const holidayToDelete = this.getUserHolidaysForDay(
                    this.user,
                    this.selectedDateRange.to
                );
                if (holidayToDelete) {
                    this.daysOffService.deleteDaysOff(
                        this.user,
                        holidayToDelete.begin,
                        holidayToDelete.end
                    );
                }
            } else if (deletionMode === HolidayDeletionMode.SELECTED_RANGE) {
                this.daysOffService.deleteDaysOff(
                    this.user,
                    this.selectedDateRange.from,
                    this.selectedDateRange.to
                );
            } else {
                throw new Error(`Unexpected deletionMode ${deletionMode}`);
            }
        }
        this.currentSelection = null;
        this.showTooltipObservable.next(false);
    }

    /**
     * @see DaysOffService#isHomogeneousRange
     */
    public isSelectedRangeHomogeneous(): boolean {
        return (
            this.selectedDateRange &&
            this.daysOffService.isHomogeneousRange(
                this.daysOff,
                this.user,
                this.selectedDateRange
            )
        );
    }

    public isSelectedRangeEndingWithHolidays(): boolean {
        if (!this.selectedDateRange) {
            return false;
        }
        return (
            this.getUserHolidaysForDay(this.user, this.selectedDateRange.to) !==
            undefined
        );
    }

    public onMouseDown(event: MouseEvent) {
        this.currentSelection = null;
        this.selectionStart = this.getDay(event);
        return false;
    }
    public onMouseOver(event: MouseEvent) {
        const day = this.getDay(event);
        if (
            this.selectionStart &&
            day &&
            (!this.selectionEnd ||
                !this.dateService.isEqual(day, this.selectionEnd))
        ) {
            this.selectionEnd = day;
            this.currentSelection = this.buildRange(
                this.selectionStart,
                this.selectionEnd
            );
        }
    }
    public onMouseUp(event: MouseEvent) {
        const end = this.getDay(event);
        if (!this.selectionStart || !end) {
            return false;
        }
        this.handleSelectedDateRange(
            this.buildRange(this.selectionStart, end),
            <HTMLElement>event.target
        );
        this.selectionStart = null;
        this.selectionEnd = null;
        return false;
    }
    private buildRange(start: Date, end: Date): DateRange {
        if (this.dateService.isBefore(start, end)) {
            return new DateRange(start, end);
        } else {
            return new DateRange(end, start);
        }
    }
    private getDay(event: MouseEvent): Date {
        let elt = <HTMLElement>event.target;
        do {
            if (
                elt.hasAttribute("data-id") &&
                elt.getAttribute("data-id") === "calendar-day"
            ) {
                const month = Number.parseInt(elt.getAttribute("data-month"));
                const year = Number.parseInt(elt.getAttribute("data-year"));
                const day = Number.parseInt(elt.getAttribute("data-day"));
                return new Date(year, month, day);
            }
            elt = elt.parentElement;
        } while (elt);
        return null;
    }

    /**
     * @returns {boolean} true iff the selected range is equal to some of current user's holidays
     */
    public isSelectedRangeWholeHoliday(): boolean {
        if (!this.selectedDateRange) {
            return false;
        } else {
            const userHolidaysForDay = this.getUserHolidaysForDay(
                this.user,
                this.selectedDateRange.to
            );
            return (
                userHolidaysForDay !== undefined &&
                this.dateService.isEqual(
                    userHolidaysForDay.begin,
                    this.selectedDateRange.from
                ) &&
                this.dateService.isEqual(
                    userHolidaysForDay.end,
                    this.selectedDateRange.to
                )
            );
        }
    }

    private getUserHolidaysForDay(user: User, day: Date) {
        const userDaysOff = this.daysOff.daysOff.find(
            (uh) => uh.user.id === user.id
        );
        if (userDaysOff) {
            return userDaysOff.daysOff.find((h) =>
                this.isInRange(day, h.begin, h.end)
            );
        }
        return undefined;
    }
    public usersOnHoliday(): User[] {
        if (!this.selectedDateRange) {
            return [];
        }
        const usersWithDayOff = this.daysOff.daysOff
            .filter(
                (uh) =>
                    uh.daysOff.find((h) =>
                        this.isInRange(
                            this.selectedDateRange.to,
                            h.begin,
                            h.end
                        )
                    ) !== undefined
            )
            .map((uh) => uh.user);

        return _.filter(
            usersWithDayOff,
            (user) => this.selectedUsersIds.indexOf(user.id) !== -1
        );
    }
    private isInRange(v: Date, start: Date, end: Date) {
        return start.getTime() <= v.getTime() && v.getTime() <= end.getTime();
    }
}

enum HolidayDeletionMode {
    WHOLE,
    SELECTED_RANGE,
}
