import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const TaskFilterView = z.object({
    keywords: z.string().array(),
    projectIds: z.string().array(),
    projectTeamCodes: z.string().array(),
    userIds: z.string().array(),
    userTeamCodes: z.string().array(),
    filterDisplays: z.string().array(),
    ignoredIds: z.string().array(),
});

export type TaskFilterView = z.infer<typeof TaskFilterView>;
