export const userBreakdownReportI18NPl = {
    user: "Użytkownik",
    date: "Data",
    time: "Czas",
    tasks: "Zadania",
    total: (num: number) => {
        if (num === 1) {
            return "Jedno zadanie";
        } else {
            const r = num % 100;
            if (r >= 10 && r <= 20) {
                return `${num} zadań`;
            }
            const lastDigit = num % 10;
            if (lastDigit > 1 && lastDigit < 5) {
                return `${num} zadania`;
            }
            return `${num} zadań`;
        }
    },
};
