import { Component } from "@angular/core";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";

@Component({
    selector: "auth",
    templateUrl: "auth.html",
})
export class AuthComponent {
    constructor(
        private currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {}

    public shouldDisplayMenus(): boolean {
        return !!this.currentCompanyUserViewService.companyUser;
    }
}
