import { Component } from "@angular/core";
import { Subject } from "rxjs";
import { DemoService } from "../../../services/demo/DemoService";
import { I18NService } from "services/i18n/I18NService";
import { CookieService } from "services/cookie/CookieService";
import { callToActionI18N } from "./i18n/call-to-action-i18n";
import { ConfigService } from "services/config";
import { Action } from "../../genericmodal/GenericModalComponent";

const CALL_TO_ACTION_INTERVAL = 1000 * 60 * 3; //ms

@Component({
    selector: "call-to-action",
    templateUrl: "call-to-action.html",
})
export class CallToActionComponent {
    public showModalStream = new Subject<boolean>();

    public actionStream = new Subject<Action>();

    public i18n;

    constructor(
        private demoService: DemoService,
        private cookieService: CookieService,
        i18nService: I18NService,
        private configService: ConfigService
    ) {
        if (this.demoService.isDemoMode()) {
            this.i18n = i18nService.extractCurrentTranslation(callToActionI18N);
            setTimeout(
                () => this.showModalStream.next(true),
                CALL_TO_ACTION_INTERVAL
            );
        }
    }

    public isDemoMode(): boolean {
        return this.demoService.isDemoMode();
    }

    public signUpHref(): string {
        const preferredLanguage =
            this.cookieService.getCookie("preferredLanguage");
        let domain = "io";
        if (preferredLanguage && preferredLanguage !== "en") {
            domain = preferredLanguage;
        }
        return this.configService.signupHref(domain);
    }

    public get encodedMailSubject() {
        return encodeURIComponent(this.i18n.mailSubject);
    }

    public get encodedMailBody() {
        return encodeURIComponent(this.i18n.mailBody);
    }
}
