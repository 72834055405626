import { googleAuthButtonI18NDe } from "./google-auth-button-i18n-de";
import { googleAuthButtonI18NPl } from "./google-auth-button-i18n-pl";
const googleAuthButtonI18NEn = {
    withGoogle: "with Google",
};

export const googleAuthButtonI18N = {
    de: googleAuthButtonI18NDe,
    en: googleAuthButtonI18NEn,
    pl: googleAuthButtonI18NPl,
};
