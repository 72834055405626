import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../config";
import { Token } from "../../models/token/Token";
import { map } from "rxjs/operators";

@Injectable()
export class ResetPasswordService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) {}

    public sendResetPasswordEmail(email: string): Observable<Token> {
        return this.http
            .post(
                this.configService.sendResetPasswordEmailEndpoint(),
                {
                    email: email,
                },
                { observe: "response" }
            )
            .pipe(map(() => Token.TOKEN));
    }

    public resetPassword(password: string, token: string): Observable<Token> {
        return this.http
            .post(
                this.configService.resetPasswordEndpoint(),
                {
                    token: token,
                    password: password,
                },
                { observe: "response" }
            )
            .pipe(map(() => Token.TOKEN));
    }
}
