import { Component, Input, OnDestroy, OnInit } from "@angular/core";

@Component({
    selector: "modal-core",
    templateUrl: "modal-core.html",
    host: { "[class.c-modal-core]": "true" },
})
export class ModalCoreComponent implements OnInit, OnDestroy {
    @Input() public thin = false;

    private body = document.getElementsByTagName("body")[0];

    public ngOnInit() {
        this.body.classList.add("u-overflow-hidden");
    }

    public ngOnDestroy() {
        this.body.classList.remove("u-overflow-hidden");
    }
}
