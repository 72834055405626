<generic-dropdown
    [items]="billableOptions"
    [selection]="selected"
    [initialSelectionStream]="preselected"
    [allowNoValue]="true"
    [selectMessage]="i18n.billability"
    [selectOnSwitch]="false"
    [searchPlaceholder]="i18n.billability"
    class="c-generic-dropdown--contained"
>
</generic-dropdown>
