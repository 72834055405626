<div
    class="c-user-menu__avatar-container"
    (click)="onMenuClick()"
    data-id="user-menu-activator"
>
    <div
        class="c-user-menu__avatar"
        [ngStyle]="{'background-image': 'url(' + avatarUrl + ')'}"
    ></div>

    <div class="c-user-menu__avatar-info">
        <div class="c-user-menu__avatar-info-name">{{ user.name }}</div>
        <div class="c-user-menu__avatar-info-email" title="{{ user.email }}">
            {{ user.email }}
        </div>
    </div>
</div>

<div class="c-user-menu__panel" id="user-profile-panel">
    <div class="c-user-menu__panel-info">
        <div class="c-user-menu__panel-info-name">{{ user.name }}</div>
        <div class="c-user-menu__panel-info-email" title="{{ user.email }}">
            {{ user.email }}
        </div>
    </div>

    <a
        class="c-user-menu__panel-control"
        [ngClass]="{'c-user-menu__panel-control--active': companiesVisible}"
        (click)="onCompaniesClick()"
        id="user-timepot-menu-control"
    >
        {{ i18n.companyName(user.companyName) }}
    </a>

    <ul
        class="c-user-menu__panel-nav c-user-menu__panel-nav--white"
        [ngClass]="{'c-user-menu__panel-nav--active': companiesVisible}"
    >
        <li *ngFor="let company of companies">
            <a
                class="c-user-menu__panel-nav-link"
                (click)="pickCompany(company)"
                title="{{ company.companyName }}"
                [ngClass]="{'c-user-menu__panel-nav-link--current': isCurrentCompany(company)}"
            >
                {{ company.companyName }}
            </a>
        </li>
    </ul>

    <ul
        class="c-user-menu__panel-nav"
        [ngClass]="{'c-user-menu__panel-nav--active': !companiesVisible}"
    >
        <li>
            <a
                class="c-user-menu__panel-nav-link"
                (click)="showEditProfile()"
                title="{{ i18n.editProfile }}"
                >{{ i18n.editProfile }}</a
            >
        </li>
        <li>
            <a
                class="c-user-menu__panel-nav-link"
                (click)="runTour()"
                title="{{i18n.introductoryTour}}"
                >{{i18n.introductoryTour}}</a
            >
        </li>
        <li *ngIf="user.admin">
            <a
                    class="c-user-menu__panel-nav-link"
                    (click)="showNewTimepotModal()"
                    title="{{ i18n.createNewCompany }}"
            >{{ i18n.createNewCompany }}</a
            >
        </li>
        <li>
            <a
                class="c-user-menu__panel-nav-link c-user-menu__panel-nav-link--signout"
                (click)="signOut()"
                title="{{ i18n.signOut }}"
                >{{ i18n.signOut }}</a
            >
        </li>
    </ul>
</div>

<div class="c-user-menu__overlay"></div>
