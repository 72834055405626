import { Component, Input } from "@angular/core";
import { TimesheetService } from "../../../services/timesheet/TimesheetService";
import { Project } from "../../../models/projects/Project";

@Component({
    selector: "timer-start",
    templateUrl: "timer-start.html",
    host: {
        "[class.c-timer-start]": "true",
    },
})
export class TimerStartComponent {
    @Input() public userId: string;

    @Input() public project: Project;
    @Input() public taskName: string;
    @Input() public taskCode: string;

    constructor(private timesheetService: TimesheetService) {}

    public startTimer() {
        this.timesheetService.startTimer(
            this.userId,
            this.project.id,
            this.taskName,
            this.taskCode
        );
    }
}
