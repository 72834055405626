import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserService } from "../user/UserService";
import { User } from "../../models/users/User";
import { CompanyUser } from "../../models/companyusers/CompanyUser";
import { ConfigService } from "../config";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class InvitationService {
    constructor(
        private userService: UserService,
        private http: HttpClient,
        private configService: ConfigService
    ) {}

    public reinvite(user: User) {
        this.invite(createEmail(user.email));
    }

    public invite(email: Email | Email[]): void {
        if (isEmail(email)) {
            this.userService.inviteUser(email);
        } else {
            email.forEach((singleEmail) => this.invite(singleEmail));
        }
    }

    public disinvite(user: User): void {
        this.userService.disinviteUser(user.id);
    }

    public isInvited(email: Email): Observable<boolean> {
        return this.userService.isInvited(email);
    }

    public getInvitedCompanyUser(
        invitationToken: string
    ): Observable<CompanyUser> {
        return this.http
            .get(
                this.configService.invitationCompaniesEndpoint(invitationToken)
            )
            .pipe(map((resp) => CompanyUser.parse(resp)));
    }
}

export interface Email {
    address: string;
}

export function isEmail(object: {}): object is Email {
    return object && (<Email>object).address !== undefined;
}

export function createEmail(email: string) {
    return { address: email };
}
