export const financialReportsI18NPl = {
    configure: "Ustaw",
    userSalaries: "pensje użytkowników",
    and: "i",
    projectRates: "stawki projektów",
    generateReports: "a my wygenerujemy raporty finansowe",
    pageTitle: "Finanse",
    income: "Przychód",
    expenses: "Koszty",
    totalBalance: "Całkowite saldo",
    project: "Projekt",
    projects: "Projekty",
    noResults: "Brak wyników dla podanych filtrów",
};
