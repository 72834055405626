import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { ConfigService } from "../config";
import { DateService } from "../date/DateService";
import { ProjectPricing } from "../../models/projects/ProjectPricing";
import { ProjectPricingHistory } from "../../models/projects/ProjectPricingHistory";
import { UserRateOverride } from "../../models/projects/UserRateOverride";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class ProjectPricingService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) {}

    public getProjectPricing(projectCode: string): Observable<ProjectPricing> {
        const date = DateService.toISOString(new Date());
        return this.http
            .get(this.configService.projectPricing(projectCode, date))
            .pipe(
                map((response) => {
                    return ProjectPricing.parse(response);
                }),
                catchError((e) => {
                    if (e instanceof HttpErrorResponse && e.status === 404) {
                        return of(undefined);
                    } else {
                        throw e;
                    }
                })
            );
    }

    public getProjectPricingHistory(
        projectCode: string
    ): Observable<ProjectPricingHistory> {
        return this.http
            .get(this.configService.projectPricingHistory(projectCode))
            .pipe(
                map((response) => {
                    return ProjectPricingHistory.parse(response);
                }),
                catchError((e) => {
                    if (e instanceof HttpErrorResponse && e.status === 404) {
                        return of(undefined);
                    } else {
                        throw e;
                    }
                })
            );
    }

    public createHourlyRate(
        projectCode: string,
        rate: number,
        currency: string,
        userOverrides: UserRateOverride[]
    ): Observable<void> {
        const payload = {
            rate: ProjectPricingService.toCents(rate),
            currency: currency,
            userOverrides: userOverrides.map((override) => {
                return {
                    rate: ProjectPricingService.toCents(override.rate),
                    userCode: override.userCode,
                };
            }),
        };
        return this.http
            .post(this.configService.projectHourlyRate(projectCode), payload)
            .pipe(map(() => undefined));
    }

    public createMonthlyRate(
        projectCode: string,
        rate: number,
        currency: string
    ): Observable<void> {
        const payload = {
            rate: ProjectPricingService.toCents(rate),
            currency: currency,
        };
        return this.http
            .post(this.configService.projectMonthlyRate(projectCode), payload)
            .pipe(map(() => undefined));
    }

    public createOrUpdateFixedPrice(
        projectCode: string,
        price: number,
        expectedWorkload: number,
        currency?: string
    ): Observable<void> {
        const payload = {
            price: ProjectPricingService.toCents(price),
            expectedWorkload: expectedWorkload,
            currency: currency || null,
        };
        return this.http
            .post(this.configService.projectFixedPrice(projectCode), payload)
            .pipe(map(() => undefined));
    }

    public addHourlyRatePeriod(
        projectCode: string,
        rate: number,
        userOverrides: UserRateOverride[],
        startDate: Date = undefined,
        endDate: Date = undefined
    ): Observable<void> {
        const payload = {
            rate: ProjectPricingService.toCents(rate),
            firstDay: startDate
                ? DateService.toISOString(startDate)
                : undefined,
            lastDay: endDate ? DateService.toISOString(endDate) : undefined,
            userOverrides: userOverrides.map((override) => {
                return {
                    rate: ProjectPricingService.toCents(override.rate),
                    userCode: override.userCode,
                };
            }),
        };
        return this.http
            .post(
                this.configService.projectHourlyRatePeriod(projectCode),
                payload
            )
            .pipe(map(() => undefined));
    }

    public addMonthlyRatePeriod(
        projectCode: string,
        rate: number,
        startDate: Date = undefined,
        endDate: Date = undefined
    ): Observable<void> {
        const payload = {
            rate: ProjectPricingService.toCents(rate),
            firstMonth: DateService.toYearMonthString(startDate),
            lastMonth: endDate ? DateService.toYearMonthString(endDate) : null,
        };
        return this.http
            .post(
                this.configService.projectMonthlyRatePeriod(projectCode),
                payload
            )
            .pipe(map(() => undefined));
    }

    public clearProjectPricing(projectCode: string): Observable<void> {
        return this.http
            .delete(this.configService.clearProjectPricing(projectCode))
            .pipe(map(() => undefined));
    }

    private static toCents(amount: number): number {
        return amount * 100;
    }
}
