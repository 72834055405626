<div
    class="o-layout o-layout__stretch c-calendar-configurator__filters"
    *ngIf="ready"
>
    <div class="o-layout__item o-layout__item--teams-menu" *ngIf="haveTeams">
        <teams-menu
            [getViewClasses]="false"
            [holidaysView]="true"
            [currentUserStream]="currentUserStream"
            (mode)="onModeChanged($event)"
            [i18n]="i18n"
            [teamsStream]="teamsStream"
            (selection)="onTeamSelected($event)"
        >
        </teams-menu>
    </div>
    <div
        class="o-layout__item"
        [ngClass]="{'o-layout__item--full-width': !haveTeams}"
    >
        <a (click)="selectAll()" class="c-calendar-configurator__filters-link"
            >{{i18n.showAll}}</a
        >
        <a
            (click)="deselectAll()"
            class="c-calendar-configurator__filters-link"
        >
            {{i18n.hideAll}}</a
        >

        <div
            class="c-calendar-configurator__users holidays-users-grid"
            id="holidays-users-grid"
            *ngIf="ready"
        >
            <configurator-user-entry
                *ngFor="let user of usersInCurrentTeamStream | async"
                [user]="user"
                [currentUser]="false"
                [selectedSubject]="getSelectionSubject(user)"
                [usedHolidayDays]="getUsedHolidayDays(user)"
            >
            </configurator-user-entry>
        </div>
    </div>
</div>
