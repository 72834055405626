import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DateService } from "services/date/DateService";

@Component({
    selector: "date-picker-week",
    templateUrl: "date-picker-week.html",
})
export class DatePickerWeekComponent implements OnInit, OnChanges {
    @Input() public month: number; // 0-based
    @Input() public year: number;
    @Input() public startDate: Date; // monday of the week
    @Input() public last: boolean;
    @Input() public first: boolean;
    @Input() public preselected: Observable<Date>;
    @Input() public selection: Subject<Date>;
    @Input() public sealDate: Observable<Date>;
    @Input() public startRange: Observable<Date>;
    @Input() public endRange: Observable<Date>;

    public days: Date[];
    private selectedDay: Date = null;
    private startDay: Date = null;
    private endDay: Date = null;
    private sealDay: Date = null;

    constructor(private dateService: DateService) {}

    public ngOnInit() {
        this.preselected.subscribe((s) => (this.selectedDay = s));
        this.selection.subscribe((s) => (this.selectedDay = s));
        if (this.startRange) {
            this.startRange.subscribe((s) => (this.startDay = s));
        }
        if (this.endRange) {
            this.endRange.subscribe((e) => (this.endDay = e));
        }
        if (this.sealDate) {
            this.sealDate.subscribe((s) => (this.sealDay = s));
        }
    }
    public ngOnChanges() {
        this.days = this.dateService.getWeek(this.startDate);
    }
    public pickDay(day: Date) {
        this.selection.next(day);
    }
    public isPrevMonth(date: Date) {
        return date.getFullYear() < this.year || date.getMonth() < this.month;
    }
    public isNextMonth(date: Date) {
        return date.getFullYear() > this.year || date.getMonth() > this.month;
    }
    public isOtherMonth(date: Date) {
        return this.isPrevMonth(date) || this.isNextMonth(date);
    }
    public isToday(date: Date) {
        return this.dateService.isToday(date);
    }
    public isWeekDay(date: Date): boolean {
        return this.dateService.isWeekDay(date);
    }
    public isSelected(day: Date) {
        return this.dateService.isEqual(this.selectedDay, day);
    }
    public isBeforeSealed(day: Date) {
        return this.dateService.isBefore(day, this.sealDay);
    }

    public isInRange(day: Date) {
        if (this.startDay && this.endDay) {
            return this.dateService.isInRange(day, this.startDay, this.endDay);
        } else if (this.startDay && !this.endDay) {
            return this.dateService.isInRange(
                day,
                this.startDay,
                this.selectedDay
            );
        } else if (this.endDay && !this.startDay) {
            return this.dateService.isInRange(
                day,
                this.selectedDay,
                this.endDay
            );
        } else {
            return false;
        }
    }

    public isEqual(day1: Date, day2: Date) {
        return this.dateService.isEqual(day1, day2);
    }
}
