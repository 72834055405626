<ng-container *ngIf="!isOnTimer">
    <div
        *ngIf="!open"
        (click)="setOpen(true)"
        [ngStyle]="getStyles()"
        [innerHTML]="activity.minutes | minutesViewPipe"
        class="c-ts-activity__container"
        (blur)="log()"
    >
        &nbsp;
    </div>
    <input
        [ngStyle]="getInputDisplay()"
        (blur)="saveDurationAndClose()"
        (keydown.escape)="setOpen(false)"
        class="c-open-activity__input"
        type="text"
    />
</ng-container>
<ng-container *ngIf="isOnTimer">
    <timer-stop
        class="c-ts-activity__timer-controls"
        [userId]="userId"
    ></timer-stop>
    <div class="c-ts-activity__container c-ts-activity__container--timer">
        {{getSecondsWithTimer() | secondsViewPipe }}
    </div>
</ng-container>
