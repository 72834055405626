import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { Observable, ReplaySubject, BehaviorSubject, Subject } from "rxjs";
import { TSProject } from "models/timesheet/TSProject";
import { TSTask } from "models/timesheet/TSTask";
import { TSTaskComponent } from "../task/TSTaskComponent";
import { VerticalDirection } from "../navigation/navigationDirections";
import { Timer } from "models/timesheet/Timer";

@Component({
    selector: "ts-project",
    templateUrl: "ts-project.html",
    host: { "[class.c-ts-project]": "true" },
})
export class TSProjectComponent implements OnInit {
    @Input() public userId: string;
    @Input() public project: TSProject;
    @Input() public userHolidaysDays: Date[];
    @Input() public onTaskEdit: (
        task: { id: string; name: string },
        project: { id: string; name: string }
    ) => void;
    @Input() public firstProject: boolean;
    @Input() public lastProject: boolean;
    @Input() public focusedActivityObservable: BehaviorSubject<{
        taskId: string;
        date: Date;
    }>;
    @Input() public timerObservable: Observable<Timer>;
    @Input() public timerSeconds: number;
    @Output() public navigateUp = new EventEmitter<Date>();
    @Input() public userSealDate: Date;

    @Output() public navigateDown = new EventEmitter<Date>();
    @ViewChildren(TSTaskComponent)
    private taskComponents: QueryList<TSTaskComponent>;

    public highlightedTaskObservable: Subject<TSTask> =
        new ReplaySubject<TSTask>(1);

    public ngOnInit() {
        this.highlightedTaskObservable.next(this.project.tasks[0] || null);
    }

    public notifyHighlighted(task) {
        this.highlightedTaskObservable.next(task);
    }

    public cssClassMap(idx: number): {} {
        const ret = {};
        /* eslint-disable  @typescript-eslint/dot-notation */
        if (this.project.color) {
            ret["c-ts-task--project-" + this.project.color.toLowerCase()] =
                true;
        }

        ret["c-ts-task--first-row"] = idx === 0;
        /* eslint-enable  @typescript-eslint/dot-notation */
        return ret;
    }

    public navigateUpOf(task: TSTaskComponent, date: Date) {
        this.navigateVertically(task, date, VerticalDirection.UP);
    }

    public navigateDownOf(task: TSTaskComponent, date: Date) {
        this.navigateVertically(task, date, VerticalDirection.DOWN);
    }

    private navigateVertically(
        task: TSTaskComponent,
        date: Date,
        direction: VerticalDirection
    ) {
        const taskComponents = this.taskComponents.toArray();
        const targetIndex = taskComponents.indexOf(task) + direction;
        if (targetIndex < 0) {
            this.navigateUp.emit(date);
        } else if (targetIndex >= taskComponents.length) {
            this.navigateDown.emit(date);
        } else {
            taskComponents[targetIndex].setOpen(date);
        }
    }

    public openFirstTask(date: Date) {
        this.taskComponents.first.setOpen(date);
    }

    public openLastTask(date: Date) {
        this.taskComponents.last.setOpen(date);
    }

    public isFirstTask(idx: number) {
        return this.firstProject && idx === 0;
    }

    public isLastTask(idx: number) {
        return this.lastProject && idx === this.project.tasks.length - 1;
    }
}
