import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SelectorSwitch } from "../../models/selector/SelectorSwitch";

@Component({
    selector: "selector",
    templateUrl: "selector.html",
    host: {
        "[class.c-selector]": "true",
        "[class.c-selector--inline]": "this.inline",
        "[class.c-selector--small]": "this.small",
    },
})
export class SelectorComponent {
    @Input() public switches: SelectorSwitch[];
    @Input() public inline: boolean;
    @Input() public small: boolean;
    @Output() public selection = new EventEmitter<string>();

    public selectSwitch(selected: SelectorSwitch) {
        this.switches.forEach((checkSwitch) => {
            checkSwitch.selected = checkSwitch === selected;
        });
        this.selection.emit(selected.label);
    }
}
