import { TSActivityComponent } from "../activity/TSActivityComponent";
import { HorizontalDirection } from "./navigationDirections";
import { KEY_CODE } from "constants/KeyCode";

export abstract class NavigableRowOfActivities {
    public navigateLeftOf(
        focusedActivity: TSActivityComponent,
        userSealDate: Date,
        evt?: KeyboardEvent
    ) {
        if (evt) {
            evt.preventDefault();
        }
        this.navigateHorizontally(
            focusedActivity,
            HorizontalDirection.LEFT,
            userSealDate
        );
    }

    public navigateRightOf(
        focusedActivity: TSActivityComponent,
        userSealDate: Date,
        evt?: KeyboardEvent
    ) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        this.navigateHorizontally(
            focusedActivity,
            HorizontalDirection.RIGHT,
            userSealDate
        );

        if (evt && (evt.which || evt.keyCode) === KEY_CODE.KEY_RETURN) {
            focusedActivity.saveDurationAndClose();
        }
    }

    protected abstract getActivities(): TSActivityComponent[];

    private navigateHorizontally(
        focusedActivity: TSActivityComponent,
        direction: HorizontalDirection,
        userSealDate: Date
    ) {
        const activityComponents = this.getActivities();
        const targetIndex =
            activityComponents.indexOf(focusedActivity) + direction;
        if (targetIndex >= 0 && targetIndex < activityComponents.length) {
            const targetActivity = activityComponents[targetIndex];
            if (
                userSealDate == null ||
                targetActivity.activity.date.getTime() > userSealDate.getTime()
            ) {
                focusedActivity.saveDurationAndClose();
                targetActivity.setOpen(true);
            }
        }
    }
}
