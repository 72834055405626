export const datePickerI18NPl = {
    monthNames: [
        "Styczeń",
        "Luty",
        "Marzec",
        "Kwiecień",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpień",
        "Wrzesień",
        "Październik",
        "Listopad",
        "Grudzień",
    ],
    sundayInitial: "N",
    mondayInitial: "P",
    tuesdayInitial: "W",
    wednesdayInitial: "Ś",
    thursdayInitial: "C",
    fridayInitial: "P",
    saturdayInitial: "S",
    startDate: "Data początowa",
    endDate: "Data końcowa",
    today: "Dziś",
    yesterday: "Wczoraj",
    thisWeek: "Ten tydz.",
    thisMonth: "Ten miesiąc",
    thisYear: "Ten rok",
    lastWeek: "Poprz. tydz.",
    lastMonth: "Poprz. miesiąc",
    lastYear: "Poprz. rok",
};
