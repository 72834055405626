import { calendarConfiguratorI18NDe } from "./calendar-configurator-i18n-de";
import { calendarConfiguratorI18NPl } from "./calendar-configurator-i18n-pl";

const calendarConfiguratorI18NEn = {
    showAll: "Show all",
    hideAll: "Hide all",
    active: "Show all",
    addTeam: "Add team",
    allTeams: "All teams",
    myTeams: "My teams",
};

export const calendarConfiguratorI18N = {
    de: calendarConfiguratorI18NDe,
    en: calendarConfiguratorI18NEn,
    pl: calendarConfiguratorI18NPl,
};
