<div class="c-month-full__week-legend">
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[0]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[1]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[2]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[3]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[4]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[5]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[6]}}</div>
</div>

<div
    class="c-month-full__week"
    (click)="showWeek(week[0])"
    [ngClass]="{'c-month-full__week--visible': weekIndex === selectedWeek}"
    *ngFor="let week of selectedMonth; let weekIndex = index"
>
    <div
        *ngFor="let day of week; let i = index"
        class="c-month-full__day {{dayClassNames[i]}}"
        [ngClass]="{'c-month-full__day--today': isToday(day), 'c-month-full__day--other-month': isOtherMonth(day)}"
    >
        <span class="c-month-full__day-label">{{day.getDate()}}</span>

        <div class="c-month-full__absence">
            <div
                class="c-month-full__absent"
                (mouseenter)="hover(absent.userId)"
                (mouseleave)="hover()"
                [ngClass]="{
                    'c-month-full__absent--me': absent.currentUser,
                    'c-month-full__absent--sick': absent.type === 'SICK_LEAVE',
                    'c-month-full__absent--hover': hoveredUser === absent.userId}"
                *ngFor="let absent of day.absence"
            >
                <div class="c-month-full__absent-photo">
                    <img
                        class="c-month-full__absent-photo-image"
                        src="{{absent.userAvatar}}"
                        alt=""
                    />
                </div>

                <div class="c-month-full__absent-user">
                    {{absent.currentUser ? 'Me' : absent.user.firstName + ' ' +
                    absent.user.lastName}}
                </div>
            </div>
            <div
                class="c-month-full__absent-more"
                [ngClass]="{'c-month-full__absent-more--hover' : hoveredUser && isHoveredUserInMore(hoveredUser, day.absence)}"
                *ngIf="day.absence && day.absence.length > 3"
            >
                + {{day.absence.length - 3}} more
            </div>
        </div>
    </div>
</div>
