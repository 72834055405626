import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { TimesheetService } from "../../../services/timesheet/TimesheetService";

@Component({
    selector: "timer-stop",
    templateUrl: "timer-stop.html",
    host: {
        "[class.c-timer-stop]": "true",
        "[class.c-timer-stop--focus]": "focused",
    },
})
export class TimerStopComponent {
    @Input() public userId: string;

    private focused = false;

    @ViewChild("cancelButton")
    private cancelButton;

    @ViewChild("submitButton")
    private submitButton;

    constructor(
        private timesheetService: TimesheetService,
        private elementRef: ElementRef
    ) {}

    public submitTimer(): void {
        this.timesheetService.submitTimer(this.userId);
    }

    public cancelTimer(): void {
        this.timesheetService.cancelTimer(this.userId);
    }

    public focus(): void {
        this.focusButton(this.submitButton.nativeElement);
    }

    public focusButton(nativeButton, event?: KeyboardEvent): void {
        this.focused = true;
        if (event) {
            event.stopPropagation();
        }
        setTimeout(() => nativeButton.focus());
    }

    public blur(event: FocusEvent): void {
        if (!this.elementRef.nativeElement.contains(event.relatedTarget)) {
            this.focused = false;
        }
    }
}
