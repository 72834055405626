<message-header></message-header>
<modal-core>
    <div class="c-profile-edit__header">
        <h1 class="c-profile-edit__header-title">{{ i18n.editYourProfile }}</h1>
    </div>

    <profile-edit-form
        [showStream]="actionStream"
        [canSkip]="true"
    ></profile-edit-form>
</modal-core>
