export const newCompanyI18nDe = {
    placeholderName: "Timepot Name",
    nameIsFree: "Nicht vergeben, jetzt zugreifen!",
    nameIsTaken: "Bereits vergeben!",
    nameIsInvalid: "Ungültiger Name!",
    validNameHint:
        "Sie können Groß- und Kleinbuchstaben verwenden, Ziffern und Bindestriche einsetzen",
    createNewCompany: "Neuen Timepot erstellen",
    save: "Speichern",
};
