export const calendarI18NPl = {
    sundayInitial: "N",
    mondayInitial: "P",
    tuesdayInitial: "W",
    wednesdayInitial: "Ś",
    thursdayInitial: "C",
    fridayInitial: "P",
    saturdayInitial: "S",
    prev: "poprzedni",
    next: "następny",
    year: "rok",
    month: "miesiąc",
    week: "tydzień",
    showHolidays: "Pokaż urlopy",
    hideHolidays: "Ukryj urlopy",
    showSickDays: "Pokaż choroby",
    hideSickDays: "Ukryj choroby",
    daysShort: ["Pon", "Wto", "śro", "Czw", "Pią", "Sob", "Nie"],
    monthNames: [
        "Styczeń",
        "Luty",
        "Marzec",
        "Kwiecień",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpień",
        "Wrzesień",
        "Październik",
        "Listopad",
        "Grudzień",
    ],
};
