<div class="o-ts-row__cell o-ts-row__cell--name c-add-task-row__name-container">
    <ts-project-selector
        [activateStream]="activateProjectsStream"
        [selection]="projectSubject"
        [userIdObservable]="userIdObservable"
        [initialSelectionStream]="mostRecentProjectStream"
        [automaticallySelectTheOnlyProject]="true"
        [userId]="userId"
    ></ts-project-selector>
    <task-dropdown
        [activateStream]="activateTasksStream"
        [selection]="taskSubject"
        [uncommittedSelection]="candidateTaskSubject"
        [autocommit]="false"
        [resetAfterCommit]="false"
        [projectObservable]="projectSubject"
        [needsProject]="true"
        [resetSearchText]="resetTaskNameSubject"
        [placeholder]="isCreatingTasksInProjectAllowed() ? i18n.findOrAddTask : i18n.selectTaskFromList"
        [dropdownOpensUpwards]="dropdownOpensUpwards"
        [allowAddingNewTasks]="isCreatingTasksInProjectAllowed()"
        (keydown.tab)="focusTodayOrFirstActivityIfTaskSelected($event)"
        (keydown.enter)="focusTodayOrFirstActivityIfTaskSelected($event)"
        (mousedown)="setCellToBeFocusedIdx(undefined)"
        (inputChange)="taskInputChange($event)"
    >
    </task-dropdown>
    <ng-container *ngIf="isCurrentWeek() && !isTodaySealed()">
        <timer-start
            *ngIf="isTaskSelected()"
            class="c-add-task-row__task-start"
            [userId]="userId"
            [project]="project"
            [taskName]="taskInputValue"
            [taskCode]="selectedTask && selectedTask.id"
            (mousedown)="preventCellFocus()"
            (click)="reset()"
        ></timer-start>
        <div
            *ngIf="!isTaskSelected()"
            class="c-add-task-row__btn-start-placeholder"
        ></div>
    </ng-container>
</div>
<ng-container *ngIf="!isTaskSelected()">
    <div
        *ngFor="let activity of activities; let i = index"
        class="o-ts-row__cell o-ts-row__cell--day"
        [attr.data-tooltip]="isSealedActivity(activity) ? null : disabledCellTooltip"
        [ngClass]="{ 'u-tooltip--top': true,
                      'c-ts-activity--disabled': true,
                      'c-ts-activity': true,
                      'c-ts-activity--holidays': isHolidaysActivity(activity),
                      'c-ts-activity--today': dateService.isToday(activity.date) && !isHolidaysActivity(activity) && !isSealedActivity(activity),
                      'c-ts-activity--holidays-today': dateService.isToday(activity.date) && isHolidaysActivity(activity),
                      'o-ts-row__cell--weekend': !dateService.isWeekDay(activity.date)}"
        (mousedown)="setCellToBeFocusedIdx(i)"
        (click)="displayDisabledCellTooltip=true"
    >
        <span
            class="c-add-task-row__seal"
            [ngClass]="{ 'c-add-task-row__seal--sealed': isSealedActivity(activity)}"
        ></span>
    </div>
</ng-container>
<ng-container *ngIf="isTaskSelected()">
    <ts-activity
        *ngFor="let activity of activities; let i = index"
        class="o-ts-row__cell o-ts-row__cell--day"
        [ngClass]="{ 'c-ts-activity--sealed': isSealedActivity(activity),
                              'c-ts-activity--holidays': isHolidaysActivity(activity),
                              'c-ts-activity--today': dateService.isToday(activity.date) && !isHolidaysActivity(activity) && !isSealedActivity(activity),
                              'c-ts-activity--holidays-today': dateService.isToday(activity.date) && isHolidaysActivity(activity),
                              'o-ts-row__cell--weekend': !dateService.isWeekDay(activity.date)}"
        [activity]="activity"
        [task]="selectedTask"
        [userId]="userId"
        [projectId]="project?.id"
        [activitiesAllowed]="true"
        #activityComponent
        (mousedown)="setCellToBeFocusedIdx(i)"
        (keydown.enter)="navigateOutOf(activityComponent, $event)"
        (keydown.control.enter)="navigateOutOf(activityComponent, $event)"
        (keydown.arrowleft)="navigateLeftOf(activityComponent, userSealDate)"
        (keydown.arrowright)="navigateRightOf(activityComponent, userSealDate)"
        (keydown.tab)="navigateRightOf(activityComponent, userSealDate)"
        (keydown.shift.tab)="navigateLeftOf(activityComponent, userSealDate)"
    >
    </ts-activity>
</ng-container>
<div
    class="o-ts-row__cell o-ts-row__cell--summary c-add-task-row__btns-container"
></div>
