<generic-modal
    [showStream]="showStream"
    [actionStream]="actionStream"
    [okEnabled]="ready"
>
    <div class="c-user-teams-modal__header">
        <user-avatar
            class="c-user-teams-modal__header-avatar"
            [user]="user"
        ></user-avatar>
        <span class="c-user-teams-modal__header-name">{{user.name}}</span>
    </div>
    <div *ngIf="!ready" class="u-loader u-loader--relative"></div>
    <ng-container *ngIf="ready">
        <div class="c-user-teams-modal__form" *ngIf="selectedTeams.length">
            <span
                class="c-user-teams-modal__team"
                *ngFor="let team of selectedTeams"
            >
                {{team.name}}
                <a
                    class="c-user-teams-modal__team-remove"
                    (click)="removeFromSelection(team)"
                ></a>
            </span>
        </div>
        <team-dropdown
            (selection)="onNewTeams($event)"
            [itemStream]="teamsStream"
            [initialSelectionStream]="userTeamsStream"
        ></team-dropdown>
    </ng-container>
</generic-modal>
