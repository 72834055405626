import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { UserPaymentService } from "services/user/UserPaymentService";
import { CurrencyService } from "services/currency/CurrencyService";
import { UserPayment } from "models/users/UserPayment";
import { UserPaymentType } from "models/users/UserPaymentType";
import { I18NService } from "services/i18n/I18NService";
import { userPaymentI18N } from "../i18n/user-payment-i18n";

const PART_TIME_HOURS_IN_DAY = 4;
const FULL_TIME_HOURS_IN_DAY = 8;

@Component({
    selector: "create-or-add-user-payment",
    templateUrl: "create-or-add-user-payment.html",
    host: {
        "[class.c-user-payment-modal]": "true",
    },
})
export class CreateOrAddUserPaymentComponent implements OnInit {
    @Input() public userCode: string;

    @Input() public set payment(p: UserPayment) {
        this.userPayment = p;
        this.oldRate = p && p.rate;
    }

    @Input() public noInitialPaymentType: boolean;
    @Output() public valid = new EventEmitter<boolean>();
    public rateValid = true;
    public userPayment: UserPayment;
    public oldRate: number;
    public i18n;
    public currenciesArray: Array<CurrencyOption>;

    public selected = new ReplaySubject<CurrencyOption[]>(1);
    public preselected = new ReplaySubject<CurrencyOption[]>(1);

    constructor(
        private userPaymentService: UserPaymentService,
        i18nService: I18NService,
        private currencyService: CurrencyService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(userPaymentI18N);

        const currencies = currencyService.getSupportedCurrencies();
        const currenciesArray = [];
        currencies.forEach(function (name) {
            currenciesArray.push({ name: name, id: name });
        });
        this.currenciesArray = currenciesArray;
    }

    public ngOnInit() {
        const preselected = [
            { name: this.userPayment.currency, id: this.userPayment.currency },
        ];
        this.preselected.next(preselected);

        this.selected.subscribe((selectedOptions) => {
            if (selectedOptions[0]) {
                this.setCurrency(selectedOptions[0].id);
            }
        });
    }

    public isMonthlyRate(): boolean {
        return (
            this.userPayment &&
            this.userPayment.type === UserPaymentType.MONTHLY_RATE
        );
    }

    public isHourlyRate(): boolean {
        return (
            this.userPayment &&
            this.userPayment.type === UserPaymentType.HOURLY_RATE
        );
    }

    public setHourlyRate(): void {
        this.userPayment.type = UserPaymentType.HOURLY_RATE;
        this.userPayment.workingHoursInDay = undefined;
        this.checkValidityAndEmit();
    }

    public setMonthlyRate(): void {
        this.userPayment.type = UserPaymentType.MONTHLY_RATE;
        this.checkValidityAndEmit();
    }

    public setCurrency(currency: string): void {
        this.userPayment.currency = currency;
        this.checkValidityAndEmit();
    }

    public isFullTime(): boolean {
        return (
            this.userPayment &&
            this.userPayment.workingHoursInDay === FULL_TIME_HOURS_IN_DAY
        );
    }

    public setFullTime(): void {
        this.userPayment.workingHoursInDay = FULL_TIME_HOURS_IN_DAY;
        this.checkValidityAndEmit();
    }

    public isPartTime(): boolean {
        return this.userPayment.workingHoursInDay === PART_TIME_HOURS_IN_DAY;
    }

    public setPartTime(): void {
        this.userPayment.workingHoursInDay = PART_TIME_HOURS_IN_DAY;
        this.checkValidityAndEmit();
    }

    public setRate(rate: string) {
        this.rateValid = this.currencyService.isFormatValid(rate);
        if (this.rateValid) {
            this.userPayment.rate = this.currencyService.convertToNumber(rate);
        }
        this.checkValidityAndEmit();
    }

    private checkValidityAndEmit() {
        const paymentTypeValid =
            this.userPayment.type === UserPaymentType.HOURLY_RATE ||
            (this.userPayment.type === UserPaymentType.MONTHLY_RATE &&
                [PART_TIME_HOURS_IN_DAY, FULL_TIME_HOURS_IN_DAY].indexOf(
                    this.userPayment.workingHoursInDay
                ) > -1);

        this.valid.next(
            this.rateValid &&
                this.userPayment.rate &&
                paymentTypeValid &&
                !!this.userPayment.currency
        );
    }

    public save(): void {
        let observable: Observable<void>;

        switch (this.userPayment.type) {
            case UserPaymentType.HOURLY_RATE:
                if (this.noInitialPaymentType) {
                    observable = this.userPaymentService.createHourlyRate(
                        this.userCode,
                        this.userPayment.rate,
                        this.userPayment.currency
                    );
                } else {
                    observable = this.userPaymentService.addHourlyRatePeriod(
                        this.userCode,
                        this.userPayment.rate,
                        new Date()
                    );
                }
                break;

            case UserPaymentType.MONTHLY_RATE:
                if (this.noInitialPaymentType) {
                    observable = this.userPaymentService.createMonthlyRate(
                        this.userCode,
                        this.userPayment.rate,
                        this.userPayment.workingHoursInDay,
                        this.userPayment.currency
                    );
                } else {
                    observable = this.userPaymentService.addMonthlyRatePeriod(
                        this.userCode,
                        this.userPayment.rate,
                        this.userPayment.workingHoursInDay,
                        new Date()
                    );
                }
                break;

            default:
                throw "unexpected userPaymentType";
        }

        observable.subscribe();
    }
}

export type CurrencyOption = { name: string; id: string };
