import { Component, Input, OnInit } from "@angular/core";
import { UserService } from "services/user/UserService";
import { emptyUser, User } from "models/users/User";
import { I18NService } from "services/i18n/I18NService";
import { timesheetI18N } from "../i18n/timesheet-i18n";

@Component({
    selector: "ts-user-header",
    templateUrl: "ts-user-header.html",
    host: { "[class.c-ts-user-header]": "true" },
})
export class TSUserHeaderComponent implements OnInit {
    @Input() public userId: string;
    public user: User = emptyUser();
    public i18n;

    constructor(
        private i18nService: I18NService,
        private userService: UserService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(timesheetI18N);
    }

    public ngOnInit() {
        this.userService
            .getUser(this.userId)
            .subscribe((user) => (this.user = user));
    }
}
