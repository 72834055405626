<div class="c-user-payment-history-entry__row">
    <div class="c-user-payment-history-entry__period" *ngIf="!isInEditMode">
        <div>{{i18n.from}}: {{getFormattedDate(snapshot.from)}}</div>
        <div>{{i18n.to}}: {{getFormattedDate(snapshot.to)}}</div>
    </div>
    <div class="c-user-payment-history-entry__period-edit" *ngIf="isInEditMode">
        <div class="u-flex-row">
            <div class="u-1/1 u-1/2@sm">
                <date-picker
                    [selection]="startSubject"
                    [preselected]="selectedStartDateStream"
                    [position]="'align-left'"
                    [iconClass]="'c-date-picker__input--start'"
                    [disabled]="!snapshot.from"
                ></date-picker>
            </div>
            <div class="u-1/1 u-1/2@sm">
                <date-picker
                    [selection]="endSubject"
                    [preselected]="selectedEndDateStream"
                    [position]="'align-right'"
                    [iconClass]="'c-date-picker__input--end'"
                    [disabled]="!snapshot.to"
                ></date-picker>
            </div>
        </div>
    </div>
    <div
        class="c-user-payment-history-entry__amount c-user-payment-history-entry__amount--wider"
    >
        {{getFormattedAmount(snapshot.amount)}}
    </div>
    <div
        class="c-user-payment-history-entry__action c-user-payment-history-entry__action--edit"
        *ngIf="!isInEditMode"
        (click)="editSnapshot(idx)"
    ></div>
    <div
        class="c-user-payment-history-entry__action c-user-payment-history-entry__action--save"
        *ngIf="isInEditMode"
        (click)="saveSnapshot(idx)"
    ></div>
</div>
