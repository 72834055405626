<div class="c-month-picker__container">
    <div class="c-month-picker__button">
        <span class="c-month-picker__button-label"
            >{{rangeStart ? i18n.from : i18n.to}}</span
        >
        <span class="c-month-picker__button-value"
            >{{selectedYearMonthString}}</span
        >
    </div>
    <year-month-picker
        *ngIf="open"
        [selectedYearMonth]="selectedYearMonth"
        [alignLeft]="rangeStart"
        [selectedYearMonthSubject]="selectedYearMonthSubject"
    ></year-month-picker>
</div>
