import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const RawUser = z.object({
    id: z.string(),
    email: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    avatarUrl: z.string().optional(),
    askForDetails: z.boolean().default(false),
    avatarUploaded: z.boolean(),
});

export type RawUser = z.infer<typeof RawUser>;

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
const createUser = (user: RawUser) => ({
    ...user,
    name: userNameFrom(user.firstName, user.lastName, user.email),
});
// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const User = RawUser.transform(createUser);
export type User = z.infer<typeof User>;
export function emptyUser(): User {
    return createUser({
        id: "",
        email: "",
        avatarUploaded: false,
    });
}

export function userNameFrom(
    firstName: string,
    lastName: string,
    fallback = ""
) {
    return !!firstName && !!lastName
        ? `${firstName} ${lastName}`
        : lastName
        ? lastName
        : firstName
        ? firstName
        : fallback;
}
