import { datePickerI18NDe } from "./datepicker-i18n-de";
import { datePickerI18NPl } from "./datepicker-i18n-pl";
const datePickerI18NEn = {
    monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    sundayInitial: "S",
    mondayInitial: "M",
    tuesdayInitial: "T",
    wednesdayInitial: "W",
    thursdayInitial: "T",
    fridayInitial: "F",
    saturdayInitial: "S",
    startDate: "Start date",
    endDate: "End date",
    today: "Today",
    yesterday: "Yesterday",
    thisWeek: "This week",
    thisMonth: "This month",
    thisYear: "This year",
    lastWeek: "Last week",
    lastMonth: "Last month",
    lastYear: "Last year",
};

export const datePickerI18N = {
    de: datePickerI18NDe,
    en: datePickerI18NEn,
    pl: datePickerI18NPl,
};
