export const passwordI18NDe = {
    save: "Speichern",
    email: "E-mail",
    gotoLogin: "Weiter zur Anmeldung",
    newPassword: "Neues Passwort",
    passwordHasBeenReset: "Ihr Passwort wurde zurückgesetzt",
    passwordValidationRequirements: `Passwort muss mindestens 7 Zeichen enthalten`,
    resetPassword: "Passwort zurücksetzen",
    resetPasswordDescription:
        "Bitte geben Sie die E-Mail-Adresse ein, mit der Sie Ihr Timepot Konto erstellt haben",
    resetPasswordEmailSent:
        "Eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts wurde verschickt",
    send: "Senden",
    setNewPassword: "Passwort",
    weakPassword: "Schwaches Passwort",
    invalidPassword: "Ungültiges Passwort",
};
