import { z } from "zod";
import { TimepotDate } from "models/TimepotDate";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const DailyTimeReportItem = z.object({
    date: TimepotDate,
    duration: z.number(),
});

export type DailyTimeReportItem = z.infer<typeof DailyTimeReportItem>;
