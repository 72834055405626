import { Component, Input, OnInit } from "@angular/core";
import { combineLatest, Observable, ReplaySubject, Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { User } from "models/users/User";
import { DaysOff } from "models/daysOff/DaysOff";
import { calendarConfiguratorI18N } from "./i18n/calendar-configurator-i18n";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { Team } from "models/teams/Team";
import { PredefinedTeamCode } from "../../teamsmenu/TeamsMenuComponent";
import { TeamService } from "services/teams/TeamService";
import { UserService } from "services/user/UserService";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { CurrentCompanyUserService } from "services/currentcompanyuser/CurrentCompanyUserService";
import { CompanyUserStatus } from "models/companyusers/CompanyUserStatus";
import { TeamViewMode } from "models/teams/TeamViewMode";

@Component({
    selector: "calendar-configurator",
    templateUrl: "calendar-configurator.html",
    host: { "[class.c-calendar-configurator]": "true" },
})
export class CalendarConfiguratorComponent implements OnInit {
    public currentUser: CompanyUser;
    public otherUsers: User[];
    public teamsStream = new ReplaySubject<Team[]>(1);

    @Input() public selectedSubject: Subject<string[]>;
    @Input() public daysOffObservable: Observable<DaysOff>;
    public usersInCurrentTeamStream = new ReplaySubject<CompanyUser[]>(1);
    private daysOff: DaysOff;
    private selectedSubjectsByUser: { [userId: string]: Subject<boolean> } = {};
    public i18n;
    private selectedTeam: {
        code?: string;
        predefinedCode?: PredefinedTeamCode;
    };
    private allUsers: CompanyUser[] = [];
    public currentUserStream = new ReplaySubject<CompanyUser>(1);
    private mode: TeamViewMode = TeamViewMode.ALL;
    public ready = false;
    public haveTeams = false;

    constructor(
        i18nService: I18NService,
        private currentCompanyUserServiceView: CurrentCompanyUserViewService,
        private userService: UserService,
        private teamService: TeamService,
        private currentCompanyUserService: CurrentCompanyUserService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(
            calendarConfiguratorI18N
        );
    }

    public ngOnInit() {
        this.selectedTeam = { predefinedCode: PredefinedTeamCode.ACTIVE };
        const us = this.userService.getUsersForCompany(null);
        const ts = this.teamService.getTeams();
        combineLatest([us, ts]).subscribe((data) => {
            const users = data[0];
            const teams = data[1];
            this.haveTeams = teams.length > 0;
            this.allUsers = users.companyUsers;
            this.currentUser = this.allUsers.find(
                (u) => u.id === this.currentCompanyUserService.companyUser.id
            );
            this.teamsStream.next(teams);
            this.currentUserStream.next(this.currentUser);
            this.onTeamSelected(this.selectedTeam);
        });

        this.daysOffObservable.subscribe((daysOff: DaysOff) => {
            this.currentUser = this.currentCompanyUserServiceView.companyUser;
            this.daysOff = daysOff;
            this.otherUsers = daysOff.daysOff
                .map((userHolidays) => userHolidays.user)
                .filter((user) => user.id !== this.currentUser.id);
            const currUserSubj = this.getSubjectForUser(
                this.currentUser.id,
                true
            );
            this.selectedSubjectsByUser[this.currentUser.id] = currUserSubj;
            const subjects: Subject<boolean>[] = [currUserSubj];
            const userIds = [this.currentUser.id];
            this.otherUsers.forEach((user) => {
                const subj = this.getSubjectForUser(user.id, false);
                this.selectedSubjectsByUser[user.id] = subj;
                subjects.push(subj);
                userIds.push(user.id);
            });
            this.ready = true;
            combineLatest(subjects).subscribe((selections: boolean[]) => {
                const ret: string[] = [];
                for (let i = 0; i < selections.length; i++) {
                    if (selections[i]) {
                        ret.push(userIds[i]);
                    }
                }
                this.selectedSubject.next(ret);
            });
        });
    }

    public onModeChanged(mode: TeamViewMode) {
        this.mode = mode;
        if (this.selectedTeam) {
            this.onTeamSelected(this.selectedTeam);
        }
    }

    public onTeamSelected(e: {
        code?: string;
        predefinedCode?: PredefinedTeamCode;
    }) {
        this.selectedTeam = e;
        this.deselectAll();
        if (e.code) {
            const selectedUsers = this.allUsers.filter(
                (cu) =>
                    cu.status === CompanyUserStatus.ACTIVE &&
                    cu.teams.find((t) => t.code === e.code)
            );
            this.selectUsers(selectedUsers);
            this.usersInCurrentTeamStream.next(selectedUsers);
        } else if (e.predefinedCode === PredefinedTeamCode.ACTIVE) {
            this.selectUsers([this.currentUser]);
            this.usersInCurrentTeamStream.next(
                this.allUsers
                    .filter(this.teamModeFilter)
                    .filter((cu) => cu.status === CompanyUserStatus.ACTIVE)
            );
        } else if (e.predefinedCode === PredefinedTeamCode.PENDING) {
            // do not filter by common team in invited and inactive users
            this.usersInCurrentTeamStream.next(
                this.allUsers.filter(
                    (cu) => cu.status === CompanyUserStatus.INVITED
                )
            );
        } else {
            const x = this.allUsers.filter(
                (cu) => cu.status === CompanyUserStatus.INACTIVE
            );
            this.usersInCurrentTeamStream.next(x);
        }
    }

    private selectUsers(users: CompanyUser[]) {
        users.forEach((u) => {
            const subject: Subject<boolean> = this.getSelectionSubject(u);
            if (subject) {
                subject.next(true);
            }
        });
    }

    private teamModeFilter = (cu: CompanyUser) => {
        // the given user has at least one team common with the current user
        if (this.mode === TeamViewMode.MY_TEAMS) {
            return (
                cu.teams.filter(
                    (team) =>
                        !!this.currentUser.teams.find(
                            (t) => t.code === team.code
                        )
                ).length > 0
            );
        }
        return true;
    };

    private getSubjectForUser(userId: string, currentUser: boolean) {
        if (
            !Object.prototype.hasOwnProperty.call(
                this.selectedSubjectsByUser,
                userId
            )
        ) {
            // at the beginning, the current user should be selected, the others - deselected
            const subj = new ReplaySubject<boolean>(1);
            subj.next(currentUser);
            return subj;
        } else {
            return this.selectedSubjectsByUser[userId];
        }
    }
    public getSelectionSubject(user: User) {
        if (user) {
            return this.selectedSubjectsByUser[user.id];
        }
        return undefined;
    }
    public getUsedHolidayDays(user: User) {
        if (!user || !this.daysOff) {
            return 0;
        }
        const userDaysOff = this.daysOff.daysOff.find(
            (uh) => uh.user.id === user.id
        );
        if (!userDaysOff) {
            return 0;
        }
        return userDaysOff.totals.holidays;
    }
    public selectAll() {
        this.setAll(true);
    }
    public deselectAll() {
        this.setAll(false);
    }
    private setAll(val: boolean) {
        for (const key in this.selectedSubjectsByUser) {
            if (
                Object.prototype.hasOwnProperty.call(
                    this.selectedSubjectsByUser,
                    key
                )
            ) {
                this.selectedSubjectsByUser[key].next(val);
            }
        }
    }
}
