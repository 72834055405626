import { Inject, Injectable } from "@angular/core";
import { LogLevel } from "./LogLevel";
import { Logger } from "./Logger";
import { Appender } from "./Appender";
import { filter } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class BackendAppender implements Appender {
    constructor(
        private http: HttpClient,
        private log: Logger,
        @Inject("endpoint") private endpoint: string,
        @Inject("client") private client: string
    ) {}
    public consume() {
        this.log.stream
            .pipe(filter((logEntry) => logEntry.postToBackend))
            .subscribe((logEntry) => {
                this.http
                    .post(this.endpoint, {
                        client: this.client,
                        message: logEntry.message,
                        details: logEntry.details,
                    })
                    .subscribe(
                        (r) => r,
                        (err) => {
                            this.log.log(
                                LogLevel.ERROR,
                                "Call to " +
                                    err.url +
                                    " failed with status " +
                                    err.status,
                                err,
                                false,
                                false
                            );
                        }
                    );
            });
    }
}
