import { signupI18NDe } from "./signup-i18n-de";
import { signupI18NPl } from "./signup-i18n-pl";
const signupI18NEn = {
    createAccount: "Create account",
    toJoin: (invitingCompanyName) => "to join " + invitingCompanyName,
    signUpWithGoogle: "Sign Up with Google",
    withGoogle: "with Google",
    with: "with",
    signUpWithEmail: "Sign Up with email",
    withEmail: "with email",
    email: "email",
    signUp: "Sign Up",
    alreadyHaveAnAccount: "Already have an account?",
    logInNow: "Log in now",
    yourEmail: "Your e-mail",
    password: "Password",
    invalidEmailAddress: "Invalid email",
    weakPassword: "Weak password",
    required: "Required",
    passwordLoginFailed:
        "Log in failed. Please try again or log in with Google",
    enterPromoCode: "Enter promo code",
    placeholderPromoCode: "Promo code",
    bySigningUpYouAreAgreeTo: "I have read and accept",
    thePrivacyPolicy: "Privacy Policy",
    and: "and",
    termsOfUse: "the Terms of Service",
    privacyUrl: "https://www.timepot.io/privacy.html",
    termsUrl: "https://www.timepot.io/terms.html",
    firstProjectName: "Default",
};

export const signupI18N = {
    de: signupI18NDe,
    en: signupI18NEn,
    pl: signupI18NPl,
};
