export const navbarI18NDe = {
    api: "API",
    introductoryTour: "Einführungstour",
    timesheet: "Ihre Woche",
    reports: "Berichte",
    finances: "Finanzen",
    projects: "Projekte",
    holidays: "Urlaub",
    users: "Benutzer",
    daysOff: "Freie Tage",
    companyName: (name) => `Timepot ${name}`,
    createNewCompany: "Neuen Timepot erstellen",
    editProfile: "Profil bearbeiten",
    settings: "Einstellungen",
    sendUsFeedback: "Feedback übermitteln",
    signOut: "Abmelden",
};
