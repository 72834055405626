import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { paginationI18N } from "./i18n/pagination-i18n";

@Component({
    selector: "pagination",
    templateUrl: "pagination.html",
    host: { "[class.c-pagination]": "true" },
})
export class PaginationComponent implements OnInit {
    @Input() public dataStream: Observable<{}[]>;
    @Output() public page = new EventEmitter<{}[]>();
    public i18n;
    public start = 0;
    public pageSize = 50;
    public currentDataLength = 0;
    public currentPageLength = 0;
    private startStream = new ReplaySubject<number>(1);

    constructor(private i18nService: I18NService) {
        this.i18n = this.i18nService.extractCurrentTranslation(paginationI18N);
    }
    public ngOnInit() {
        this.startStream.subscribe((v) => (this.start = v));
        this.startStream.next(this.start);
        this.dataStream.subscribe(() => this.startStream.next(0));
        combineLatest([this.startStream, this.dataStream]).subscribe((arg) => {
            let start = arg[0];
            const data = arg[1];
            this.currentDataLength = data.length;
            start = Math.max(0, Math.min(start, data.length - 1));
            this.start = start;
            const last = Math.min(start + this.pageSize, data.length);
            const p = data.slice(start, last);
            this.currentPageLength = p.length;
            this.page.emit(p);
        });
    }
    public get prevEnabled() {
        return this.start > 0;
    }

    public get nextEnabled() {
        return this.start + this.pageSize < this.currentDataLength;
    }

    public next(): void {
        if (!this.nextEnabled) {
            return;
        }
        this.startStream.next(this.start + this.pageSize);
    }

    public prev(): void {
        if (!this.prevEnabled) {
            return;
        }
        this.startStream.next(this.start - this.pageSize);
    }
}
