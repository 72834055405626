import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { profileEditI18N } from "./i18n/profile-edit-i18n";
import { Action } from "../genericmodal/GenericModalComponent";
import { DateService } from "services/date/DateService";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";

@Component({
    selector: "profile-edit",
    templateUrl: "profile-edit.html",
    host: { "[class.c-profile-edit]": "true" },
})
export class ProfileEditComponent implements OnInit {
    public i18n;
    public actionStream = new Subject<Action>();

    constructor(
        private i18nService: I18NService,
        private router: Router,
        private dateService: DateService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(profileEditI18N);
    }

    public ngOnInit() {
        this.actionStream.subscribe(() => {
            const now = new Date();
            const monday = DateService.toISOString(
                this.dateService.getMondayOfWeek(now)
            );
            const sunday = DateService.toISOString(
                this.dateService.getSundayOfWeek(now)
            );
            const userId = this.currentCompanyUserViewService.userId;

            this.router.navigate([
                "auth",
                "ts",
                { from: monday, to: sunday, userId: userId },
            ]);
        });
    }
}
