import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProjectPricingHistory } from "models/projects/ProjectPricingHistory";
import { CompanyUser } from "models/companyusers/CompanyUser";

@Component({
    selector: "project-pricing-history",
    templateUrl: "project-pricing-history.html",
    host: { "[class.c-project-pricing-history]": "true" },
})
export class ProjectPricingHistoryComponent {
    @Input() public history: ProjectPricingHistory;
    @Input() public currency: string;
    @Input() public users: CompanyUser[] = [];
    @Input() public projectCode: string;
    @Output() public editHistory = new EventEmitter<void>();

    public onSave() {
        this.editHistory.emit(null);
    }
}
