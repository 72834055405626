export const tourI18NPl = {
    nextBtn: "Dalej",
    doneBtn: "Koniec",
    demoTour: {
        intro: {
            title: "Odkryj Timepota",
            content:
                "Skorzystaj z samouczka, aby poznać wszystkie cechy Timepota.",
        },

        logTime: {
            content: "Wprowadź czas bezpośrednio w tabeli.",
        },

        newTask: {
            content: "Lub dodaj nowe zadanie",
        },

        reports: {
            content: "Analizuj dane za pomocą kompleksowych raportów",
        },

        reportFilter: {
            content: "Filtruj dokładnie te dane, których potrzebujesz",
        },

        barChart: {
            content: "Kliknij na wykres, aby zawęzić raport",
        },

        projects: {
            content: "Zarządzaj projektami",
        },

        holidays: {
            content: "Przeglądaj dni wolne wszystkich członków zespołu",
        },

        reportVacations: {
            content: "Zaznacz zgłaszany urlop bezpośrednio w kalendarzu",
        },

        users: {
            content: "Zarządzaj zespołem",
        },

        previewUser: {
            content: "Kliknij, aby zobaczyć aktywności użytkownika",
        },

        farewell: {
            title: "Dziękujemy!",
            content: "Zawsze możesz uruchomić tę pomoc z menu użytkownika",
        },
    },

    onboardingTour2: {
        intro: {
            title: "Poznaj Timepota!",
            content:
                "Skorzystaj z samouczka, aby poznać wszystkie cechy Timepota.",
        },

        newTask: {
            content: "Wybierz projekt i dodaj zadanie",
        },

        startTimer: {
            content: "Uruchom stoper...",
        },

        logTime: {
            content: "...lub zaloguj czas",
        },

        projects: {
            content: "Zarządzaj projektami",
        },

        reports: {
            content: "Analizuj dane za pomocą kompleksowych raportów",
        },

        reportFilter: {
            content: "Filtruj dokładnie te dane, których potrzebujesz",
        },

        holidays: {
            content: "Przeglądaj dni wolne wszystkich członków zespołu",
        },

        reportVacations: {
            content: "Zaznacz zgłaszany urlop bezpośrednio w kalendarzu",
        },

        users: {
            content: "Zarządzaj zespołem",
        },

        previewUser: {
            content: "Kliknij, aby zobaczyć aktywności użytkownika",
        },

        farewell: {
            title: "Dziękujemy!",
            content: "Zawsze możesz uruchomić tę pomoc z menu użytkownika",
        },
    },
};
