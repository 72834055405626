<div *ngIf="currentOverrides && currentOverrides.length > 0">
    <user-rate-override
        *ngFor="let override of currentOverrides; let idx = index"
        [override]="override"
        [users]="getAvailableUsersForOverride(idx)"
        (dataChanged)="updateOverride($event, idx)"
        (delete)="removeOverride($event, idx)"
    ></user-rate-override>
</div>

<div class="c-user-rate-overrides__btn-add" (click)="addOverride()">
    <svg><use [attr.xlink:href]="'images/menu/all.svg#plus'"></use></svg>
    {{i18n.addRatesPerUser}}
</div>
