import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { CurrencyService } from "services/currency/CurrencyService";
import { I18NService } from "services/i18n/I18NService";
import { projectsI18N } from "../../i18n/projects-i18n";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { ProjectPricing } from "models/projects/ProjectPricing";
import { UserRateOverride } from "models/projects/UserRateOverride";
import * as _ from "lodash";

@Component({
    selector: "project-hourly-rate-pricing",
    templateUrl: "project-hourly-rate-pricing.html",
})
export class ProjectHourlyRatePricingComponent implements OnInit {
    @Input() public currentPricing: ProjectPricing;
    @Input() public noInitialPricing: boolean;
    @Input() public users: CompanyUser[] = [];
    @Output() public valid = new EventEmitter<boolean>();
    @Output() public newPricing = new EventEmitter<ProjectPricing>();
    public rateValid: boolean;
    public rateTouch = false;
    public i18n;
    private pricing: ProjectPricing;
    public currenciesArray: Array<CurrencyOption>;

    public selected = new ReplaySubject<CurrencyOption[]>(1);
    public preselected = new ReplaySubject<CurrencyOption[]>(1);

    constructor(
        i18nService: I18NService,
        private currencyService: CurrencyService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectsI18N);
        const currencies = currencyService.getSupportedCurrencies();
        const currenciesArray = [];
        currencies.forEach(function (name) {
            currenciesArray.push({ name: name, id: name });
        });
        this.currenciesArray = currenciesArray;
    }

    public ngOnInit() {
        this.pricing = _.cloneDeep(this.currentPricing);
        this.rateValid = !!this.pricing.rate;

        const preselected = [
            { name: this.pricing.currency, id: this.pricing.currency },
        ];
        this.preselected.next(preselected);

        this.selected.subscribe((selectedOptions) => {
            if (selectedOptions[0]) {
                this.setCurrency(selectedOptions[0].id);
                this.currentPricing.currency = selectedOptions[0].id;
            }
        });
    }

    public setRate(rate: string) {
        const isValid = this.currencyService.isFormatValid(rate);
        this.rateValid = isValid;
        this.rateTouch = true;
        this.valid.next(this.isPricingValid());
        if (isValid) {
            this.pricing.rate = this.currencyService.convertToNumber(rate);
            this.newPricing.next(this.pricing);
        }
    }

    public setCurrency(currency: string) {
        this.pricing.currency = currency;
        this.newPricing.next(this.pricing);
        this.valid.next(this.isPricingValid());
    }

    public onOverridesChanged(userOverrides: UserRateOverride[]) {
        this.pricing.userOverrides = userOverrides;
        this.newPricing.next(this.pricing);
        this.valid.next(this.isPricingValid());
    }

    private isPricingValid() {
        let overridesValid = true;
        this.pricing.userOverrides.forEach(
            (override) =>
                (overridesValid =
                    overridesValid && this.isOverrideValid(override))
        );
        return this.rateValid && !!this.pricing.currency && overridesValid;
    }
    private isOverrideValid(override: UserRateOverride) {
        return !!override.userCode && override.rate !== undefined;
    }
}

export type CurrencyOption = { name: string; id: string };
