import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { DateRange } from "models/daterange/DateRange";
import { DateService } from "services/date/DateService";
import { ClickService } from "services/click/ClickService";

@Component({
    selector: "ts-week-picker",
    templateUrl: "ts-week-picker.html",
    host: {
        "[class.c-ts-week-picker]": "true",
    },
})
export class TSWeekPickerComponent implements OnInit {
    public isOpen = false;
    public selectedDate = new Subject<Date>();
    public preselected = new ReplaySubject<Date>(1);
    public startRange = new ReplaySubject<Date>();
    public endRange = new ReplaySubject<Date>();

    @Input() public dateRangeObservable: Observable<DateRange>;
    @Input() public userId: string;

    private outsideClicksSubscription: Subscription;

    constructor(
        private elementRef: ElementRef,
        private dateService: DateService,
        private clickService: ClickService,
        private router: Router
    ) {}

    public ngOnInit() {
        this.dateRangeObservable.subscribe((selectedRange: DateRange) => {
            this.preselected.next(selectedRange.from);
            this.startRange.next(selectedRange.from);
            this.endRange.next(selectedRange.to);
        });
        this.selectedDate.subscribe((date) => {
            this.handlePickDay(date);
        });
        this.outsideClicksSubscription = this.clickService
            .getOutsideNodeClicksStream(<Node>this.elementRef.nativeElement)
            .subscribe(() => (this.isOpen = false));
    }

    public setOpen(newIsOpenValue: boolean) {
        this.isOpen = newIsOpenValue;
    }

    public handlePickDay(day: Date) {
        const monday = this.dateService.getMondayOfWeek(day);
        const sunday = new Date();

        sunday.setTime(monday.getTime());
        sunday.setDate(monday.getDate() + 6);

        const dateParams = {
            userId: this.userId,
            from: DateService.toISOString(monday),
            to: DateService.toISOString(sunday),
        };

        this.router.navigate(["auth", "ts", dateParams]);
    }
}
