import { Component, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { DateRange } from "models/daterange/DateRange";
import { DateService } from "services/date/DateService";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { UserService } from "services/user/UserService";
import { DemoService } from "../../services/demo/DemoService";
import { DEMO_TOUR, ONBOARDING_TOUR } from "../../services/tour/TimepotTours";

@Component({
    selector: "timesheet",
    templateUrl: "timesheet.html",
    host: {
        "(window:resize)": "onResize($event)",
        "[class.c-timesheet]": "true",
        "[class.c-timesheet--fixed-footer]": "isContentHigherThanWindow",
        "[class.c-timesheet--drop-up]": "isNoPlaceForDropdown",
    },
})
export class TimesheetComponent implements OnInit {
    private static USER_PARAM = "userId";
    private static FROM_PARAM = "from";
    private static TO_PARAM = "to";
    private mobileViewBreakpoint = 768;
    private dropdownHeight = 290;
    private lastInnerWidth = window.innerWidth;
    private isContentHigherThanWindow = false;
    private isNoPlaceForDropdown = false;
    public currentUserId: string;
    public userId: string;
    public userIdObservable = new Subject<string>();
    public fixedAddBtnObservable: Subject<number> = new BehaviorSubject<number>(
        0
    );
    public timesheetParametersObservable =
        new ReplaySubject<TimesheetParameters>(1);
    public ready: boolean;
    public range;

    private shouldChangeToMobileVersion(range): boolean {
        return (
            this.dateService.isValidWeekRange(range) &&
            this.lastInnerWidth < this.mobileViewBreakpoint
        );
    }
    private isCorrectDateRange(): boolean {
        return (
            this.dateService.isValidWeekRange(this.range) ||
            this.dateService.isValidRange(this.range, 0)
        );
    }
    private widthExceededMobileViewBreakpoint(innerWidth: number): boolean {
        return (
            this.lastInnerWidth <= this.mobileViewBreakpoint &&
            innerWidth > this.mobileViewBreakpoint
        );
    }
    private compareNativeElementAndWindowHeight(nativeElementHeight): void {
        if (window.innerHeight - nativeElementHeight < 0) {
            this.isContentHigherThanWindow = true;
        } else {
            this.isNoPlaceForDropdown =
                window.innerHeight - nativeElementHeight <
                    this.dropdownHeight &&
                window.innerHeight - nativeElementHeight >= 0;
            this.isContentHigherThanWindow = false;
        }
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dateService: DateService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService,
        private el: ElementRef,
        private demoService: DemoService,
        private userService: UserService
    ) {}

    public ngOnInit() {
        const companyUser = this.currentCompanyUserViewService.companyUser;
        this.currentUserId = companyUser.id;
        this.userService.updateTimeZone().subscribe(() => {
            //fire & forget
        });
        this.route.params.subscribe((params) => {
            const userParamId = params[TimesheetComponent.USER_PARAM];
            const fromParam = params[TimesheetComponent.FROM_PARAM];
            const toParam = params[TimesheetComponent.TO_PARAM];

            this.userId = userParamId || this.currentUserId;
            this.userIdObservable.next(this.userId);
            this.range = this.dateService.convertToRange(fromParam, toParam);

            if (this.shouldChangeToMobileVersion(this.range)) {
                const today = DateService.toISOString(new Date());
                this.router.navigate([
                    "auth",
                    "ts",
                    {
                        userId: this.userId,
                        from: today,
                        to: today,
                    },
                ]);
            } else if (this.isCorrectDateRange()) {
                this.timesheetParametersObservable.next(
                    createNewTimesheetParameters(this.range, this.userId)
                );
                this.ready = true;
            } else {
                const redirectWeek = this.range.from
                        ? this.dateService.getWeekStartingOnMonday(
                              this.range.from
                          )
                        : this.dateService.getWeek(new Date()),
                    redirectFrom = DateService.toISOString(redirectWeek[0]),
                    redirectTo = DateService.toISOString(redirectWeek[6]);
                this.router.navigate([
                    "auth",
                    "ts",
                    {
                        userId: companyUser.id,
                        from: redirectFrom,
                        to: redirectTo,
                    },
                ]);
            }
        });

        this.fixedAddBtnObservable.subscribe((state) => {
            this.compareNativeElementAndWindowHeight(state);
        });
    }

    public onResize(event) {
        if (this.widthExceededMobileViewBreakpoint(event.target.innerWidth)) {
            const redirectWeek = this.dateService.getWeekStartingOnMonday(
                    this.range.to
                ),
                redirectFrom = DateService.toISOString(redirectWeek[0]),
                redirectTo = DateService.toISOString(redirectWeek[6]);
            this.router.navigate([
                "auth",
                "ts",
                { userId: this.userId, from: redirectFrom, to: redirectTo },
            ]);
        }

        this.lastInnerWidth = event.target.innerWidth;

        this.compareNativeElementAndWindowHeight(
            this.el.nativeElement.offsetHeight
        );
    }

    public get tourName(): string {
        if (this.demoService.isDemoMode()) {
            return DEMO_TOUR;
        } else {
            return ONBOARDING_TOUR;
        }
    }
}

export interface TimesheetParameters {
    userId: string;
    dateRange: DateRange;
}

function createNewTimesheetParameters(
    dateRange: DateRange,
    userId: string
): TimesheetParameters {
    return { dateRange: dateRange, userId: userId };
}
