import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { datePickerI18N } from "../i18n/datepicker-i18n";

@Component({
    selector: "datepicker-header",
    templateUrl: "datepicker-header.html",
    host: { "[class.o-date-picker__header]": "true" },
})
export class DatepickerHeaderComponent implements OnInit {
    public month: number; // 0-based
    public year: number;
    public i18n;
    @Input() public selectedMonthSubject: Subject<{
        month: number;
        year: number;
    }>;

    constructor(private i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(datePickerI18N);
    }

    public ngOnInit() {
        this.selectedMonthSubject.subscribe(({ month, year }) => {
            this.month = month;
            this.year = year;
        });
    }
    public nextMonth(event: MouseEvent) {
        this.changeMonth(1);
        event.preventDefault();
    }

    public previousMonth(event: MouseEvent) {
        this.changeMonth(-1);
        event.preventDefault();
    }

    private changeMonth(monthModifier: number): void {
        if (monthModifier) {
            const tmp = this.month + monthModifier;
            this.month = (12 + (tmp % 12)) % 12; // convert negative values to positive
            this.year += Math.floor(tmp / 12);
            this.selectedMonthSubject.next({
                month: this.month,
                year: this.year,
            });
        }
    }
}
