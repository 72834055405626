import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { holidaysI18N } from "../i18n/holidays-i18n";

@Component({
    selector: "usage-indicator",
    templateUrl: "usage-indicator.html",
    host: { "[class.c-usage-indicator]": "true" },
})
export class UsageIndicatorComponent implements OnInit {
    public i18n;
    @Input() public year: number;
    @Input() public usedDaysObservable: Observable<{
        holidays: number;
        sickLeave: number;
    }>;
    public usedDays = {
        holidays: 0,
        sickLeave: 0,
    };

    constructor(
        private i18nService: I18NService,
        private chandeDetectorRef: ChangeDetectorRef
    ) {
        this.i18n = i18nService.extractCurrentTranslation(holidaysI18N);
    }
    public ngOnInit() {
        this.usedDaysObservable.subscribe((val) => (this.usedDays = val));
    }
}
