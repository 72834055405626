import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";
import { FilterSettings } from "models/reports/filters/FilterSettings";

@Component({
    selector: "timesheet-report-filter",
    templateUrl: "timesheet-report-filter.html",
    host: { "[class.c-report-filter]": "true" },
})
export class TimesheetReportFilterComponent {
    @Input() public filterSubject: Subject<FilterSettings>;
}
