<div class="c-month-full__week-legend">
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[0]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[1]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[2]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[3]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[4]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[5]}}</div>
    <div class="c-month-full__week-legend-day">{{i18n.daysShort[6]}}</div>
</div>

<div class="c-month-full__week">
    <div
        *ngFor="let day of selectedWeek; let i = index"
        class="c-month-full__day {{dayClassNames[i]}}"
        [ngClass]="{'c-month-full__day--today': isToday(day)}"
    >
        <span class="c-month-full__day-label">{{day.getDate()}}</span>

        <div class="c-month-full__absence">
            <div
                class="c-month-full__absent"
                (mouseenter)="hover(absent.userId)"
                (mouseleave)="hover()"
                [ngClass]="{
                    'c-month-full__absent--me': absent.currentUser,
                    'c-month-full__absent--sick': absent.type === 'SICK_LEAVE',
                    'c-month-full__absent--hover': hoveredUser === absent.userId}"
                *ngFor="let absent of day.absence"
            >
                <div class="c-month-full__absent-photo">
                    <img
                        class="c-month-full__absent-photo-image"
                        src="{{absent.userAvatar}}"
                        alt=""
                    />
                </div>

                <div class="c-month-full__absent-user">
                    {{absent.currentUser ? 'Me' : absent.user.firstName + ' ' +
                    absent.user.lastName}}
                </div>
            </div>
        </div>
    </div>
</div>
