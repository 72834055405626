import { tourI18NDe } from "./tour-i18n-de";
import { tourI18NPl } from "./tour-i18n-pl";

const tourI18NEn = {
    nextBtn: "Next",
    doneBtn: "Done",
    demoTour: {
        intro: {
            title: "Discover Timepot",
            content:
                "Join a quick tour that will help you to discover all the features of Timepot.",
        },

        logTime: {
            content: "Log time directly in the table...",
        },

        newTask: {
            content: "Or enter a new task",
        },

        reports: {
            content: "Analyze data through powerful reports",
        },

        reportFilter: {
            content: "Filter exactly the data you need",
        },

        barChart: {
            content: "Click on the charts to narrow your filter",
        },

        projects: {
            content: "Manage your projects",
        },

        holidays: {
            content: "See days off of everyone in the team",
        },

        reportVacations: {
            content: "Easily report your vacations directly in calendar",
        },

        users: {
            content: "Manage your team",
        },

        previewUser: {
            content: "Click to preview user's activities",
        },

        farewell: {
            title: "Enjoy using Timepot!",
            content: "You can always access this help from the user menu",
        },
    },

    onboardingTour2: {
        intro: {
            title: "Discover Timepot",
            content:
                "Join a quick tour that will help you to discover all the features of Timepot.",
        },

        newTask: {
            content: "Select project and add task",
        },

        startTimer: {
            content: "Start timer...",
        },

        logTime: {
            content: "...or log time manually",
        },

        projects: {
            content: "Manage your projects",
        },

        reports: {
            content: "Analyze data through powerful reports",
        },

        reportFilter: {
            content: "Filter exactly the data you need",
        },

        holidays: {
            content: "See days off of everyone in the team",
        },

        reportVacations: {
            content: "Easily report your vacations directly in calendar",
        },

        users: {
            content: "Manage your team",
        },

        previewUser: {
            content: "Click to preview user's activities",
        },

        farewell: {
            title: "Enjoy using Timepot!",
            content: "You can always access this help from the user menu",
        },
    },
};

export const tourI18N = {
    de: tourI18NDe,
    pl: tourI18NPl,
    en: tourI18NEn,
};
