<span *ngIf="viewMode === calendarViewMode.YEAR">{{year}}</span>
<span *ngIf="viewMode === calendarViewMode.MONTH"
    >{{i18n.monthNames[month]}} {{year}}</span
>
<span *ngIf="viewMode === calendarViewMode.WEEK">
    {{i18n.monthNames[(monday | date:'M') - 1]}} {{monday | date:'d'}} -
    <span *ngIf="isDifferentMonth"
        >{{i18n.monthNames[(sunday | date:'M') - 1]}}</span
    >
    {{sunday | date:'d'}}, {{sunday | date:'y'}}
</span>
