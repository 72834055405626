import { dayHeaderI18NDe } from "./day-header-i18n-de";
import { dayHeaderI18NPl } from "./day-header-i18n-pl";
const dayHeaderI18NEn = {
    dayNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ],
};

export const dayHeaderI18N = {
    de: dayHeaderI18NDe,
    en: dayHeaderI18NEn,
    pl: dayHeaderI18NPl,
};
