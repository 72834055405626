import { Inject, Injectable } from "@angular/core";
import { I18NService } from "../i18n/I18NService";
import { CurrentCompanyUserViewService } from "../currentcompanyuser/CurrentCompanyUserViewService";
import { TourStep } from "./TourStep";
import { Tour } from "./Tour";
import { TourDefinitions } from "./TourDefinitions";

@Injectable()
export class TourService {
    private hopscotch: Hopscotch;
    private tours: Tour[];

    constructor(
        @Inject("tourTranslations") translations,
        definedTours: TourDefinitions,
        i18NService: I18NService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        this.hopscotch = <Hopscotch>(<any>window).hopscotch;
        /* eslint-enable @typescript-eslint/no-explicit-any */
        const i18n = i18NService.extractCurrentTranslation(translations);
        this.tours = this.resolveNames(definedTours.definitions, i18n);
    }

    public runTour(name: string, withIntro = false) {
        const tour = this.tours.find((tour) => tour.id === name);
        if (tour) {
            const copy = this.cloneTour(tour);
            if (withIntro) {
                copy.steps.unshift(tour.intro);
            }

            if (!this.currentCompanyUserViewService.isCompanyAdmin) {
                copy.steps = copy.steps.filter((step) => !step.isAdminOnly);
            }

            this.hopscotch.startTour(copy);
        }
    }

    private resolveNames(tours: Tour[], i18n): Tour[] {
        const resolveStepNames = (
            tour: Tour,
            step: TourStep
        ): ResolvedTourStep => {
            return {
                isAdminOnly: step.isAdminOnly,
                name: step.name,
                target: step.target,
                placement: step.placement,
                runBeforeStep: step.runBeforeStep,
                waitCondition: step.waitCondition,
                global: step.global,
                title:
                    i18n[tour.id] &&
                    i18n[tour.id][step.name] &&
                    i18n[tour.id][step.name].title,
                content:
                    i18n[tour.id] &&
                    i18n[tour.id][step.name] &&
                    i18n[tour.id][step.name].content,
            };
        };
        return tours.map((tour) => {
            return {
                id: tour.id,
                i18n: {
                    nextBtn: i18n.nextBtn,
                    doneBtn: i18n.doneBtn,
                },
                closeOnClickOutsideBubble: tour.closeOnClickOutsideBubble,
                jumpToLastStepBeforeClose: tour.jumpToLastStepBeforeClose,
                intro: tour.intro && resolveStepNames(tour, tour.intro),
                steps: tour.steps.map((tourStep) =>
                    resolveStepNames(tour, tourStep)
                ),
            };
        });
    }

    private cloneTour(tour: Tour): Tour {
        return {
            id: tour.id,
            i18n: tour.i18n,
            closeOnClickOutsideBubble: tour.closeOnClickOutsideBubble,
            jumpToLastStepBeforeClose: tour.jumpToLastStepBeforeClose,
            intro: tour.intro,
            steps: tour.steps.slice(),
        };
    }
}

interface ResolvedTourStep extends TourStep {
    title: string;
    content: string;
}

interface Hopscotch {
    startTour(tour: Tour);
}
