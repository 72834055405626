import { Component, Input, OnInit } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { projectDropdownI18n } from "./i18n/project-dropdown-i18n";
import { ProjectService } from "../../../services/projects/ProjectService";
import { Project } from "../../../models/projects/Project";
import { I18NService } from "../../../services/i18n/I18NService";
import { Token } from "../../../models/token/Token";
import { CurrentCompanyUserViewService } from "../../../services/currentcompanyuser/CurrentCompanyUserViewService";

@Component({
    host: { "[class.c-project-dropdown]": "true" },
    selector: "project-dropdown",
    templateUrl: "project-dropdown.html",
})
export class ProjectDropdownComponent implements OnInit {
    public items: Project[] = [];
    @Input() public selection: Subject<Project>;
    @Input() public activateStream: Observable<Token>;
    @Input() public initialSelectionStream: Observable<Project>;
    @Input() public automaticallySelectTheOnlyProject = false;
    @Input() public includeImportedProjects: boolean;
    public initialMultiSelectionStream: Observable<Project[]>;
    public selectedProjects = new ReplaySubject<Project[]>();
    public i18n;

    constructor(
        private projectService: ProjectService,
        public i18nService: I18NService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectDropdownI18n);
    }

    public ngOnInit(): void {
        const initialMultiSelectionStream = new Subject<Project[]>();

        this.projectService
            .getMostRecentProjectsForUser(
                this.currentCompanyUserViewService.userId,
                this.includeImportedProjects
            )
            .subscribe((projects) => {
                this.items = projects.projects;

                this.initialSelectionStream.subscribe((project) => {
                    if (project) {
                        initialMultiSelectionStream.next([project]);
                    } else if (
                        this.automaticallySelectTheOnlyProject &&
                        this.items.length === 1
                    ) {
                        initialMultiSelectionStream.next(this.items);
                    } else {
                        initialMultiSelectionStream.next([]);
                    }
                });
            });

        this.initialMultiSelectionStream = initialMultiSelectionStream;

        this.selectedProjects.subscribe((projects) =>
            this.selection.next(projects.length > 0 ? projects[0] : null)
        );
    }

    public getProjectCSS(project: Project) {
        return project && project.color
            ? "c-project-dropdown--" + project.color.toLocaleLowerCase()
            : "";
    }
}
