export const timesheetReportsI18NPl = {
    pageTitle: "Raporty",
    projects: "projekty",
    users: "użytkownicy",
    tasks: "zadania",
    noResults: "Brak wyników dla podanych filtrów",
    hideGraph: "Ukryj wykres",
    hours: "h",
    noData: "Brak danych",
    showGraph: "Wyświetl wykres",
    total: (time: string) => `Razem: ${time}`,
    csv: "CSV",
    pdf: "PDF",
    share: "Publiczny link",
    yourLink: "Publiczny link raportu:",
    copyToClipboard: "Kopiuj do schowka",
    all: "Wszystkie",
    billable: "Płatne",
    nonBillable: "Niepłatne",
    publicLinkDesc: "Wyeksportowane zostaną jedynie płatne zadania.",
};
