export const newCompanyI18nPl = {
    placeholderName: "Nazwa Twojego Timepota",
    nameIsFree: "Dostępne",
    nameIsTaken: "Zajęte",
    nameIsInvalid: "Niepoprawna nazwa!",
    validNameHint:
        "Możesz użyć dużych i małych liter, cyfr oraz oddzielać je kreskami",
    createNewCompany: "Utwórz nowy Timepot",
    save: "Zapisz",
};
