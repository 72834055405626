export const tasksI18NDe = {
    pageTitle: "Aufgaben",
    project: "Projekt",
    task: "Aufgabe",
    time: "Zeit",
    users: "Benutzer",
    updated: "Aktualisiert",
    userSeparator: " + ",
    twoPeople(first, second) {
        return first + this.userSeparator + second;
    },
    manyPeople: (count) => count + " Personen",
    showMore: "Mehr anzeigen",
    negated: (name) => "¬ " + name,
};
