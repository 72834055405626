<div class="u-flex-row">
    <div class="o-form__group u-1/2">
        <span class="o-form__label">{{i18n.currency}}</span>
        <div class="o-form__input-group">
            <generic-dropdown
                [items]="currenciesArray"
                [selection]="selected"
                [disabled]="!noInitialPricing"
                [initialSelectionStream]="preselected"
                [selectMessage]="''"
                [selectOnSwitch]="false"
                [searchPlaceholder]="i18n.selectCurrency"
            >
            </generic-dropdown>
        </div>
    </div>

    <div class="o-form__group u-1/2">
        <span class="o-form__label">{{i18n.value}}</span>
        <div class="o-form__input-group">
            <input
                class="o-form__input"
                [ngModel]="currentPricing.rate"
                #rate
                id="rate"
                (keyup)="setRate(rate.value)"
                name="rate"
            />
            <label
                for="rate"
                class="o-form__validation"
                [class.u-hidden]="rateValid || !rateTouch"
            >
                <span
                    class="o-form__validation-msg o-form__validation-msg--error"
                    [ngClass]="{'u-hidden': rateValid}"
                >
                    {{i18n.invalid}}
                </span>
            </label>
        </div>
    </div>
</div>
<!--<div class="c-project-hourly-rate__personalized">-->
<!--{{i18n.personalizedRates}}-->
<!--</div>-->
<user-rate-overrides
    *ngIf="users.length > 0"
    [initialOverrides]="currentPricing.userOverrides"
    [users]="users"
    (dataChanged)="onOverridesChanged($event)"
></user-rate-overrides>
