import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { FeedbackService } from "services/feedback/FeedbackService";
import { I18NService } from "services/i18n/I18NService";
import { KEY_CODE } from "constants/KeyCode";
import { Action } from "../genericmodal/GenericModalComponent";
import { feedbackI18N } from "./i18n/feedback-i18n";
import { first } from "rxjs/operators";

@Component({
    selector: "feedback-modal",
    templateUrl: "feedback-modal.html",
})
export class FeedbackModalComponent implements OnInit {
    @Input() public showStream: Subject<boolean>;
    public actionStream = new Subject<Action>();
    public i18n;
    public feedback: string;

    constructor(
        i18nService: I18NService,
        private feedbackService: FeedbackService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(feedbackI18N);
    }

    public ngOnInit() {
        this.showStream.subscribe(() => {
            this.feedback = undefined;
        });
        this.actionStream.subscribe((action) => {
            if (action === Action.OK) {
                this.feedbackService
                    .sendFeedback(this.feedback)
                    .pipe(first())
                    .subscribe();
            }
        });
    }

    public feedbackNonEmpty() {
        return this.feedback && !!this.feedback.trim().length;
    }

    public onKeyDown(evt: KeyboardEvent) {
        if (
            (evt.which || evt.keyCode) === KEY_CODE.KEY_TAB ||
            (evt.which || evt.keyCode) === KEY_CODE.KEY_RETURN
        ) {
            evt.stopPropagation();
        }
    }
}
