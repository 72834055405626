<generic-modal
    [showStream]="showStream"
    [actionStream]="actionStream"
    [title]="modalTitle"
    [okEnabled]="isFormValid()"
    [confirmLabel]="modalConfirmLabel"
>
    <div class="c-project-modal__form">
        <div class="u-form-group u-form-group--bigger-margin-bottom">
            <input
                data-id="project-name"
                class="u-form-control"
                type="text"
                [(ngModel)]="projectName"
                maxlength="80"
                placeholder="{{ i18n.projectNamePlaceholder }}"
            />
        </div>
        <label class="c-project-modal__label"
            >{{ i18n.defaultTasksBillability }}</label
        >
        <div class="c-project-modal__wrapper-selector">
            <selector
                [switches]="[
                    { text: i18n.billable, label: true, selected: project && project.billableByDefault },
                    { text: i18n.nonBillable, label: false, selected: project && !project.billableByDefault }
                ]"
                (selection)="setBillabilityStream($event)"
                [inline]="true"
            ></selector>
        </div>
        <label class="c-project-modal__label">{{ i18n.chooseColor }}</label>
        <color-picker
            [selectionStream]="colorSelectionStream"
            (change)="onColorChange($event)"
        ></color-picker>
    </div>
</generic-modal>
