import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    empty,
    Observable,
    of,
    ReplaySubject,
    Subject,
    Subscription,
} from "rxjs";
import * as _ from "lodash";
import { taskDropdownI18n } from "./i18n/task-dropdown-i18n";
import { ResetSearchTextToken } from "../../genericdropdown/GenericDropdownComponent";
import {
    DEFAULT_PAGE_SIZE,
    INITIAL_PAGE_ACTION,
    PageAction,
    PageResponse,
} from "../../pageddropdown/PagedDropdownComponent";
import { TimesheetService } from "../../../services/timesheet/TimesheetService";
import { Project } from "../../../models/projects/Project";
import { Task } from "../../../models/timesheet/Task";
import { Tasks } from "../../../models/timesheet/Tasks";
import { I18NService } from "../../../services/i18n/I18NService";
import { Token } from "../../../models/token/Token";
import { UserService } from "../../../services/user/UserService";
import { CurrentCompanyUserViewService } from "../../../services/currentcompanyuser/CurrentCompanyUserViewService";
import { flatMap, map, startWith } from "rxjs/operators";

function unsubscribeAndYield(
    subscription: Subscription,
    passed?: Subscription
): Subscription {
    if (subscription != null) {
        subscription.unsubscribe();
    }

    return passed;
}

export const DEFAULT_MINIMAL_SEARCH_LENGTH = 3;

@Component({
    selector: "task-dropdown",
    templateUrl: "task-dropdown.html",
})
export class TaskDropdownComponent implements OnInit {
    @Input() public selection: Subject<Task>;
    @Input() public uncommittedSelection: Subject<Task>;
    @Input() public taskSource = new Subject<PageResponse>();
    @Input() public page = new BehaviorSubject<PageAction>(INITIAL_PAGE_ACTION);
    @Input() public tasksTotal = new BehaviorSubject<number>(0);
    @Input() public projectObservable: Observable<Project>;
    @Input() public selectOnSwitch = true;
    @Input() public preselected = empty();
    @Input() public selectedTasks = new ReplaySubject<Task[]>(1);
    @Input() public activateStream: Subject<Token>;
    @Input() public resetSearchText: Observable<ResetSearchTextToken>;
    @Input() public searchThreshold = 0;
    @Input() public initialQuery = "";
    @Input() public placeholder = "";
    @Input() public disabled = false;
    @Input() public needsProject = true;
    @Input() public dropdownOpensUpwards = false;
    @Input() public allowAddingNewTasks: boolean;

    @Output() public inputChange = new EventEmitter<string>();

    public pageSize: number = DEFAULT_PAGE_SIZE;
    public i18n;
    public uncommittedSelectedTasks = new ReplaySubject<Task[]>(1);

    private userIdStream = new ReplaySubject<string>(1);
    private query = new ReplaySubject<string>(1);
    private pagesSubscription: Subscription;
    private totalSubscription: Subscription;
    private tasksSubscription: Subscription;
    private previousProjectId: string = null;
    private previousTotal: number = null;
    private previousQuery: string = null;

    constructor(
        private timesheetService: TimesheetService,
        private userService: UserService,
        public i18nService: I18NService,
        currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(taskDropdownI18n);
        this.userIdStream.next(currentCompanyUserViewService.userId);
    }

    protected get total(): Subscription {
        return this.totalSubscription;
    }

    protected set total(subscription: Subscription) {
        this.totalSubscription = unsubscribeAndYield(this.total, subscription);
    }

    protected get pages(): Subscription {
        return this.pagesSubscription;
    }

    protected set pages(subscription: Subscription) {
        this.pagesSubscription = unsubscribeAndYield(this.pages, subscription);
    }

    protected get tasks(): Subscription {
        return this.tasksSubscription;
    }

    protected set tasks(subscription: Subscription) {
        this.tasksSubscription = unsubscribeAndYield(this.tasks, subscription);
    }

    public ngOnInit(): void {
        // observe user, project and query changes:
        // • fetch total count of relevant tasks
        // • register updates for those tasks
        combineLatest(
            this.projectObservable.pipe(
                startWith(null),
                map((project) => (project != null ? project.id : null))
            ),
            this.userIdStream,
            this.query
        )
            .pipe(
                map(([projectId, userId, query]: [string, string, string]) => {
                    // quite an ugly workaround to reset query (and circumvent superfluous reset event) during project change
                    if (this.previousProjectId !== projectId) {
                        this.previousProjectId = projectId;
                        query = "";
                    }

                    // always reset page (after canceling current subscription) during change any of project, user or query
                    this.pages = null;
                    this.page.next(INITIAL_PAGE_ACTION);

                    return [projectId, userId, query];
                }),
                flatMap(
                    ([projectId, userId, query]: [string, string, string]) => {
                        const totalCount = !this.skipQuery(query)
                            ? this.timesheetService.countTasks(
                                  projectId,
                                  userId,
                                  query
                              )
                            : of(0);

                        this.previousQuery = query ? query : null;

                        return totalCount.pipe(
                            map((total) => [projectId, userId, query, total])
                        );
                    }
                )
            )
            .subscribe(
                ([projectId, userId, query, total]: [
                    string,
                    string,
                    string,
                    number
                ]) => {
                    const tasksUpdater: TasksUpdater = (page) => {
                        const [offset, limit] =
                            this.pageSize > 0
                                ? [this.pageSize * page, this.pageSize]
                                : [null, null];
                        return this.timesheetService.listTasks(
                            projectId,
                            userId,
                            query,
                            offset,
                            limit
                        );
                    };

                    this.registerTasksUpdates(tasksUpdater, total);
                }
            );

        if (this.needsProject) {
            this.projectObservable.subscribe(
                (project) => (this.disabled = project === null)
            );
        }

        this.selectedTasks.subscribe((tasks) =>
            this.selection.next(
                tasks != null && tasks.length > 0 ? tasks[0] : null
            )
        );
        if (this.uncommittedSelection) {
            this.uncommittedSelectedTasks.subscribe((tasks) => {
                this.uncommittedSelection.next(
                    tasks != null && tasks.length > 0 ? tasks[0] : null
                );
            });
        }
        if (this.initialQuery !== null && this.initialQuery !== undefined) {
            this.query.next(this.initialQuery);
        }
    }

    private skipQuery(query: string): boolean {
        return (
            _.isNull(query) ||
            _.isUndefined(query) ||
            query.length < this.searchThreshold ||
            (this.previousTotal === 0 && query.startsWith(this.previousQuery))
        );
    }

    private registerTasksUpdates(getTasksUpdates: TasksUpdater, total: number) {
        this.previousTotal = total;
        this.tasksTotal.next(total);

        if (total === 0) {
            // do not use provided tasks observable when no tasks found: return empty one!
            getTasksUpdates = () => of({ tasks: [] });
        }

        this.pages = this.page.subscribe((pageAction) => {
            this.tasks = getTasksUpdates(pageAction.page).subscribe((tasks) =>
                this.updateTasks(pageAction, tasks)
            );
        });
    }

    protected updateTasks(pageAction: PageAction, tasks: Tasks) {
        setTimeout(() =>
            this.taskSource.next({
                action: pageAction,
                items: tasks.tasks.map((t) => ({ id: t.id, name: t.name })),
            })
        );
    }

    public getTaskCSS() {
        return "";
    }

    public inputSearch(searchText: string) {
        this.query.next(searchText);
        this.inputChange.emit(searchText);
    }
}

interface TasksUpdater {
    (page: number): Observable<Tasks>;
}
