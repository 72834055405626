import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChange,
} from "@angular/core";
import { Subject } from "rxjs";
import * as _ from "lodash";
import {
    DEFAULT_SEARCH_DELAY,
    GenericDropdownComponent,
    Item,
} from "../genericdropdown/GenericDropdownComponent";
import { I18NService } from "../../services/i18n/I18NService";
import { pagedDropdownI18N } from "./i18n/pageddropdown-i18n";
import { KEY_CODE } from "../../constants/KeyCode";
import { DomSanitizer } from "@angular/platform-browser";
import { startWith } from "rxjs/operators";

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SEARCH_INDICATOR_DELAY = 5 * DEFAULT_SEARCH_DELAY;
export const INITIAL_PAGE_ACTION: PageAction = {
    index: 0,
    page: 0,
    source: null,
};

@Component({
    selector: "paged-dropdown",
    templateUrl: "paged-dropdown.html",
    host: { "[class.c-generic-dropdown]": "true" },
})
export class PagedDropdownComponent
    extends GenericDropdownComponent
    implements OnInit, OnChanges
{
    @Input() public itemSource = new Subject<PageResponse>();
    @Input() public padding: Item[] = [];
    @Input() public override disabled: boolean;
    @Input() public page = new Subject<PageAction>();
    @Input() public totalItems = new Subject<number>();
    @Input() public listMessage: string;
    @Input() public pageSize = DEFAULT_PAGE_SIZE;

    private i18n;
    private currentPage = 0;
    private pagesTotal = 0;
    private totalCount = 0;
    private isSearching = false;
    private searchingTimer;

    constructor(
        protected override elementRef: ElementRef,
        private i18nService: I18NService,
        protected override sanitizer: DomSanitizer
    ) {
        super(elementRef, sanitizer);
        this.i18n = i18nService.extractCurrentTranslation(pagedDropdownI18N);
    }

    public override ngOnInit(): void {
        super.ngOnInit();

        this.listMessage = null;
        this.itemSource.subscribe((response) => this.updatePage(response));
        this.page
            .pipe(startWith(INITIAL_PAGE_ACTION))
            .subscribe((pageAction) => (this.currentPage = pageAction.page));
        this.totalItems
            .pipe(startWith(0))
            .subscribe((total) => this.updatePaging(total));
    }

    public override ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        /* eslint-disable  @typescript-eslint/dot-notation */
        console.assert(
            !changes["pageSize"] || changes["pageSize"].currentValue > 0,
            "Wrong page size for paged dropdown!"
        );
        /* eslint-enable  @typescript-eslint/dot-notation */
    }

    get hasMultiplePages(): boolean {
        return this.pagesTotal > 1;
    }

    get firstPage(): number {
        return 0;
    }

    get lastPage(): number {
        return this.hasMultiplePages ? this.pagesTotal - 1 : this.firstPage;
    }

    get hasNextPage(): boolean {
        return this.currentPage < this.lastPage;
    }

    get hasPreviousPage(): boolean {
        return this.currentPage > this.firstPage;
    }

    protected getSourceAction(
        defaultSourceAction: PageActionSource,
        event: Event
    ): PageActionSource {
        if (event instanceof KeyboardEvent) {
            switch (event.keyCode) {
                case KEY_CODE.KEY_HOME:
                    return PageActionSource.FIRST_ITEM;
                case KEY_CODE.KEY_END:
                    return PageActionSource.LAST_ITEM;
                case KEY_CODE.KEY_PAGE_DOWN:
                    return PageActionSource.NEXT_PAGE;
                case KEY_CODE.KEY_PAGE_UP:
                    return PageActionSource.PREVIOUS_PAGE;
                case KEY_CODE.KEY_DOWN:
                    return PageActionSource.NEXT_ITEM;
                case KEY_CODE.KEY_UP:
                    return PageActionSource.PREVIOUS_ITEM;
                default:
                    return null;
            }
        }

        return defaultSourceAction ? defaultSourceAction : null;
    }

    public nextPage(event?: Event): void {
        if (this.hasNextPage) {
            this.currentPage += 1;
            this.searching = true;
            this.page.next(
                this.createPageAction(PageActionSource.NEXT_PAGE, event)
            );
            this.gobbleEvent(event);
        } else {
            this.lastItem(event);
        }
    }

    public previousPage(event?: Event): void {
        if (this.hasPreviousPage) {
            this.currentPage -= 1;
            this.searching = true;
            this.page.next(
                this.createPageAction(PageActionSource.PREVIOUS_PAGE, event)
            );
            this.gobbleEvent(event);
        } else {
            this.firstItem(event);
        }
    }

    public firstItem(event?: Event): void {
        this.currentPage = this.firstPage;
        this.page.next(
            this.createPageAction(PageActionSource.FIRST_ITEM, event)
        );

        this.gobbleEvent(event);
    }

    public lastItem(event?: Event): void {
        this.currentPage = this.lastPage;
        this.searching = true;
        this.page.next(
            this.createPageAction(PageActionSource.LAST_ITEM, event)
        );

        this.gobbleEvent(event);
    }

    get firstItemOnPage(): number {
        return this.currentPage * this.pageSize;
    }

    get lastItemOnPage(): number {
        return (
            Math.min((this.currentPage + 1) * this.pageSize, this.totalCount) -
            1
        );
    }

    protected createPageAction(
        defaultSource: PageActionSource,
        event?: Event
    ): PageAction {
        return {
            index: this.getIndexInItems(this.currentSelection[0]),
            page: this.currentPage,
            source: this.getSourceAction(defaultSource, event),
        };
    }

    protected updatePage(response: PageResponse): void {
        const padSize = this.hasMultiplePages
            ? this.pageSize - response.items.length
            : 0;
        this.padding = new Array(padSize);

        this.updateItems(response.items);
        const item = this.matchCurrentItem(response);

        if (!!item && this.isListOpened()) {
            this.focusItem(item);
        }
        this.searching = false;
    }

    private matchCurrentItem(response: PageResponse): Item {
        return (
            pageActionToItem(response.items, response.action) ||
            this.provideCurrentItem()
        );
    }

    private provideCurrentItem(): Item {
        if (this.multiSelect) {
            return null;
        }

        const currentItem =
            this.currentSelection.length > 0 && this.currentSelection[0];
        const searchItem = { id: null, name: this.searchText };
        return !!currentItem && currentItem.name === this.searchText
            ? currentItem
            : searchItem;
    }

    protected updatePaging(total: number): void {
        this.currentPage = 0;
        this.totalCount = total;
        this.pagesTotal =
            this.totalCount > 0
                ? Math.ceil(this.totalCount / this.pageSize)
                : 0;
    }

    protected override beforeSearch(): void {
        super.beforeSearch();
        this.searching = true;
    }

    public override isListOpened(): boolean {
        return super.isListOpened() || this.currentPage > 0;
    }

    get searching(): boolean {
        return this.isSearching;
    }

    set searching(state: boolean) {
        clearTimeout(this.searchingTimer);

        if (state) {
            this.searchingTimer = setTimeout(
                () => (this.isSearching = true),
                DEFAULT_SEARCH_INDICATOR_DELAY
            );
        } else {
            this.isSearching = false;
        }
    }

    public override matchesFilter(): boolean {
        return true; // filtering made in backend—method might be removed to gain some speed…
    }

    protected override handleArrowKeyNavigation(
        eventItem: Item,
        event: KeyboardEvent
    ) {
        const filteredItems: Item[] = this.getFilteredItems();
        const eventItemIndex: number = _.findIndex(
            filteredItems,
            (item) => item.name === eventItem.name
        );

        let nextItem: Item = null;

        switch (event.keyCode) {
            case KEY_CODE.KEY_DOWN:
                nextItem = this.getNextItem(eventItemIndex);
                if (!nextItem && this.hasNextPage) {
                    this.nextPage(event);
                    return;
                }
                break;

            case KEY_CODE.KEY_UP:
                nextItem = this.getPreviousItem(eventItemIndex);
                if (!nextItem && this.hasPreviousPage) {
                    this.previousPage(event);
                    return;
                }
                break;

            case KEY_CODE.KEY_HOME:
                this.firstItem(event);
                break;

            case KEY_CODE.KEY_END:
                this.lastItem(event);
                break;

            case KEY_CODE.KEY_PAGE_DOWN:
                this.nextPage(event);
                break;

            case KEY_CODE.KEY_PAGE_UP:
                this.previousPage(event);
                break;

            default:
                this.gobbleEvent(event);
                break;
        }

        super.handleArrowKeyNavigation(eventItem, event);
    }
}

export enum PageActionSource {
    PREVIOUS_PAGE,
    NEXT_PAGE,
    PREVIOUS_ITEM,
    NEXT_ITEM,
    FIRST_ITEM,
    LAST_ITEM,
}

export type PageAction = {
    page: number;
    index: number;
    source: PageActionSource;
};

export type PageResponse = {
    action: PageAction;
    items: Item[];
};

function pageActionToItem(items: Item[], action: PageAction): Item {
    switch (action.source) {
        case PageActionSource.LAST_ITEM:
        case PageActionSource.PREVIOUS_ITEM:
            return items.length > 0 ? items[items.length - 1] : null;
        case PageActionSource.PREVIOUS_PAGE:
            return items.length > 0 ? items[action.index] : null;
        case PageActionSource.FIRST_ITEM:
        case PageActionSource.NEXT_ITEM:
            return items.length > 0 ? items[0] : null;
        case PageActionSource.NEXT_PAGE:
            return items.length > 0
                ? action.index < items.length
                    ? items[action.index]
                    : items[items.length - 1]
                : null;
        default:
            return null;
    }
}
