<div
    (document:mouseup)="onMouseUp($event)"
    (document:mousedown)="onMouseDown($event)"
    (window:scroll)="onScroll()"
>
    <div
        *ngIf="show"
        class="tooltip fade top in"
        [style.left]="left + 'px'"
        [style.top]="top + 'px'"
        style="position: fixed"
    >
        <div class="tooltip-arrow" style="left: 50%"></div>
        <div class="tooltip-inner">
            <ng-content></ng-content>
        </div>
    </div>
</div>
