import { Project } from "../../projects/Project";
import { User } from "../../users/User";
import { z } from "zod";
import { TimepotDate } from "models/TimepotDate";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const TaskReport = z.object({
    project: Project,
    id: z.string(),
    name: z.string(),
    minutes: z.number(),
    users: User.array(),
    lastUpdated: TimepotDate,
});

export type TaskReport = z.infer<typeof TaskReport>;
