<generic-modal
    [showStream]="showModalStream"
    [actionStream]="actionStream"
    [openModalStream]="openModalStream"
    [title]="''"
    [buttonsVisible]="false"
>
    <div class="c-video-modal__container">
        <iframe
            class="c-video-modal__iframe"
            src="https://www.youtube.com/embed/epLbyx1z3Pg?rel=0&amp;showinfo=0&amp;autoplay=1"
        >
        </iframe>
    </div>
</generic-modal>
