<generic-dropdown
    [items]="projects"
    [itemsName]="i18n.projectsSwitch"
    [optionalItems]="groups"
    [optionalItemsName]="i18n.groupsSwitch"
    [selection]="selectedItems"
    [multiSelect]="true"
    [initialSelectionStream]="preselected"
    [selectMessage]="groups.length > 0 ? i18n.projects : i18n.projectsSwitch"
    [selectOnSwitch]="false"
    [searchPlaceholder]="groups.length > 0 ? i18n.findProjectGroupPlaceholder : i18n.findProjectPlaceholder"
    [getItemStyle]="getProjectCSS"
    class="c-generic-dropdown--contained"
>
</generic-dropdown>
