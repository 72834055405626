export const passwordI18NPl = {
    save: "Zapisz",
    email: "E-mail",
    gotoLogin: "Przejdź do logowania",
    newPassword: "Nowe hasło",
    passwordHasBeenReset: "Nowe hasło zostało ustawione",
    passwordValidationRequirements: `Hasło musi składać się z co najmniej 7 znaków, w tym co najmniej jednej cyfry
         i co najmniej jednego znaku nie będącego cyfrą`,
    resetPassword: "Ustaw nowe hasło",
    resetPasswordDescription:
        "Podaj adres email użyty podczas rejestracji w Timepocie",
    resetPasswordEmailSent:
        "Email z linkiem do ustawienia nowego hasła został wysłany",
    send: "Wyślij",
    setNewPassword: "Ustaw nowe hasło",
    weakPassword: "Słabe hasło",
    invalidPassword: "Niepoprawne hasło",
};
