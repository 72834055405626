<div class="c-barchart-horizontal__header">
    <div class="c-barchart-horizontal__header-name">{{i18n[keyI18N]}}</div>
    <div class="c-barchart-horizontal__header-unit">{{i18n.time}}</div>
</div>
<div class="c-barchart-horizontal__container">
    <ul class="c-barchart-horizontal__container-labels">
        <li
            *ngFor="let item of items"
            [ngStyle]="getLabelStyle(item)"
            (mouseover)="setHover(item.id)"
            (mouseleave)="releaseHover()"
            (click)="selectEntry(item.data)"
            showOverflownTitle
            class="c-barchart-horizontal__container-labels-element"
        >
            <span>{{item.label}}</span>
        </li>
    </ul>
    <ul class="c-barchart-horizontal__container-bars">
        <li
            *ngFor="let item of items"
            class="c-barchart-horizontal__container-bar-element"
            [ngClass]="{ 'c-barchart-horizontal__container-bar-element--hover': isOnHover(item.id) }"
            (mouseover)="setHover(item.id)"
            (click)="selectEntry(item.data)"
            (mouseleave)="releaseHover()"
        >
            <div
                class="c-barchart-horizontal__bar"
                [ngStyle]="{ width: getBarWidthPercentage(item.minutes) }"
            >
                <div
                    class="c-barchart-horizontal__bar-inner"
                    [ngStyle]="{ 'background-color': item.colorHash }"
                ></div>
                <span class="c-barchart-horizontal__bar-value"
                    >{{getDuration(item.minutes)}}</span
                >
            </div>
        </li>
    </ul>
</div>
