import { inviteI18NDe } from "./invite-i18n-de";
import { inviteI18NPl } from "./invite-i18n-pl";

const inviteI18NEn = {
    firstEmailAddressPlaceholder: "Type email address",
    nextEmailAddressPlaceholder: "Type next email address",
    invalidEmailAddress: "Invalid email",
    usedEmailAddress: "Already invited",
    required: "Required",
    nonUnique: "Duplicated email",
    sendInvitations: "send invitations",
    tip: "Type each person's email address:",
    title: "Add users to your timepot",
    ok: "send invitations",
    add: "Add",
};

export const inviteI18N = {
    de: inviteI18NDe,
    en: inviteI18NEn,
    pl: inviteI18NPl,
};
