<generic-dropdown
    [items]="items"
    [selection]="selectedTeams"
    [multiSelect]="true"
    [initialSelectionStream]="initialSelectionStream"
    [selectMessage]="i18n.teams"
    [selectOnSwitch]="false"
    [searchPlaceholder]="i18n.findTeamPlaceholder"
    class="basic-dropdown"
>
</generic-dropdown>
