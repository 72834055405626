<div class="c-selector__toggle">
    <div
        *ngFor="let s of switches"
        class="c-selector__toggle-item"
        [ngClass]="{'c-selector__toggle-item--selected': s.selected }"
        (click)="selectSwitch(s)"
    >
        {{s.text}}
        <svg class="c-selector__toggle-item-icon" *ngIf="s.icon">
            <use [attr.xlink:href]="'images/menu/all.svg#' + s.icon"></use>
        </svg>
    </div>
</div>
