import { Injectable } from "@angular/core";

@Injectable()
export class DisplayService {
    public static VIEW_BREAKPOINT = 992;

    public isMobile() {
        return window.innerWidth <= DisplayService.VIEW_BREAKPOINT;
    }
}
