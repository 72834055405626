import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UserPaymentHistory } from "models/users/UserPaymentHistory";

@Component({
    selector: "user-payment-history",
    templateUrl: "user-payment-history.html",
    host: { "[class.c-user-payment-history]": "true" },
})
export class UserPaymentHistoryComponent {
    @Input() public history: UserPaymentHistory;
    @Input() public currency: string;
    @Input() public userId: string;
    @Input() public workingHoursInDay: number;
    @Output() public edit = new EventEmitter<void>();

    public onEntrySave() {
        this.edit.emit(null);
    }
}
