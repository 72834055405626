export const datePickerI18NDe = {
    monthNames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ],
    sundayInitial: "S",
    mondayInitial: "M",
    tuesdayInitial: "D",
    wednesdayInitial: "M",
    thursdayInitial: "D",
    fridayInitial: "F",
    saturdayInitial: "S",
    startDate: "Anfangsdatum",
    endDate: "Enddatum",
    today: "Heute",
    yesterday: "Gestern",
    thisWeek: "Diese Woche",
    thisMonth: "Dieser Monat",
    thisYear: "Dieses Jahr",
    lastWeek: "Letzte Woche",
    lastMonth: "Letzter Monat",
    lastYear: "Letztes Jahr",
};
