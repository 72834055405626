<div class="c-report-filter__form reports-filter">
    <task-filter-field
        class="c-report-filter__form-input c-report-filter__form-input--tasks button-search-task popdown-trigger"
        [filterSubject]="filterSubject"
    >
    </task-filter-field>

    <projects-filter
        class="c-report-filter__form-input c-report-filter__form-input--projects button-search-project popdown-trigger"
        id="button-search-project"
        [filterSubject]="filterSubject"
    >
    </projects-filter>

    <users-filter
        class="c-report-filter__form-input c-report-filter__form-input--users button-search-user popdown-trigger"
        id="button-search-user"
        [filterSubject]="filterSubject"
    >
    </users-filter>

    <billable-filter
        class="c-report-filter__form-input c-report-filter__form-input--billable popdown-trigger"
        [filterSubject]="filterSubject"
    ></billable-filter>

    <report-date-picker-range
        class="c-report-filter__form-input c-report-filter__form-input--calendars"
        [filterSubject]="filterSubject"
    ></report-date-picker-range>
</div>
