<modal-core>
    <div class="box-form-content">
        <div class="form-heading">
            <h1 class="form-title">{{i18n.title}}</h1>
            <p class="form-sub-title">{{i18n.tip}}</p>
        </div>

        <invitation-form [actionStream]="actionStream"></invitation-form>
    </div>
</modal-core>
