<div class="c-search__container" [formGroup]="searchForm">
    <input
        #sinput
        class="c-search__input"
        (blur)="hideSearch()"
        formControlName="search"
        placeholder="{{placeholder}}"
    />
    <span
        class="c-search__close"
        *ngIf="!isEmptySearchInput"
        (click)="clear()"
    ></span>
</div>

<div
    class="c-search__trigger"
    (click)="!isSearchVisible ? changeSearchState() : null"
>
    <svg><use [attr.xlink:href]="'images/menu/all.svg#search'"></use></svg>
</div>
