<div class="o-date-picker">
    <datepicker-header [selectedMonthSubject]="monthSubject">
    </datepicker-header>

    <date-picker-month
        [selectedMonthSubject]="monthSubject"
        (mouseup)="handleMouseUp($event)"
        [selection]="selection"
        [preselected]="preselected"
        [startRange]="startRange"
        [endRange]="endRange"
        [sealDate]="sealDate"
    >
    </date-picker-month>
</div>
