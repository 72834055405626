export const projectsI18NDe = {
    addUser: "Benutzer hinzufügen",
    addRatesPerUser: "Fügen Sie die Preise pro Benutzer hinzu",
    personalizedRates: "Personalisierter Stundensatz",
    pageTitle: "Projektliste",
    undo: "Rückgängig",
    active: "Aktive Projekte",
    inactive: "Archivierte Projekte",
    addProject: "Projekt hinzufügen",
    archive: "Archivieren",
    undoArchiveTooltip: "Archivieren rückgängig",
    edit: "Bearbeiten",
    duration: (hours: number, minutes: number) => `${hours}Std. ${minutes}Min.`,
    unknownDuration: "Unbekannte Dauer",
    restore: "Wiederherstellen",
    value: "Wert",
    estimatedWorkload: "Geschätzter Arbeitsaufwand in Stunden",
    hourlyRate: "Stundensatz",
    monthlyRate: "Monatlicher Satz",
    fixedPrice: "Festpreis",
    invalid: "Ungültig",
    pricing: "Preis",
    from: "Von",
    to: "Bis",
    price: "Satz",
    history: "Verlauf",
    currency: "Währung",
    hourShort: "Std.",
    currentRate: "Aktueller Satz",
    reports: "Berichte",
    selectCurrency: "Währung wählen",
    user: "Benutzer",
    of: "von",
    findProjects: "Projekt finden",
    more: "mehr",
    add: "Hinzufügen",
    addTeam: "Gruppe hinzufügen",
    teamsCountLabel: (count: number) =>
        count === 1 ? "1 Gruppe" : `${count} Gruppen`,
    allTeams: "Alle Gruppen",
    myTeams: "Meine Gruppen",
    tasksAreNonBillableByDefault: "Tasks are non-billable by default",
};
