import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { UserService } from "services/user/UserService";
import { I18NService } from "services/i18n/I18NService";
import { timesheetI18N } from "timepot/components/timesheet/i18n/timesheet-i18n";
import { SealView } from "models/users/SealView";

@Component({
    selector: "sealer-flyout",
    templateUrl: "sealer-flyout.html",
    host: { "[class.c-timepot-sealer__flyout]": "true" },
})
export class SealerFlyoutComponent implements OnInit {
    @Input() private userId: string;
    @Input() private flyoutState: Subject<boolean>;

    public i18n;
    public selectedDate = new Subject<Date>();
    public sealDate = new ReplaySubject<Date>();
    public preselected = new ReplaySubject<Date>(1);
    private date: Date;

    constructor(
        private userService: UserService,
        private i18nService: I18NService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(timesheetI18N);
    }

    public ngOnInit() {
        this.date = new Date();
        this.preselected.next(this.date);
        this.userService
            .getUserSealDate(this.userId)
            .subscribe((sealView: SealView) => {
                if (sealView !== null) {
                    const sealDate = new Date(sealView.sealedUntil);
                    this.sealDate.next(sealDate);
                }
            });

        this.selectedDate.subscribe((date) => {
            this.date = date;
        });
    }

    public seal() {
        this.userService.sealUser(this.userId, this.date).subscribe(() => {
            this.flyoutState.next(false);
        });
    }

    public cancel() {
        this.flyoutState.next(false);
    }
}
