import {
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
} from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    interval,
    Observable,
    of,
    ReplaySubject,
    Subject,
} from "rxjs";
import * as _ from "lodash";
import {
    createTimesheet,
    Timesheet,
    timesheetEmpty,
    timesheetMerge,
} from "models/timesheet/Timesheet";
import { Timer } from "models/timesheet/Timer";
import { createTSTask, TSTask, tsTaskPadded } from "models/timesheet/TSTask";
import { createTSProject, TSProject } from "models/timesheet/TSProject";
import { DateService } from "services/date/DateService";
import { DaysOffService } from "services/daysOff/DaysOffService";
import { TimesheetService } from "services/timesheet/TimesheetService";
import { FeatureToggleService } from "services/featuretoggle/FeatureToggleService";
import { DateRange } from "models/daterange/DateRange";
import { UserDaysOff } from "models/daysOff/UserDaysOff";
import { TimesheetParameters } from "../TimesheetComponent";
import { TSProjectComponent } from "../project/TSProjectComponent";
import { VerticalDirection } from "../navigation/navigationDirections";
import { UserService } from "services/user/UserService";
import { SealView } from "models/users/SealView";
import { map, startWith, switchMap } from "rxjs/operators";

@Component({
    selector: "timesheet-table",
    templateUrl: "timesheet-table.html",
    host: { "[class.c-timesheet-table]": "true" },
})
export class TimesheetTableComponent implements OnInit {
    private static PREVIOUS_TASKS_MARGIN_DAYS = 4;

    @Input()
    public timesheetParametersObservable: Observable<TimesheetParameters>;
    @Input() public fixedAddBtnObservable: Subject<number>;
    @Input() public userIdObservable: Subject<string>;
    @Input() public dropdownOpensUpwards = false;
    @Input() public currentUserId: string;

    public timesheetSubject = new ReplaySubject<Timesheet>(1);
    public timerObservable: Observable<Timer>;
    public timerSeconds: number;

    public focusedActivityObservable = new BehaviorSubject<{
        taskId: string;
        date: Date;
    }>({ taskId: "", date: undefined });

    public dateRangeObservable = new ReplaySubject<DateRange>(1);
    public userId: string;
    public tasksInTotal: number = null;
    public userHolidaysDays: Date[] = [];
    public userDaysOff = [];
    private dateRange: DateRange;
    public addTaskModalVisible: boolean;
    public editTaskModalVisible: boolean;
    public showAddTaskSubject = new BehaviorSubject<boolean>(false);
    public showEditTaskSubject = new BehaviorSubject<boolean>(false);
    public timesheet = timesheetEmpty();
    public editedTask: { id: string; name: string };
    public editedTasksProject: { id: string; name: string };
    public userSealDate: Date;

    public shouldDisplayExplanation = false;
    public isCurrentWeek = false;

    @ViewChildren(TSProjectComponent)
    private projectComponents: QueryList<TSProjectComponent>;

    public onTaskEdit = (
        task: { id: string; name: string },
        project: { id: string; name: string }
    ) => {
        this.editedTask = task;
        this.editedTasksProject = project;
        this.showEditTaskSubject.next(true);
    };

    constructor(
        private dateService: DateService,
        private daysOffService: DaysOffService,
        private timesheetService: TimesheetService,
        private userService: UserService,
        private featureToggleService: FeatureToggleService,
        private el: ElementRef
    ) {}

    public ngOnInit(): void {
        this.showAddTaskSubject.subscribe(
            (visible) => (this.addTaskModalVisible = visible)
        );
        this.showEditTaskSubject.subscribe(
            (visible) => (this.editTaskModalVisible = visible)
        );

        this.timesheetParametersObservable
            .pipe(
                switchMap((parameters) =>
                    this.provideUserTimesheetAndHolidayDays(parameters)
                )
            )
            .subscribe(([timesheet, userHolidayDays]) =>
                this.refreshTimesheet(timesheet, userHolidayDays)
            );

        this.timerObservable = this.timesheetSubject.pipe(
            map((timesheet) => timesheet.timer)
        );
        const currentSeconds = () => Math.floor(new Date().getTime() / 1000);
        this.timesheetSubject
            .pipe(
                map((timesheet) => timesheet.timer),
                switchMap((timer) => {
                    if (timer) {
                        const timerStart =
                            currentSeconds() - timer.elapsedSeconds;
                        return interval(1000).pipe(
                            startWith(timer.elapsedSeconds),
                            map(() => currentSeconds() - timerStart)
                        );
                    } else {
                        return of(undefined);
                    }
                })
            )
            .subscribe((timerSeconds) => (this.timerSeconds = timerSeconds));
        this.refreshUserSealDate();
        this.userService.refreshSubject.subscribe(() => {
            this.refreshUserSealDate();
        });
    }

    public isFirstProject(idx: number) {
        return idx === 0;
    }

    public isLastProject(idx: number) {
        return idx === this.timesheet.projects.length - 1;
    }

    public shouldDisplayAddTaskRow() {
        return (
            this.userSealDate == null ||
            this.dateService.isAfter(this.dateRange.to, this.userSealDate)
        );
    }

    private refreshUserSealDate() {
        this.userService
            .getUserSealDate(this.userId)
            .subscribe((sealView: SealView) => {
                if (sealView != null) {
                    this.userSealDate = new Date(sealView.sealedUntil);
                } else {
                    this.userSealDate = null;
                }
            });
    }

    private provideUserTimesheetAndHolidayDays(
        parameters: TimesheetParameters
    ): Observable<TimesheetData> {
        this.storeUser(parameters.userId);
        this.storeDateRange(parameters.dateRange);

        const userActivitiesObservable =
            this.timesheetService.getUserActivities(
                parameters.userId,
                parameters.dateRange,
                TimesheetTableComponent.PREVIOUS_TASKS_MARGIN_DAYS
            );
        const daysOffDaysObservable = this.daysOffService
            .getUserDaysOffInRange(
                parameters.userId,
                parameters.dateRange,
                null
            )
            .pipe(
                map((userHolidays) => {
                    this.prepareUserDaysOff(userHolidays);
                    return this.reduceUserHolidayRanges(userHolidays);
                })
            );

        return combineLatest([userActivitiesObservable, daysOffDaysObservable]);
    }

    private reduceUserHolidayRanges(userDaysOff: UserDaysOff): Date[] {
        const userHolidayDateRanges = userDaysOff.daysOff.map((holiday) =>
            this.dateService.getDateSpan(holiday.begin, holiday.end)
        );
        return userHolidayDateRanges.reduce(
            (previous, current) => _.uniq(previous.concat(current)),
            []
        );
    }

    private refreshTimesheet(
        newTimesheet: Timesheet,
        userHolidaysDays: Date[]
    ): void {
        this.userHolidaysDays = userHolidaysDays;
        this.tasksInTotal = newTimesheet.projects.reduce(
            (accumulator, project: TSProject) =>
                accumulator + project.tasks.length,
            0
        );
        this.refreshUserSealDate();

        const holidaysInThisWeek: boolean = this.userHolidaysDays.length > 0;

        if (this.tasksInTotal === 0 && holidaysInThisWeek) {
            const paddedFakeTask: TSTask = tsTaskPadded(
                createTSTask({
                    id: null,
                    name: null,
                    readOnly: null,
                    source: null,
                    sealed: false,
                    billable: true,
                    activities: [],
                }),
                this.dateRange
            );
            paddedFakeTask.isFake = true;

            const displayRange = newTimesheet.displayRange;
            const project = createTSProject({
                id: null,
                name: null,
                color: null,
                readOnly: null,
                source: null,
                tasks: [paddedFakeTask],
            });
            newTimesheet = createTimesheet({
                projects: [project],
                timer: newTimesheet.timer,
            });
            newTimesheet.displayRange = displayRange;
            this.tasksInTotal = 1;
        }

        this.shouldDisplayExplanation = newTimesheet.projects.length === 0;
        this.timesheet = timesheetMerge(this.timesheet, newTimesheet);
        this.timesheetSubject.next(this.timesheet);
        setTimeout(() =>
            this.fixedAddBtnObservable.next(this.el.nativeElement.offsetHeight)
        );
    }

    private storeDateRange(dateRange: DateRange): void {
        this.dateRange = dateRange;
        this.dateRangeObservable.next(dateRange);
        this.isCurrentWeek = this.dateService.isInDateRange(
            new Date(),
            this.dateRange
        );
    }

    private storeUser(userId: string): void {
        this.userId = userId;
    }

    public navigateUpOf(project: TSProjectComponent, date: Date) {
        const open = (targetProject: TSProjectComponent) =>
            targetProject.openLastTask(date);
        this.navigateVertically(project, VerticalDirection.UP, open);
    }

    public navigateDownOf(project: TSProjectComponent, date: Date) {
        const open = (targetProject: TSProjectComponent) =>
            targetProject.openFirstTask(date);
        this.navigateVertically(project, VerticalDirection.DOWN, open);
    }

    private navigateVertically(
        project: TSProjectComponent,
        direction: VerticalDirection,
        open: (project: TSProjectComponent) => void
    ) {
        const projectComponents: TSProjectComponent[] =
            this.projectComponents.toArray();
        const targetIndex = projectComponents.indexOf(project) + direction;

        if (targetIndex >= 0 && targetIndex < projectComponents.length) {
            open(projectComponents[targetIndex]);
        }
    }

    private prepareUserDaysOff(daysOff: UserDaysOff) {
        this.userDaysOff = [];
        daysOff.daysOff.forEach((dayOff) => {
            let strippedFrom = this.dateService.stripTime(dayOff.begin);
            const strippedTo = this.dateService.stripTime(dayOff.end);

            while (!this.dateService.isAfter(strippedFrom, strippedTo)) {
                this.userDaysOff[strippedFrom.getTime()] = dayOff.type;
                strippedFrom = this.dateService.addDays(strippedFrom, 1);
            }
        });
    }
}

type TimesheetData = [Timesheet, Date[]];
