import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import {
    Action,
    OpenModalToken,
} from "../../genericmodal/GenericModalComponent";
import { ResetSearchTextToken } from "components/genericdropdown/GenericDropdownComponent";
import { TimesheetService } from "services/timesheet/TimesheetService";
import { timesheetI18N } from "../i18n/timesheet-i18n";
import { I18NService } from "services/i18n/I18NService";
import { TimeUnitConversionService } from "services/timeunitconversion/TimeUnitConversionService";
import { Project } from "models/projects/Project";
import { UserService } from "services/user/UserService";
import { ProjectService } from "services/projects/ProjectService";
import { Task } from "models/timesheet/Task";
import { Logger } from "services/logging/Logger";
import { KEY_CODE } from "constants/KeyCode";
import { Token } from "models/token/Token";

const FORM_INPUTS =
    ".c-generic-dropdown__activator, " +
    ".c-add-task__input, " +
    ".c-generic-modal__modal-footer button";
const DROPDOWN_INPUTS = ".c-generic-dropdown__input";

@Component({
    host: {
        "[class.c-add-task]": "true",
        "(document:keydown)": "onKeyDown($event)",
    },
    selector: "add-task-modal",
    templateUrl: "add-task-modal.html",
})
export class AddTaskModalComponent implements OnInit, OnDestroy {
    @Input() public showStream: Subject<boolean>;
    @Input() public userId: string;

    public openModalStream: Subject<OpenModalToken> =
        new Subject<OpenModalToken>();
    public activateProjectsStream = new Subject<Token>();
    public activateTasksStream = new Subject<Token>();
    public resetTaskNameSubject: Subject<ResetSearchTextToken> =
        new Subject<ResetSearchTextToken>();
    public projectSubject: Subject<Project> = new ReplaySubject<Project>(1);
    public mostRecentProjectStream: Subject<Project> =
        new ReplaySubject<Project>(1);
    public taskSubject = new ReplaySubject<Task>(1);
    public actionStream = new Subject<Action>();
    public preselected = new ReplaySubject<Date>(1);
    public i18n;
    public selectedDate = new Subject<Date>();
    public time = "";
    public project: Project = null;

    private selectedTask: { id: string; name: string } = null;
    private date = new Date();
    private minutes: number;

    private actionStreamSubscription: Subscription;

    constructor(
        private i18nService: I18NService,
        private timesheetService: TimesheetService,
        private projectService: ProjectService,
        private timeUnitConversionService: TimeUnitConversionService,
        private userService: UserService,
        private logger: Logger,
        private elementRef: ElementRef
    ) {
        this.projectSubject.subscribe((project: Project) => {
            if (!project || !this.isTheSameProjectName(project.name)) {
                this.resetTaskNameSubject.next(ResetSearchTextToken.RESET);
            }
            this.project = project;
        });
        this.taskSubject.subscribe(
            (task) =>
                (this.selectedTask = !task
                    ? null
                    : { id: task.id, name: task.name })
        );
        this.i18n = i18nService.extractCurrentTranslation(timesheetI18N);
        this.actionStreamSubscription = this.actionStream.subscribe(
            (action) => {
                if (action === Action.OK) {
                    this.save();
                }
            }
        );
        this.openModalStream.subscribe(() =>
            this.activateTasksStream.next(Token.TOKEN)
        );
    }

    public ngOnInit() {
        this.selectedDate.subscribe((d) => {
            this.date = d;
            this.preselected.next(d);
        });
        this.clearForm();
    }

    public ngOnDestroy(): void {
        this.actionStreamSubscription.unsubscribe();
    }

    public isValid() {
        return this.selectedTask && this.project && this.date && this.time;
    }

    public onKeyDown(event: KeyboardEvent): void {
        if (
            ((event.which || event.keyCode) === KEY_CODE.KEY_TAB ||
                (event.which || event.keyCode) === KEY_CODE.KEY_RETURN) &&
            !this.isEventTargetButton(event.target)
        ) {
            event.stopPropagation();
            event.preventDefault();

            const inputsList =
                this.elementRef.nativeElement.querySelectorAll(FORM_INPUTS);
            const currentIdx = this.getCurrentIdx(event.target, inputsList);

            inputsList[
                this.getNextInputIdx(
                    event.shiftKey,
                    currentIdx,
                    inputsList.length
                )
            ].focus();
        }
    }

    private isEventTargetButton(target: EventTarget) {
        return target instanceof HTMLElement && target.tagName === "BUTTON";
    }

    private save() {
        // TODO: validation? make generic component allow to prevent closing the modal
        this.normalizeTime();

        if (this.selectedTask.id) {
            this.timesheetService.addUserActivity(
                this.userId,
                this.selectedTask.id,
                this.date,
                this.minutes
            );
        } else {
            const minutes = this.minutes;
            const date = this.date;
            const userId = this.userId;
            this.timesheetService
                .createTask(this.project.id, this.selectedTask.name)
                .subscribe((taskCreated) => {
                    this.timesheetService.addUserActivity(
                        userId,
                        taskCreated.id,
                        date,
                        minutes
                    );
                });
        }
    }

    private clearForm() {
        this.selectedTask = null;
        this.time = "";
        this.minutes = NaN;

        if (this.userId) {
            this.projectService
                .getMostRecentProjectForUser(this.userId)
                .subscribe((project) => {
                    this.resetTaskNameSubject.next(ResetSearchTextToken.RESET);

                    this.projectSubject.next(project);
                    this.mostRecentProjectStream.next(project);
                });
        }

        this.date = new Date();
        this.preselected.next(this.date);
    }

    private isTheSameProjectName(projectName: string): boolean {
        return !!this.project && this.project.name === projectName;
    }

    public normalizeTime() {
        this.minutes = this.timeUnitConversionService.userInputToMinutes(
            this.time
        );
        this.time =
            this.timeUnitConversionService.minutesToFormattedTextWithDefault(
                this.minutes,
                ""
            );
    }

    private getNextInputIdx(shiftKey, currentIdx, listLength): number {
        const [firstIdx, lastIdx] = [0, listLength - 1];
        let nextIdx;

        if (shiftKey) {
            const prevElement = currentIdx - 1;

            if (prevElement < 0) {
                nextIdx = lastIdx;
            } else {
                nextIdx = prevElement;
            }
        } else {
            const nextElement = currentIdx + 1;

            if (nextElement > lastIdx) {
                nextIdx = firstIdx;
            } else {
                nextIdx = nextElement;
            }
        }

        return nextIdx;
    }

    private getCurrentIdx(target, inputsList): number {
        const targetInput: HTMLInputElement = <HTMLInputElement>target;
        const targetInputParentName = targetInput.parentNode.nodeName;
        const allDropdownInputs =
            this.elementRef.nativeElement.querySelectorAll(DROPDOWN_INPUTS);

        let currentIdx = Array.from(inputsList).indexOf(targetInput);
        const dropdownInputCurrentIdx =
            Array.from(allDropdownInputs).indexOf(targetInput);

        if (currentIdx === -1) {
            currentIdx = dropdownInputCurrentIdx;
        }

        if (targetInputParentName === "LI" && currentIdx === -1) {
            if (targetInput.parentElement.classList.length === 0) {
                currentIdx = 1;
            } else {
                currentIdx = 0;
            }
        }

        return currentIdx;
    }
}
