import { Component, Input } from "@angular/core";
import { User } from "models/users/User";
import { I18NService } from "services/i18n/I18NService";
import { tasksI18N } from "../../i18n/tasks-i18n";

@Component({
    selector: "task-users",
    templateUrl: "task-users.html",
})
export class TaskUsersComponent {
    @Input() public users: User[];
    public i18n;

    constructor(private i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(tasksI18N);
    }

    public usersNames(): string {
        if (this.users) {
            return this.users
                .map((user) => user.name)
                .join(this.i18n.userSeparator);
        }
    }
}
