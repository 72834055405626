<message-header></message-header>
<modal-core>
    <div class="c-signup-invitation__header">
        <h1 class="c-signup-invitation__header-title">
            {{ i18n.createAccount }}
        </h1>
        <h2 class="c-signup-invitation__header-subtitle">
            {{ i18n.toJoin(invitedCompanyUser.companyName) }}
        </h2>
    </div>

    <form
        class="c-signup-invitation__form"
        accept-charset="UTF-8"
        role="form"
        method="post"
        action=""
    >
        <div class="u-form-group c-signup__btn-group">
            <google-auth-button>
                <svg>
                    <use [attr.xlink:href]="'images/menu/all.svg#google'"></use>
                </svg>
            </google-auth-button>
        </div>

        <div>
            <h2 class="c-signup__subtitle section-title">
                {{ i18n.signUpWithEmail }}
            </h2>

            <div class="u-form-group">
                <input
                    class="u-form-control"
                    name="email"
                    value="{{invitedCompanyUser.email}}"
                    type="text"
                    disabled
                />
            </div>
            <signup-password></signup-password>

            <div
                *ngIf="passwordLoginError"
                class="c-signup-invitation__login-error u-form-group"
            >
                {{i18n.passwordLoginFailed}}
            </div>

            <div class="u-form-group">
                <input
                    class="o-btn o-btn--large o-btn--primary c-signup__form-btn"
                    value="{{ i18n.signUp }}"
                    type="submit"
                    (click)="signupWithPassword()"
                />
            </div>
        </div>

        <div class="c-signup-invitation__form-log-in">
            {{i18n.alreadyHaveAnAccount}} <br />
            <a
                [routerLink]="['/login']"
                class="c-signup-invitation__form-log-in-link"
                >{{ i18n.logInNow }}</a
            >
        </div>
    </form>
    <small class="c-signup__desc">
        {{ i18n.bySigningUpYouAreAgreeTo }}
        <a
            href="https://www.timepot.io/?page=privacy"
            class="c-signup__desc-link"
            target="_blank"
            >{{ i18n.thePrivacyPolicy }}</a
        >
        {{ i18n.and }}
        <a
            href="https://www.timepot.io/?page=terms"
            class="c-signup__desc-link"
            target="_blank"
            >{{ i18n.termsOfUse }}</a
        >.
    </small>
</modal-core>
