import { timesheetI18NDe } from "./timesheet-i18n-de";
import { timesheetI18NPl } from "./timesheet-i18n-pl";

const timesheetI18NEn = {
    cancel: "Cancel",
    youAreAtTimepotOf: (name) => "Looking at " + name + "'s timepot",
    newTaskTitle: "Add task to a project",
    editTaskTitle: "Edit task",
    taskNamePlaceholder: "Task name",
    noTasksInProject: "No tasks in current project",
    add: "Add",
    save: "Save",
    pageTitle: "Your week",
    selectTaskFromList: "Select task from list",
    findOrAddTask: "Find or add task",
    chooseTaskFirst: "Choose task first",
    selectProject: "Select project",
    enterTask: "Enter task",
    startTimer: "Start timer...",
    orLogTimeManually: "...or log time manually",
    logYourTime: "Log time",
    watchTutorial: "Watch tutorial",
    taskSealed: "Editing task is disabled",
    sealTimepot: "Seal Timepot",
    seal: "Seal",
    timepot: "Timepot",
    sealDateTitle: "Pick a date to seal this Timepot",
    sealDateExplanation:
        "Note: it won't be possible for this user to update any activities before the date you select. This includes renaming and removing tasks.",
    billable: "Billable",
    nonBillable: "Non-billable",
    nonBillableTask: "Non-billable task",
};

export const timesheetI18N = {
    de: timesheetI18NDe,
    pl: timesheetI18NPl,
    en: timesheetI18NEn,
};
