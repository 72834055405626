import { barchartI18NDe } from "./barchart-i18n-de";
import { barchartI18NPl } from "./barchart-i18n-pl";

const barchartI18NEn = {
    noData: "No data",
};

export const barchartI18N = {
    de: barchartI18NDe,
    pl: barchartI18NPl,
    en: barchartI18NEn,
};
