import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { I18NService } from "services/i18n/I18NService";
import { signupI18N } from "./i18n/signup-i18n";
import { DateService } from "services/date/DateService";
import { emptyCompanyUser } from "models/companyusers/CompanyUser";
import { InvitationService } from "services/invitation/InvitationService";
import { SignupPasswordComponent } from "./SignupPasswordComponent";
import { AuthService } from "services/auth/AuthService";

@Component({
    selector: "signup-invitation",
    templateUrl: "signup-invitation.html",
    host: { "[class.c-signup-invitation]": "true" },
})
export class SignupInvitationComponent implements OnInit {
    public static INVITATION_TOKEN_PARAM = "invitationToken";

    public i18n: (typeof signupI18N)["en"];

    public invitedCompanyUser = emptyCompanyUser();

    public passwordLoginError = false;

    private invitationToken: string;

    @ViewChild(SignupPasswordComponent)
    private signupPasswordComponent: SignupPasswordComponent;

    constructor(
        private i18nService: I18NService,
        private dateService: DateService,
        private invitationService: InvitationService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.i18n = i18nService.extractCurrentTranslation(signupI18N);
    }

    public ngOnInit() {
        this.route.params.subscribe((params) => {
            this.invitationToken =
                params[SignupInvitationComponent.INVITATION_TOKEN_PARAM];
            this.invitationService
                .getInvitedCompanyUser(this.invitationToken)
                .subscribe(
                    (invitedCompanyUser) =>
                        (this.invitedCompanyUser = invitedCompanyUser)
                );
        });
    }

    public signupWithPassword() {
        this.authService
            .signUpWithInvitationAndEmail(
                this.invitationToken,
                this.signupPasswordComponent.value
            )
            .subscribe(
                () => {
                    this.afterUserSignIn();
                },
                () => {
                    this.passwordLoginError = true;
                }
            );
    }

    public afterUserSignIn(): void {
        this.router.navigate([""]);
    }
}
