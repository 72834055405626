import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { ProjectPricingSnapshot } from "models/projects/ProjectPricingSnapshot";
import { ProjectPricingType } from "models/projects/ProjectPricingType";
import { PricingDisplayService } from "services/pricingdisplay/PricingDisplayService";
import { ProjectPricingService } from "services/projects/ProjectPricingService";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { UserService } from "services/user/UserService";
import { I18NService } from "services/i18n/I18NService";

import { projectsI18N } from "../i18n/projects-i18n";

@Component({
    selector: "project-pricing-history-entry",
    templateUrl: "project-pricing-history-entry.html",
})
export class ProjectPricingHistoryEntryComponent implements OnInit {
    @Input() public snapshot: ProjectPricingSnapshot;
    @Input() public currency: string;
    @Input() public users: CompanyUser[] = [];
    @Input() public pricingType: ProjectPricingType;
    @Input() public projectCode: string;
    @Output() public save = new EventEmitter<void>();

    public i18n;
    public ready: boolean;
    public expanded: boolean;
    public isInEditMode: boolean;
    public snapshotCopy: ProjectPricingSnapshot;

    public selectedStartDateStream = new ReplaySubject<Date>();
    public selectedEndDateStream = new ReplaySubject<Date>();
    public startSubject = new ReplaySubject<Date>();
    public endSubject = new ReplaySubject<Date>();

    constructor(
        private projectPricingService: ProjectPricingService,
        private pricingDisplayService: PricingDisplayService,
        i18nService: I18NService,
        private userService: UserService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectsI18N);
    }

    public ngOnInit() {
        this.snapshotCopy = { ...this.snapshot };

        if (this.snapshot.from) {
            this.selectedStartDateStream.next(this.snapshot.from);
        }
        if (this.snapshot.to) {
            this.selectedEndDateStream.next(this.snapshot.to);
        }

        this.startSubject.subscribe((from) => {
            this.snapshotCopy.from = from;
        });

        this.endSubject.subscribe((to) => {
            this.snapshotCopy.to = to;
        });
    }

    public getFormattedAmount(amount: number) {
        return this.pricingDisplayService.getFormattedAmount(
            this.pricingType === ProjectPricingType.HOURLY_RATE,
            amount,
            this.currency
        );
    }

    public getFormattedDate(date: Date) {
        return this.pricingDisplayService.getFormattedDate(
            this.pricingType === ProjectPricingType.HOURLY_RATE,
            date
        );
    }

    public toggleExpanded() {
        this.expanded = !this.expanded;
    }

    public getUsername(userCode: string) {
        const user = this.users.find((u) => u.id === userCode);
        if (user) {
            return user.name;
        }
        return "";
    }

    public getUserAvatar(userCode: string) {
        const user = this.users.find((u) => u.id === userCode);
        if (user) {
            return this.userService.getAvatarForUser(user);
        }
        return "";
    }

    public editSnapshot() {
        this.isInEditMode = !this.isInEditMode;
    }

    public saveSnapshot() {
        this.isInEditMode = !this.isInEditMode;
        if (this.isSnapshotChanged(this.snapshot, this.snapshotCopy)) {
            let observable: Observable<void>;
            if (this.pricingType === ProjectPricingType.HOURLY_RATE) {
                observable = this.projectPricingService.addHourlyRatePeriod(
                    this.projectCode,
                    this.snapshotCopy.amount,
                    this.snapshotCopy.userOverrides,
                    this.snapshotCopy.from,
                    this.snapshotCopy.to
                );
            } else if (this.pricingType === ProjectPricingType.MONTHLY_RATE) {
                observable = this.projectPricingService.addMonthlyRatePeriod(
                    this.projectCode,
                    this.snapshotCopy.amount,
                    this.snapshotCopy.from,
                    this.snapshotCopy.to
                );
            }
            observable.subscribe(() => {
                this.save.emit(null);
                this.snapshotCopy = { ...this.snapshot };
            });
        }
    }

    private isSnapshotChanged(snapshot, copy) {
        return snapshot.from !== copy.from || snapshot.to !== copy.to;
    }
}
