<div class="c-add-task-row-explanation__tip c-add-task-row-explanation__tip--1">
    <span class="c-add-task-row-explanation__tip-label">
        <span class="c-add-task-row-explanation__tip-label-indicator">1.</span>
        {{i18n.selectProject}}
    </span>
</div>
<div class="c-add-task-row-explanation__tip c-add-task-row-explanation__tip--2">
    <span class="c-add-task-row-explanation__tip-label">
        <span class="c-add-task-row-explanation__tip-label-indicator">2.</span>
        {{i18n.enterTask}}
    </span>
</div>
<div
    class="c-add-task-row-explanation__tip c-add-task-row-explanation__tip--3"
    *ngIf="isCurrentWeek"
>
    <span class="c-add-task-row-explanation__tip-label">
        <span class="c-add-task-row-explanation__tip-label-indicator">3.</span>
        {{i18n.startTimer}}
    </span>
</div>
<div class="c-add-task-row-explanation__tip c-add-task-row-explanation__tip--4">
    <span class="c-add-task-row-explanation__tip-label" *ngIf="isCurrentWeek">
        <span class="c-add-task-row-explanation__tip-label-indicator">4.</span>
        {{i18n.orLogTimeManually}}
    </span>
    <span class="c-add-task-row-explanation__tip-label" *ngIf="!isCurrentWeek">
        <span class="c-add-task-row-explanation__tip-label-indicator">3.</span>
        {{i18n.logYourTime}}
    </span>
</div>

<button
    class="c-add-task-row-explanation__btn o-btn o-btn--large o-btn--default"
    (click)="showVideo()"
>
    <svg><use [attr.xlink:href]="'images/menu/all.svg#play'"></use></svg>
    {{i18n.watchTutorial}}
</button>

<video-modal [showStream]="showVideoStream"></video-modal>
