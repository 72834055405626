export const userPaymentI18NPl = {
    hourlyRate: "Stawka godzinowa",
    monthlyRate: "Stawka miesięczna",
    fullTime: "Pełen etat",
    partTime: "Pół etatu",
    ratePlaceholder: "Stawka",
    invalid: "Niepoprawna",
    currency: "Waluta",
    currentRate: "Aktualne stawka",
    payment: "Koszt",
    history: "Historia",
    from: "Od",
    to: "Do",
};
