import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { CurrencyService } from "services/currency/CurrencyService";
import { projectsI18N } from "../../../i18n/projects-i18n";
import { UserRateOverride } from "models/projects/UserRateOverride";
import * as _ from "lodash";

@Component({
    selector: "user-rate-override",
    templateUrl: "user-rate-override.html",
    host: { "[class.c-user-rate-override]": "true" },
})
export class UserRateOverrideComponent implements OnInit {
    @Input() public override: UserRateOverride;
    @Input() public users: { id: string; name: string }[] = [];
    @Output() public dataChanged = new EventEmitter<UserRateOverride>();
    @Output() public delete = new EventEmitter<UserRateOverride>();
    public i18n;
    public initialSelectionStream = new ReplaySubject<
        { id: string; name: string }[]
    >(1);
    public selectedUsers = new ReplaySubject<{ id: string; name: string }[]>(1);

    constructor(
        private currencyService: CurrencyService,
        private i18nService: I18NService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectsI18N);
    }

    public ngOnInit() {
        this.override = _.cloneDeep(this.override);
        const user = this.users.find(
            (u) => u.id === this.override.userCode
        ) || {
            id: this.override.userCode,
            name: "",
        };
        this.initialSelectionStream.next([user]);
        this.selectedUsers.subscribe((users) => {
            if (users.length === 1) {
                this.override.userCode = users[0].id;
                this.emit();
            }
        });
    }

    public setRate(rate: string) {
        const isValid = this.currencyService.isFormatValid(rate);
        if (isValid) {
            this.override.rate = this.currencyService.convertToNumber(rate);
        } else {
            this.override.rate = undefined;
        }
        this.emit();
    }

    public emit() {
        this.dataChanged.emit(this.override);
    }
}
