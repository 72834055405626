import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const RawCountry = z.object({
    code: z.string(),
    name: z.string(),
    iso2Code: z.string(),
});

export type RawCountry = z.infer<typeof RawCountry>;

export function createCountry(raw: RawCountry) {
    return {
        ...raw,
        id: raw.code,
    };
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const Country = RawCountry.transform(createCountry);
export type Country = z.infer<typeof Country>;

export function emptyCountry(): Country {
    return createCountry({ code: "", iso2Code: "", name: "" });
}
