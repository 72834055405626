import { Injectable } from "@angular/core";
import { TimeUnitConversionService } from "../timeunitconversion/TimeUnitConversionService";

@Injectable()
export class TimeZoneService {
    constructor(private timeUnitConversionService: TimeUnitConversionService) {}

    public getTimeZone(): string {
        const offsetInMinutes = new Date().getTimezoneOffset();
        const absOffsetInMinutes = Math.abs(offsetInMinutes);

        const formattedText = (
            "0" +
            this.timeUnitConversionService.minutesToFormattedText(
                absOffsetInMinutes
            )
        ).slice(-5);

        return (offsetInMinutes < 0 ? "+" : "-") + formattedText;
    }
}
