<div class="c-user-list__users-container">
    <user
        [user]="user"
        [teamsStream]="teamsStream"
        *ngFor="let user of page"
        class="c-user--searched"
    ></user>
</div>
<pagination
    [dataStream]="usersStream"
    (page)="onPageChanged($event)"
></pagination>
