import { donutChartI18NDe } from "./donutchart-i18n-de";
import { donutChartI18NPl } from "./donutchart-i18n-pl";

const donutChartI18NEn = {
    projectLabel: "All Projects",
    userLabel: "All Users",
    othersLabel: "Others",
};

export const donutChartI18N = {
    de: donutChartI18NDe,
    pl: donutChartI18NPl,
    en: donutChartI18NEn,
};
