<generic-modal
    [showStream]="showStream"
    [actionStream]="actionStream"
    [title]="i18n.editTaskTitle"
    [okEnabled]="isValid()"
    [confirmLabel]="i18n.save"
>
    <div class="c-edit-task__container u-form-group">
        <div class="c-edit-task__container-input">
            <project-dropdown
                [selection]="projectSubject"
                [initialSelectionStream]="initialProjectSubject"
                [includeImportedProjects]="false"
            ></project-dropdown>
        </div>
        <input
            class="u-form-control"
            type="text"
            [(ngModel)]="name"
            maxlength="180"
            placeholder="{{ i18n.taskNamePlaceholder }}"
        />
        <div class="c-edit-task__container-billable" *ngIf="user.manager">
            <selector
                [switches]="[
                    { text: i18n.billable, label: 'billable', selected: task && task.billable },
                    { text: i18n.nonBillable, label: 'non-billable', selected: task && !task.billable }
                ]"
                (selection)="setTaskBillableState($event)"
                [inline]="true"
            ></selector>
        </div>
    </div>
</generic-modal>
