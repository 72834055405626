import { newCompanyI18nDe } from "timepot/components/newcompany/i18n/new-company-i18n-de";
import { newCompanyI18nPl } from "timepot/components/newcompany/i18n/new-company-i18n-pl";
const newCompanyI18NEn = {
    placeholderName: "Timepot name",
    nameIsFree: "It’s free, take it!",
    nameIsTaken: "Already taken!",
    nameIsInvalid: "Invalid name!",
    validNameHint:
        "You can use upper and lower case letters, digits and break them up with dashes",
    createNewCompany: "Create New Timepot",
    save: "Save",
};

export const newCompanyI18n = {
    de: newCompanyI18nDe,
    en: newCompanyI18NEn,
    pl: newCompanyI18nPl,
};
