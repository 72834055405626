import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { Token } from "models/token/Token";
import { Action, OpenModalToken } from "../genericmodal/GenericModalComponent";
import { map } from "rxjs/operators";

@Component({
    selector: "video-modal",
    templateUrl: "video-modal.html",
    host: {
        "[class.c-video-modal]": "true",
    },
})
export class VideoModalComponent implements OnInit {
    @Input() public showStream: Subject<Token>;
    @Input() public actionStream: Subject<Action> = new ReplaySubject<Action>(
        1
    );
    @Input() public openModalStream: Subject<OpenModalToken> =
        new ReplaySubject<OpenModalToken>(1);

    public showModalStream = new Subject<boolean>();

    public ngOnInit() {
        this.showStream.subscribe(() => this.showModalStream.next(true));
        this.actionStream
            .pipe(map((action) => action === Action.OK))
            .subscribe(() => this.showModalStream.next(false));
    }

    public isBtnOkEnabled() {
        return true;
    }
}
