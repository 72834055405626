import { genericModalI18NDe } from "./genericmodal-i18n-de";
import { genericModalI18NPl } from "./genericmodal-i18n-pl";
const genericModalI18NEn = {
    cancel: "Cancel",
    ok: "OK",
    reset: "Reset",
};

export const genericModalI18N = {
    de: genericModalI18NDe,
    en: genericModalI18NEn,
    pl: genericModalI18NPl,
};
