<div
    class="c-date-picker__container"
    (keydown)="onKeyDown($event)"
    (document:click)="close($event)"
>
    <input
        class="c-date-picker__input {{iconClass}} {{inputClass}}"
        [value]="dateString"
        (click)="showDatePicker()"
        (blur)="hideDatePicker()"
        (keydown)="onInputKeyDown($event)"
        tabindex="0"
        [disabled]="disabled"
    />

    <div *ngIf="open" class="c-date-picker__flyout {{position}}">
        <date-picker-body
            [selection]="selection"
            [startRange]="startRange"
            [endRange]="endRange"
            [preselected]="preselected"
            (mouseUp)="hideDatePicker()"
        >
        </date-picker-body>
    </div>
</div>
