import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../config";
import { ApiKey } from "../../models/api/ApiKey";
import { CurrentCompanyUserViewService } from "../currentcompanyuser/CurrentCompanyUserViewService";
import { CompanyNameAvailability } from "../../models/company/CompanyNameAvailability";
import { CompanyUser } from "../../models/companyusers/CompanyUser";
import { AuthService } from "../auth/AuthService";
import { CompanySettings } from "../../models/company/CompanySettings";
import { map } from "rxjs/operators";

@Injectable()
export class CompanyService {
    constructor(
        private http: HttpClient,
        private currentCompanyUserViewService: CurrentCompanyUserViewService,
        private authService: AuthService,
        private configService: ConfigService
    ) {}

    public getCompanyNameAvailability(
        companyName: string
    ): Observable<boolean> {
        return this.http
            .get(this.configService.signUpCompaniesEndpoint(companyName))
            .pipe(map((resp) => CompanyNameAvailability.parse(resp).available));
    }

    public createCompany(companyName: string): Observable<CompanyUser> {
        return this.http
            .post(this.configService.companiesEndpoint(), { companyName })
            .pipe(map((resp) => CompanyUser.parse(resp)));
    }

    public getApiKey(): Observable<string> {
        return this.http.get(this.configService.apiKeyEndpoint()).pipe(
            map((resp) => {
                return ApiKey.parse(resp).apiKey;
            })
        );
    }

    public getCompanySettings(): Observable<CompanySettings> {
        return this.http.get(this.configService.companySettingsEndpoint()).pipe(
            map((resp) => {
                return CompanySettings.parse(resp);
            })
        );
    }

    public updateCompanySettings(settings: CompanySettings): Observable<void> {
        return this.http
            .put(this.configService.companySettingsEndpoint(), settings)
            .pipe(map(() => null));
    }
}
