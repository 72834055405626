import * as _ from "lodash";
import { ErrorHandler, Injectable } from "@angular/core";

@Injectable()
export class TimepotExceptionHandler extends ErrorHandler {
    private static agents: IssueAgent[] = [];

    public static registerAgent(agent: IssueAgent | IssueAgent[]): void {
        if (isIssueAgent(agent)) {
            if (!_.find(TimepotExceptionHandler.agents, agent)) {
                TimepotExceptionHandler.agents.push(agent);
            }
        } else if (agent) {
            agent.forEach(TimepotExceptionHandler.registerAgent);
        }
    }

    constructor() {
        super();
    }

    public override handleError(error, stackTrace = null, reason = null) {
        const issue: Issue = {
            error: error,
            reason: reason,
            stackTrace: stackTrace,
        };

        TimepotExceptionHandler.agents
            .filter((agent) => issue && agent.manages(issue))
            .forEach((agent) => agent.tackle(issue));
    }
}

export interface Issue {
    error;
    reason;
    stackTrace;
}

@Injectable()
export class IssueAgent {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public manages(issue: Issue): boolean {
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public tackle(issue: Issue): void {
        return;
    }
}

function isIssueAgent(object: object): object is IssueAgent {
    return (
        object &&
        (<IssueAgent>object).manages !== undefined &&
        (<IssueAgent>object).tackle !== undefined
    );
}
