<ts-task
    (mouseenter)="notifyHighlighted(task)"
    [project]="project"
    [userHolidaysDays]="userHolidaysDays"
    [userSealDate]="userSealDate"
    [task]="task"
    [highlightedObservable]="highlightedTaskObservable"
    [userId]="userId"
    [onTaskEdit]="onTaskEdit"
    [ngClass]="cssClassMap(idx)"
    *ngFor="let task of project.tasks; let idx = index"
    [firstTaskInTable]="isFirstTask(idx)"
    [lastTaskInTable]="isLastTask(idx)"
    [focusedActivityObservable]="focusedActivityObservable"
    [timerObservable]="timerObservable"
    [timerSeconds]="timerSeconds"
    #taskComponent
    (navigateUp)="navigateUpOf(taskComponent, $event)"
    (navigateDown)="navigateDownOf(taskComponent, $event)"
>
</ts-task>
