<form>
    <div class="c-project-pricing-modal__form-container">
        <ng-container *ngIf="!noInitialPricing">
            <div class="c-project-pricing-modal__selected-payment-type">
                {{currenPricingTypeStr}}
            </div>
        </ng-container>
        <ng-container *ngIf="noInitialPricing">
            <div class="o-form__group o-form__group--inline">
                <label for="hourly" class="o-form__radio">
                    <input
                        class="o-form__radio-input"
                        type="radio"
                        id="hourly"
                        name="pricingType"
                        (change)="setHourlyRate()"
                        [checked]="isHourlyRate()"
                    />
                    <span class="o-form__radio-label">{{i18n.hourlyRate}}</span>
                </label>
                <label for="monthly" class="o-form__radio">
                    <input
                        class="o-form__radio-input"
                        type="radio"
                        id="monthly"
                        name="pricingType"
                        (change)="setMonthlyRate()"
                        [checked]="isMonthlyRate()"
                    />
                    <span class="o-form__radio-label"
                        >{{i18n.monthlyRate}}</span
                    >
                </label>
                <label for="fixed" class="o-form__radio">
                    <input
                        class="o-form__radio-input"
                        type="radio"
                        id="fixed"
                        name="pricingType"
                        (change)="setFixedPrice()"
                        [checked]="isFixedPrice()"
                    />
                    <span class="o-form__radio-label">{{i18n.fixedPrice}}</span>
                </label>
            </div>
        </ng-container>
    </div>
    <div
        class="c-project-pricing-modal__form"
        *ngIf="isFixedPrice() || isHourlyRate() || isMonthlyRate()"
    >
        <div class="c-project-pricing-modal__form-container">
            <project-fixed-rate-pricing
                *ngIf="isFixedPrice()"
                [currentPricing]="currentPricing"
                (valid)="setValid($event)"
                [noInitialPricing]="noInitialPricing"
                (newPricing)="setNewPricing($event)"
            ></project-fixed-rate-pricing>
            <project-hourly-rate-pricing
                *ngIf="isHourlyRate()"
                [currentPricing]="currentPricing"
                [noInitialPricing]="noInitialPricing"
                [users]="users"
                (valid)="setValid($event)"
                (newPricing)="setNewPricing($event)"
            ></project-hourly-rate-pricing>
            <project-monthly-rate-pricing
                *ngIf="isMonthlyRate()"
                [currentPricing]="currentPricing"
                [noInitialPricing]="noInitialPricing"
                (valid)="setValid($event)"
                (newPricing)="setNewPricing($event)"
            ></project-monthly-rate-pricing>
        </div>
    </div>
</form>
