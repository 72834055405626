import { paginationI18NDe } from "./pagination-i18n-de";
import { paginationI18NPl } from "./pagination-i18n-pl";

const paginationI18NEn = {
    prev: "Prev",
    next: "Next",
    of: "of",
};

export const paginationI18N = {
    de: paginationI18NDe,
    pl: paginationI18NPl,
    en: paginationI18NEn,
};
