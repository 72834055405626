<span
    class="c-ts-project-selector__project-name c-ts-project-selector__project-name--{{ getProjectCSS(currentSelection) }}"
    (click)="toggleSelector(true)"
    [formGroup]="searchForm"
>
    <span
        *ngIf="!currentSelection || currentSelection.length === 0"
        class="c-generic-dropdown__activator-placeholder"
        >Undefined</span
    >
    <span
        class="c-ts-project-selector__project-name-container"
        *ngIf="currentSelection"
    >
        {{currentSelection.name}}
    </span>
    <input
        class="c-ts-project-selector__input"
        type="text"
        (input)="onInputSearch(searchForm.value.search)"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        (keydown)="onInputKeyDown($event)"
        formControlName="search"
    />
    <span
        class="c-ts-project-selector__close"
        (click)="closeSelector($event)"
    ></span>
</span>
<div class="c-ts-project-selector__project-list">
    <ul class="c-ts-project-selector__project-list-ul ps-child">
        <li
            *ngFor="let item of items"
            (mousedown)="select(item, $event)"
            class="c-ts-project-selector__project-list-item"
        >
            <a
                class="c-ts-project-selector__project-list-item-link c-ts-project-selector__project-list-item-link--{{ getProjectCSS(item) }}"
                [ngClass]="{'c-ts-project-selector__project-list-item-link--selected': isSelected(item),
                           'c-ts-project-selector__project-list-item-link--keySelected': isKeySelected(item)}"
            >
                {{item.name}}
            </a>
        </li>
    </ul>
    <button
        *ngIf="allowAddingProject"
        (mousedown)="showAddProjectModal($event)"
        class="c-ts-project-selector__project-list-btn"
    >
        {{i18n.addNewProject}}...
    </button>
</div>

<project-modal
    [projectObservable]="projectModalStream"
    (result)="onProjectCreated($event)"
></project-modal>
