<div
    class="c-bar-chart__barchart"
    [ngStyle]="{'height.px': visible ? scaleHeight * (positiveScale.length + negativeScale.length) + containerPadding : 0}"
    [ngClass]="{ 'c-bar-chart__barchart--visible': visible}"
    data-id="barchart"
>
    <div *ngIf="isEmpty" class="c-bar-chart__empty chart-empty">
        {{i18n.noData}}
    </div>
    <div *ngIf="!isEmpty" id="barchart-chart" class="c-bar-chart__chart">
        <div
            class="c-bar-chart__chart-container"
            [ngStyle]="{'height.px': 5 * scaleHeight}"
        >
            <ul class="c-bar-chart__chart-grid">
                <li
                    class="c-bar-chart__chart-grid-row c-bar-chart__chart-grid-row--positive"
                    *ngFor="let scaleItem of positiveScale"
                >
                    <span
                        class="c-bar-chart__chart-grid-row-label c-bar-chart__chart-grid-row-label--positive"
                        showOverflownTitle
                    >
                        {{formatScaleValue(scaleItem)}}
                    </span>
                </li>
                <li
                    class="c-bar-chart__chart-grid-row c-bar-chart__chart-grid-row--negative"
                    *ngFor="let scaleItem of negativeScale"
                >
                    <span
                        class="c-bar-chart__chart-grid-row-label c-bar-chart__chart-grid-row-label--negative"
                        showOverflownTitle
                    >
                        - {{formatScaleValue(scaleItem)}}
                    </span>
                </li>
            </ul>
            <ul class="c-bar-chart__chart-columns barchart-columns">
                <li
                    class="c-bar-chart__chart-columns-column"
                    *ngFor="let dataItem of dataItems"
                >
                    <div
                        *ngIf="dataItem.value > 0"
                        class="u-tooltip--top c-bar-chart__chart-columns-column-bar c-bar-chart__chart-columns-column-bar--positive"
                        [ngStyle]="{height: dataItem.axisValue}"
                        [attr.data-tooltip]="dataItem.tooltipValue"
                        (click)="selectItem(dataItem)"
                        #div
                    ></div>
                    <div
                        *ngIf="dataItem.value < 0"
                        class="u-tooltip--top c-bar-chart__chart-columns-column-bar c-bar-chart__chart-columns-column-bar--negative"
                        [ngStyle]="{height: dataItem.axisValue}"
                        [attr.data-tooltip]="dataItem.tooltipValue"
                        (click)="selectItem(dataItem)"
                        #div
                    ></div>
                    <span
                        class="c-bar-chart__chart-columns-column-value"
                        [ngStyle]="{'bottom.px': - negativeScale.length * scaleHeight - 60}"
                    >
                        <span *ngFor="let part of splitLabel(dataItem.label)">
                            {{part}}
                        </span>
                    </span>
                </li>
            </ul>
        </div>
        <div class="c-bar-chart__base-line">
            <span class="c-bar-chart__base-line-divider"></span>
            <span class="c-bar-chart__base-line-label-container">
                <span class="c-bar-chart__base-line-label"
                    >{{formatScaleValue(0)}}</span
                >
            </span>
        </div>
    </div>
</div>
