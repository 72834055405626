import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const CompanySettings = z.object({
    holidayNotifications: z.string(),
    timepotSealed: z.boolean(),
    timesheetsReminders: z.string(),
});

export type CompanySettings = z.infer<typeof CompanySettings>;
