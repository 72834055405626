import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { AuthService } from "services/auth/AuthService";
import { RefreshToken, UserService } from "services/user/UserService";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { I18NService } from "services/i18n/I18NService";
import { navbarI18N } from "../i18n/navbar-i18n";
import { CompanyPickerService } from "services/currentcompanyuser/CompanyPickerService";
import { TourService } from "services/tour/TourService";
import { Token } from "models/token/Token";
import { DemoService } from "../../../services/demo/DemoService";
import {
    DEMO_TOUR,
    ONBOARDING_TOUR,
} from "../../../services/tour/TimepotTours";
import { startWith } from "rxjs/operators";

@Component({
    host: {
        "(document:click)": "onClick($event)",
        "[class.c-user-menu]": "true",
        "[class.c-user-menu--active]": "visible",
    },
    selector: "user-menu",
    templateUrl: "user-menu.html",
})
export class UserMenuComponent implements OnInit {
    @Input() public showEditProfileStream: Subject<Token>;
    @Input() public showNewCompanyModalStream: Subject<Token>;
    public companies: CompanyUser[];
    public visible = false;
    public companiesVisible = false;
    public avatarUrl: string;
    public i18n: (typeof navbarI18N)["en"];

    constructor(
        i18nService: I18NService,
        private authService: AuthService,
        private userService: UserService,
        private elementRef: ElementRef,
        private router: Router,
        private companyPickerService: CompanyPickerService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService,
        private tourService: TourService,
        private demoService: DemoService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(navbarI18N);
    }

    public ngOnInit(): void {
        if (this.authService.isAuthenticated()) {
            this.companies = [this.user];
            this.userService
                .getCompaniesAvailableForUser(this.user.id)
                .subscribe((companies) => (this.companies = companies));
        }
        this.userService.refreshSubject
            .pipe(startWith(RefreshToken.REFRESH))
            .subscribe(() => {
                this.avatarUrl = this.user
                    ? this.userService.getAvatarForUser(this.user, true)
                    : "#";
            });
    }

    public get user(): CompanyUser {
        return this.currentCompanyUserViewService.companyUser;
    }

    public showEditProfile(): void {
        this.showEditProfileStream.next(Token.TOKEN);
        this.close();
    }

    public showNewTimepotModal(): void {
        this.showNewCompanyModalStream.next(Token.TOKEN);
        this.close();
    }

    public signOut(): void {
        this.authService
            .signOut()
            .subscribe(() => this.router.navigate(["/login"]));
    }

    public runTour() {
        const tour = this.demoService.isDemoMode()
            ? DEMO_TOUR
            : ONBOARDING_TOUR;
        this.tourService.runTour(tour, true);
    }

    public isCurrentCompany(companyUser: CompanyUser): boolean {
        return this.user.companyName === companyUser.companyName;
    }

    public pickCompany(companyUser: CompanyUser): void {
        this.companyPickerService.pickCompany(companyUser);
    }

    public onMenuClick(): void {
        this.toggle();
    }

    public onCompaniesClick(): void {
        this.companiesVisible = !this.companiesVisible;
    }

    public onClick(event): void {
        if (
            !this.isInside(event, "c-user-menu__panel") &&
            !this.isInside(event, "c-user-menu__avatar-container")
        ) {
            this.close();
        }
    }

    private close(): void {
        this.visible = false;
        this.companiesVisible = false;
    }

    private toggle() {
        this.visible = !this.visible;

        if (!this.visible) {
            this.companiesVisible = false;
        }
    }

    /**
     * Checks if event’s target was inside of given element; NOTE: detached nodes are treated as inside!
     * @param event
     * @param className of element
     * @returns {boolean}
     */
    private isInside(event: Event, className: string): boolean {
        const currentNode: Node = <Node>event.target;
        const checkedNode =
            this.elementRef.nativeElement.getElementsByClassName(className)[0];
        return (
            checkedNode.contains(currentNode) ||
            !checkedNode.ownerDocument.contains(currentNode)
        );
    }
}
