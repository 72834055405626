import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableProdMode } from "@angular/core";
import { environment } from "environments/environment";
import { TimepotModule } from "timepot/timepot.module";

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(TimepotModule);
