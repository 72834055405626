<div
    class="o-list-row"
    [ngClass]="{ 'c-project-entry__entry--archive': !project.active }"
    [attr.draggable]="project.active"
    (dragstart)="onDragStart($event, project.id, elem)"
    (dragend)="onDragEnd()"
>
    <div #elem class="o-list-row__dragable">
        <div
            class="o-list-row__cell o-list-row__cell--color {{ cssClass }}"
        ></div>

        <div class="o-list-row__cell o-list-row__cell--name">
            <span>{{ project.name }}</span>
        </div>
    </div>

    <div class="o-list-row__cell o-list-row__cell--links">
        <span
            *ngIf="!project.billableByDefault"
            class="o-list-row__link-icon o-list-row__link-icon--nonbillable u-tooltip--top u-tooltip--wider"
            [attr.data-tooltip]="i18n.tasksAreNonBillableByDefault"
        >
            <svg><use xlink:href="images/menu/all.svg#nonbillable"></use></svg>
        </span>
        <a
            #ed
            *ngIf="project.active && !project.imported"
            (click)="edit()"
            class="o-list-row__link u-tooltip--top"
            [attr.data-tooltip]="i18n.edit"
        >
            <span class="o-list-row__link-icon">
                <svg><use xlink:href="images/menu/all.svg#edit"></use></svg>
            </span>
            <span class="o-list-row__link-desc">{{ i18n.edit }}</span>
        </a>
        <a
            *ngIf="project.active && isManager"
            (click)="onShowPricing()"
            class="o-list-row__link u-tooltip--top"
            [attr.data-tooltip]="i18n.pricing"
        >
            <span class="o-list-row__link-icon">
                <svg><use xlink:href="images/menu/all.svg#finances"></use></svg>
            </span>
            <span class="o-list-row__link-desc">{{ i18n.pricing }}</span>
        </a>
        <a
            #arch
            *ngIf="project.active && !project.imported"
            (click)="archive(true)"
            class="o-list-row__link u-tooltip--top"
            [attr.data-tooltip]="i18n.archive"
        >
            <span class="o-list-row__link-icon">
                <svg><use xlink:href="images/menu/all.svg#archive"></use></svg>
            </span>
            <span class="o-list-row__link-desc">{{ i18n.archive }}</span>
        </a>
        <a
            #undo
            *ngIf="!project.active"
            (click)="archive(false)"
            class="o-list-row__link u-tooltip--top"
            [attr.data-tooltip]="i18n.restore"
        >
            <span class="o-list-row__link-icon">
                <svg><use xlink:href="images/menu/all.svg#restore"></use></svg>
            </span>
            <span class="o-list-row__link-desc">{{ i18n.restore }}</span>
        </a>
    </div>

    <div
        class="o-list-row__cell o-list-row__cell--teams"
        *ngIf="project.active && !project.imported"
    >
        <div class="o-list-row__teams">
            <div
                class="o-list-row__team"
                [ngClass]="{ 'o-list-row__team--hidden': i + 1 > teamsWrapper.numberOfDisplayedTeams }"
                *ngFor="let team of teamsWrapper.teams; let i = index"
            >
                {{ team.name }}
                <span
                    class="o-list-row__team-remove"
                    (click)="removeFromSelection(team)"
                ></span>
            </div>
            <span
                (click)="showTeamModal()"
                class="o-list-row__teams-more"
                *ngIf="teamsWrapper.hiddenTeamsCount > 0"
                >+{{ teamsWrapper.hiddenTeamsCount }} {{ i18n.more }}</span
            >
            <span
                *ngIf="allTeams.length > 0"
                (click)="showTeamModal()"
                class="o-list-row__teams-add"
                >{{ addTeamLabel }}</span
            >
        </div>
    </div>

    <div
        class="o-list-row__cell o-list-row__cell--info o-list-row__cell--teams-count"
        *ngIf="project.active && !project.imported && countProjectTeams()"
    >
        {{ teamsWrapper.label }}
    </div>

    <div class="o-list-row__cell o-list-row__cell--info" *ngIf="project.active">
        {{ hours !== NaN && minutes !== NaN ? i18n.duration(hours, minutes) :
        i18n.unknownDuration }}
    </div>

    <a
        class="o-list-row__cell o-list-row__cell--raports u-tooltip--top"
        [attr.data-tooltip]="i18n.reports"
        title=""
        *ngIf="project.active"
        [routerLink]="['/auth/reports', params]"
    >
        <svg><use xlink:href="images/menu/all.svg#reports"></use></svg>
    </a>

    <a
        class="o-list-row__cell o-list-row__cell--dropdown-mobile"
        title=""
        (click)="toggleDropdown()"
    >
        <svg><use xlink:href="images/menu/all.svg#dropdown"></use></svg>
    </a>
</div>

<div class="o-list-row__overlay" (click)="closeDropdown($event)"></div>

<div *ngIf="editTeamsVisible">
    <project-teams-modal
        [project]="project"
        [showStream]="teamModalShowStream"
    ></project-teams-modal>
</div>
