import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { Calendar } from "models/calendar/Calendar";
import { CalendarViewMode } from "models/calendar/CalendarViewMode";
import { I18NService } from "services/i18n/I18NService";
import { calendarI18N } from "../i18n/calendar-i18n";

@Component({
    selector: "calendar-header",
    templateUrl: "header.html",
    host: { "[class.c-header]": "true" },
})
export class CalendarHeaderComponent implements OnInit {
    public year: number = new Date().getFullYear();
    public month: number;
    public monday: Date;
    public sunday: Date;
    public viewMode: CalendarViewMode = CalendarViewMode.YEAR;

    @Input() public calendarStateObservable: Subject<Calendar>;

    public i18n;
    public isDifferentMonth: boolean;
    public calendarViewMode = CalendarViewMode;

    constructor(i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(calendarI18N);
    }

    public ngOnInit() {
        this.calendarStateObservable.subscribe((calendarState) => {
            this.year = calendarState.year;
            this.month = calendarState.month;
            this.viewMode = calendarState.viewMode;

            if (this.monday !== calendarState.monday) {
                this.monday = calendarState.monday;

                this.setSundayDate(this.monday);
                this.isDifferentMonth =
                    this.monday.getMonth() !== this.sunday.getMonth();
            }
        });
    }

    private setSundayDate(monday: Date) {
        const endDate = new Date(monday.getTime());
        endDate.setDate(endDate.getDate() + 6);

        this.sunday = endDate;
    }
}
