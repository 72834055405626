<div class="c-month__name" (click)="showMonth()">
    <span>{{i18n.monthNames[month]}}</span>
    <svg class="c-month__name-icon">
        <use [attr.xlink:href]="'images/menu/all.svg#maximize'"></use>
    </svg>
</div>

<table class="o-calendar__week-row c-month__header">
    <thead>
        <tr>
            <th class="c-month__header-day">{{i18n.mondayInitial}}</th>
            <th class="c-month__header-day">{{i18n.tuesdayInitial}}</th>
            <th class="c-month__header-day">{{i18n.wednesdayInitial}}</th>
            <th class="c-month__header-day">{{i18n.thursdayInitial}}</th>
            <th class="c-month__header-day">{{i18n.fridayInitial}}</th>
            <th class="c-month__header-day">{{i18n.saturdayInitial}}</th>
            <th class="c-month__header-day">{{i18n.sundayInitial}}</th>
        </tr>
    </thead>
</table>

<div class="c-month__weeks">
    <week
        *ngFor="let monday of mondays; let i = index"
        [month]="month"
        [year]="year"
        [startDate]="monday"
        [last]="i === mondays.length - 1"
        [first]="i === 0"
        [daysOff]="daysOff"
        [currentUser]="currentUser"
        [currentSelection]="currentSelection"
    ></week>
</div>
