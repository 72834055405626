import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../config";
import { FilterSettings } from "../../models/reports/filters/FilterSettings";
import { FilterSettingsQueryHelper } from "../report/FilterSettingsQueryHelper";
import { MonthlyBalanceReport } from "../../models/financialreport/MonthlyBalanceReport";
import { ProjectIncomeAndExpenseReport } from "../../models/financialreport/ProjectIncomeAndExpenseReport";
import { map } from "rxjs/operators";

@Injectable()
export class FinancialReportService {
    constructor(
        private http: HttpClient,
        private filterSettingQueryHelper: FilterSettingsQueryHelper,
        private configService: ConfigService
    ) {}

    public getProjectIncomeAndExpenseReport(
        filterSettings: FilterSettings
    ): Observable<ProjectIncomeAndExpenseReport> {
        return this.http
            .get(
                this.configService.projectIncomeAndExpensesEndpoint() +
                    this.filterSettingQueryHelper.getQueryString(filterSettings)
            )
            .pipe(map((res) => ProjectIncomeAndExpenseReport.parse(res)));
    }

    public getMonthlyBalanceReport(
        filterSettings: FilterSettings
    ): Observable<MonthlyBalanceReport> {
        return this.http
            .get(
                this.configService.monthlyBalanceEndpoint() +
                    this.filterSettingQueryHelper.getQueryString(filterSettings)
            )
            .pipe(map((res) => MonthlyBalanceReport.parse(res)));
    }
}
