export const usersI18NDe = {
    admin: "Admin",
    pageTitle: "Benutzerliste",
    deactivatedUsers: "Deaktivierte Benutzer",
    active: "Aktive Benutzer",
    inactive: "Inaktive Benutzer",
    pending: "Eingeladene Benutzer",
    inviteUsers: "Benutzer einladen",
    edit: "Bearbeiten",
    editUsers: "Benutzer bearbeiten",
    manager: "Manager",
    regular: "Regulär",
    viewUsers: "Benutzer anzeigen",
    sendInvitations: "Einladungen senden",
    userView: "Anzeigen",
    userGrantAdmin: "Admin genehmigen",
    userFinance: "Finanzen",
    userRevokeAdmin: "Admin widerrufen",
    userDeactivate: "Deaktivieren",
    userActivate: "Reaktivieren",
    userInviteAgain: "Nochmals einladen",
    userRemove: "Entfernen",
    userFinances: "Finanzen",
    total: "Gesamt",
    findUsers: "Benutzer finden",
    add: "Hinzufügen",
    addTeam: "Team hinzufügen",
    addUsers: "Benutzer hinzufügen",
    selectCurrency: "Währung wählen",
    more: "mehr",
    teamsCountLabel: (count: number) =>
        count === 1 ? "1 Team" : `${count} Teams`,
    allTeams: "Alle Teams",
    myTeams: "Meine Teams",
};
