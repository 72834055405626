<div
    class="o-ts-row__cell o-ts-row__cell--name c-ts-task__name-container u-tooltip--top u-tooltip--wide"
    [ngClass]="{'u-tooltip--hide u-tooltip--hide-delay': higherPriorityTooltip}"
    (mouseenter)="active = true"
    (mouseleave)="active = false"
    showOverflownTitle="data-tooltip"
    titleForSelector="[data-title-anchor='task-name']"
>
    <div class="c-ts-task__task-container">
        <div class="c-ts-task__task-container-name">
            <span
                class="c-ts-task__task-container-name-txt"
                data-title-anchor="task-name"
                >{{ task.name }}</span
            >
        </div>
        <div
            (mouseover)="higherPriorityTooltip = true"
            (mouseleave)="higherPriorityTooltip = false"
            *ngIf="!task.billable"
            [attr.data-tooltip]="i18n.nonBillableTask"
            class="c-ts-task__task-container-nonbillable u-tooltip--top u-tooltip--wider u-tooltip--delay"
        >
            <svg *ngIf="!task.billable">
                <use xlink:href="images/menu/all.svg#nonbillable"></use>
            </svg>
        </div>
        <img
            src="images/icons/edit.svg"
            *ngIf="active && !task.isFake && !task.readOnly && !task.imported && !task.sealed"
            class="c-ts-task__task-container-edit"
            (click)="editTask()"
        />
        <img
            src="images/icons/lock.svg#dark"
            class="c-ts-task__task-container-edit"
            *ngIf="active && task.sealed"
        />
    </div>
    <div
        *ngIf="task === highlightedTask"
        class="c-ts-task__project-selector"
        showOverflownTitle
    >
        {{ project.name }}
    </div>

    <ng-container *ngIf="hasCurrentDay()">
        <timer-start
            class="c-ts-task__task-start"
            *ngIf="!task.readOnly && !isOnTimer && !isTodaySealed()"
            [userId]="userId"
            [project]="project"
            [taskName]="task.name"
            [taskCode]="task.id"
        ></timer-start>
        <timer-stop
            class="c-ts-task__task-stop"
            *ngIf="isOnTimer"
            [userId]="userId"
        ></timer-stop>
    </ng-container>
    <div class="c-ts-task__project-mobile">{{ project.name }}</div>
</div>

<ts-activity
    class="o-ts-row__cell o-ts-row__cell--day"
    [ngClass]="{
        'c-ts-activity--sealed': isSealedActivity(activity),
        'c-ts-activity--holidays': isHolidaysActivity(activity),
        'c-ts-activity--today': dateService.isToday(activity.date) && !isHolidaysActivity(activity),
        'c-ts-activity--holidays-today': dateService.isToday(activity.date) && isHolidaysActivity(activity),
        'o-ts-row__cell--weekend': !dateService.isWeekDay(activity.date)
    }"
    [activity]="activity"
    [task]="task"
    [userId]="userId"
    [activitiesAllowed]="!task.isFake"
    [focusedActivityObservable]="focusedActivityObservable"
    [timerObservable]="timerObservable"
    [timerSeconds]="timerSeconds"
    *ngFor="let activity of task.activities"
    #activityComponent
    (keydown.enter)="navigateRightOf(activityComponent, userSealDate, $event)"
    (keydown.control.enter)="navigateRightOf(activityComponent, userSealDate, $event)"
    (keydown.arrowleft)="navigateLeftOf(activityComponent, userSealDate)"
    (keydown.arrowright)="navigateRightOf(activityComponent, userSealDate)"
    (keydown.tab)="navigateRightOf(activityComponent, userSealDate, $event)"
    (keydown.shift.tab)="navigateLeftOf(activityComponent, userSealDate, $event)"
    (keydown.arrowup)="navigateUpOf(activityComponent)"
    (keydown.arrowdown)="navigateDownOf(activityComponent)"
>
</ts-activity>

<div class="o-ts-row__cell o-ts-row__cell--summary c-ts-task__summary">
    <ng-container *ngIf="!isOnTimer">
        <span *ngIf="!!task.summary">{{ task.summary | minutesViewPipe }}</span>
        <span *ngIf="!task.summary">0:00</span>
    </ng-container>

    <ng-container *ngIf="isOnTimer">
        <span>{{ getSecondsWithTimer() | secondsViewPipe }}</span>
    </ng-container>
</div>
