import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ResetPasswordService } from "services/password/ResetPasswordService";
import { EmailValidator } from "services/validators/EmailValidator";
import { I18NService } from "services/i18n/I18NService";
import { passwordI18N } from "./i18n/password-i18n";

@Component({
    selector: "send-reset-password-email",
    templateUrl: "send-reset-password-email.html",
    host: { "[class.c-send-reset-password-email]": "true" },
})
export class SendResetPasswordEmailComponent implements OnInit {
    public sendLinkForm: FormGroup;
    public emailSent: boolean;
    public i18n;

    constructor(
        private passwordService: ResetPasswordService,
        i18nService: I18NService,
        private formBuilder: FormBuilder
    ) {
        this.i18n = i18nService.extractCurrentTranslation(passwordI18N);
    }

    public ngOnInit() {
        this.sendLinkForm = this.formBuilder.group({
            email: [
                "",
                Validators.compose([
                    Validators.required,
                    EmailValidator.invalid,
                ]),
            ],
        });
    }

    public submit(event) {
        event.preventDefault();
        /* eslint-disable @typescript-eslint/dot-notation */
        const email = this.sendLinkForm.value["email"];
        /* eslint-enable @typescript-eslint/dot-notation */
        this.passwordService
            .sendResetPasswordEmail(email)
            .subscribe(() => (this.emailSent = true));
    }
}
