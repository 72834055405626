const datepicker = {
    ranges: [
        "Dzisiaj",
        "Ten Tydzień",
        "Ten Miesiąc",
        "",
        "Wczoraj",
        "Poprzedni Tydzień",
        "Poprzedni Miesiąc",
        "Poprzedni Rok",
    ],
    datepickerNames: ["Od", "Do"],
    dayInitials: {
        sundayInitial: "niedz.",
        mondayInitial: "pon.",
        tuesdayInitial: "wt.",
        wednesdayInitial: "śr.",
        thursdayInitial: "czw.",
        fridayInitial: "pt.",
        saturdayInitial: "sob.",
    },
};

export const reportFilterI18NPl = {
    currency: "Waluta",
    from: "Od",
    to: "Do",
    keywordInputPlaceholder: "Słowo kluczowe",
    users: "Użytkownicy / Zespoły",
    projects: "Projekty / Grupy",
    projectsSwitch: "Projekty",
    usersSwitch: "Użytkownicy",
    teamsSwitch: "Zespoły",
    teamDisplay: "Zespół: ",
    groupsSwitch: "Grupy",
    groupDisplay: "Grupa: ",
    findUserPlaceholder: "Znajdź użytkownika",
    findUserTeamPlaceholder: "Znajdź użytkownika / zespół",
    findProjectPlaceholder: "Znajdź projekt",
    findProjectGroupPlaceholder: "Znajdź projekt / grupę",
    monthNames: [
        "Styczeń",
        "Luty",
        "Marzec",
        "Kwiecień",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpień",
        "Wrzesień",
        "Październik",
        "Listopad",
        "Grudzień",
    ],
    monthShortNames: [
        "Sty",
        "Lut",
        "Mar",
        "Kwi",
        "Maj",
        "Cze",
        "Lip",
        "Sie",
        "Wrz",
        "Paź",
        "Lis",
        "Gru",
    ],
    datepicker: datepicker,
    yes: "Tak",
    no: "Nie",
    holidays: "Urlopy",
    holidaysIncluded: "Uwzględnij koszt urlopów",
    holidaysExcluded: "Nie uwzględniaj kosztów urlopów",
    noFilters: "Nie wybrano filtrów",
    billability: "Rodzaj",
    billable: "Płatne",
    nonBillable: "Niepłatne",
};
