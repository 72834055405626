<generic-modal
        [showStream]="showModalStream"
        [actionStream]="actionStream"
        [openModalStream]="openModalStream"
        [title]="i18n.createNewCompany"
        [confirmLabel]="i18n.save"
        [okEnabled]="isNameValidAndAvailable"
>
    <div class="signup">
        <form
                class="c-new-company__form"
                [formGroup]="signUpForm"
                accept-charset="UTF-8"
        >
            <div class="u-form-group">
                <div class="c-new-company__form-name">
                    <input
                            class="c-new-company__form-name-input"
                            placeholder="{{ i18n.placeholderName }}"
                            #name
                            [ngClass]="{'c-new-company__form-name-input--error': !isNameValid && !isNameEmpty}"
                            (input)="onInput('companyName')"
                            type="text"
                            formControlName="companyName"
                            [(ngModel)]="companyName"
                            name="companyName"
                            id="companyName"
                            data-id="timepot-name"
                    />
                    <label
                            for="companyName"
                            class="c-new-company__validation"
                            [class.u-hidden]="isNameEmpty || !touched('companyName')"
                    >
                        <span
                                class="c-new-company__validation-msg c-new-company__validation-msg--info"
                                [ngClass]="{'u-hidden': !(isNameAvailable && isNameValid)}"
                        >{{ i18n.nameIsFree }}</span
                        >
                        <span
                                class="c-new-company__validation-msg c-new-company__validation-msg--error"
                                [ngClass]="{'u-hidden': isNameAvailable && isNameValid}"
                        >
                            {{ !isNameValid ? i18n.nameIsInvalid :
                            !isNameAvailable ? i18n.nameIsTaken : "" }}
                        </span>
                    </label>
                </div>
            </div>

            <div
                    *ngIf="!isNameValid && !isNameEmpty"
                    class="c-new-company__form-name-error"
            >
                {{i18n.validNameHint}}
            </div>
        </form>
    </div>
</generic-modal>

