import { tasksI18NDe } from "./tasks-i18n-de";
import { tasksI18NPl } from "./tasks-i18n-pl";

const tasksI18NEn = {
    pageTitle: "Tasks",
    project: "Project",
    task: "Task",
    time: "Time",
    users: "Users",
    updated: "Updated",
    userSeparator: " + ",
    twoPeople(first, second) {
        return first + this.userSeparator + second;
    },
    manyPeople: (count) => count + " people",
    showMore: "Show more",
    negated: (name) => "¬ " + name,
};

export const tasksI18N = {
    de: tasksI18NDe,
    pl: tasksI18NPl,
    en: tasksI18NEn,
};
