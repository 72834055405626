import { Component, Input, NgZone, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { I18NService } from "../../services/i18n/I18NService";
import { googleAuthButtonI18N } from "./i18n/google-auth-button-i18n";

@Component({
    selector: "google-auth-button",
    templateUrl: "google-auth-button.html",
    host: { "[class.c-google-auth-button]": "true" },
})
export class GoogleAuthButtonComponent implements OnInit {
    @Input() public disabled: boolean;
    @Input() public validation: Observable<boolean> = of(false);

    public i18n;
    public isValid = false;

    constructor(private i18nService: I18NService, private zone: NgZone) {
        this.i18n = i18nService.extractCurrentTranslation(googleAuthButtonI18N);
    }

    public ngOnInit() {
        this.validation.subscribe((validation) => (this.isValid = validation));
    }
}
