import { passwordI18NDe } from "./password-i18n-de";
import { passwordI18NPl } from "./password-i18n-pl";
const passwordI18NEn = {
    save: "Save",
    email: "E-mail",
    gotoLogin: "Proceed to login",
    newPassword: "New password",
    passwordHasBeenReset: "Your password has been reset",
    passwordValidationRequirements: `Password must be at least 7 characters long and contain
        at least 1 digit and at least 1 non-digit`,
    resetPassword: "Reset password",
    resetPasswordDescription: `Please type in the email address used to create your Timepot account.
        Timepot will send you a message with a link to reset your password.`,
    resetPasswordEmailSent:
        "An email with a link for reseting password has been sent",
    send: "Send",
    setNewPassword: "Set new password",
    weakPassword: "Weak password",
    invalidPassword: "Invalid password",
};

export const passwordI18N = {
    de: passwordI18NDe,
    en: passwordI18NEn,
    pl: passwordI18NPl,
};
