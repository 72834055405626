export const calendarI18NDe = {
    sundayInitial: "S",
    mondayInitial: "M",
    tuesdayInitial: "D",
    wednesdayInitial: "M",
    thursdayInitial: "D",
    fridayInitial: "F",
    saturdayInitial: "S",
    year: "year",
    month: "month",
    week: "week",
    showHolidays: "Ferien anzeigen",
    hideHolidays: "Ferien ausblenden",
    showSickDays: "Krankentage anzeigen",
    hideSickDays: "Krankentage ausblenden",
    daysShort: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    monthNames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ],
};
