export const tourI18NDe = {
    nextBtn: "Weiter",
    doneBtn: "Ende",
    demoTour: {
        intro: {
            title: "Timepot entdecken",
            content:
                "Zur Einführungstour, um alle Funktionen von Timepot kennenzulernen.",
        },

        logTime: {
            content: "Zeit direkt in der Tabelle erfassen...",
        },

        newTask: {
            content: "Oder eine neue Aufgabe eingeben",
        },

        reports: {
            content: "Daten durch leistungsstarke Berichte analysieren",
        },

        reportFilter: {
            content: "Genau die Daten filtern, die Sie benötigen",
        },

        barChart: {
            content: "Zur Eingrenzung Ihres Filters auf die Tabellen klicken",
        },

        projects: {
            content: "Projekte verwalten",
        },

        holidays: {
            content: "Alle Urlaubstage von jedem in Ihrem Team anzeigen",
        },

        reportVacations: {
            content: "Ihren Urlaub einfach direkt in den Kalender eintragen",
        },

        users: {
            content: "Team verwalten",
        },

        previewUser: {
            content: "Für eine Voransicht der Aktivitäten der Benutzer klicken",
        },

        farewell: {
            title: "Viel Spaß mit Timepot!",
            content: "Aus dem Benutzermenü immer auf diese Hilfe zugreifen",
        },
    },

    onboardingTour2: {
        intro: {
            title: "Timepot entdecken",
            content:
                "Zur Einführungstour, um alle Funktionen von Timepot kennenzulernen.",
        },

        newTask: {
            content: "Projekt auswählen und Aufgabe hinzufügen",
        },

        startTimer: {
            content: "Zeituhr starten",
        },

        logTime: {
            content: "...oder Zeit manuell erfassen",
        },

        projects: {
            content: "Projekte verwalten",
        },

        reports: {
            content: "Daten durch leistungsstarke Berichte analysieren",
        },

        reportFilter: {
            content: "Genau die Daten filtern, die Sie benötigen",
        },

        holidays: {
            content: "Alle Urlaubstage von jedem in Ihrem Team anzeigen",
        },

        reportVacations: {
            content: "Ihren Urlaub einfach direkt in den Kalender eintragen",
        },

        users: {
            content: "Team verwalten",
        },

        previewUser: {
            content: "Für eine Voransicht der Aktivitäten der Benutzer klicken",
        },

        farewell: {
            title: "Viel Spaß mit Timepot!",
            content: "Aus dem Benutzermenü immer auf diese Hilfe zugreifen",
        },
    },
};
