import { Component, Input, OnInit } from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    ReplaySubject,
} from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { Team } from "models/teams/Team";
import { usersI18N } from "../../i18n/users-i18n";
import { UserService } from "services/user/UserService";
import { DateService } from "services/date/DateService";
import { CompanyUserStatus } from "models/companyusers/CompanyUserStatus";
import { TeamService } from "services/teams/TeamService";
import { InvitationService } from "services/invitation/InvitationService";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { PARAMETER_USER } from "../../../timesheetreports/TimesheetReportsComponent";
import { FeatureToggleService } from "services/featuretoggle/FeatureToggleService";
import { Action } from "../../../genericmodal/GenericModalComponent";

function shorten(message: string, cut = 25) {
    return message && message.length > cut
        ? message.substring(0, cut - 1) + "…"
        : message;
}

@Component({
    selector: "user",
    templateUrl: "user.html",
    host: {
        "[class.c-user]": "true",
        "[class.o-list-row--dropdown]": "this.isOpenDropdown",
    },
})
export class UserComponent implements OnInit {
    @Input() public user: CompanyUser;
    @Input() public teamsStream: Observable<Team[]>;
    public actionStream: ReplaySubject<Action> = new ReplaySubject<Action>(1);
    public params: { [name: string]: string } = {};
    public dropdownObservable = new BehaviorSubject<boolean>(false);
    public paymentModalShowStream = new ReplaySubject<boolean>(1);
    public teamModalShowStream = new ReplaySubject<boolean>(1);

    public userTeamsStream = new ReplaySubject<Team[]>(1);
    public isOpenDropdown = false;
    public isCurrentUser;
    public paymentModalVisible = false;
    public editTeamsVisible = false;
    public isAdmin = false;
    public allTeams: Team[] = [];
    public teamsWrapper = {
        isVisible: false,
        label: "",
        teams: [],
        numberOfDisplayedTeams: 4,
        hiddenTeamsCount: 0,
    };

    public i18n;
    private timesheetLink;

    constructor(
        private dateService: DateService,
        private i18nService: I18NService,
        private userService: UserService,
        private teamService: TeamService,
        private invitationService: InvitationService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService,
        public featureToggleService: FeatureToggleService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(usersI18N);
        this.isAdmin = currentCompanyUserViewService.companyUser.admin;
        this.dropdownObservable.subscribe((state) => {
            this.isOpenDropdown = state;
        });
    }

    public ngOnInit(): void {
        this.isCurrentUser =
            this.currentCompanyUserViewService.userId === this.user.id;
        const from = DateService.toISOString(
            this.dateService.getMondayOfWeek(new Date())
        );
        const to = DateService.toISOString(
            this.dateService.getSundayOfWeek(new Date())
        );
        this.timesheetLink = [
            "/auth/ts",
            { userId: this.user.id, from: from, to: to },
        ];
        this.params[PARAMETER_USER] = this.user.id;
        this.paymentModalShowStream.subscribe(
            (v) => (this.paymentModalVisible = v)
        );
        this.teamModalShowStream.subscribe((v) => (this.editTeamsVisible = v));
        this.userTeamsStream.next(this.user.teams);

        this.teamsStream.subscribe((teams) => {
            this.allTeams = teams;
        });
    }

    public setAdmin(): void {
        this.userService.convertToAdmin(this.user.id);
    }

    public setRegular(): void {
        this.userService.convertToRegular(this.user.id);
    }

    public setManager(): void {
        this.userService.convertToManager(this.user.id);
    }

    public setActive(newActivityStatus: boolean): void {
        this.user.status = newActivityStatus
            ? CompanyUserStatus.ACTIVE
            : CompanyUserStatus.INACTIVE;
        this.userService.setCompanyUserStatus(this.user, this.user.status);
    }

    public inviteAgain() {
        this.invitationService.reinvite(this.user);
    }

    public remove() {
        this.invitationService.disinvite(this.user);
    }

    public get self() {
        return this.isCurrentUser;
    }

    public get userName() {
        return this.user.name.indexOf("@") > 0
            ? shorten(this.user.name)
            : this.user.name;
    }

    public get avatar() {
        return this.userService.getAvatarForUser(this.user);
    }

    public get link() {
        return this.timesheetLink;
    }

    public toggleDropdown() {
        this.dropdownObservable.next(true);
    }

    public closeDropdown(event) {
        this.dropdownObservable.next(false);
        event.stopPropagation();
    }

    public showPaymentModal() {
        this.paymentModalShowStream.next(true);
    }

    public showTeamModal() {
        this.teamModalShowStream.next(true);
    }

    public getUserTeams(): string {
        return this.user.teams
            .filter((t) => !t.readOnly)
            .map((team) => team.name)
            .reduce((acc, val) => (acc ? acc + ", " + val : val), "");
    }

    public get showTeams() {
        return this.user.active;
    }

    public countUserTeams(): string {
        const teams = this.user.teams.filter((t) => !t.readOnly);
        const teamsCount = teams.length;

        const numberOfHiddenTeams =
            teamsCount - this.teamsWrapper.numberOfDisplayedTeams > 0
                ? teamsCount - this.teamsWrapper.numberOfDisplayedTeams
                : 0;

        this.teamsWrapper = {
            isVisible: teamsCount > 0,
            label: this.i18n.teamsCountLabel(teamsCount),
            teams: teams,
            numberOfDisplayedTeams: this.teamsWrapper.numberOfDisplayedTeams,
            hiddenTeamsCount: numberOfHiddenTeams,
        };

        return this.user.teams
            .filter((t) => !t.readOnly)
            .map((team) => team.name)
            .reduce((acc, val) => (acc ? acc + ", " + val : val), "");
    }

    public removeFromSelection(team: Team): void {
        const idx = this.user.teams.findIndex((t) => t.code === team.code);
        const selectedTeams = [...this.user.teams];
        selectedTeams.splice(idx, 1);

        const updatedTeams = this.teamService.calculateUpdatedTeamsForUser(
            this.user.id,
            this.user.teams.map((t) => t.code),
            selectedTeams.map((t) => t.code),
            this.allTeams
        );
        combineLatest(
            updatedTeams.map((collbackTeam) =>
                this.teamService.updateTeam(collbackTeam)
            )
        ).subscribe(() => {
            this.user.teams = selectedTeams;
        });
    }

    public get addTeamLabel() {
        if (this.teamsWrapper.teams.length > 0) {
            return this.i18n.edit;
        }
        return this.i18n.addTeam;
    }

    public onDragStart(event: DragEvent, userCode: string, elem: HTMLElement) {
        event.dataTransfer.setData("text/plain", userCode);
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const dt = <any>event.dataTransfer;
        /* eslint-enable  @typescript-eslint/no-explicit-any */
        if (dt.setDragImage) {
            const dragElCopy = <HTMLElement>elem.cloneNode(true);
            dragElCopy.style.transform = "translateX(-2000px)";
            dragElCopy.style.width = "200px";
            dragElCopy.style.padding = "0 6px";
            dragElCopy.style.backgroundColor = "#fdfdfd";
            dragElCopy.style.border = "1px solid #98a8b3";
            dragElCopy.setAttribute("title", "onDragStart");
            document.body.appendChild(dragElCopy);

            dt.setDragImage(dragElCopy, 0, 0);
        }
    }

    public onDragEnd() {
        const body = document.body;
        const dragEl = document.querySelectorAll(".c-user--searched");

        for (let i = 0, n = dragEl.length; i < n; i++) {
            if (dragEl[i].getAttribute("title") === "onDragStart") {
                body.removeChild(dragEl[i]);
            }
        }
    }
}
