import { Component, Input, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as _ from "lodash";
import { TaskReport } from "models/reports/tasks/TaskReport";
import { TasksReport } from "models/reports/tasks/TasksReport";
import { I18NService } from "services/i18n/I18NService";
import { tasksI18N } from "./../i18n/tasks-i18n";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import {
    FilterSetting,
    FilterType,
} from "models/reports/filters/FilterSetting";

@Component({
    selector: "tasks-list",
    templateUrl: "tasks-list.html",
    host: { "[class.c-tasks-list]": "true" },
})
export class TasksListComponent implements OnInit {
    private static BATCH_SIZE = 50;

    @Input() public tasksListObservable: Observable<TasksReport>;
    @Input() public filterSubject: Subject<FilterSettings>;

    public allTasks: TaskReport[];
    public tasks: TaskReport[];
    public lastVisible = TasksListComponent.BATCH_SIZE;
    public i18n;

    private filterSettings: FilterSettings;

    constructor(private i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(tasksI18N);
    }

    public ngOnInit() {
        this.filterSubject.subscribe(
            (filterSettings) => (this.filterSettings = filterSettings)
        );
        this.tasksListObservable.subscribe((tasksReport) => {
            this.allTasks = tasksReport.tasks;
            this.lastVisible = TasksListComponent.BATCH_SIZE;
            this.tasks = this.allTasks.slice(0, this.lastVisible);
        });
    }

    public showMore() {
        this.lastVisible += TasksListComponent.BATCH_SIZE;
        this.tasks = this.allTasks.slice(0, this.lastVisible);
    }

    public hasMore() {
        return (
            this.tasks != null &&
            this.allTasks != null &&
            this.tasks.length < this.allTasks.length
        );
    }

    public removeTask(taskReport: TaskReport) {
        if (this.filterSettings) {
            _.remove(this.tasks, taskReport);
            _.remove(this.allTasks, taskReport);

            this.filterSettings.settings.push(
                new FilterSetting(
                    FilterType.IGNORED,
                    taskReport.id,
                    this.i18n.negated(taskReport.name)
                )
            );
            this.filterSubject.next(this.filterSettings);
        }
    }
}
