<input
    #cancelButton
    type="button"
    class="c-timer-stop__btn c-timer-stop__btn--cancel"
    (click)="cancelTimer()"
    (keydown.enter)="cancelTimer()"
    (keydown.arrowright)="focusButton(submitButton, $event)"
    (keydown.tab)="focusButton(submitButton, $event)"
    (blur)="blur($event)"
/>
<input
    #submitButton
    type="button"
    class="c-timer-stop__btn c-timer-stop__btn--stop"
    (click)="submitTimer()"
    (keydown.enter)="submitTimer()"
    (keydown.arrowleft)="focusButton(cancelButton, $event)"
    (keydown.shift.tab)="focusButton(cancelButton, $event)"
    (blur)="blur($event)"
/>
