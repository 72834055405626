import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

@Component({
    selector: "date-picker-body",
    templateUrl: "date-picker-body.html",
})
export class DatePickerBodyComponent implements OnInit {
    @Input() public preselected: Observable<Date>;
    @Input() public selection: ReplaySubject<Date>;
    @Input() public sealDate: ReplaySubject<Date>;
    @Input() public startRange: ReplaySubject<Date>;
    @Input() public endRange: ReplaySubject<Date>;
    @Output() public mouseUp = new EventEmitter();
    public monthSubject = new ReplaySubject<{ month: number; year: number }>(1);

    public ngOnInit() {
        this.preselected.subscribe((d) => {
            this.monthSubject.next({
                month: d.getMonth(),
                year: d.getFullYear(),
            });
        });
    }

    public handleMouseUp(v) {
        this.mouseUp.next(v);
    }
}
