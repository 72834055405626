export const navbarI18NPl = {
    api: "API",
    introductoryTour: "Pokaż instrukcję",
    timesheet: "Twój tydzień",
    reports: "Raporty",
    finances: "Finanse",
    projects: "Projekty",
    holidays: "Urlopy",
    users: "Użytkownicy",
    daysOff: "Dni wolne",

    companyName: (name) => `Timepot ${name}`,
    createNewCompany: "Utwórz nowy Timepot",
    editProfile: "Edytuj profil",
    settings: "Ustawienia",
    sendUsFeedback: "Podziel się uwagami",
    signOut: "Wyloguj",
};
