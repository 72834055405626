import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Issue, IssueAgent } from "../../TimepotExceptionHandler";
import { ConfigService } from "../config";
import {
    createClientErrorMessage,
    createServerErrorMessage,
    Message,
    MessageService,
} from "../message/MessageService";

@Injectable()
export class MessagingIssueAgent extends IssueAgent {
    constructor(
        private messageService: MessageService,
        private configService: ConfigService
    ) {
        super();
    }

    public override manages(issue: Issue): boolean {
        const error = issue.error;
        return !(
            error instanceof Response &&
            (error.status < 400 || error.status === 401)
        );
    }

    public override tackle(issue: Issue) {
        let message: Message = null;
        const error = issue && issue.error;

        if (error instanceof HttpErrorResponse) {
            message = createServerErrorMessage(error.message);
        } else if (error instanceof Error && this.configService.isProdMode()) {
            message = createClientErrorMessage(error.message);
        }

        if (message) {
            this.messageService.addMessage(message);
        }
    }
}
