import { Injectable } from "@angular/core";
import { CookieService } from "services/cookie/CookieService";

@Injectable()
export class DemoService {
    private static DEMO_COOKIE_NAME = "timepot.demo";

    constructor(private cookieService: CookieService) {}

    public isDemoMode(): boolean {
        return (
            this.cookieService.getCookie(DemoService.DEMO_COOKIE_NAME) !== null
        );
    }
}
