import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { DateService } from "services/date/DateService";
import { UserService } from "services/user/UserService";
import { DateRange } from "models/daterange/DateRange";
import { I18NService } from "services/i18n/I18NService";
import { timesheetI18N } from "../i18n/timesheet-i18n";

@Component({
    selector: "ts-header",
    templateUrl: "ts-header.html",
    host: { "[class.c-ts-header]": "true" },
})
export class TSHeaderComponent implements OnInit {
    public from: Date;
    public to: Date;
    public days: Date[] = [];
    public i18n;
    public headerI18n;
    public animateClass = "";
    private prevSealDayNumber: number = undefined;
    @Input() public userId: string;
    @Input() public currentUserId: string;
    @Input() public dateRangeObservable: Observable<DateRange>;
    @Input() public userDaysOff;
    @Input() public userSealDate: Date;
    @Input() public tasksInTotal: number;

    constructor(
        private dateService: DateService,
        private i18nService: I18NService,
        private userService: UserService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(timesheetI18N);
    }

    public ngOnInit() {
        this.dateRangeObservable.subscribe((dr: DateRange) => {
            this.to = dr.to;
            this.from = dr.from;
            this.refreshHeader();
        });
    }

    public isHoliday(day: Date): boolean {
        const strippedDate = this.dateService.stripTime(day);
        return (
            !!this.userDaysOff &&
            this.userDaysOff[strippedDate.getTime()] === "HOLIDAY"
        );
    }

    public isSickDay(day: Date): boolean {
        const strippedDate = this.dateService.stripTime(day);
        return (
            !!this.userDaysOff &&
            this.userDaysOff[strippedDate.getTime()] === "SICK_LEAVE"
        );
    }

    public isSealedDay(day: Date): boolean {
        return (
            this.userSealDate != null &&
            !this.dateService.isAfter(day, this.userSealDate)
        );
    }

    public moveSealDateLeft() {
        this.moveSealDate(-1);
    }

    public moveSealDateRight() {
        this.moveSealDate(1);
    }

    public getDisabledClass() {
        return this.userId === this.currentUserId
            ? "c-seal-button--disabled"
            : "";
    }

    public getClassForSealDate() {
        if (!this.userSealDate || this.days.length === 0) {
            this.animateClass = "";
            return "c-seal-button--none";
        }
        const stripped = this.dateService.stripTime(this.userSealDate);
        if (this.dateService.isBefore(stripped, this.days[0])) {
            this.animateClass = "";
            return "c-seal-button--before";
        }

        if (
            this.dateService.isAfter(stripped, this.days[this.days.length - 1])
        ) {
            this.animateClass = "";
            return "c-seal-button--after";
        }

        const dayNumber =
            this.days.findIndex((day) =>
                this.dateService.isEqual(day, stripped)
            ) + 1;
        if (this.prevSealDayNumber !== dayNumber) {
            setTimeout(() => {
                this.animateClass = "c-seal-button--animated";
            }, 100);
        }
        this.prevSealDayNumber = dayNumber;
        return `c-seal-button--${dayNumber}`;
    }

    private moveSealDate(days: number) {
        this.userService
            .sealUser(
                this.userId,
                this.dateService.addDays(this.userSealDate, days)
            )
            .subscribe(() => {
                // do nothing
            });
    }

    private refreshHeader() {
        this.days = this.dateService.getDateSpan(this.from, this.to);
    }
}
