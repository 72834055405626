import { Component, Input } from "@angular/core";
import { DayActivityDetail } from "models/reports/userdaytaskactivities/DayActivityDetail";
import { TimeUnitConversionService } from "services/timeunitconversion/TimeUnitConversionService";
import { I18NService } from "services/i18n/I18NService";
import { userBreakdownReportI18N } from "./i18n/userBreakdownReport-i18n";

@Component({
    selector: "user-day-activity-details",
    templateUrl: "user-day-activity-details.html",
    host: { "[class.c-user-day-activity-details]": "true" },
})
export class UserDayActivityDetailsComponent {
    @Input() public details: DayActivityDetail;

    public showDetails = false;
    public i18n;

    constructor(
        public timeUnitConversionService: TimeUnitConversionService,
        private i18nService: I18NService
    ) {
        this.i18n = this.i18nService.extractCurrentTranslation(
            userBreakdownReportI18N
        );
    }

    public toggleDayDetails() {
        this.showDetails = !this.showDetails;
    }
}
