export const settingsI18NDe = {
    pageTitle: "Einstellungen",
    off: "Aus",
    on: "Auf",
    daily: "Täglich",
    weekly: "Wöchentlich",
    monthly: "Monatlich",
    notifications: "Benachrichtigungen",
    sendHoliday: "Urlaubsbenachrichtigungen senden:",
    toTeamManagers: "Für Teamleiter",
    toAdmins: "Admins",
    sendTimesheets: "Senden von Zeiterfassungserinnerungen:",
    timepotSealing: "Timepot Abdichtung:",
    dailyLoggingHours: "Standardmäßige tägliche Protokollierungsstunden:",
    features: "Erweiterte Funktionen",
    holidayHelp:
        "Konfigurieren Sie, wer Benachrichtigungen erhalten soll, wenn ein Teammitglied einen Tag frei hat.",
    timesheetsHelp:
        "Wir können Ihre Benutzerbenachrichtigungen senden, wenn sie weniger Zeit als gewöhnlich erfassen." +
        " Konfigurieren Sie Ihre Erinnerungseinstellungen.",
    sealingHelp:
        "Verhindern Sie, dass Ihre Benutzer ihre Timepots für Daten ändern, die Sie bereits genehmigt haben.",
    apiAccess: "AAPI-Zugang",
    apiKey: "API-Schlüssel:",
    apiKeyHelper:
        "Geben Sie den folgenden Schlüssel in den X-API-Key Header in jeder Anfrage an die API an.",
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
};
