<form
    class="c-profile__form"
    [formGroup]="profileEditForm"
    accept-charset="UTF-8"
    (ngSubmit)="submit(profileEditForm.value)"
>
    <div class="u-form-group">
        <div #avatarPreview></div>
        <input
            type="file"
            #avatarUpload
            (change)="fileChanged($event)"
            id="avatarUpload"
            class="u-hidden"
        />
        <label class="c-profile__upload-label" for="avatarUpload">
            <div class="c-profile__avatar" *ngIf="!croppie">
                <img
                    [src]="getAvatarUrl()"
                    alt=""
                    class="c-profile__avatar-img"
                />
            </div>
            <span class="c-profile__avatar-label"
                >{{ i18n.changeProfilePicture }}</span
            >
        </label>
    </div>

    <div class="u-form-group">
        <input
            class="u-form-control c-profile__form-first-input"
            placeholder="{{ i18n.firstName }}"
            formControlName="firstName"
            name="firstName"
            id="firstName"
            #firstName
            type="text"
            (focus)="onFocus('firstName')"
            (blur)="onBlur()"
            (input)="onInput()"
        />

        <label
            for="firstName"
            class="c-profile__validation"
            *ngIf="!valid('firstName') && touched('firstName')"
        >
            <span
                *ngIf="isEmpty('firstName') && touched('firstName')"
                class="c-profile__validation-msg c-profile__validation-msg--error"
            >
                {{ i18n.required }}
            </span>
        </label>
    </div>

    <div class="u-form-group">
        <input
            class="u-form-control c-profile__form-last-input"
            placeholder="{{ i18n.lastName }}"
            formControlName="lastName"
            name="lastName"
            id="lastName"
            #lastName
            type="text"
            (focus)="onFocus('lastName')"
            (blur)="onBlur()"
            (input)="onInput()"
        />

        <label
            for="lastName"
            class="c-profile__validation"
            *ngIf="!valid('lastName') && touched('lastName')"
        >
            <span
                *ngIf="isEmpty('lastName') && touched('lastName')"
                class="c-profile__validation-msg c-profile__validation-msg--error"
            >
                {{ i18n.required }}
            </span>
        </label>
    </div>

    <div class="u-form-group" *ngIf="!hideButton">
        <input
            class="o-btn o-btn--large o-btn--primary"
            value="{{ i18n.saveChanges }}"
            type="submit"
        />
    </div>

    <div class="c-profile__form-skip-now" *ngIf="canSkip">
        {{ i18n.wantToStart }}<br />
        <a
            data-id="skip-profile-edit"
            (click)="skip()"
            class="c-profile__form-skip-now-link"
            >{{ i18n.skipNow }}</a
        >
    </div>
</form>
