import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const ProjectIncomeAndExpense = z.object({
    projectCode: z.string(),
    projectName: z.string(),
    projectColor: z.string(),
    income: z.number().transform((value) => value / 100),
    expense: z.number().transform((value) => value / 100),
});
