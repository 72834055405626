import { DayActivityDetail } from "./DayActivityDetail";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const UserDayTaskActivities = z.object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string(),
    userCode: z.string(),
    dayActivityDetails: DayActivityDetail.array(),
});

export type UserDayTaskActivities = z.infer<typeof UserDayTaskActivities>;
