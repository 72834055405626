<task-dropdown
    [activateStream]="activateStream"
    [selection]="taskSubject"
    [initialQuery]="null"
    [selectOnSwitch]="false"
    [autocommit]="false"
    [resetAfterCommit]="true"
    [allowAddingNewTasks]="true"
    [needsProject]="false"
    [projectObservable]="projectSubject"
    [resetSearchText]="resetTaskNameSubject"
>
</task-dropdown>
