import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../config";
import { I18NService } from "../i18n/I18NService";
import { Countries } from "../../models/country/Countries";
import { map } from "rxjs/operators";

@Injectable()
export class CountryService {
    constructor(
        private http: HttpClient,
        private i18nService: I18NService,
        private configService: ConfigService
    ) {}

    public listCountries(
        query?: string,
        offset?: number,
        limit?: number
    ): Observable<Countries> {
        return this.http
            .get(this.configService.countriesEndpoint(query, offset, limit))
            .pipe(map((res) => Countries.parse(res)));
    }

    public countCountries(query?: string): Observable<number> {
        return this.http
            .get(this.configService.countriesCountEndpoint(query))
            .pipe(map((res) => (res as number) ?? 0));
    }

    public isEuropean(countryCode: string): Observable<boolean> {
        return this.http
            .get(this.configService.isEuropeanCountryEndpont(countryCode))
            .pipe(map((res) => (res as boolean) ?? false));
    }
}
