<main-header [pageTitle]="i18n.pageTitle"></main-header>

<div class="c-settings__container" *ngIf="settingsReady">
    <section class="c-settings__section">
        <header class="c-settings__section-header">
            {{i18n.notifications}}
        </header>

        <div class="c-settings__position">
            <div class="c-settings__position-label">{{i18n.sendHoliday}}</div>
            <div class="c-settings__position-action">
                <selector
                    [switches]="[
                    {text: i18n.off, label: 'OFF', selected: isHolidayNotificationSelected('OFF')},
                    {text: i18n.toTeamManagers, label: 'SEND_TO_TEAM_MANAGERS', selected: isHolidayNotificationSelected('SEND_TO_TEAM_MANAGERS')},
                    {text: i18n.toAdmins, label: 'SEND_TO_ADMINS', selected: isHolidayNotificationSelected('SEND_TO_ADMINS')}

                ]"
                    (selection)="setHolidayNotification($event)"
                    [inline]="true"
                ></selector>
            </div>
            <div
                class="c-settings__position-help u-tooltip--top u-tooltip--settings"
                [attr.data-tooltip]="i18n.holidayHelp"
            >
                <span>?</span>
            </div>
        </div>
        <div class="c-settings__position">
            <div class="c-settings__position-label">
                {{i18n.sendTimesheets}}
            </div>
            <div class="c-settings__position-action">
                <selector
                    [switches]="[
                    {text: i18n.off, label: 'OFF', selected: isTimesheetRemindersSelected('OFF')},
                    {text: i18n.daily, label: 'DAILY', selected: isTimesheetRemindersSelected('DAILY')},
                    {text: i18n.weekly, label: 'WEEKLY', selected: isTimesheetRemindersSelected('WEEKLY')},
                    {text: i18n.monthly, label: 'MONTHLY', selected: isTimesheetRemindersSelected('MONTHLY')}
                ]"
                    (selection)="setTimesheetReminders($event)"
                    [inline]="true"
                ></selector>
            </div>
            <div
                class="c-settings__position-help u-tooltip--top u-tooltip--settings"
                [attr.data-tooltip]="i18n.timesheetsHelp"
            >
                <span>?</span>
            </div>
        </div>
    </section>

    <section class="c-settings__section">
        <header class="c-settings__section-header">{{i18n.features}}</header>

        <div class="c-settings__position">
            <div class="c-settings__position-label">
                {{i18n.timepotSealing}}
            </div>
            <div class="c-settings__position-action">
                <selector
                    [switches]="[
                    {text: i18n.off, label: 'OFF', selected: isTimepotSealingSelected('OFF')},
                    {text: i18n.on,  label: 'ON',  selected: isTimepotSealingSelected('ON')}
                ]"
                    (selection)="setTimepotSealing($event)"
                    [inline]="true"
                ></selector>
            </div>
            <div
                class="c-settings__position-help u-tooltip--top u-tooltip--settings"
                [attr.data-tooltip]="i18n.sealingHelp"
            >
                <span>?</span>
            </div>
        </div>
    </section>

    <section class="c-settings__section" *ngIf="apiKey">
        <header class="c-settings__section-header">{{i18n.apiAccess}}</header>

        <div class="c-settings__position">
            <div class="c-settings__position-label">{{i18n.apiKey}}</div>
            <div class="c-settings__position-action">{{apiKey}}</div>
            <div
                class="c-settings__position-help u-tooltip--top u-tooltip--settings"
                [attr.data-tooltip]="i18n.apiKeyHelper"
            >
                <span>?</span>
            </div>
        </div>
    </section>
</div>
