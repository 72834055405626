import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { teamDropdownI18n } from "./i18n/team-dropdown-i18n";
import { Team } from "../../../models/teams/Team";
import { I18NService } from "../../../services/i18n/I18NService";

@Component({
    host: { "[class.c-team-dropdown]": "true" },
    selector: "team-dropdown",
    templateUrl: "team-dropdown.html",
})
export class TeamDropdownComponent implements OnInit {
    public items: Team[] = [];
    @Input() public itemStream: Observable<Team[]>;
    @Input() public initialSelectionStream: Observable<Team[]>;
    @Output() public selection = new EventEmitter<Team[]>();

    public selectedTeams = new ReplaySubject<Team[]>();
    public i18n;

    constructor(public i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(teamDropdownI18n);
    }

    public ngOnInit(): void {
        this.itemStream.subscribe((teams) => (this.items = teams));

        this.selectedTeams.subscribe((teams) => this.selection.emit(teams));
    }
}
