export const profileEditI18NPl = {
    editYourProfile: "Zmień swój profil",
    changePicture: "Zmień obraz profilu",
    firstName: "Twoje imię",
    lastName: "Twoje nazwisko",
    required: "Wymagane",
    saveChanges: "Zapisz zmiany",
    uploadPicture: "Wgraj zdjęcie",
    changeProfilePicture: "Zmień zdjęcie profilowe",
    save: "Zapisz",
    wantToStart: "Chcesz już przejść do Timepota?",
    skipNow: "Pomiń",
};
