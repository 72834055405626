import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    ReplaySubject,
    Subject,
} from "rxjs";

import { createProject, Project } from "models/projects/Project";
import { Team } from "models/teams/Team";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { I18NService } from "services/i18n/I18NService";
import { ProjectService } from "services/projects/ProjectService";
import { TeamService } from "services/teams/TeamService";
import { FeatureToggleService } from "services/featuretoggle/FeatureToggleService";
import { projectsI18N } from "../i18n/projects-i18n";
import { PARAMETER_PROJECT } from "../../timesheetreports/TimesheetReportsComponent";

@Component({
    selector: "project-entry",
    templateUrl: "project-entry.html",
    host: {
        "[class.o-list-row--dropdown]": "this.isOpenDropdown",
    },
})
export class ProjectEntryComponent implements OnInit {
    @Input() public project: Project;
    @Input() public set activitiesDuration(activitiesDuration) {
        this.hours = activitiesDuration
            ? Math.floor(activitiesDuration / 60)
            : 0;
        this.minutes = activitiesDuration ? activitiesDuration % 60 : 0;
    }
    @Input() public projectModalStream: Subject<Project>;
    @Input() public link: string;
    @Input() public params: { [name: string]: string } = {};
    @Input() public teamsStream: Observable<Team[]>;
    @Output() public showPricing = new EventEmitter<boolean>();

    public i18n;
    public showTooltipObservable = new BehaviorSubject<boolean>(false);
    public hoveredElement = null;
    public cssClass = "";
    public isManager = false;
    public teamModalShowStream = new ReplaySubject<boolean>(1);
    public allTeams: Team[] = [];
    public editTeamsVisible = false;
    public dropdownObservable = new BehaviorSubject<boolean>(false);
    public isOpenDropdown = false;

    public teamsWrapper = {
        isVisible: false,
        label: "",
        teams: [],
        numberOfDisplayedTeams: 4,
        hiddenTeamsCount: 0,
    };

    private hours = 0;
    private minutes = 0;

    constructor(
        private i18nService: I18NService,
        private projectService: ProjectService,
        private teamService: TeamService,
        private featureToggleService: FeatureToggleService,
        currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectsI18N);
        this.isManager = currentCompanyUserViewService.companyUser.manager;
        this.dropdownObservable.subscribe((state) => {
            this.isOpenDropdown = state;
        });
    }

    public ngOnInit() {
        this.params[PARAMETER_PROJECT] = this.project.id;
        this.cssClass =
            "c-project-entry__color--" + this.project.color.toLowerCase();
        this.teamModalShowStream.subscribe((v) => {
            this.editTeamsVisible = v;
        });

        this.teamsStream.subscribe((teams) => {
            this.allTeams = teams;
        });
    }

    public archive(archive: boolean) {
        const project = createProject({
            ...this.project,
            active: !archive,
            teams: [],
        });
        this.projectService.updateProject(project);
    }

    public showTooltip(text: string, elem: HTMLElement) {
        this.hoveredElement = elem;
        this.showTooltipObservable.next(true);
    }

    public hideTooltip() {
        this.showTooltipObservable.next(false);
    }

    public edit() {
        this.projectModalStream.next(this.project);
    }
    public onShowPricing() {
        this.showPricing.emit(true);
    }

    public countProjectTeams(): string {
        const teams = this.project.teams.filter((t) => !t.readOnly);
        const teamsCount = teams.length;

        const numberOfHiddenTeams =
            teamsCount - this.teamsWrapper.numberOfDisplayedTeams > 0
                ? teamsCount - this.teamsWrapper.numberOfDisplayedTeams
                : 0;

        this.teamsWrapper = {
            isVisible: teamsCount > 0,
            label: this.i18n.teamsCountLabel(teamsCount),
            teams: teams,
            numberOfDisplayedTeams: this.teamsWrapper.numberOfDisplayedTeams,
            hiddenTeamsCount: numberOfHiddenTeams,
        };

        return this.project.teams
            .filter((t) => !t.readOnly)
            .map((team) => team.name)
            .reduce((acc, val) => (acc ? acc + ", " + val : val), "");
    }

    public showTeamModal() {
        this.teamModalShowStream.next(true);
    }

    public removeFromSelection(team: Team): void {
        const idx = this.project.teams.findIndex((t) => t.code === team.code);
        const selectedTeams = [...this.project.teams];
        selectedTeams.splice(idx, 1);

        const updatedTeams = this.teamService.calculateUpdatedTeamsForProject(
            this.project.id,
            this.project.teams.map((t) => t.code),
            selectedTeams.map((t) => t.code),
            this.allTeams
        );
        combineLatest(
            updatedTeams.map((collbackTeam) =>
                this.teamService.updateTeam(collbackTeam)
            )
        ).subscribe(() => {
            this.project.teams = selectedTeams;
        });
    }

    public onDragStart(
        event: DragEvent,
        projectCode: string,
        elem: HTMLElement
    ) {
        event.dataTransfer.setData("text/plain", projectCode);
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const dt = <any>event.dataTransfer;
        /* eslint-enable  @typescript-eslint/no-explicit-any */
        if (dt.setDragImage) {
            const dragElCopy = <HTMLElement>elem.cloneNode(true);
            dragElCopy.style.transform = "translateX(-2000px)";
            dragElCopy.style.width = "200px";
            dragElCopy.style.backgroundColor = "#fdfdfd";
            dragElCopy.style.border = "1px solid #98a8b3";
            dragElCopy.setAttribute("title", "onDragStart");
            document.body.appendChild(dragElCopy);
            dt.setDragImage(dragElCopy, 0, 0);
        }
    }

    public onDragEnd() {
        const body = document.body;
        const dragEl = document.querySelectorAll(".o-project-entry__entry");

        for (let i = 0, n = dragEl.length; i < n; i++) {
            if (dragEl[i].getAttribute("title") === "onDragStart") {
                body.removeChild(dragEl[i]);
            }
        }
    }

    public get addTeamLabel() {
        if (this.teamsWrapper.teams.length > 0) {
            return this.i18n.edit;
        }
        return this.i18n.addTeam;
    }

    public toggleDropdown() {
        this.dropdownObservable.next(true);
    }

    public closeDropdown(event) {
        this.dropdownObservable.next(false);
        event.stopPropagation();
    }
}
