{{start + 1}} - {{start + currentPageLength}} {{i18n.of}} {{currentDataLength}}
<div class="c-pagination__wrapper-button">
    <div
        class="c-pagination__button c-pagination__button--prev"
        [ngClass]="{'c-pagination__button--disabled' : !prevEnabled}"
        (click)="prev()"
    >
        <svg><use [attr.xlink:href]="'images/menu/all.svg#arrow'"></use></svg
        >{{i18n.prev}}
    </div>
    <div
        class="c-pagination__button c-pagination__button--next"
        [ngClass]="{'c-pagination__button--disabled' : !nextEnabled}"
        (click)="next()"
    >
        {{i18n.next}}<svg>
            <use [attr.xlink:href]="'images/menu/all.svg#arrow'"></use>
        </svg>
    </div>
</div>
