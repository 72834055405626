import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import * as _ from "lodash";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import { UserService } from "services/user/UserService";
import { AuthService } from "services/auth/AuthService";
import { CompanyUsers } from "models/companyusers/CompanyUsers";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { I18NService } from "services/i18n/I18NService";
import { reportFilterI18N } from "../i18n/reportfilter-i18n";
import {
    FilterSetting,
    FilterType,
} from "models/reports/filters/FilterSetting";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { TeamService } from "services/teams/TeamService";
import { Item } from "../../timesheet/tsprojectselector/TSProjectSelectorComponent";

@Component({
    selector: "users-filter",
    templateUrl: "users-filter.html",
})
export class UsersFilterComponent implements OnInit {
    @Input() public filterSubject: Subject<FilterSettings>;
    public users: { id: string; name: string; manager: boolean }[] = [];
    public teams: Item[] = [];
    private filterSettings: FilterSettings;
    private currentUser: CompanyUser;
    public selectedItems = new ReplaySubject<{ id: string; name: string }[]>(1);
    public preselected = new ReplaySubject<{ id: string; name: string }[]>(1);

    public i18n;

    constructor(
        private userService: UserService,
        private teamService: TeamService,
        private authService: AuthService,
        private i18nService: I18NService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {
        this.i18n =
            this.i18nService.extractCurrentTranslation(reportFilterI18N);
    }

    public ngOnInit(): void {
        this.currentUser = this.currentCompanyUserViewService.companyUser;
        if (this.currentUser.manager) {
            this.userService
                .getUsersForCompany()
                .subscribe((companyUsers: CompanyUsers) => {
                    this.users = companyUsers.companyUsers.map((u) => {
                        const displayName =
                            u.firstName && u.lastName
                                ? `${u.firstName} ${u.lastName}`
                                : u.email;
                        return {
                            id: u.id,
                            name: displayName,
                            manager: u.manager,
                        };
                    });
                });
        }
        this.teamService.getTeams().subscribe((teams) => {
            this.teams = teams.map(
                ({ code, name }): Item => ({ id: code, name, type: "team" })
            );
        });
        this.filterSubject.subscribe((filterSettings) => {
            this.filterSettings = filterSettings;
            const preselected = _.filter(
                filterSettings.settings,
                (fs) =>
                    fs.filterType === FilterType.USER ||
                    fs.filterType === FilterType.USER_TEAMS
            ).map((fs) => {
                return { id: fs.filterValue, name: fs.filterDisplay };
            });
            this.preselected.next(preselected);
        });
        this.selectedItems.subscribe((selectedItems) => {
            _.remove(
                this.filterSettings.settings,
                (fs) =>
                    fs.filterType === FilterType.USER ||
                    fs.filterType === FilterType.USER_TEAMS
            );
            selectedItems.forEach((item) => {
                this.filterSettings.settings.push(
                    this.buildFilterSetting(item)
                );
            });
            this.filterSubject.next(this.filterSettings);
        });
    }

    public isManager(): boolean {
        return this.currentUser && this.currentUser.manager;
    }

    private buildFilterSetting(item: Item): FilterSetting {
        if (item.type === "team") {
            return new FilterSetting(
                FilterType.USER_TEAMS,
                item.id,
                item.name.startsWith(this.i18n.teamDisplay)
                    ? item.name
                    : this.i18n.teamDisplay + item.name
            );
        }
        return new FilterSetting(FilterType.USER, item.id, item.name);
    }
}
