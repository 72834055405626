import * as _ from "lodash";

import {
    getTSProjectTaskWithId,
    TSProject,
    tsProjectMergeFunctions,
} from "./TSProject";
import { Merger } from "models/timesheet/Merger";
import { DateRange } from "../daterange/DateRange";
import { Timer } from "./Timer";
import { TSTask } from "./TSTask";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const RawTimesheet = z.object({
    projects: TSProject.array(),
    timer: Timer.optional(),
});

export type RawTimesheet = z.infer<typeof RawTimesheet>;

export function createTimesheet(raw: RawTimesheet) {
    const timeSummariesByDay = _.reduce(
        raw.projects,
        (summaries, project) => {
            _.forOwn(project.timeSummariesByDay, (value, key) => {
                summaries[key]
                    ? (summaries[key] += value)
                    : (summaries[key] = value);
            });
            return summaries;
        },
        {}
    );
    return {
        ...raw,
        timeSummariesByDay,
        totalTime: _.reduce(
            _.values(timeSummariesByDay),
            (sum: number, current: number) => sum + current,
            0
        ),
        displayRange: undefined as DateRange,
    };
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const Timesheet = RawTimesheet.transform(createTimesheet);
export type Timesheet = z.infer<typeof Timesheet>;

export function getTimesheetTaskWithId(timesheet: Timesheet, taskId: string) {
    return timesheet.projects.reduce(
        (acc, project) => acc || getTSProjectTaskWithId(project, taskId),
        undefined as TSTask
    );
}
export function timesheetEmpty(): Timesheet {
    return createTimesheet({
        projects: [],
        timer: null,
    });
}

export function timesheetMerge(that: Timesheet, other: Timesheet): Timesheet {
    return {
        ...that,
        ...other,
        projects: Merger.mergeArrays(
            that.projects,
            other.projects,
            tsProjectMergeFunctions
        ),
    };
}
