<year-month-picker-popdown
    [selectedYearMonthSubject]="firstYearMonthChanges"
    [selectedYearMonth]="firstYearMonthSelected"
    [rangeStart]="true"
    class="c-double-year-month-picker__button"
>
</year-month-picker-popdown>
<year-month-picker-popdown
    [selectedYearMonthSubject]="lastYearMonthChanges"
    [selectedYearMonth]="lastYearMonthSelected"
    [rangeStart]="false"
    class="c-double-year-month-picker__button"
>
</year-month-picker-popdown>
