import { Injectable } from "@angular/core";
import { BarChartDataItem } from "models/charts/BarChartDataItem";
import { FilterSetting } from "models/reports/filters/FilterSetting";
import { DateService } from "services/date/DateService";

@Injectable()
export class BarchartDataService {
    constructor(private dateService: DateService) {}
    private aggregate<T>(
        data: T[],
        cmp: (left: T, right: T) => boolean,
        label: (item: T) => string,
        filterSettingFactory: (first: T, last: T) => FilterSetting,
        val: (item: T) => number
    ): BarChartDataItem<FilterSetting>[] {
        if (!data || data.length === 0) {
            return [];
        }
        let i = 0;
        let prev: T = undefined;
        let start: T = data[0];
        let acc = 0;
        const tmp: { first: T; last: T; value: number }[] = [];
        do {
            if (prev && !cmp(prev, data[i])) {
                tmp.push({ first: start, last: prev, value: acc });
                acc = 0;
                start = data[i];
            }
            prev = data[i];
            acc += val(data[i]);
            i++;
        } while (i < data.length);
        tmp.push({ first: start, last: prev, value: acc });
        return tmp.map(
            (elem) =>
                new BarChartDataItem<FilterSetting>(
                    label(elem.first),
                    elem.value,
                    filterSettingFactory(elem.first, elem.last)
                )
        );
    }

    public aggregateBasedOnRange<T>(
        data: T[],
        dateExtractor: (elem: T) => Date,
        valueExtractor: (elem: T) => number,
        filterSettingFactory: (startDate: Date, endDate: Date) => FilterSetting
    ): BarChartDataItem<FilterSetting>[] {
        if (!data || data.length === 0) {
            return [];
        }
        const formatQuarter = (date: Date) => {
            const year = date.getFullYear();
            const q = Math.floor(date.getMonth() / 3) + 1;
            return "Q" + q + " " + year;
        };
        const sameDay = (first: T, second: T) => {
            const d1 = dateExtractor(first);
            const d2 = dateExtractor(second);
            return (
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate()
            );
        };
        const sameMonth = (first: T, second: T) => {
            const d1 = dateExtractor(first);
            const d2 = dateExtractor(second);
            return (
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth()
            );
        };
        const sameQuarter = (first: T, second: T) => {
            const d1 = dateExtractor(first);
            const d2 = dateExtractor(second);
            return (
                d1.getFullYear() === d2.getFullYear() &&
                Math.floor(d1.getMonth() / 3) === Math.floor(d2.getMonth() / 3)
            );
        };
        const startDate = dateExtractor(data[0]);
        const lastDate = dateExtractor(data[data.length - 1]);
        const duration = Math.ceil(
            (lastDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
        );
        if (duration <= 31) {
            //do not group at all
            return this.aggregate(
                data,
                sameDay,
                (item: T) => this.dateService.prettyDate(dateExtractor(item)),
                (first: T, last: T) =>
                    filterSettingFactory(
                        dateExtractor(first),
                        dateExtractor(last)
                    ),
                valueExtractor
            );
        } else if (duration <= 900) {
            //group by month
            return this.aggregate(
                data,
                sameMonth,
                (item: T) =>
                    this.dateService.prettyMonthAndYear(dateExtractor(item)),
                (first: T, last: T) =>
                    filterSettingFactory(
                        this.dateService.getFirstDayOfMonth(
                            dateExtractor(first)
                        ),
                        this.dateService.getLastDayOfMonth(dateExtractor(last))
                    ),
                valueExtractor
            );
        } else {
            //group by quarter
            return this.aggregate(
                data,
                sameQuarter,
                (item: T) => formatQuarter(dateExtractor(item)),
                (first: T, last: T) =>
                    filterSettingFactory(
                        this.dateService.getFirstDayOfMonth(
                            dateExtractor(first)
                        ),
                        this.dateService.getLastDayOfMonth(dateExtractor(last))
                    ),
                valueExtractor
            );
        }
    }
}
