import * as _ from "lodash";

import { TSTask, tsTaskMergeFunctions } from "./TSTask";
import { DateService } from "../../services/date/DateService";
import { MergeFunctions, Merger } from "models/timesheet/Merger";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const RawTSProject = z.object({
    id: z.string(),
    name: z.string(),
    color: z.string(),
    readOnly: z.boolean(),
    source: z.string().optional(),
    tasks: TSTask.array(),
});
export type RawTSProject = z.infer<typeof RawTSProject>;

function getTimeSummariesByDay(raw: RawTSProject): object {
    const activitiesArr = _.flatten(
        _.map(raw.tasks, (task) => task.activities)
    ); // array of all activities in this project
    const actByDays = _.groupBy(activitiesArr, (activity) =>
        DateService.toISOString(activity.date)
    ); // activities grouped by date
    return _.mapValues(actByDays, (activities) => {
        return _.reduce(
            activities,
            (sum, activity) => {
                return sum + (activity.minutes || 0);
            },
            0
        );
    });
}

export function createTSProject(raw: RawTSProject) {
    return {
        ...raw,
        timeSummariesByDay: getTimeSummariesByDay(raw),
    };
}
// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const TSProject = RawTSProject.transform(createTSProject);

export type TSProject = z.infer<typeof TSProject>;

export function getTSProjectTaskWithId(
    project: TSProject,
    taskId: string
): TSTask {
    return project.tasks.find((task) => task.id === taskId);
}

export const tsProjectMergeFunctions: MergeFunctions<TSProject> = {
    match: (a, b) => b && a.id === b.id,
    merge: (a, b) => ({
        ...a,
        ...b,
        tasks: Merger.mergeArrays(a.tasks, b.tasks, tsTaskMergeFunctions),
    }),
};
