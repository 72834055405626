<modal-core>
    <message-header></message-header>
    <div *ngIf="!desktop" class="c-login__header">
        <h1 class="c-login__header-title">{{i18n.logIn}}</h1>
    </div>

    <form
        class="c-login__form"
        [formGroup]="loginForm"
        accept-charset="UTF-8"
        (ngSubmit)="onSubmit(loginForm.value)"
    >
        <div class="u-form-group">
            <google-auth-button></google-auth-button>
        </div>

        <p class="c-login__form-or">{{i18n.or}}</p>

        <div class="u-form-group">
            <input
                id="email"
                data-id="email"
                class="u-form-control"
                placeholder="{{i18n.yourEmail}}"
                (input)="onInput('email')"
                formControlName="email"
                name="email"
                type="text"
            />
            <label
                for="email"
                class="c-signup__validation"
                *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].invalid"
            >
                <span
                    class="c-signup__validation-msg c-signup__validation-msg--error"
                    *ngIf="loginForm.controls['email'].value === ''"
                >
                    {{ i18n.required }}
                </span>
                <span
                    class="c-signup__validation-msg c-signup__validation-msg--error"
                    *ngIf="loginForm.controls['email'].value !== ''"
                >
                    {{ i18n.invalidEmailAddress }}
                </span>
            </label>
        </div>

        <div class="u-form-group">
            <input
                id="password"
                data-id="password"
                class="c-login__form-password-input"
                placeholder="{{i18n.password}}"
                formControlName="password"
                name="password"
                type="{{ passwordVisibility ? 'text' : 'password' }}"
            />
            <span
                class="u-icon u-icon-eye c-login__form-password-icon"
                (click)="togglePasswordVisibility()"
            ></span>
            <label
                for="password"
                class="c-signup__validation"
                *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].invalid"
            >
                <span
                    class="c-signup__validation-msg c-signup__validation-msg--error"
                    *ngIf="loginForm.controls['password'].value === ''"
                >
                    {{ i18n.required }}
                </span>
            </label>
        </div>

        <div
            *ngIf="passwordLoginFailed"
            class="c-login__login-error u-form-group"
        >
            {{i18n.passwordLoginFailed}}
        </div>

        <div class="u-form-group">
            <button
                data-id="login"
                class="o-btn o-btn--large o-btn--primary c-login__form-btn"
                type="submit"
            >
                {{i18n.logIn}}
            </button>
        </div>
    </form>
    <a
        *ngIf="!desktop"
        [routerLink]="['/sendResetPasswordEmail']"
        class="c-login__form-link"
        >{{i18n.forgotPassword}}</a
    >
</modal-core>
