import { settingsI18NPl } from "./settings-i18n-pl";
import { settingsI18NDe } from "./settings-i18n-de";

const settingsI18NEn = {
    pageTitle: "Settings",
    off: "Off",
    on: "On",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    notifications: "Notifications",
    sendHoliday: "Send holiday notifications:",
    toTeamManagers: "To team managers",
    toAdmins: "To admins",
    sendTimesheets: "Send timesheet reminders:",
    timepotSealing: "Timepot sealing:",
    features: "Advanced Features",
    holidayHelp:
        "Configure who should get notifications whenever any team member takes a day off.",
    timesheetsHelp:
        "We can send your users notifications whenever they log less time than usual. Configure your reminder settings.",
    sealingHelp:
        "Prevent your users from modifying their Timepots for dates that you have already approved.",
    apiAccess: "API access",
    apiKey: "API key:",
    apiKeyHelper:
        "Provide the following key in the X-API-Key header in each request to the API.",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
};

export const settingsI18N = {
    de: settingsI18NDe,
    en: settingsI18NEn,
    pl: settingsI18NPl,
};
