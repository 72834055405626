import { Injectable } from "@angular/core";

@Injectable()
export class TimeUnitConversionService {
    //Matches 1h, 1h30m, 1h 30m, 30m
    public static LONG_FORMAT =
        /^(?:\s*([0-9]+)*h){0,1}(?:\s*([0-9]+)m\s*){0,1}$/;
    //Matches 1:00
    public static COLON_SEPARATED_FORMAT = /^\s*([0-9]+):([0-9]{2})\s*$/;
    //Matches any integer, two capturing groups are only for consistency with other formats
    public static RAW_NUMBER_FORMAT = /^\s*(\d+)\s*(){0}$/;

    //Matches from-to period, e.g. 10:12 - 13:15
    public static PERIOD_FORMAT =
        /^\s*([0-9]+[:[0-9]+]?)\s*-\s*([0-9]+[:[0-9]+]?)\s*$/;

    public minutesToFormattedText(minutes: number): string {
        return this.minutesToFormattedTextWithDefault(minutes, "0:00");
    }

    public minutesToFormattedTextWithDefault(
        minutes: number,
        fallbackValue: string
    ): string {
        if (minutes && minutes > 0) {
            return (
                Math.floor(minutes / 60) +
                ":" +
                ("00" + (minutes % 60)).slice(-2)
            );
        }
        return fallbackValue;
    }

    public userInputToMinutes(input: string): number {
        const periodMatch = TimeUnitConversionService.PERIOD_FORMAT.exec(input);
        if (periodMatch) {
            return this.minutesFromPeriod(periodMatch[1], periodMatch[2]);
        }
        //caution: the assumption is that  format has 2 capturing groups
        const parseWithFormat = (format: RegExp): HoursAndMinutes => {
            const match = format.exec(input);
            if (match) {
                return [match[1], match[2]];
            }
        };
        const formatsToTry = [
            TimeUnitConversionService.LONG_FORMAT,
            TimeUnitConversionService.COLON_SEPARATED_FORMAT,
            TimeUnitConversionService.RAW_NUMBER_FORMAT,
        ];

        const hoursAndMinutes: HoursAndMinutes = formatsToTry.reduce(
            (acc, format) => acc || parseWithFormat(format),
            undefined as HoursAndMinutes
        );

        if (hoursAndMinutes) {
            const [hours, minutes] = hoursAndMinutes.map(
                (val) => (val && Number.parseInt(val)) || 0
            );
            return hours * 60 + minutes;
        }
        return Number.NaN;
    }

    public secondsToFormattedTextWithDefault(
        seconds: number,
        fallbackValue: string
    ): string {
        if (seconds && seconds > 0) {
            return (
                this.minutesToFormattedText(Math.floor(seconds / 60)) +
                ":" +
                ("00" + Math.floor(seconds % 60)).slice(-2)
            );
        }
        return fallbackValue;
    }

    private minutesFromPeriod(startHour: string, endHour: string) {
        return Math.max(
            this.hourStringToMinutesOfDay(endHour) -
                this.hourStringToMinutesOfDay(startHour),
            0
        );
    }

    private hourStringToMinutesOfDay(hour: string) {
        const hoursAndMinutes = hour.split(":");
        if (hoursAndMinutes.length >= 2) {
            return (
                Number.parseInt(hoursAndMinutes[0]) * 60 +
                Number.parseInt(hoursAndMinutes[1])
            );
        } else {
            return Number.parseInt(hour) * 60;
        }
    }
}
type HoursAndMinutes = [string, string];
