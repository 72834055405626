<generic-modal
    [showStream]="showStream"
    [actionStream]="actionStream"
    [okEnabled]="ready"
>
    <div class="c-project-teams-modal__header">
        <span class="c-project-teams-modal__header-name">{{project.name}}</span>
    </div>
    <div *ngIf="!ready" class="u-loader u-loader--relative"></div>
    <ng-container *ngIf="ready">
        <div class="c-project-teams-modal__form" *ngIf="selectedTeams.length">
            <span
                class="c-project-teams-modal__team"
                *ngFor="let team of selectedTeams"
            >
                {{team.name}}
                <a
                    class="c-project-teams-modal__team-remove"
                    (click)="removeFromSelection(team)"
                ></a>
            </span>
        </div>
        <team-dropdown
            (selection)="onNewTeams($event)"
            [itemStream]="teamsStream"
            [initialSelectionStream]="projectTeamsStream"
        ></team-dropdown>
    </ng-container>
</generic-modal>
