import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const ProjectActivitiesDuration = z.object({
    code: z.string(),
    projectName: z.string(),
    duration: z.number(),
    projectColor: z.string(),
});

export type ProjectActivitiesDuration = z.infer<
    typeof ProjectActivitiesDuration
>;
