import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as _ from "lodash";
import { BarChartData } from "models/charts/BarChartData";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import { ChartScaleService } from "services/chartscale/ChartScaleService";
import { I18NService } from "services/i18n/I18NService";
import { barchartI18N } from "./i18n/barchart-i18n";

@Component({
    selector: "barchart",
    templateUrl: "bar-chart.html",
    host: { "[class.c-bar-chart]": "true" },
})
export class BarChartComponent<T> implements OnInit {
    public static NUMBER_OF_POSITIVE_SCALE_UNITS = 5;

    @Input() public dataItemsObservable: Observable<BarChartData<T>>;
    @Input() public config: BarChartConfig;
    @Input() public selectedItemStream: Subject<T>;
    @Input() public filterSubject: Subject<FilterSettings>;
    @Input() public displayNegativeValues = true;
    @Input() public visible = true;

    public scaleHeight = 40;
    public containerPadding = 120;

    public i18n;
    public dataItems: {
        label: string;
        axisValue: string;
        tooltipValue: string;
        data: T;
    }[];
    public negativeDataItems: {
        label: string;
        axisValue: string;
        tooltipValue: string;
        data: T;
    }[];
    public positiveScale: number[] = [];
    public negativeScale: number[] = [];
    public total: string;
    public isEmpty: boolean;
    public hoveredItemValue: string;
    public hoveredElement = null;

    private filterSettings: FilterSettings;

    constructor(
        i18nService: I18NService,
        private chartScaleService: ChartScaleService,
        private element: ElementRef
    ) {
        this.i18n = i18nService.extractCurrentTranslation(barchartI18N);
    }

    public ngOnInit() {
        this.filterSubject.subscribe(
            (filterSettings) => (this.filterSettings = filterSettings)
        );
        this.dataItemsObservable.subscribe((barChartData) => {
            const items = barChartData.barChartDataItems;

            const maxScaleValue = Math.max(
                ...items.map((item) => Math.abs(item.value)),
                10
            );

            const normalizedMaxScaleCeiling = Math.ceil(
                this.config.valueNormalizer(maxScaleValue)
            );
            const scaleUnitSize = this.chartScaleService.getScale(
                normalizedMaxScaleCeiling,
                BarChartComponent.NUMBER_OF_POSITIVE_SCALE_UNITS
            );
            const scaleMax =
                scaleUnitSize *
                BarChartComponent.NUMBER_OF_POSITIVE_SCALE_UNITS;

            const minValue = Math.min(
                ...items
                    .filter((item) => item.value < 0)
                    .map((item) => item.value),
                0
            );
            const normalizedMinValueFloor = Math.floor(
                this.config.valueNormalizer(minValue)
            );
            const numberOfNegativeUnits = Math.ceil(
                -normalizedMinValueFloor / scaleUnitSize
            );

            this.dataItems = items.map((item) => {
                return {
                    label: item.label,
                    data: item.data,
                    value: item.value,
                    axisValue:
                        item.value >= 0
                            ? Math.round(
                                  (this.config.valueNormalizer(item.value) /
                                      scaleMax) *
                                      100
                              ) + "%"
                            : Math.round(
                                  (this.config.valueNormalizer(
                                      Math.abs(item.value)
                                  ) /
                                      scaleMax) *
                                      100
                              ) + "%",
                    tooltipValue:
                        item.value >= 0
                            ? this.config.valueFormatter(item.value)
                            : "-" +
                              this.config.valueFormatter(Math.abs(item.value)),
                };
            });

            this.negativeScale = _.range(1, numberOfNegativeUnits + 1).map(
                (fraction) => fraction * scaleUnitSize
            );
            this.positiveScale = _.range(
                1,
                BarChartComponent.NUMBER_OF_POSITIVE_SCALE_UNITS + 1
            )
                .reverse()
                .map((fraction) => fraction * scaleUnitSize);

            this.isEmpty = !this.dataItems.length;
            setTimeout(() =>
                this.element.nativeElement
                    .querySelector("[data-id='barchart']")
                    .classList.add("c-bar-chart__barchart--with-transition")
            );
        });
    }

    public formatScaleValue(value: number) {
        return this.config.scaleFormatter(value);
    }

    public selectItem(item) {
        if (this.selectedItemStream && item.data) {
            this.selectedItemStream.next(item.data);
        }
    }
    public splitLabel(label: string): string[] {
        if (label.lastIndexOf(" ") > 0) {
            const first = label.substring(0, label.lastIndexOf(" ") + 1);
            const second = label.substring(
                label.lastIndexOf(" ") + 1,
                label.length
            );
            return [first, second];
        } else {
            return [label];
        }
    }
}

export class BarChartConfig {
    constructor(
        public scaleFormatter: (value: number) => string,
        public valueNormalizer: (value: number) => number,
        public valueFormatter: (value: number) => string
    ) {}
}
