<main-header [pageTitle]="i18n.pageTitle">
    <a
        [attr.data-tooltip]="i18n.addUsers"
        class="o-add-button u-tooltip--left"
        data-toggle="modal"
        data-target="#invite_users_form"
        (click)="showInviteUsersModal()"
    >
        <svg class="o-add-button__main-icon">
            <use [attr.xlink:href]="'images/menu/all.svg#users'"></use>
        </svg>
        <svg class="o-add-button__plus-icon">
            <use [attr.xlink:href]="'images/menu/all.svg#plus-circle'"></use>
        </svg>
    </a>
</main-header>

<div class="o-layout o-layout__stretch">
    <div class="o-layout__item o-layout__item--teams-menu">
        <teams-menu
            [currentUserStream]="currentUserStream"
            [elementList]="getUserCodes"
            [i18n]="i18n"
            [teamsStream]="teamsStream"
            (selection)="onTeamSelected($event)"
            (drag)="onUserDragged($event)"
            (mode)="onModeChanged($event)"
        >
        </teams-menu>
    </div>
    <div class="o-layout__item">
        <search
            [dataStream]="usersInCurrentTeamStream"
            (match)="onSearchMatched($event)"
            [placeholder]="i18n.findUsers"
        ></search>

        <users-list
            [teamsStream]="teamsStream"
            [usersStream]="filteredUsersStream"
            [footer]="i18n.total"
        ></users-list>
    </div>
</div>
<invite-users-modal [showStream]="showInviteUsersStream"></invite-users-modal>
