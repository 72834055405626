import { Injectable } from "@angular/core";
import { fromEvent, Observable } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable()
export class ClickService {
    private clickStream: Observable<Event>;

    public constructor() {
        this.clickStream = <Observable<Event>>fromEvent(document, "click");
    }

    public getOutsideNodeClicksStream(node: Node) {
        return this.clickStream.pipe(
            filter((click) => this.isClickOutsideNode(click, node))
        );
    }

    private isClickOutsideNode(click: Event, node: Node) {
        return !node.contains(<Node>click.target);
    }
}
