import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { concat, Observable, ReplaySubject, Subject } from "rxjs";
import * as _ from "lodash";
import { ConfigService } from "../config";
import { ClientIdService } from "../clientid/ClientIdService";
import { WebSocketNotificationService } from "../websocketnotification/WebSocketNotificationService";
import { DateService } from "../date/DateService";
import { createTimesheet, Timesheet } from "../../models/timesheet/Timesheet";
import { createTSProject } from "../../models/timesheet/TSProject";
import { TSActivity } from "../../models/timesheet/TSActivity";
import { Tasks } from "../../models/timesheet/Tasks";
import { Task } from "../../models/timesheet/Task";
import { FullTask } from "../../models/timesheet/FullTask";
import { DateRange } from "../../models/daterange/DateRange";
import { TimeZoneService } from "../timezone/TimeZoneService";
import { TimesheetChangeNotification } from "../../models/notification/TimesheetChangeNotification";
import { UserDayTaskActivities } from "../../models/reports/userdaytaskactivities/UserDayTaskActivites";
import { FilterSettings } from "../../models/reports/filters/FilterSettings";
import { FilterSettingsQueryHelper } from "../report/FilterSettingsQueryHelper";
import {
    filter,
    flatMap,
    map,
    merge,
    publishReplay,
    refCount,
    tap,
} from "rxjs/operators";
import { tsTaskPadded } from "models/timesheet/TSTask";

@Injectable()
export class TimesheetService {
    private localRefreshStream = new Subject<RefreshToken>();
    private currentDateRangeStream = new ReplaySubject<DateRange>(1);
    private refreshStream: Observable<RefreshToken>;
    private lastUserId: string;

    private taskCreationsInProgress: Map<string, Observable<Task>> = new Map<
        string,
        Observable<Task>
    >();

    constructor(
        private http: HttpClient,
        private dateService: DateService,
        private filterSettingQueryHelper: FilterSettingsQueryHelper,
        private webSocketNotificationService: WebSocketNotificationService,
        private clientIdService: ClientIdService,
        private timeZoneService: TimeZoneService,
        private configService: ConfigService
    ) {}

    private bootstrapRefreshStream(userId: string) {
        const topic = this.configService.activitiesTopic(userId);
        const activityChangedNotificationStream: Observable<TimesheetChangeNotification> =
            this.webSocketNotificationService
                .getNotificationStreamForTopic(topic)
                .pipe(
                    map((notification: object) =>
                        TimesheetChangeNotification.parse(notification)
                    )
                );

        const remoteRefreshStream = activityChangedNotificationStream.pipe(
            filter(
                (activityChange) =>
                    activityChange.clientId !==
                    this.clientIdService.getClientId()
            ),
            map(() => RefreshToken.REFRESH)
        );
        this.refreshStream = this.localRefreshStream.pipe(
            merge(remoteRefreshStream)
        );
    }

    private getPreviousTasksMargin(
        dateRange: DateRange,
        previousTasksMarginDays: number
    ): Date {
        if (!previousTasksMarginDays) {
            return dateRange.from;
        }

        const currentDate = new Date();
        const [from, to] = [dateRange.from, dateRange.to];

        if (this.dateService.isBefore(to, currentDate)) {
            return from;
        } else if (this.dateService.isAfter(from, currentDate)) {
            return this.dateService.addDays(from, -previousTasksMarginDays);
        } else {
            const candiDate = this.dateService.addDays(
                currentDate,
                -previousTasksMarginDays
            );
            return this.dateService.min(from, candiDate);
        }
    }

    public getUserActivities(
        userId: string,
        dateRange: DateRange,
        previousTasksMarginDays = 0
    ): Observable<Timesheet> {
        if (this.lastUserId !== userId) {
            if (!_.isUndefined(this.lastUserId)) {
                this.webSocketNotificationService.unsubscribeTopic(
                    this.configService.activitiesTopic(this.lastUserId)
                );
            }
            this.bootstrapRefreshStream(userId);
            this.lastUserId = userId;
        }

        const previousTasksMargin = this.getPreviousTasksMargin(
            dateRange,
            previousTasksMarginDays
        );
        const dateRangeWithMargin = new DateRange(
            previousTasksMargin,
            dateRange.to
        );

        const getActivitiesObservable = () => {
            return this.http
                .get(
                    this.configService.timesheetEndpoint(
                        userId,
                        dateRangeWithMargin
                    )
                )
                .pipe(
                    map((res) => {
                        const timesheet = Timesheet.parse(res);
                        return this.createPaddedTimesheet(timesheet, dateRange);
                    })
                );
        };
        this.currentDateRangeStream.next(dateRange);
        const refreshedActivities = this.refreshStream.pipe(
            flatMap(getActivitiesObservable)
        );
        return concat(getActivitiesObservable(), refreshedActivities);
    }

    private createPaddedTimesheet(timesheet: Timesheet, dateRange: DateRange) {
        const projects = timesheet.projects.map((project) =>
            createTSProject({
                ...project,
                tasks: project.tasks.map((task) =>
                    tsTaskPadded(task, dateRange)
                ),
            })
        );
        const padded = createTimesheet({ projects, timer: timesheet.timer });
        padded.displayRange = dateRange;
        return padded;
    }

    public addUserActivity(
        userId: string,
        taskId: string,
        date: Date,
        minutes: number
    ): void {
        const activityJSON = {
            date: DateService.toISOString(date),
            minutes: minutes,
            userId: userId,
        };
        this.http
            .post(
                this.configService.taskActivitiesEndpoint(taskId),
                activityJSON
            )
            .subscribe(() =>
                this.localRefreshStream.next(RefreshToken.REFRESH)
            );
    }

    public updateUserActivity(activity: TSActivity, minutes: number): void {
        const activityJSON = { minutes: minutes };
        this.http
            .put(this.configService.activityEndpoint(activity.id), activityJSON)
            .subscribe(() =>
                this.localRefreshStream.next(RefreshToken.REFRESH)
            );
    }

    public deleteUserActivity(activity: TSActivity): void {
        this.http
            .delete(this.configService.activityEndpoint(activity.id))
            .subscribe(() =>
                this.localRefreshStream.next(RefreshToken.REFRESH)
            );
    }

    public createTask(projectId: string, taskName: string): Observable<Task> {
        const creationKey = projectId + taskName;
        const oldObservable = this.taskCreationsInProgress.get(creationKey);

        if (oldObservable) {
            return oldObservable;
        } else {
            const newTaskJSON = { name: taskName };
            const freshObservable = this.http
                .post(
                    this.configService.projectTasksEndpoint(projectId),
                    newTaskJSON
                )
                .pipe(
                    map((res) => Task.parse(res)),
                    tap(() => this.taskCreationsInProgress.delete(creationKey)),
                    publishReplay(1),
                    refCount()
                );
            this.taskCreationsInProgress.set(creationKey, freshObservable);
            return freshObservable;
        }
    }

    public updateTask(
        taskId: string,
        projectId: string,
        taskName: string,
        billable: boolean
    ) {
        const updatedTaskJSON = {
            name: taskName,
            projectId: projectId,
            billable: billable,
        };
        this.http
            .put(this.configService.taskEndpoint(taskId), updatedTaskJSON)
            .subscribe(() =>
                this.localRefreshStream.next(RefreshToken.REFRESH)
            );
    }
    public getTask(taskId: string): Observable<FullTask> {
        return this.http
            .get(this.configService.taskEndpoint(taskId))
            .pipe(map((res) => FullTask.parse(res)));
    }

    public listTasks(
        projectId?: string,
        userId?: string,
        query?: string,
        offset?: number,
        limit?: number
    ): Observable<Tasks> {
        return this.http
            .get(
                this.configService.tasksEndpoint(
                    projectId,
                    userId,
                    query,
                    offset,
                    limit
                )
            )
            .pipe(map((res) => Tasks.parse(res)));
    }

    public countTasks(
        projectId?: string,
        userId?: string,
        query?: string
    ): Observable<number> {
        return this.http
            .get(
                this.configService.tasksCountEndpoint(projectId, userId, query)
            )
            .pipe(map((res) => (res ? (res as number) : 0)));
    }

    public isHolidaysActivity(
        userHolidaysDays: Date[],
        activity: TSActivity
    ): boolean {
        return (
            !!userHolidaysDays &&
            userHolidaysDays.some((holidaysDay) =>
                this.dateService.isEqual(holidaysDay, activity.date)
            )
        );
    }

    public startTimer(
        userId: string,
        projectId: string,
        taskName: string,
        taskId: string
    ): Observable<void> {
        const ret = new ReplaySubject<void>(1);
        const startTimerJSON = {
            projectCode: projectId,
            taskName: taskName,
            taskCode: taskId,
            timeZone: this.timeZoneService.getTimeZone(),
        };
        this.http
            .post(this.configService.timerStartEndpoint(userId), startTimerJSON)
            .subscribe(() => {
                this.localRefreshStream.next(RefreshToken.REFRESH);
                ret.next(null);
            });
        return ret;
    }

    public submitTimer(userId: string): Observable<void> {
        const ret = new ReplaySubject<void>(1);
        const submitTimerJSON = {
            timeZone: this.timeZoneService.getTimeZone(),
        };
        this.http
            .put(this.configService.timerStartEndpoint(userId), submitTimerJSON)
            .subscribe(() => {
                this.localRefreshStream.next(RefreshToken.REFRESH);
                ret.next(null);
            });
        return ret;
    }

    public cancelTimer(userId: string) {
        this.http
            .delete(this.configService.timerStartEndpoint(userId))
            .subscribe(() =>
                this.localRefreshStream.next(RefreshToken.REFRESH)
            );
    }

    public requestRefresh() {
        this.localRefreshStream.next(RefreshToken.REFRESH);
    }

    public getUserDayTaskActivities(
        filterSettings: FilterSettings
    ): Observable<UserDayTaskActivities[]> {
        return this.http
            .get(
                this.configService.userDayTaskActivitiesEndpoint() +
                    this.filterSettingQueryHelper.getQueryString(filterSettings)
            )
            .pipe(map((res) => UserDayTaskActivities.array().parse(res)));
    }

    public getPublicUserDayTaskActivities(
        publicReportId: string
    ): Observable<UserDayTaskActivities[]> {
        return this.http
            .get(
                this.configService.publicUserDayTaskActivitiesEndpoint(
                    publicReportId
                )
            )
            .pipe(map((res) => UserDayTaskActivities.array().parse(res)));
    }
}

enum RefreshToken {
    REFRESH,
}
