import { Component, OnInit } from "@angular/core";
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { usersI18N } from "./i18n/users-i18n";
import { UserService } from "services/user/UserService";
import { TeamService } from "services/teams/TeamService";
import { CurrentCompanyUserService } from "services/currentcompanyuser/CurrentCompanyUserService";
import { CompanyUserStatus } from "models/companyusers/CompanyUserStatus";
import { PredefinedTeamCode } from "../teamsmenu/TeamsMenuComponent";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { Team } from "models/teams/Team";
import { TeamViewMode } from "models/teams/TeamViewMode";
import { CompanyService } from "services/company/CompanyService";
import { CompanySettings } from "models/company/CompanySettings";

@Component({
    selector: "users",
    templateUrl: "users.html",
    host: { "[class.c-users]": "true" },
})
export class UsersComponent implements OnInit {
    public i18n;
    public usersInCurrentTeamStream = new ReplaySubject<CompanyUser[]>(1);
    public filteredUsersStream = new ReplaySubject<CompanyUser[]>(1);
    public teamsStream = new ReplaySubject<Team[]>(1);
    public currentUser: CompanyUser;
    public currentUserStream = new ReplaySubject<CompanyUser>(1);
    public showInviteUsersStream: Subject<boolean> =
        new BehaviorSubject<boolean>(false);
    public currentSettings: CompanySettings;

    private allUsers: CompanyUser[] = [];
    private teams: Team[] = [];
    private mode: TeamViewMode = TeamViewMode.ALL;

    private selectedTeam: {
        code?: string;
        predefinedCode?: PredefinedTeamCode;
    };

    constructor(
        private userService: UserService,
        private i18nService: I18NService,
        private teamService: TeamService,
        private currentCompanyUserService: CurrentCompanyUserService,
        private companyService: CompanyService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(usersI18N);
    }

    public ngOnInit(): void {
        this.selectedTeam = { predefinedCode: PredefinedTeamCode.ACTIVE };
        const us = this.userService.getUsersForCompany(null);
        const ts = this.teamService.getTeams();
        combineLatest([us, ts]).subscribe((data) => {
            const users = data[0];
            const teams = data[1];
            this.allUsers = users.companyUsers;
            this.currentUser = this.allUsers.find(
                (u) => u.id === this.currentCompanyUserService.companyUser.id
            );
            this.teamsStream.next(teams);
            this.teams = teams;
            this.currentUserStream.next(this.currentUser);
            this.onTeamSelected(this.selectedTeam);
        });

        this.companyService
            .getCompanySettings()
            .subscribe((settings: CompanySettings) => {
                this.currentSettings = settings;
            });
    }

    public onTeamSelected(e: {
        code?: string;
        predefinedCode?: PredefinedTeamCode;
    }) {
        this.selectedTeam = e;
        if (e.code) {
            this.usersInCurrentTeamStream.next(
                this.allUsers.filter(
                    (cu) =>
                        cu.status === CompanyUserStatus.ACTIVE &&
                        cu.teams.find((t) => t.code === e.code)
                )
            );
        } else if (e.predefinedCode === PredefinedTeamCode.ACTIVE) {
            this.usersInCurrentTeamStream.next(
                this.allUsers
                    .filter(this.teamModeFilter)
                    .filter((cu) => cu.status === CompanyUserStatus.ACTIVE)
            );
        } else if (e.predefinedCode === PredefinedTeamCode.PENDING) {
            // do not filter by common team in invited and inactive users
            this.usersInCurrentTeamStream.next(
                this.allUsers.filter(
                    (cu) => cu.status === CompanyUserStatus.INVITED
                )
            );
        } else {
            this.usersInCurrentTeamStream.next(
                this.allUsers.filter(
                    (cu) => cu.status === CompanyUserStatus.INACTIVE
                )
            );
        }
    }

    public onUserDragged(e: { elementCode: string; teamCode: string }) {
        const user = this.allUsers.find((cu) => cu.id === e.elementCode);
        if (user) {
            const team = this.teams.find((t) => t.code === e.teamCode);
            if (team) {
                user.teams.push(team);
            }
        }
    }

    public onSearchMatched(users: CompanyUser[]) {
        this.filteredUsersStream.next(users);
    }

    public onModeChanged(mode: TeamViewMode) {
        this.mode = mode;
        if (this.selectedTeam) {
            this.onTeamSelected(this.selectedTeam);
        }
    }

    private teamModeFilter = (cu: CompanyUser) => {
        // the given user has at least one team common with the current user
        if (this.mode === TeamViewMode.MY_TEAMS) {
            return (
                cu.teams.filter(
                    (team) =>
                        !!this.currentUser.teams.find(
                            (t) => t.code === team.code
                        )
                ).length > 0
            );
        }
        return true;
    };

    public showInviteUsersModal(): void {
        this.showInviteUsersStream.next(true);
    }

    public getUserCodes(team: Team) {
        return team.userCodes;
    }
}
