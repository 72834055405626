<generic-modal
    [showStream]="showStream"
    [actionStream]="actionStream"
    [title]="i18n.addHolidayTitle"
    [okEnabled]="isAddEnabled()"
    [confirmLabel]="i18n.add"
>
    <div class="c-add-holidays-modal__form">
        <div
            class="c-add-holidays-modal__form-dropdown"
            *ngIf="isCompanyManager"
        >
            <generic-dropdown
                [items]="users"
                [selection]="selectedUsers"
                [initialSelectionStream]="currUserStream"
                [getItemStyle]="noop"
                [selectMessage]="i18n.usersField"
                [searchPlaceholder]="i18n.findUser"
                class="basic-dropdown"
            >
            </generic-dropdown>
        </div>

        <date-picker-range
            [dateRangeObservable]="selectedDateRangeObservable"
            [dateRangeSubject]="selectedDateRangeObservable"
        ></date-picker-range>

        <div class="u-flex-row c-add-holidays-modal__type-selector">
            <div
                class="u-1/3 u-1/4@sm c-add-holidays-modal__type-selector-label"
            >
                {{i18n.selectType}}
            </div>
            <div class="u-2/3 u-3/4@sm">
                <selector
                    [switches]="[
                    {text: i18n.holidays, label: dayOffTypes.holiday, selected: dayOffType === dayOffTypes.holiday, icon: 'holidays' },
                    {text: i18n.sickDays, label: dayOffTypes.sickLeave, selected: dayOffType === dayOffTypes.sickLeave, icon: 'ambulance' }
                ]"
                    (selection)="switchDayOffType($event)"
                ></selector>
            </div>
        </div>
    </div>
</generic-modal>
