<div class="o-ts-row__cell o-ts-row__cell--name c-ts-header__name-wrapper">
    <a class="c-ts-header__logo" href="#" title="{{i18n.pageTitle}}"></a>
    <ts-week-changer
        class="c-ts-week-changer--prev"
        [userId]="userId"
        [isNextWeekChanger]="false"
        [dateRangeObservable]="dateRangeObservable"
    >
    </ts-week-changer>
</div>

<ts-day-changer
    class="c-ts-day-changer--prev"
    [userId]="userId"
    [isNextDayChanger]="false"
    [dateRangeObservable]="dateRangeObservable"
>
</ts-day-changer>

<day-header
    class="o-ts-row__cell o-ts-row__cell--day"
    [ngClass]="{
            'c-day-header--sealed': isSealedDay(day),
            'c-day-header--holiday': isHoliday(day),
            'c-day-header--sick-day': isSickDay(day),
            'c-day-header--today': dateService.isToday(day),
            'c-day-header--weekend': !dateService.isWeekDay(day)
            }"
    [tasksInTotal]="tasksInTotal"
    [day]="day"
    [userId]="userId"
    [userSealDate]="userSealDate"
    *ngFor="let day of days"
>
</day-header>

<ts-day-changer
    class="c-ts-day-changer--next"
    [userId]="userId"
    [isNextDayChanger]="true"
    [dateRangeObservable]="dateRangeObservable"
>
</ts-day-changer>

<div
    class="o-ts-row__cell o-ts-row__cell--summary c-ts-header__summary-wrapper"
>
    <ts-week-changer
        class="c-ts-week-changer--next"
        [userId]="userId"
        [isNextWeekChanger]="true"
        [dateRangeObservable]="dateRangeObservable"
    >
    </ts-week-changer>
    <ts-week-picker
        [userId]="userId"
        [dateRangeObservable]="dateRangeObservable"
    >
    </ts-week-picker>
</div>

<div
    [ngClass]="getClassForSealDate() + ' ' + animateClass + ' ' + getDisabledClass()"
    class="c-seal-button"
>
    <svg
        class="c-seal-button__navigate c-seal-button__navigate--prev"
        (click)="moveSealDateLeft()"
    >
        <use [attr.xlink:href]="'images/menu/all.svg#arrow'"></use>
    </svg>

    <svg class="c-seal-button__icon">
        <use [attr.xlink:href]="'images/menu/all.svg#seal'"></use>
    </svg>

    <svg class="c-seal-button__navigate" (click)="moveSealDateRight()">
        <use [attr.xlink:href]="'images/menu/all.svg#arrow'"></use>
    </svg>
</div>
