import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { I18NService } from "../../services/i18n/I18NService";
import { DateService } from "../../services/date/DateService";
import { CurrentCompanyUserViewService } from "../../services/currentcompanyuser/CurrentCompanyUserViewService";
import { AuthService } from "../../services/auth/AuthService";
import { ConfigService } from "../../services/config";
import { EmailValidator } from "../../services/validators/EmailValidator";
import { loginI18N } from "./i18n/login-i18n";

@Component({
    selector: "login",
    templateUrl: "login.html",
    host: { "[class.c-login]": "true" },
})
export class LoginComponent implements OnInit {
    public i18n;
    public desktop: boolean;
    public loginForm: FormGroup;
    public passwordVisibility = false;
    public passwordLoginFailed = false;
    public showModalStream = new Subject<boolean>();

    public unknownGoogleAccount = new Subject<boolean>();

    constructor(
        private i18nService: I18NService,
        private dateService: DateService,
        private formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private route: ActivatedRoute,
        private companyUserView: CurrentCompanyUserViewService,
        configService: ConfigService
    ) {
        this.desktop = configService.isDesktop();
        this.i18n = i18nService.extractCurrentTranslation(loginI18N);
        this.loginForm = this.formBuilder.group({
            email: [
                "",
                Validators.compose([
                    Validators.required,
                    EmailValidator.invalid,
                ]),
            ],
            password: ["", Validators.required],
        });
    }

    public ngOnInit() {
        this.authService.currentUser.subscribe(() =>
            this.navigateToCompanyTimesheet()
        );
    }

    public onSubmit(data: LoginData): void {
        if (this.isFormValid()) {
            this.authService
                .signInWithEmail(data.email, data.password)
                .subscribe(
                    () => this.navigateToCompanyTimesheet(),
                    () => (this.passwordLoginFailed = true)
                );
        } else {
            Object.keys(this.loginForm.controls).map((controlName) => {
                this.loginForm.get(controlName).markAsTouched();
            });
        }
    }

    private navigateToCompanyTimesheet(): void {
        const linkParams = this.createTimesheetLink();
        this.router.navigate(linkParams);
    }

    private createTimesheetLink() {
        const companyUser = this.companyUserView.companyUser;

        if (companyUser) {
            const now = new Date();
            const monday = DateService.toISOString(
                this.dateService.getMondayOfWeek(now)
            );
            const sunday = DateService.toISOString(
                this.dateService.getSundayOfWeek(now)
            );
            return [
                "auth",
                "ts",
                { from: monday, to: sunday, userId: companyUser.id },
            ];
        } else {
            return ["auth"];
        }
    }

    public onInput(name: string): void {
        this.getControl(name).markAsUntouched();
    }

    public getControl(name: string): AbstractControl {
        return this.loginForm.controls[name];
    }

    public isFormValid(): boolean {
        this.loginForm.updateValueAndValidity();
        return this.loginForm.valid;
    }

    public togglePasswordVisibility(): void {
        this.passwordVisibility = !this.passwordVisibility;
    }
}

export interface LoginData {
    email: string;
    password: string;
}
