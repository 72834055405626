<div
    class="c-report-filter__form c-report-filter__form--finances reports-filter"
>
    <projects-filter
        class="c-report-filter__form-input c-report-filter__form-input--projects button-search-project popdown-trigger"
        id="button-search-project"
        [filterSubject]="filterSubject"
    >
    </projects-filter>

    <users-filter
        class="c-report-filter__form-input c-report-filter__form-input--users button-search-user popdown-trigger"
        id="button-search-user"
        [filterSubject]="filterSubject"
    >
    </users-filter>
    <currency-picker-filter
        class="c-report-filter__form-input c-report-filter__form-input--users button-search-user popdown-trigger"
        [filterSubject]="filterSubject"
    >
    </currency-picker-filter>
    <holidays-included-filter
        class="c-report-filter__form-input c-report-filter__form-input--users button-search-user popdown-trigger"
        [filterSubject]="filterSubject"
    ></holidays-included-filter>
    <double-year-month-picker
        [filterSubject]="filterSubject"
        class="c-report-filter__form-input c-report-filter__form-input--calendars"
    ></double-year-month-picker>
</div>

<filter-display-field [filterSubject]="filterSubject"></filter-display-field>
