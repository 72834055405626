import { Component, Input, OnChanges } from "@angular/core";
import { DateService } from "services/date/DateService";
import { UserService } from "services/user/UserService";
import { Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { DaysOff } from "models/daysOff/DaysOff";
import { Calendar } from "models/calendar/Calendar";
import { User } from "models/users/User";
import { calendarI18N } from "../i18n/calendar-i18n";

@Component({
    selector: "week-full",
    templateUrl: "week-full.html",
    host: {
        "[class.c-month-full]": "true",
        "[class.c-month-full--week-view]": "true",
        "[class.c-month-full--hovered]": "this.hoveredUser",
    },
})
export class WeekFullComponent implements OnChanges {
    @Input() public month: number; // 0-based
    @Input() public year: number;
    @Input() public monday: Date;
    @Input() public daysOff: DaysOff;
    @Input() public currentUser: User;
    @Input() public calendarStateObservable: Subject<Calendar>;

    public dayClassNames = [
        "c-month-full__day--mon",
        "c-month-full__day--tue",
        "c-month-full__day--wed",
        "c-month-full__day--thu",
        "c-month-full__day--fri",
        "c-month-full__day--sat",
        "c-month-full__day--sun",
    ];

    public selectedWeek = [];
    public hoveredUser: number;
    public i18n;

    constructor(
        private dateService: DateService,
        i18nService: I18NService,
        private userService: UserService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(calendarI18N);
    }
    public ngOnChanges() {
        this.selectedWeek = [];
        this.selectedWeek = this.dateService.getWeek(this.monday);

        if (this.daysOff) {
            this.selectedWeek.forEach((day) => {
                day.absence = this.getAbsence(day);
            });
        }
    }

    public isToday(date: Date) {
        return this.dateService.isToday(date);
    }

    public hover(userId) {
        this.hoveredUser = userId;
    }

    public getAbsence(date: Date) {
        if (!this.daysOff || !this.currentUser) {
            return " ";
        }
        let dayHolidays: { userId: string; begin: Date; end: Date }[] = [];
        for (let i = 0; i < this.daysOff.daysOff.length; i++) {
            const userHolidaysForDay = this.daysOff.daysOff[i].daysOff.filter(
                (holiday) =>
                    this.dateService.isInRange(date, holiday.begin, holiday.end)
            );
            dayHolidays = dayHolidays.concat(
                userHolidaysForDay.map((h) => {
                    return {
                        userId: this.daysOff.daysOff[i].user.id,
                        user: this.daysOff.daysOff[i].user,
                        userAvatar: this.userService.getAvatarForUser(
                            this.daysOff.daysOff[i].user
                        ),
                        currentUser:
                            this.currentUser.id ===
                            this.daysOff.daysOff[i].user.id,
                        begin: h.begin,
                        end: h.end,
                        type: h.type,
                    };
                })
            );
        }

        return dayHolidays;
    }
}
