import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { Action } from "../../genericmodal/GenericModalComponent";
import { TimesheetService } from "services/timesheet/TimesheetService";
import { timesheetI18N } from "../i18n/timesheet-i18n";
import { I18NService } from "services/i18n/I18NService";
import { Project } from "models/projects/Project";
import { Task } from "models/timesheet/Task";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";

@Component({
    selector: "edit-task-modal",
    templateUrl: "edit-task-modal.html",
    host: { "[class.c-edit-task]": "true" },
})
export class EditTaskModalComponent implements OnInit {
    @Input() public showStream: Subject<boolean>;
    @Input() public task: Task;
    @Input() public initialProject: Project;

    public projectSubject: Subject<Project> = new ReplaySubject<Project>(1);
    public initialProjectSubject: Subject<Project> = new ReplaySubject<Project>(
        1
    );
    public actionStream = new Subject<Action>();
    public name: string = null;
    public billable = true;

    private project: Project = null;
    public i18n;
    constructor(
        private i18nService: I18NService,
        private timesheetService: TimesheetService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService
    ) {
        this.projectSubject.subscribe((project: Project) => {
            this.project = project;
        });

        this.i18n = i18nService.extractCurrentTranslation(timesheetI18N);
        this.actionStream.subscribe((action) => {
            if (action === Action.OK) {
                this.save();
            }
        });
    }
    public ngOnInit() {
        this.project = this.initialProject;
        this.name = this.task.name;
        this.initialProjectSubject.next(this.initialProject);
        this.billable = this.task.billable;
    }

    public get user(): CompanyUser {
        return this.currentCompanyUserViewService.companyUser;
    }

    public isValid() {
        return this.name && this.project;
    }
    private save() {
        this.timesheetService.updateTask(
            this.task.id,
            this.project.id,
            this.name,
            this.billable
        );
    }
    public setTaskBillableState(event: string) {
        this.billable = event === "billable";
    }
    public isTaskBillableStateSelected(state): boolean {
        return this.billable === (state === "billable");
    }
}
