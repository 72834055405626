import { teamsMenuI18NDe } from "./teams-menu-i18n-de";
import { teamsMenuI18NPl } from "./teams-menu-i18n-pl";

const teamsMenuI18NEn = {
    allTeams: "All teams",
    myTeams: "My teams",
    addTeam: "Create new team",
    removeTeam: (name: string) => `Remove ${name}`,
    removeTeamNotice: (name: string) =>
        `You are about to delete '${name}' team. Do you want to proceed?`,
};

export const teamsMenuI18N = {
    de: teamsMenuI18NDe,
    pl: teamsMenuI18NPl,
    en: teamsMenuI18NEn,
};
