import { Component, Input } from "@angular/core";
import { UserDayTaskActivities } from "models/reports/userdaytaskactivities/UserDayTaskActivites";
import { userNameFrom } from "models/users/User";

@Component({
    selector: "day-activity-detail",
    templateUrl: "day-activity-detail.html",
    host: { "[class.c-user-day-activity]": "true" },
})
export class DayActivityDetailComponent {
    @Input() public dayActivity: UserDayTaskActivities;

    public userName() {
        return userNameFrom(
            this.dayActivity.firstName,
            this.dayActivity.lastName,
            this.dayActivity.email
        );
    }
}
