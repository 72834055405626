import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import * as _ from "lodash";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import { CurrencyService } from "services/currency/CurrencyService";

import { I18NService } from "services/i18n/I18NService";
import { reportFilterI18N } from "../i18n/reportfilter-i18n";
import {
    FilterSetting,
    FilterType,
} from "models/reports/filters/FilterSetting";

@Component({
    selector: "currency-picker-filter",
    templateUrl: "currency-picker-filter.html",
})
export class CurrencyPickerFilterComponent implements OnInit {
    @Input() public filterSubject: Subject<FilterSettings>;
    private filterSettings: FilterSettings;
    public selectedCurrency = new ReplaySubject<{ id: string; name: string }>(
        1
    );
    public preselected = new ReplaySubject<{ id: string; name: string }[]>(1);
    public currencies: { id: string; name: string }[];
    public i18n;

    constructor(
        private currencyService: CurrencyService,
        private i18nService: I18NService
    ) {
        this.i18n =
            this.i18nService.extractCurrentTranslation(reportFilterI18N);
    }

    public ngOnInit(): void {
        this.currencies = this.currencyService
            .getSupportedCurrencies()
            .map((v) => {
                return { id: v, name: v };
            });

        this.filterSubject.subscribe((filterSettings) => {
            this.filterSettings = filterSettings;
            const preselected = _.filter(
                filterSettings.settings,
                (fs) => fs.filterType === FilterType.CURRENCY
            ).map((fs) => {
                return { id: fs.filterValue, name: fs.filterValue };
            });
            this.preselected.next(preselected);
        });
        this.selectedCurrency.subscribe((currencies) => {
            _.remove(
                this.filterSettings.settings,
                (fs) => fs.filterType === FilterType.CURRENCY
            );
            this.filterSettings.settings.push(
                new FilterSetting(
                    FilterType.CURRENCY,
                    currencies[0].name,
                    this.i18n.currency + ": " + currencies[0].name,
                    true
                )
            );
            this.filterSubject.next(this.filterSettings);
        });
    }
}
