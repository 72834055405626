import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";

const EMAIL_REGEXP = /^[^@\s]+@(?!.*\.\.)[^@\s]+\.[^@\s]*[^@\s.]$/i;

@Injectable()
export class EmailValidator {
    public static invalid(control: FormControl) {
        return EMAIL_REGEXP.test(control.value) ? null : { invalid: true };
    }
}
