<div class="o-ts-row__cell o-ts-row__cell--name c-ts-footer__btn-container">
    <a
        *ngIf="displayService.isMobile()"
        class="c-ts-footer__btn-add-new-project"
        (click)="handleClick($event)"
        [ngClass]="{'c-ts-footer__btn-add-new-project--fixed': fixedBtn}"
        data-id="add-new-task-link"
    ></a>
</div>
<day-footer
    class="o-ts-row__cell o-ts-row__cell--day"
    [timeSummariesByDay]="timeSummariesByDay"
    [day]="day"
    *ngFor="let day of days"
    [timerObservable]="timerObservable"
    [timerSeconds]="timerSeconds"
    [ngClass]="{'c-day-footer--weekend': isWeekend(day)}"
>
</day-footer>
<div class="o-ts-row__cell o-ts-row__cell--summary">
    <ng-container *ngIf="!isOnTimer">
        <div
            *ngIf="totalTime"
            class="c-ts-footer__summary"
            [innerHTML]="totalTime | minutesViewPipe"
        >
            &nbsp;
        </div>
        <div *ngIf="!totalTime" class="c-ts-footer__summary">0:00</div>
    </ng-container>
    <ng-container *ngIf="isOnTimer">
        <div class="c-ts-footer__summary">
            {{getSecondsWithTimer() | secondsViewPipe }}
        </div>
    </ng-container>
</div>
