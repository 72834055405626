<span class="c-usage-indicator__label">{{i18n.usedIn}}</span>
<div class="c-usage-indicator__quantity">
    <span class="c-usage-indicator__quantity-holidays">
        {{usedDays.holidays}}
        <svg><use xlink:href="images/menu/all.svg#holidays"></use></svg>
    </span>
    <span class="c-usage-indicator__quantity-sick">
        {{usedDays.sickLeave}}
        <svg><use xlink:href="images/menu/all.svg#ambulance"></use></svg>
    </span>
</div>
<span class="c-usage-indicator__label">{{i18n.days}} {{year}}</span>
