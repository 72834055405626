import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Logger } from "./Logger";
import { Issue, IssueAgent } from "../../TimepotExceptionHandler";

/*
Global exception interceptor that logs all uncaught exceptions to the logging service
*/
@Injectable()
export class LoggingIssueAgent extends IssueAgent {
    constructor(private log: Logger) {
        super();
    }

    public override manages(): boolean {
        return true;
    }

    public override tackle(issue: Issue) {
        /* eslint-disable @typescript-eslint/dot-notation */
        let message = "";
        let details = "";
        const error = issue.error;
        const stackTrace = issue.stackTrace || error.stack;
        if (error instanceof HttpErrorResponse) {
            if (error.url && error.status !== 200) {
                message =
                    "Call to '" +
                    error.url +
                    "' failed with status " +
                    error.status;
            } else {
                message = "The requested operation could not be completed";
            }

            details = error.message;
        } else if (error instanceof Object) {
            if (Object.prototype.hasOwnProperty.call(error, "message")) {
                message = error["message"];
            }

            if (Object.prototype.hasOwnProperty.call(error, "reason")) {
                details = error["reason"];
            } else if (stackTrace) {
                if (typeof stackTrace === "string") {
                    details = stackTrace;
                } else if (stackTrace instanceof Array) {
                    const stacks = stackTrace.filter((s) => s !== null);

                    if (stacks.length > 0) {
                        details = stacks.join("\n");
                    }
                }
            }
        }

        if (!message) {
            message = error + "";
        }

        this.log.error(message, details, !(error instanceof HttpErrorResponse));
        /* eslint-enable @typescript-eslint/dot-notation */
    }
}
