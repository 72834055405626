<div class="c-project-summary__container">
    <div class="c-project-summary__label">
        {{i18n.totalBalance}}
        <span
            [ngClass]="{'c-project-summary__expenses' : totalBalance < 0, 'c-project-summary__income' : totalBalance >= 0}"
        >
            {{totalBalance | currency: currency:true:'1.0-2'}}
        </span>
    </div>
    <div class="c-project-summary__sublabel">
        {{i18n.income}}
        <span class="c-project-summary__income"
            >{{totalIncome | currency: currency:true:'1.0-2'}}</span
        >
    </div>
    <div class="c-project-summary__sublabel">
        {{i18n.expenses}}
        <span class="c-project-summary__expenses"
            >{{totalExpenses| currency: currency:true:'1.0-2'}}</span
        >
    </div>
</div>
