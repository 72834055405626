<main-header [pageTitle]="i18n.pageTitle">
    <a
        [attr.data-tooltip]="i18n.addHolidays"
        class="o-add-button u-tooltip--left"
        data-toggle="modal"
        data-target="#invite_users_form"
        (click)="openAddHolidayDialog()"
    >
        <svg class="o-add-button__main-icon">
            <use [attr.xlink:href]="'images/menu/all.svg#calendar'"></use>
        </svg>
        <svg class="o-add-button__plus-icon">
            <use [attr.xlink:href]="'images/menu/all.svg#plus-circle'"></use>
        </svg>
    </a>
</main-header>

<calendar
    [daysOffObservable]="daysOffObservable"
    [selectedYearSubject]="selectedYearSubject"
    [currentSelection]="currentSelection"
    [selectedUsersObservable]="selectedUsersSubject"
    (mouseup)="onMouseUp($event)"
    (mousedown)="onMouseDown($event)"
    (mouseover)="onMouseOver($event)"
></calendar>

<calendar-configurator
    [selectedSubject]="selectedUsersSubject"
    [daysOffObservable]="daysOffObservable"
></calendar-configurator>

<add-holidays-modal
    [showStream]="showAddHolidayObservable"
    [selectedDateRangeObservable]="daysOffSelectedDateRange"
></add-holidays-modal>

<tooltip [showStream]="showTooltipObservable" [element]="clickedElement">
    <div
        *ngFor="let user of usersOnHoliday()"
        class="c-holidays__tooltip-username"
    >
        {{user.firstName + " " + user.lastName}}
    </div>

    <a
        *ngIf="!isSelectedRangeHomogeneous() || !isSelectedRangeEndingWithHolidays()"
        (click)="openAddHolidayDialogForSelectedRange()"
        class="c-holidays__tooltip-btn c-holidays__tooltip-btn--add"
    >
        {{i18n.addSelected}}
    </a>

    <a
        *ngIf="!isSelectedRangeHomogeneous() || isSelectedRangeEndingWithHolidays()"
        (click)="deleteHolidaysForSelectedRange()"
        class="c-holidays__tooltip-btn c-holidays__tooltip-btn--delete"
    >
        {{i18n.deleteSelected}}
    </a>

    <a
        *ngIf="isSelectedRangeHomogeneous() && (isSelectedRangeEndingWithHolidays() && !isSelectedRangeWholeHoliday())"
        (click)="deleteWholeHoliday()"
        class="c-holidays__tooltip-btn c-holidays__tooltip-btn--delete"
    >
        {{i18n.deleteWhole}}
    </a>
</tooltip>
