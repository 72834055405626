import { UserRateOverride } from "./UserRateOverride";
import { z } from "zod";
import { TimepotDate } from "models/TimepotDate";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const ProjectPricingSnapshot = z.object({
    amount: z.number().transform((value) => value / 100),
    from: TimepotDate.optional(),
    to: TimepotDate.optional(),
    userOverrides: UserRateOverride.array().optional(),
});

export type ProjectPricingSnapshot = z.infer<typeof ProjectPricingSnapshot>;
