import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const Task = z.object({
    id: z.string(),
    name: z.string(),
    billable: z.boolean(),
    sealed: z.boolean(),
});

export type Task = z.infer<typeof Task>;
