<div class="c-navbar__header">
    <a class="c-navbar__header-btn-menu" (click)="openNavbar()">
        <span class="c-navbar__header-btn-menu-line"></span>
    </a>
</div>

<nav class="c-navbar__nav">
    <user-menu
        [showEditProfileStream]="showEditProfileStream"
        [showNewCompanyModalStream]="showNewCompanyModalStream"
        data-id="user-menu"
    ></user-menu>
    <ul class="c-navbar__nav-menu">
        <li
            class="c-navbar__nav-menu-entry u-tooltip--left"
            (click)="openNavbar(false)"
            [attr.data-tooltip]="i18n.timesheet"
        >
            <menu-entry
                [icon]="'time'"
                [link]="'ts'"
                [label]="i18n.timesheet"
                [params]="{from: currMonday, to: currSunday, userId: userId}"
            ></menu-entry>
        </li>
        <li
            class="c-navbar__nav-menu-entry u-tooltip--left"
            (click)="openNavbar(false)"
            [attr.data-tooltip]="i18n.reports"
        >
            <menu-entry
                [icon]="'reports'"
                [link]="'reports'"
                [label]="i18n.reports"
            ></menu-entry>
        </li>
        <li
            class="c-navbar__nav-menu-entry u-tooltip--left"
            (click)="openNavbar(false)"
            [attr.data-tooltip]="i18n.finances"
            *ngIf="isAdmin"
        >
            <menu-entry
                [icon]="'finances'"
                [link]="'finances'"
                [label]="i18n.finances"
            ></menu-entry>
        </li>
        <li
            *ngIf="isManager"
            class="c-navbar__nav-menu-entry u-tooltip--left"
            (click)="openNavbar(false)"
            [attr.data-tooltip]="i18n.projects"
        >
            <menu-entry
                [icon]="'projects'"
                [link]="'projects'"
                [label]="i18n.projects"
            ></menu-entry>
        </li>
        <li
            class="c-navbar__nav-menu-entry u-tooltip--left"
            (click)="openNavbar(false)"
            [attr.data-tooltip]="i18n.daysOff"
        >
            <menu-entry
                [icon]="'calendar'"
                [link]="'holidays'"
                [label]="i18n.daysOff"
            ></menu-entry>
        </li>
        <li
            *ngIf="isManager"
            class="c-navbar__nav-menu-entry u-tooltip--left"
            (click)="openNavbar(false)"
            [attr.data-tooltip]="i18n.users"
        >
            <menu-entry
                [icon]="'users'"
                [link]="'users'"
                [label]="i18n.users"
            ></menu-entry>
        </li>
    </ul>

    <ul class="c-navbar__nav-menu c-navbar__nav-menu--other">
        <li
            class="c-navbar__nav-menu-entry u-tooltip--left"
            [attr.data-tooltip]="i18n.sendUsFeedback"
        >
            <a (click)="showFeedback()" class="c-navbar__nav-feedback">
                <svg><use xlink:href="images/menu/all.svg#feedback"></use></svg>
                <span class="c-navbar__nav-logout-btn-label"
                    >{{i18n.sendUsFeedback}}</span
                >
            </a>
        </li>
        <li
            *ngIf="isAdmin"
            class="c-navbar__nav-menu-entry u-tooltip--left"
            (click)="openNavbar(false)"
            [attr.data-tooltip]="i18n.settings"
        >
            <menu-entry
                [icon]="'settings'"
                [link]="'settings'"
                [label]="i18n.settings"
            ></menu-entry>
        </li>
    </ul>
</nav>

<div class="c-navbar__overflow" (click)="openNavbar()"></div>

<profile-edit-modal [showStream]="showEditProfileStream"></profile-edit-modal>
<new-company-modal [showStream]="showNewCompanyModalStream"></new-company-modal>
<feedback-modal [showStream]="showFeedbackStream"></feedback-modal>
