import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import {
    FilterSetting,
    FilterType,
} from "models/reports/filters/FilterSetting";
import { I18NService } from "services/i18n/I18NService";
import { reportFilterI18N } from "../i18n/reportfilter-i18n";
import { Token } from "models/token/Token";
import { ResetSearchTextToken } from "components/genericdropdown/GenericDropdownComponent";
import { Project } from "models/projects/Project";
import { Task } from "models/timesheet/Task";
import { ProjectService } from "services/projects/ProjectService";
import { filter } from "rxjs/operators";

@Component({
    selector: "task-filter-field",
    templateUrl: "task-filter-field.html",
    host: { "[class.c-task-filter-field]": "true" },
})
export class TaskFilterFieldComponent implements OnInit {
    public activateStream = new Subject<Token>();
    public resetTaskNameSubject: Subject<ResetSearchTextToken> =
        new Subject<ResetSearchTextToken>();
    public projectSubject: Subject<Project> = new BehaviorSubject(null);
    public taskSubject: Subject<Task> = new ReplaySubject<Task>(1);

    @Input() public filterSubject: Subject<FilterSettings>;
    private filterSettings: FilterSettings;
    private i18n;

    constructor(
        private i18nService: I18NService,
        private projectService: ProjectService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(reportFilterI18N);
    }

    public ngOnInit() {
        // TODO tpot-351: listen to project changes and feed them to task subject (which needs to accept an array of projects)
        this.filterSubject.subscribe((fs) => (this.filterSettings = fs));
        this.taskSubject
            .pipe(filter((task) => task != null))
            .subscribe((task) => {
                this.filterSettings.settings.push(
                    new FilterSetting(FilterType.KEYWORD, task.name)
                );
                this.filterSubject.next(this.filterSettings);
                this.resetTaskNameSubject.next(ResetSearchTextToken.RESET);
                this.taskSubject.next(null);
            });
    }
}
