import { Component, Input, OnInit } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { genericModalI18N } from "./i18n/genericmodal-i18n";
import { KEY_CODE } from "constants/KeyCode";
import { PerfectScrollbarService } from "services/perfectscrollbar/PerfectScrollbarService";

@Component({
    selector: "generic-modal",
    templateUrl: "generic-modal.html",
    host: {
        "[class.c-generic-modal]": "true",
        "(document:keydown)": "onKeyDown($event)",
    },
    animations: [
        trigger("dialog", [
            transition("void => *", [style({ opacity: "0" }), animate(300)]),
            transition("* => void", [animate(0, style({ opacity: "1" }))]),
        ]),
    ],
})
export class GenericModalComponent implements OnInit {
    @Input() public title: string;
    @Input() public subtitle: string;
    @Input() public buttonsVisible = true;
    @Input() public okEnabled: boolean;
    @Input() public showStream: Subject<boolean>;
    @Input() public actionStream: Subject<Action>;
    @Input() public openModalStream: Subject<OpenModalToken>;
    @Input() public confirmLabel: string;
    @Input() public showClose = true;
    @Input() public closeOnOk = true;
    @Input() public visible = false;
    @Input() public closeOnlyByOk = false;
    @Input() public showBusyIndicator: Subject<boolean>;
    @Input() public showReset = false;

    public busyIndicator = false;
    private i18n;

    private static isCtrlEnterPressed(event): boolean {
        return (
            (event.which || event.keyCode) === KEY_CODE.KEY_RETURN &&
            event.ctrlKey
        );
    }

    private static isEscPressed(event): boolean {
        return (event.which || event.keyCode) === KEY_CODE.KEY_ESCAPE;
    }

    constructor(
        i18nService: I18NService,
        private perfectScrollbar: PerfectScrollbarService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(genericModalI18N);
    }

    public ngOnInit() {
        if (this.showStream) {
            this.showStream.subscribe((show) => {
                this.visible = show;
                if (show) {
                    this.perfectScrollbar.destroyScrollbar();
                } else {
                    this.perfectScrollbar.initScrollbar();
                }
            });
        }

        if (this.showBusyIndicator) {
            this.showBusyIndicator.subscribe((show) => {
                this.busyIndicator = show;
            });
        }
    }

    public ok() {
        if (this.okEnabled) {
            this.actionStream.next(Action.OK);
            if (this.closeOnOk) {
                this.showStream.next(false);
            }
        }
    }

    public close() {
        if (!this.closeOnlyByOk) {
            this.showStream.next(false);
            if (this.actionStream) {
                this.actionStream.next(Action.CANCEL);
            }
        }
    }

    public reset() {
        this.actionStream.next(Action.RESET);
    }

    public onKeyDown(event: KeyboardEvent): void {
        if (this.visible && GenericModalComponent.isCtrlEnterPressed(event)) {
            this.ok();
        }
        if (this.visible && GenericModalComponent.isEscPressed(event)) {
            this.close();
        }
    }
}

export enum Action {
    OK,
    CANCEL,
    RESET,
}

export enum OpenModalToken {
    OPEN,
}
