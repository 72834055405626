<div class="u-form-group" [formGroup]="passwordForm">
    <input
        data-id="password"
        class="c-login__form-password-input"
        placeholder="{{ i18n.password }}"
        formControlName="password"
        name="password"
        id="password"
        type="{{ passwordVisibility ? 'text' : 'password' }}"
        (input)="onInput()"
    />
    <span
        class="u-icon u-icon-eye c-login__form-password-icon"
        (click)="togglePasswordVisibility()"
    ></span>

    <label for="password" class="c-signup__validation">
        <span
            class="c-signup__validation-msg c-signup__validation-msg--error"
            *ngIf="!isValidPassword() && !isEmpty() && touched()"
        >
            {{ i18n.weakPassword }}
        </span>
        <span
            class="c-signup__validation-msg c-signup__validation-msg--error"
            *ngIf="isEmpty() && touched()"
        >
            {{ i18n.required }}
        </span>
    </label>
</div>
