import { Directive, Input, OnInit } from "@angular/core";
import { CookieService } from "services/cookie/CookieService";
import { TourService } from "services/tour/TourService";
import { DisplayService } from "services/display/DisplayService";

const COOKIE_LIFE_TIME_IN_YEARS = 10;

@Directive({
    selector: "[runTour]",
})
export class RunTourDirective implements OnInit {
    public static TIMEPOT_TOUR_COOKIE_PREFIX = "timepot.tour.";

    @Input() public runTour: string;

    constructor(
        private cookieService: CookieService,
        private tourService: TourService,
        private displayService: DisplayService
    ) {}

    public ngOnInit() {
        if (!this.displayService.isMobile()) {
            const cookieName = this.getCookieName();
            const cookie = this.cookieService.getCookie(cookieName);
            if (!cookie) {
                this.tourService.runTour(this.runTour, true);
            }
            this.cookieService.setCookie(
                cookieName,
                new Date().toISOString(),
                this.getExpirationDate()
            );
        }
    }

    private getCookieName() {
        return (
            RunTourDirective.TIMEPOT_TOUR_COOKIE_PREFIX +
            this.runTour.split(" ").join("_")
        );
    }

    private getExpirationDate(): Date {
        const expirationDate = new Date();
        expirationDate.setFullYear(
            expirationDate.getFullYear() + COOKIE_LIFE_TIME_IN_YEARS
        );
        return expirationDate;
    }
}
