import { usersI18NDe } from "./users-i18n-de";
import { usersI18NPl } from "./users-i18n-pl";

const usersI18NEn = {
    admin: "Admin",
    pageTitle: "User list",
    deactivatedUsers: "Deactivated users: ",
    active: "Active Users",
    inactive: "Inactive Users",
    pending: "Invited Users",
    inviteUsers: "Invite Users",
    edit: "Edit",
    editUsers: "Edit Users",
    manager: "Manager",
    regular: "Regular",
    sendInvitations: "send invitations",
    viewUsers: "View Users",
    userView: "View",
    userGrantAdmin: "Grant Admin",
    userRevokeAdmin: "Revoke Admin",
    userFinance: "Finance",
    userDeactivate: "Deactivate",
    userActivate: "Reactivate",
    userInviteAgain: "Invite again",
    userRemove: "Remove",
    userFinances: "Finances",
    findUsers: "Find users",
    total: "Total",
    add: "Add",
    addTeam: "Add team",
    addUsers: "Add Users",
    selectCurrency: "Select currency",
    more: "more",
    teamsCountLabel: (count: number) =>
        count === 1 ? "1 team" : `${count} teams`,
    allTeams: "All teams",
    myTeams: "My teams",
};

export const usersI18N = {
    de: usersI18NDe,
    en: usersI18NEn,
    pl: usersI18NPl,
};
