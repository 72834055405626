export const loginI18NPl = {
    logIn: "Zaloguj",
    withGoogle: "z Google",
    or: "lub",
    yourEmail: "Twój adres email",
    password: "Hasło",
    passwordLoginFailed:
        "Logowanie nie powiodło się. Spróbuj ponownie lub zaloguj się z Google",
    forgotPassword: "Nie pamietasz hasła?",
    invalidEmailAddress: "Niepoprawny email",
    required: "Wymagane pole",
    unknownGoogleAccount:
        "Nieznane konto Google. Proszę spróbuj ponownie z innym kontem, bądź zaloguj się emailem i hasłem.",
};
