import { Component, Input } from "@angular/core";

@Component({
    selector: "menu-entry",
    host: { "[class.c-menu-entry]": "true" },
    templateUrl: "menu-entry.html",
})
export class MenuEntryComponent {
    @Input() public link: string;
    @Input() public label: string;
    @Input() public params: { [name: string]: string } = {};
    @Input() public icon: string;
    public get dataId() {
        if (this.label) {
            return "menu-entry-" + this.link.toLowerCase().split(" ").join("-");
        }
        return "";
    }
}
