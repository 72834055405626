import { Injectable } from "@angular/core";
import { Tour } from "services/tour/Tour";
import { TourDefinitions } from "services/tour/TourDefinitions";

export const DEMO_TOUR = "demoTour";

export const ONBOARDING_TOUR = "onboardingTour2";

@Injectable()
export class TimepotTours implements TourDefinitions {
    public definitions: Tour[] = [
        {
            id: DEMO_TOUR,
            closeOnClickOutsideBubble: true,
            jumpToLastStepBeforeClose: false,

            intro: {
                name: "intro",
                global: true,
            },

            steps: [
                {
                    target: "add-task-row .c-ts-activity--today",
                    name: "logTime",
                    placement: "top",
                },

                {
                    target: ".c-ts-footer__btn-add-new-project",
                    name: "newTask",
                    placement: "left",
                },

                {
                    target: "[data-id='menu-entry-reports']",
                    name: "reports",
                    placement: "left",
                    runBeforeStep: () =>
                        (<HTMLLinkElement>(
                            document.querySelector(
                                "[data-id='menu-entry-reports']"
                            )
                        )).click(),
                    waitCondition: () =>
                        !!document.querySelector("report-filter"),
                },

                {
                    target: "report-filter",
                    name: "reportFilter",
                    placement: "top",
                },

                {
                    target: ".c-bar-chart__chart-columns-column-bar",
                    name: "barChart",
                    placement: "right",
                },

                {
                    isAdminOnly: true,
                    target: "[data-id='menu-entry-projects']",
                    name: "projects",
                    placement: "left",
                    runBeforeStep: () =>
                        (<HTMLLinkElement>(
                            document.querySelector(
                                "[data-id='menu-entry-projects']"
                            )
                        )).click(),
                    waitCondition: () =>
                        !!document.querySelector(
                            "[data-id='show-add-project-link']"
                        ),
                },

                {
                    target: "[data-id='menu-entry-holidays']",
                    name: "holidays",
                    placement: "left",
                    runBeforeStep: () =>
                        (<HTMLLinkElement>(
                            document.querySelector(
                                "[data-id='menu-entry-holidays']"
                            )
                        )).click(),
                    waitCondition: () =>
                        !!document.querySelector(".c-holidays__add-btn-image"),
                },

                {
                    target: "#holidays-calendar-year > month:nth-child(3) > div.c-month__weeks > week:nth-child(2)",
                    name: "reportVacations",
                    placement: "top",
                },

                {
                    isAdminOnly: true,
                    target: "[data-id='menu-enewntry-users']",
                    name: "users",
                    placement: "left",
                    runBeforeStep: () =>
                        (<HTMLLinkElement>(
                            document.querySelector(
                                "[data-id='menu-entry-users']"
                            )
                        )).click(),
                    waitCondition: () => !!document.querySelector("users"),
                },

                {
                    isAdminOnly: true,
                    target: ".c-user__view",
                    name: "previewUser",
                    placement: "top",
                },

                {
                    target: "[data-id='user-menu']",
                    name: "farewell",
                    placement: "left",
                    runBeforeStep: () =>
                        (<HTMLLinkElement>(
                            document.querySelector(
                                "[data-id='menu-entry-your-week']"
                            )
                        )).click(),
                    waitCondition: () =>
                        !!document.querySelector(
                            ".c-ts-footer__btn-add-new-project"
                        ),
                },
            ],
        },

        {
            id: ONBOARDING_TOUR,
            closeOnClickOutsideBubble: true,
            jumpToLastStepBeforeClose: false,

            intro: {
                name: "intro",
                global: true,
            },

            steps: [
                {
                    target: "task-dropdown",
                    name: "newTask",
                    placement: "top",
                },

                {
                    target: ".c-add-task-row__btn-start-placeholder",
                    name: "startTimer",
                    placement: "top",
                },

                {
                    target: "add-task-row .c-ts-activity--today",
                    name: "logTime",
                    placement: "top",
                },

                {
                    isAdminOnly: true,
                    target: "[data-id='menu-entry-projects']",
                    name: "projects",
                    placement: "left",
                    runBeforeStep: () =>
                        (<HTMLLinkElement>(
                            document.querySelector(
                                "[data-id='menu-entry-projects']"
                            )
                        )).click(),
                    waitCondition: () =>
                        !!document.querySelector(
                            "[data-id='show-add-project-link']"
                        ),
                },

                {
                    target: "[data-id='menu-entry-reports']",
                    name: "reports",
                    placement: "left",
                    runBeforeStep: () =>
                        (<HTMLLinkElement>(
                            document.querySelector(
                                "[data-id='menu-entry-reports']"
                            )
                        )).click(),
                    waitCondition: () =>
                        !!document.querySelector("report-filter"),
                },
                {
                    target: "report-filter",
                    name: "reportFilter",
                    placement: "top",
                },

                {
                    target: "[data-id='menu-entry-holidays']",
                    name: "holidays",
                    placement: "left",
                    runBeforeStep: () =>
                        (<HTMLLinkElement>(
                            document.querySelector(
                                "[data-id='menu-entry-holidays']"
                            )
                        )).click(),
                    waitCondition: () =>
                        !!document.querySelector(".c-holidays__add-btn-image"),
                },

                {
                    target: "#holidneways-calendar-year > month:nth-child(3) > div.c-month__weeks > week:nth-child(2)",
                    name: "reportVacations",
                    placement: "top",
                },

                {
                    isAdminOnly: true,
                    target: "[data-id='menu-entry-users']",
                    name: "users",
                    placement: "left",
                    runBeforeStep: () =>
                        (<HTMLLinkElement>(
                            document.querySelector(
                                "[data-id='menu-entry-users']"
                            )
                        )).click(),
                    waitCondition: () => !!document.querySelector("users"),
                },

                {
                    isAdminOnly: true,
                    target: ".c-user__view",
                    name: "previewUser",
                    placement: "top",
                },

                {
                    target: "[data-id='user-menu']",
                    name: "farewell",
                    placement: "left",
                    runBeforeStep: () =>
                        (<HTMLLinkElement>(
                            document.querySelector(
                                "[data-id='menu-entry-your-week']"
                            )
                        )).click(),
                    waitCondition: () =>
                        !!document.querySelector(
                            ".c-ts-f@Injectable()ooter__btn-add-new-project"
                        ),
                },
            ],
        },
    ];
}
