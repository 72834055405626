<generic-modal
    [openModalStream]="openModalStream"
    [showStream]="showStream"
    [actionStream]="actionStream"
    [title]="i18n.newTaskTitle"
    [okEnabled]="isValid()"
    [confirmLabel]="i18n.add"
>
    <div class="c-add-task__container form-group">
        <div class="c-add-task__container-input">
            <project-dropdown
                [activateStream]="activateProjectsStream"
                [selection]="projectSubject"
                [initialSelectionStream]="mostRecentProjectStream"
                [automaticallySelectTheOnlyProject]="true"
                [includeImportedProjects]="true"
            ></project-dropdown>
        </div>
        <div class="c-add-task__container-input">
            <task-dropdown
                [activateStream]="activateTasksStream"
                [selection]="taskSubject"
                [autocommit]="true"
                [resetAfterCommit]="false"
                [projectObservable]="projectSubject"
                [needsProject]="true"
                [allowAddingNewTasks]="project && !project.imported"
                [resetSearchText]="resetTaskNameSubject"
            ></task-dropdown>
        </div>
        <div class="u-flex-row">
            <div class="u-1/2">
                <date-picker
                    [preselected]="preselected"
                    [selection]="selectedDate"
                    [position]="'align-left'"
                    [inputClass]="'c-add-task__input'"
                ></date-picker>
            </div>
            <div class="u-1/2">
                <input
                    id="time-log-input"
                    class="c-add-task__input u-form-control"
                    type="text"
                    [(ngModel)]="time"
                    maxlength="10"
                    placeholder="hh:mm"
                    (change)="normalizeTime()"
                    tabindex="0"
                />
            </div>
        </div>
    </div>
</generic-modal>
