export const BACKEND_ERROR_CODES = {
    ACCESS_DENIED: "ACCESS_DENIED",
    CONTENT_TYPE_NOT_SUPPORTED: "CONTENT_TYPE_NOT_SUPPORTED",
    DUPLICATE_ENTRY: "DUPLICATE_ENTRY",
    ILLEGAL_REFERENCE: "ILLEGAL_REFERENCE",
    INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
    MEDIA_TYPE_NOT_ACCEPTABLE: "MEDIA_TYPE_NOT_ACCEPTABLE",
    METHOD_NOT_ALLOWED: "METHOD_NOT_ALLOWED",
    MESSAGE_NOT_READABLE: "MESSAGE_NOT_READABLE",
    MISSING_REQUEST_PARAMETER: "MISSING_REQUEST_PARAMETER",
    NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
    OBJECT_NOT_FOUND: "OBJECT_NOT_FOUND",
    TYPE_MISMATCH: "TYPE_MISMATCH",
    VALIDATION_ERROR: "VALIDATION_ERROR",
    USER_COMPANY_MISMATCH: "USER_COMPANY_MISMATCH",
    MOVE_TASK_BETWEEN_COMPANIES: "MOVE_TASK_BETWEEN_COMPANIES",
};
