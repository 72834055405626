<div class="c-user-day-activity">
    <div class="c-user-day-activity__row">
        <div class="c-user-day-activity__cell c-user-day-activity__cell--first">
            {{i18n.user}}
        </div>
        <div
            class="c-user-day-activity__cell c-user-day-activity__cell--second"
        >
            {{i18n.date}}
        </div>
        <div class="c-user-day-activity__cell c-user-day-activity__cell--third">
            {{i18n.time}}
        </div>
        <div
            class="c-user-day-activity__cell c-user-day-activity__cell--fourth"
        >
            {{i18n.tasks}}
        </div>
    </div>

    <day-activity-detail
        *ngFor="let dayActivity of userDayTaskActivities"
        [dayActivity]="dayActivity"
    ></day-activity-detail>
</div>
