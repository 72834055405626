import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { timesheetReportsI18N } from "./i18n/timesheet-reports-i18n";
import { Action } from "../genericmodal/GenericModalComponent";

@Component({
    selector: "report-share-link-modal",
    templateUrl: "report-share-link.html",
})
export class ReportShareLinkModalComponent {
    @Input() public showLinkStream: Subject<boolean>;
    public actionStream: Subject<Action>;
    @Input() public currentLink: string;

    public i18n;

    constructor(private i18nService: I18NService) {
        this.i18n =
            this.i18nService.extractCurrentTranslation(timesheetReportsI18N);
        this.actionStream = new Subject();
        this.actionStream.subscribe(() => this.copy());
    }

    private copy() {
        const txtArea = document.createElement("textarea");
        txtArea.style.position = "fixed";
        txtArea.style.top = "0";
        txtArea.style.left = "0";
        txtArea.style.opacity = "0";
        txtArea.value = this.currentLink;
        document.body.appendChild(txtArea);
        txtArea.select();
        document.execCommand("copy");
        document.body.removeChild(txtArea);
        this.showLinkStream.next(false);
    }
}
