import { Team } from "../teams/Team";
import { emptyUser, userNameFrom } from "../users/User";
import { CompanyUserStatus } from "./CompanyUserStatus";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const RawCompanyUser = z.object({
    companyName: z.string(),
    companyId: z.number(),
    status: z.string().transform((s) => CompanyUserStatus[s]),
    role: z.string(),
    teams: Team.array().transform((teams) => teams.filter((t) => !t.readOnly)),
    id: z.string(),
    email: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    avatarUrl: z.string().optional(),
    askForDetails: z.boolean().default(false),
    avatarUploaded: z.boolean(),
});
export type RawCompanyUser = z.infer<typeof RawCompanyUser>;

const createCompanyUser = (user: RawCompanyUser) => ({
    ...user,
    userId: user.id,
    invited: CompanyUserStatus.INVITED === user.status,
    active: CompanyUserStatus.ACTIVE === user.status,
    inactive: CompanyUserStatus.INACTIVE === user.status,
    name: userNameFrom(user.firstName, user.lastName, user.email),
    admin: user.role === "ADMIN",
    manager: user.role === "MANAGER" || user.role === "ADMIN",
});
// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const CompanyUser = RawCompanyUser.transform(createCompanyUser);

export type CompanyUser = z.infer<typeof CompanyUser>;
export function emptyCompanyUser(): CompanyUser {
    return createCompanyUser({
        ...emptyUser(),
        companyName: "",
        status: CompanyUserStatus.INACTIVE.toString(),
        role: "REGULAR",
        teams: [],
    });
}
