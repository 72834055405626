import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Issue, IssueAgent } from "../../TimepotExceptionHandler";

@Injectable()
export class UnauthenticatedIssueAgent extends IssueAgent {
    constructor(private router: Router) {
        super();
    }

    public override manages(issue: Issue): boolean {
        return (
            issue.error instanceof HttpErrorResponse &&
            issue.error.status === 401
        );
    }

    public override tackle() {
        this.router.navigate(["/login"]);
    }
}
