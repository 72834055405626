import { Component, ElementRef, Input } from "@angular/core";
import { Observable } from "rxjs";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { Team } from "models/teams/Team";

@Component({
    selector: "users-list",
    templateUrl: "user-list.html",
    host: { "[class.c-user-list]": "true" },
})
export class UserListComponent {
    @Input() public usersStream: Observable<CompanyUser[]>;
    @Input() public header;
    @Input() public footer;
    @Input() public teamsStream: Observable<Team[]>;

    public page: CompanyUser[] = [];

    constructor(private elementRef: ElementRef) {}

    public onPageChanged(page: CompanyUser[]) {
        this.page = page;
    }
}
