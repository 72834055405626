import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const TimesheetChangeNotification = z.object({
    clientId: z.string().optional(),
});

export type TimesheetChangeNotification = z.infer<
    typeof TimesheetChangeNotification
>;
