import { projectDropdownI18nDe } from "./project-dropdown-i18n-de";
import { projectDropdownI18nPl } from "./project-dropdown-i18n-pl";
const projectDropdownI18nEn = {
    projects: "Projects",
    findProjectPlaceholder: "Find Project",
};

export const projectDropdownI18n = {
    de: projectDropdownI18nDe,
    pl: projectDropdownI18nPl,
    en: projectDropdownI18nEn,
};
