<div
    class="o-list-row"
    [attr.draggable]="user.active"
    (dragstart)="onDragStart($event, user.id, elem)"
    (dragend)="onDragEnd()"
>
    <div #elem class="o-list-row__dragable">
        <div class="o-list-row__cell">
            <div
                class="o-list-row__photo"
                [ngClass]="{'o-list-row__photo--custom': !user.invited}"
            >
                <img
                    class="o-list-row__photo-image"
                    src="{{avatar}}"
                    *ngIf="!user.invited"
                    alt=""
                />
            </div>
        </div>

        <a
            [routerLink]="link"
            *ngIf="user.active"
            class="o-list-row__cell o-list-row__cell--name o-list-row__cell--hover"
        >
            <span>{{userName}}</span></a
        >
        <div
            *ngIf="!user.active"
            class="o-list-row__cell o-list-row__cell--name"
        >
            <span>{{userName}}</span>
        </div>
    </div>

    <div class="o-list-row__cell o-list-row__cell--links">
        <div
            class="c-user__switch-roles"
            *ngIf="user.active && isAdmin"
            [ngClass]="{'c-user__switch-roles--current': isCurrentUser, 'c-user__switch-roles--regular': user.role === 'REGULAR'}"
        >
            <ul class="c-user__switch-list">
                <li
                    class="c-user__switch-role"
                    [ngClass]="{'c-user__switch-role--active': user.role === 'ADMIN'}"
                    (click)="setAdmin()"
                >
                    {{i18n.admin}}
                </li>
                <li
                    class="c-user__switch-role"
                    [ngClass]="{'c-user__switch-role--active': user.role === 'MANAGER'}"
                    (click)="setManager()"
                >
                    {{i18n.manager}}
                </li>
                <li
                    class="c-user__switch-role"
                    [ngClass]="{'c-user__switch-role--active': user.role === 'REGULAR'}"
                    (click)="setRegular()"
                >
                    {{i18n.regular}}
                </li>
            </ul>
        </div>
        <a
            *ngIf="user.active && isAdmin"
            (click)="showPaymentModal()"
            class="o-list-row__link u-tooltip--top"
            [attr.data-tooltip]="i18n.userFinances"
        >
            <span class="o-list-row__link-icon">
                <svg><use xlink:href="images/menu/all.svg#finances"></use></svg>
            </span>
            <span class="o-list-row__link-desc">{{i18n.userFinances}}</span>
        </a>
        <a
            *ngIf="user.active && !isCurrentUser"
            (click)="setActive(false)"
            class="o-list-row__link u-tooltip--top"
            [attr.data-tooltip]="i18n.userDeactivate"
        >
            <span class="o-list-row__link-icon">
                <svg><use xlink:href="images/menu/all.svg#archive"></use></svg>
            </span>
            <span class="o-list-row__link-desc">{{i18n.userDeactivate}}</span>
        </a>
        <a
            *ngIf="user.invited"
            (click)="inviteAgain()"
            class="o-list-row__link u-tooltip--top"
            [attr.data-tooltip]="i18n.userInviteAgain"
        >
            <span class="o-list-row__link-icon">
                <svg><use xlink:href="images/menu/all.svg#restore"></use></svg>
            </span>
            <span class="o-list-row__link-desc">{{i18n.userInviteAgain}}</span>
        </a>
        <a
            *ngIf="user.invited"
            (click)="remove()"
            class="o-list-row__link u-tooltip--top"
            [attr.data-tooltip]="i18n.userRemove"
        >
            <span class="o-list-row__link-icon">
                <svg><use xlink:href="images/menu/all.svg#x"></use></svg>
            </span>
            <span class="o-list-row__link-desc">{{i18n.userRemove}}</span>
        </a>
        <a
            *ngIf="user.inactive"
            (click)="setActive(true)"
            class="o-list-row__link u-tooltip--top"
            [attr.data-tooltip]="i18n.userActivate"
        >
            <span class="o-list-row__link-icon">
                <svg><use xlink:href="images/menu/all.svg#restore"></use></svg>
            </span>
            <span class="o-list-row__link-desc">{{i18n.userActivate}}</span>
        </a>
    </div>

    <div class="o-list-row__cell o-list-row__cell--teams" *ngIf="showTeams">
        <div class="o-list-row__teams">
            <div
                class="o-list-row__team"
                [ngClass]="{'o-list-row__team--hidden': i + 1 > teamsWrapper.numberOfDisplayedTeams}"
                *ngFor="let team of teamsWrapper.teams; let i = index"
            >
                {{team.name}}
                <span
                    class="o-list-row__team-remove"
                    (click)="removeFromSelection(team)"
                ></span>
            </div>
            <span
                (click)="showTeamModal()"
                class="o-list-row__teams-more"
                *ngIf="teamsWrapper.hiddenTeamsCount > 0"
                >+{{teamsWrapper.hiddenTeamsCount}} {{i18n.more}}</span
            >
            <span
                *ngIf="allTeams.length > 0"
                (click)="showTeamModal()"
                class="o-list-row__teams-add"
                >{{addTeamLabel}}</span
            >
        </div>
    </div>

    <div
        class="o-list-row__cell o-list-row__cell--info o-list-row__cell--teams-count"
        *ngIf="showTeams && countUserTeams()"
    >
        {{teamsWrapper.label}}
    </div>

    <div
        class="o-list-row__cell o-list-row__cell--info"
        *ngIf="user.active && featureToggleService.shouldDisplayDevOnlyFeature()"
    >
        $40/h
    </div>

    <a
        class="o-list-row__cell o-list-row__cell--raports u-tooltip--top"
        data-tooltip="Reports"
        title=""
        *ngIf="user.active && featureToggleService.shouldDisplayDevOnlyFeature()"
        [routerLink]="['/auth/reports', params]"
    >
        <svg><use xlink:href="images/menu/all.svg#reports"></use></svg>
    </a>

    <a
        class="o-list-row__cell o-list-row__cell--dropdown-mobile"
        title=""
        (click)="toggleDropdown()"
    >
        <svg><use xlink:href="images/menu/all.svg#dropdown"></use></svg>
    </a>
</div>

<div class="o-list-row__overlay" (click)="closeDropdown($event)"></div>

<div *ngIf="paymentModalVisible">
    <user-payment-modal
        [user]="user"
        [showStream]="paymentModalShowStream"
    ></user-payment-modal>
</div>

<div *ngIf="editTeamsVisible">
    <user-teams-modal
        [user]="user"
        [showStream]="teamModalShowStream"
    ></user-teams-modal>
</div>
