import { TaskMinutes } from "./TaskMinutes";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const DayActivityDetail = z.object({
    totalMinutes: z.number(),
    taskMinutes: TaskMinutes.array(),
    date: z.string(),
});

export type DayActivityDetail = z.infer<typeof DayActivityDetail>;
