import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import * as _ from "lodash";
import { DateService } from "services/date/DateService";
import { KEY_CODE } from "constants/KeyCode";

@Component({
    selector: "date-picker",
    templateUrl: "date-picker.html",
    host: { "[class.c-date-picker]": "true" },
})
export class DatePickerComponent implements OnInit {
    @Input() public preselected: Observable<Date>;
    @Input() public selection: ReplaySubject<Date>;
    @Input() public startRange: ReplaySubject<Date>;
    @Input() public endRange: ReplaySubject<Date>;
    @Input() public iconClass: string;
    @Input() public inputClass: string;
    @Input() public position: string;
    @Input() public disabled: boolean;
    public open = false;
    public currentDate: Date = null;
    public monthSubject = new ReplaySubject<{ month: number; year: number }>(1);

    constructor(
        private dateService: DateService,
        private elementRef: ElementRef
    ) {}
    public ngOnInit() {
        this.preselected.subscribe((d) => {
            this.currentDate = d;
            this.monthSubject.next({
                month: d.getMonth(),
                year: d.getFullYear(),
            });
        });
        this.selection.subscribe((d) => {
            this.currentDate = d;
        });
    }
    public showDatePicker() {
        this.open = true;
    }
    public hideDatePicker() {
        this.open = false;
    }
    public get dateString() {
        if (this.currentDate) {
            return DateService.toISOString(this.currentDate);
        } else {
            return "";
        }
    }
    public close(event: Event) {
        const datePicker = this.elementRef.nativeElement.getElementsByClassName(
            "c-date-picker__container"
        )[0];
        if (datePicker.contains(event.target)) {
            return;
        }
        this.hideDatePicker();
    }
    public onInputKeyDown(event: KeyboardEvent) {
        if (event.keyCode === KEY_CODE.KEY_DOWN) {
            if (!this.open) {
                event.stopPropagation();
                this.showDatePicker();
            }

            return false;
        }
    }
    public onKeyDown(event: KeyboardEvent) {
        if (event.keyCode === KEY_CODE.KEY_TAB) {
            this.hideDatePicker();
        } else if (
            event.keyCode === KEY_CODE.KEY_ENTER ||
            event.keyCode === KEY_CODE.KEY_RETURN ||
            event.keyCode === KEY_CODE.KEY_SPACE
        ) {
            const inputElement =
                this.elementRef.nativeElement.getElementsByTagName("input")[0];

            inputElement.focus();
            this.hideDatePicker();
            event.preventDefault();
        } else if (
            this.open &&
            _.indexOf(
                [
                    KEY_CODE.KEY_LEFT,
                    KEY_CODE.KEY_UP,
                    KEY_CODE.KEY_DOWN,
                    KEY_CODE.KEY_RIGHT,
                ],
                event.keyCode
            ) !== -1
        ) {
            let nextSelectedDay: Date = null;

            if (this.currentDate) {
                switch (event.keyCode) {
                    case KEY_CODE.KEY_LEFT:
                        nextSelectedDay = this.dateService.getPreviousDay(
                            this.currentDate
                        );
                        break;
                    case KEY_CODE.KEY_UP:
                        nextSelectedDay = this.dateService.getDayFromWeekAgoOf(
                            this.currentDate
                        );
                        break;
                    case KEY_CODE.KEY_RIGHT:
                        nextSelectedDay = this.dateService.getNextDay(
                            this.currentDate
                        );
                        break;
                    case KEY_CODE.KEY_DOWN:
                        nextSelectedDay = this.dateService.getDayFromNextWeekOf(
                            this.currentDate
                        );
                        break;
                    default:
                        console.log(
                            "Unhandled keyboard event key code: ",
                            event.keyCode
                        );
                }

                if (
                    this.dateService.equalMonthOfYear(
                        this.currentDate,
                        nextSelectedDay
                    )
                ) {
                    this.selection.next(nextSelectedDay);
                } else {
                    return false;
                }
            } else {
                this.selection.next(new Date());
            }

            event.preventDefault();
        } else {
            return false;
        }
    }
}
