export const usersI18NPl = {
    admin: "Administrator",
    pageTitle: "Lista użytkowników",
    deactivatedUsers: "Użytkownicy nieaktywni: ",
    active: "Aktywni użytkownicy",
    inactive: "Nieaktywni użytkownicy",
    pending: "Zaproszeni użytkownicy",
    inviteUsers: "Zaproś użytkowników",
    edit: "Edytuj",
    editUsers: "Edycja użytkowników",
    manager: "Manager",
    regular: "Zwykły",
    viewUsers: "Podgląd użytkowników",
    sendInvitations: "Wyślij zaproszenia",
    userView: "Podgląd",
    userGrantAdmin: "Nadaj uprawnienia administratora",
    userFinance: "Finanse",
    userRevokeAdmin: "Odbierz uprawnienia administratora",
    userDeactivate: "Dezaktywuj",
    userActivate: "Aktywuj ponownie",
    userInviteAgain: "Zaproś ponownie",
    userRemove: "Usuń",
    userFinances: "Finanse",
    findUsers: "Znajdź użytkowników",
    total: "Razem",
    add: "Dodaj",
    addTeam: "Dodaj zespół",
    addUsers: "Dodaj użytkownika",
    selectCurrency: "Wybierz walutę",
    more: "więcej",
    teamsCountLabel: (count: number) => {
        if (count === 1) {
            return "1 zespół";
        } else {
            const r = count % 100;
            if (r >= 10 && r <= 20) {
                return `${count} zespołów`;
            }
            const lastDigit = count % 10;
            if (lastDigit > 1 && lastDigit < 5) {
                return `${count} zespoły`;
            }
            return `${count} zespołów`;
        }
    },
    allTeams: "Wszystkie zespoły",
    myTeams: "Moje",
};
