const tsActivityI18NEn = {
    wrongActivityTimeValue: (minutes) =>
        `Activity’s "${minutes}" duration is incorrect!`,
    wrongActivityTimeValueSuggested: (value, suggested) =>
        `Activity’s duration ${value} (hours) is incorrect. ` +
        `If you meant ${value} minutes, enter "${suggested}" or "${value}m"`,
};

const tsActivityI18NPl = {
    wrongActivityTimeValue: (minutes) =>
        `Długość "${minutes}" aktywności jest niepoprawna!`,
    wrongActivityTimeValueSuggested: (value, suggested) =>
        `Długość aktywności ${value} (godzin) jest niepoprawna. ` +
        `Jeśli miałeś na myśli ${value} minut, wpisz "${suggested}" lub "${value}m"`,
};

const tsActivityI18NDe = {
    wrongActivityTimeValue: (minutes) =>
        `Dauer der Aktivität "${minutes}" ist nicht korrekt!`,
    wrongActivityTimeValueSuggested: (value, suggested) =>
        `Die Dauer der Aktivität ${value} (Stunden) ist nicht korrekt. ` +
        `Wenn Sie ${value} Minuten gemeint haben, geben Sie "${suggested}" oder "${value}m" ein`,
};

export const tsActivityI18N = {
    de: tsActivityI18NDe,
    pl: tsActivityI18NPl,
    en: tsActivityI18NEn,
};
