export const settingsI18NPl = {
    pageTitle: "Ustawienia",
    off: "Wyłączone",
    on: "Włączone",
    daily: "Dzienne",
    weekly: "Tygodniowe",
    monthly: "Miesięczne",
    notifications: "Powiadomienia",
    sendHoliday: "Powiadamiaj o urlopach:",
    toTeamManagers: "managerów zespołów",
    toAdmins: "administratorów",
    sendTimesheets: "Przypominaj o raportowaniu czasu:",
    timepotSealing: "Zapieczętowanie Timepota:",
    dailyLoggingHours: "Domyślne czasy pracy:",
    features: "Ustawienia zaawansowane",
    holidayHelp:
        "Ustaw kto dostanie powiadomienie gdy pracownik idzie na urlop.",
    timesheetsHelp:
        "Ustaw powiadomienia gdy pracownik zaraportuje mniej czasu niż zwykle.",
    sealingHelp: "Zablokuj możliwość edycji już zatwierdzonych danych",
    apiAccess: "Dostęp do API",
    apiKey: "Klucz do API:",
    apiKeyHelper:
        "Dołącz ten klucz do każdego żądania do API (w nagłówku X-API-Key).",
    monday: "Poniedziałek",
    tuesday: "Wtorek",
    wednesday: "Środa",
    thursday: "Czwartek",
    friday: "Piątek",
    saturday: "Sobota",
    sunday: "Niedziela",
};
