import { Component, ElementRef, Input, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { inviteI18N } from "./i18n/invite-i18n";
import { EmailValidator } from "services/validators/EmailValidator";
import { Action, OpenModalToken } from "../genericmodal/GenericModalComponent";
import {
    createEmail,
    Email,
    InvitationService,
} from "services/invitation/InvitationService";
import { InvitedEmailValidator } from "services/validators/InvitedEmailValidator";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: "invitation-form",
    templateUrl: "invitation-form.html",
    host: { "[class.c-invitation-form]": "true" },
})
export class InvitationFormComponent implements OnInit {
    @Input() public openModalStream: Observable<OpenModalToken>;
    @Input() public actionStream: Subject<Action>;
    @Input() public okEnabled: ReplaySubject<boolean>;
    @Input() public hideButton = false;

    public emailsForm: FormGroup;
    private i18n;

    constructor(
        private elementRef: ElementRef,
        private i18nService: I18NService,
        private builder: FormBuilder,
        private invitationService: InvitationService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(inviteI18N);
    }

    public ngOnInit() {
        this.emailsForm = this.builder.group({
            emails: this.builder.array([this.prepareValidatedControl("")]),
        });

        this.emailsForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
            this.isFormValid();
        });

        this.actionStream.subscribe((action) => {
            if (action === Action.OK) {
                this.onSubmit(this.emailsForm.value);
                this.reset();
            } else if (action === Action.CANCEL) {
                this.okEnabled.next(false);
                this.reset();
            }
        });

        if (this.openModalStream) {
            this.openModalStream.subscribe(() => this.reset());
        } else {
            this.setFocusOnInput(0);
        }
    }

    public get formData() {
        return <FormArray>this.emailsForm.get("emails");
    }

    private reset() {
        this.emailsForm.reset();
        const control = <FormArray>this.emailsForm.controls.emails;
        if (control.length > 1) {
            for (let i = control.length - 1; i >= 1; i--) {
                control.removeAt(i);
            }
        }
    }

    public addNextFormControl(focus = true, value = ""): void {
        const control = <FormArray>this.emailsForm.controls.emails;
        const nextInputIdx = control.length;
        control.push(this.prepareValidatedControl(value));

        if (focus) {
            setTimeout(() => this.setFocusOnInput(nextInputIdx));
        }
    }

    public onRemove(idx: number): void {
        const control = <FormArray>this.emailsForm.controls.emails;
        control.removeAt(idx);
        this.isFormValid();
    }

    public onAddInput(event) {
        this.addNextFormControl(true, event.target.value);
        event.target.value = "";
    }

    public onSubmit(form): void {
        if (this.isFormValid()) {
            const items: Email[] = [];
            Object.keys(form.emails).map((key) => {
                items.push(createEmail(form.emails[key]));
            });
            setTimeout(() => this.actionStream.next(Action.OK));
            this.invitationService.invite(items);
        }
    }

    // VALIDATION

    public getControlAtIndex(idx: number): AbstractControl {
        const control = <FormArray>this.emailsForm.controls.emails;
        return control.at(idx);
    }

    public isInvalidEmail(idx: number): boolean {
        return (
            this.getControlAtIndex(idx).hasError("invalid") &&
            this.getControlAtIndex(idx).value &&
            this.touched(idx)
        );
    }

    public isAlreadyInvited(idx: number): boolean {
        return this.getControlAtIndex(idx).hasError("invited");
    }

    public isEmpty(idx: number): boolean {
        return !this.getControlAtIndex(idx).value && this.touched(idx);
    }

    public isValid(idx: number): boolean {
        const control = <FormArray>this.emailsForm.controls.emails;
        const valid =
            control.at(idx).valid &&
            !this.nonUnique(idx) &&
            !this.isAlreadyInvited(idx);
        if (valid) {
            this.getControlAtIndex(idx).markAsPristine();
            this.getControlAtIndex(idx).markAsUntouched();
        }
        return valid;
    }

    public nonUnique(idx?: number): boolean {
        const control = <FormArray>this.emailsForm.controls.emails;
        const tmp = [...control.value];
        if (idx !== undefined) {
            const checked = control.at(idx).value;
            return tmp
                .slice(0, idx)
                .some((value) => value && value === checked);
        } else {
            const values = new Set<string>();
            return tmp
                .filter((value) => value !== "")
                .some((value) => values.has(value) || !values.add(value));
        }
    }

    public isLastIdx(idx: number): boolean {
        const control = <FormArray>this.emailsForm.controls.emails;
        return idx === control.length - 1;
    }

    public isFirstIdx(idx: number): boolean {
        return idx === 0;
    }

    private prepareValidatedControl(value = ""): AbstractControl {
        return new FormControl(
            value,
            Validators.compose([Validators.required, EmailValidator.invalid]),
            InvitedEmailValidator.unused(this.invitationService)
        );
    }

    private setFocusOnInput(idx: number, dirty = false): void {
        this.getInputElementByIndex(idx).focus();

        if (dirty) {
            this.getControlAtIndex(idx).markAsDirty();
            this.getControlAtIndex(idx).markAsTouched();
        }
    }

    private getInputElementByIndex(idx: number): HTMLInputElement {
        return this.elementRef.nativeElement.getElementsByTagName("input")[idx];
    }

    private isFormValid(): boolean {
        const isValid = this.emailsForm.valid && !this.nonUnique();
        this.okEnabled.next(isValid);
        return isValid;
    }

    public isFormWithoutEmptyInputs(): boolean {
        return !this.emailsForm.value.emails.some((value) => value === "");
    }

    private touched(idx: number): boolean {
        return this.getControlAtIndex(idx).touched;
    }
}
