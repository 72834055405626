import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Action } from "../../genericmodal/GenericModalComponent";
import { I18NService } from "services/i18n/I18NService";
import { ProjectService } from "services/projects/ProjectService";
import { projectModalI18N } from "./i18n/projectmodal-i18n";
import { createProject, Project } from "models/projects/Project";
import { filter } from "rxjs/operators";

@Component({
    selector: "project-modal",
    templateUrl: "project-modal.html",
    host: { "[class.c-project-modal]": "true" },
})
export class ProjectModalComponent implements OnInit {
    @Input() public projectObservable: Observable<Project>;
    @Output() public result: EventEmitter<Project> =
        new EventEmitter<Project>();
    public showStream = new BehaviorSubject<boolean>(false);
    public colorSelectionStream = new BehaviorSubject<string>(null);
    public actionStream = new Subject<Action>();
    public project: Project;
    public i18n;
    public projectName = "";
    private color: string;
    private isNew: boolean;

    constructor(
        private i18nService: I18NService,
        private projectService: ProjectService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectModalI18N);
    }

    public ngOnInit(): void {
        this.projectObservable.subscribe((project) => this.populate(project));
        this.actionStream
            .pipe(filter((action) => action === Action.OK))
            .subscribe(() => this.accept());
    }

    private populate(project: Project) {
        this.isNew = !project;
        this.project = this.isNew
            ? createProject({
                  id: null,
                  name: "",
                  color: null,
                  active: true,
                  billableByDefault: true,
                  source: null,
                  teams: [],
              })
            : createProject({
                  ...project,
                  teams: [],
              });

        this.showStream.next(true);

        this.restoreForm();
    }

    private restoreForm(): void {
        this.setProjectName(this.project.name);
        this.pickColor(this.project.color);
    }

    private accept() {
        this.saveForm();
        const ret = this.persist();
        ret.subscribe((p) => this.result.emit(p));
    }

    private saveForm() {
        this.project.name = this.projectName;
        this.project.color = this.color;
    }

    private persist(): Observable<Project> {
        if (this.isNew) {
            return this.projectService.addProject(this.project);
        } else {
            return this.projectService.updateProject(this.project);
        }
    }

    private pickColor(color: string) {
        this.colorSelectionStream.next(color);
    }

    private setProjectName(name: string) {
        this.projectName = name;
    }

    private setColor(color: string) {
        this.color = color;
    }

    public setBillabilityStream(flag: boolean): void {
        this.project.billableByDefault = flag;
    }

    public onColorChange(color: string): void {
        this.setColor(color);
    }

    public get modalTitle(): string {
        return this.isNew
            ? this.i18n.addProjectTitle
            : this.i18n.editProjectTitle;
    }

    public get modalConfirmLabel(): string {
        return this.isNew ? this.i18n.add : this.i18n.save;
    }

    public isFormValid(): boolean {
        return this.projectName.length > 0;
    }
}
