import {
    TSActivity,
    tsActivityEmpty,
    tsActivityMergeFunctions,
} from "./TSActivity";
import { DateRange } from "../daterange/DateRange";
import { MergeFunctions, Merger } from "models/timesheet/Merger";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const RawTSTask = z.object({
    id: z.string(),
    name: z.string(),
    readOnly: z.boolean(),
    source: z.string().optional(),
    sealed: z.boolean(),
    billable: z.boolean(),
    activities: TSActivity.array(),
});

export function createTSTask(raw: RawTSTask) {
    return {
        ...raw,
        isFake: false,
        summary: raw.activities.reduce(
            (sum, activity) => sum + (activity.minutes || 0),
            0
        ),
        imported: !!raw.source,
    };
}
export type RawTSTask = z.infer<typeof RawTSTask>;

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const TSTask = RawTSTask.transform(createTSTask);

export type TSTask = z.infer<typeof TSTask>;

export function tsTaskPadded(task: TSTask, dateRange: DateRange): TSTask {
    const padded = [];
    const tmp = new Date(dateRange.from.getTime());
    tmp.setHours(0, 0, 0, 0);
    while (tmp.getTime() <= dateRange.to.getTime()) {
        const activity = task.activities.find(
            (a) => a.date.getTime() === tmp.getTime()
        );
        if (activity) {
            padded.push(activity);
        } else {
            padded.push(tsActivityEmpty(new Date(tmp.getTime())));
        }
        tmp.setDate(tmp.getDate() + 1);
    }
    return {
        ...task,
        activities: padded,
    };
}

export const tsTaskMergeFunctions: MergeFunctions<TSTask> = {
    match: (a, b) => b && a.id === b.id,
    merge: (a, b) => ({
        ...a,
        ...b,
        activities: Merger.mergeArrays(
            a.activities,
            b.activities,
            tsActivityMergeFunctions
        ),
    }),
};
