import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";

// at least six chars with, at least one digit and at least one non-digit
const PASSWORD_REGEXP = /^(?=.*[\d])(?=.*[\D]).{7,}$/;

@Injectable()
export class PasswordValidator {
    public static invalid(control: FormControl) {
        return PASSWORD_REGEXP.test(control.value) ? null : { invalid: true };
    }
}
