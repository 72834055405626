import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, ReplaySubject, Subject } from "rxjs";
import { ConfigService } from "../config";
import { User } from "../../models/users/User";
import { CompanyUser } from "../../models/companyusers/CompanyUser";
import { CompanyUsers } from "../../models/companyusers/CompanyUsers";
import { CompanyUserStatus } from "../../models/companyusers/CompanyUserStatus";
import { Email } from "../invitation/InvitationService";
import { CurrentCompanyUserService } from "../currentcompanyuser/CurrentCompanyUserService";
import { CurrentCompanyUserViewService } from "../currentcompanyuser/CurrentCompanyUserViewService";
import { TimeZoneService } from "../timezone/TimeZoneService";
import { DateService } from "../date/DateService";
import { TimesheetService } from "../timesheet/TimesheetService";
import { SealView } from "../../models/users/SealView";
import { concat, flatMap, map, tap } from "rxjs/operators";

@Injectable()
export class UserService {
    public refreshSubject = new Subject<RefreshToken>();

    public static getAvatarForName(name: string): string {
        return `images/avatar-${(name.length % 4) + 1}.png`;
    }

    constructor(
        private http: HttpClient,
        private currentCompanyUserViewService: CurrentCompanyUserViewService,
        private currentCompanyUserService: CurrentCompanyUserService,
        private configService: ConfigService,
        private timeZoneService: TimeZoneService,
        private timesheetService: TimesheetService
    ) {}

    public getAvatarForUser(user: User, withRandomParam = false): string {
        return (
            (user.avatarUploaded &&
                this.configService.avatarEndpoint(user.id, withRandomParam)) ||
            user.avatarUrl ||
            UserService.getAvatarForName(user.name)
        );
    }

    public getUsersForCompany(
        status = CompanyUserStatus.ACTIVE
    ): Observable<CompanyUsers> {
        const getUsersObservable = () =>
            this.http
                .get(this.configService.companyUsersEndpoint(status))
                .pipe(map((res) => CompanyUsers.parse(res)));

        const refreshedUsers = this.refreshSubject.pipe(
            flatMap(() => getUsersObservable())
        );
        return getUsersObservable().pipe(concat(refreshedUsers));
    }

    public getCompaniesAvailableForUser(
        userId: string
    ): Observable<CompanyUser[]> {
        return this.getCompaniesForUser(userId, [
            CompanyUserStatus.INVITED,
            CompanyUserStatus.ACTIVE,
        ]);
    }

    public getCompaniesForUser(
        userId: string,
        statuses = [CompanyUserStatus.ACTIVE]
    ): Observable<CompanyUser[]> {
        const getUsersObservable = () =>
            this.http
                .get(this.configService.userCompaniesEndpoint(userId, statuses))
                .pipe(map((res) => CompanyUsers.parse(res).companyUsers));

        const refreshedUsers = this.refreshSubject.pipe(
            flatMap(() => getUsersObservable())
        );
        return getUsersObservable().pipe(concat(refreshedUsers));
    }

    public convertToAdmin(userId: string) {
        this.changeUserRole(userId, "ADMIN");
    }

    public convertToRegular(userId: string) {
        this.changeUserRole(userId, "REGULAR");
    }

    public convertToManager(userId: string) {
        this.changeUserRole(userId, "MANAGER");
    }

    private changeUserRole(userId: string, newRole: string): void {
        const userJSON = { role: newRole };
        this.http
            .put(this.configService.companyUserEndpoint(userId), userJSON)
            .subscribe(() => this.refreshSubject.next(RefreshToken.REFRESH));
    }

    public setCompanyUserStatus(
        companyUser: CompanyUser,
        newStatus: CompanyUserStatus
    ): void {
        this.setCompanyUserStatusObservable(companyUser, newStatus).subscribe();
    }

    private setCompanyUserStatusObservable(
        companyUser: CompanyUser,
        newStatus: CompanyUserStatus
    ): Observable<void> {
        const userJSON = { status: newStatus };
        return this.http
            .put(
                this.configService.companyUserEndpoint(companyUser.userId),
                userJSON
            )
            .pipe(
                map(() => {
                    this.refreshSubject.next(RefreshToken.REFRESH);
                    return;
                })
            );
    }

    public activateUserIfNeeded(companyUser: CompanyUser): Observable<void> {
        if (companyUser.status === CompanyUserStatus.INVITED) {
            return this.setCompanyUserStatusObservable(
                companyUser,
                CompanyUserStatus.ACTIVE
            ).pipe(tap(() => (companyUser.status = CompanyUserStatus.ACTIVE)));
        } else {
            return of(null);
        }
    }

    public getUser(userId: string): Observable<User> {
        return this.http
            .get(this.configService.userEndpoint(userId))
            .pipe(map((res) => User.nullable().parse(res)));
    }

    public inviteUser(email: Email): void {
        this.http
            .post(this.configService.invitationsEndpoint(email), null)
            .subscribe(() => this.refreshSubject.next(RefreshToken.REFRESH));
    }

    public disinviteUser(userId: string): void {
        this.http
            .delete(this.configService.invitationEndpoint(userId))
            .subscribe(() => this.refreshSubject.next(RefreshToken.REFRESH));
    }

    public isInvited(email: Email): Observable<boolean> {
        return this.http
            .get(this.configService.invitationsEndpoint(email))
            .pipe(map((res) => res === true));
    }

    public getProfile(): Observable<User> {
        return this.http
            .get(this.configService.profileEndpoint())
            .pipe(map((res) => User.nullable().parse(res)));
    }

    public updateProfile(multipart: FormData): Observable<void> {
        return this.http
            .post(this.configService.profileEndpoint(), multipart)
            .pipe(
                flatMap(() =>
                    this.currentCompanyUserService.reloadCurrentCompanyUser()
                ),
                map(() => this.refreshSubject.next(RefreshToken.REFRESH))
            );
    }

    public sealUser(userId: string, date: Date): Observable<void> {
        const payload = { sealedUntil: DateService.toISOString(date) };
        const ret = new ReplaySubject<void>(1);
        this.http
            .put(this.configService.userSealingEndpoint(userId), payload)
            .subscribe(() => {
                ret.next(null);
                this.timesheetService.requestRefresh();
            });
        return ret;
    }

    public getUserSealDate(userId: string): Observable<SealView> {
        const ret = new ReplaySubject<SealView>(1);
        this.http.get(this.configService.userSealingEndpoint(userId)).subscribe(
            (res) => {
                ret.next(SealView.parse(res));
            },
            () => {
                ret.next(null);
            }
        );
        return ret;
    }

    public updateTimeZone(): Observable<void> {
        const payload = { zoneId: this.timeZoneService.getTimeZone() };
        return this.http
            .put(this.configService.timezoneEndpoint(), payload)
            .pipe(map(() => null));
    }

    public forceRefresh(): void {
        this.refreshSubject.next(RefreshToken.REFRESH);
    }
}

export enum RefreshToken {
    REFRESH,
}
