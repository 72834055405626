import { timesheetReportsI18NDe } from "./timesheet-reports-i18n-de";
import { timesheetReportsI18NPl } from "./timesheet-reports-i18n-pl";

const timesheetReportsI18NEn = {
    pageTitle: "Reports",
    projects: "projects",
    users: "users",
    tasks: "tasks",
    noResults: "No results for current filters",
    hideGraph: "Hide graph",
    hours: "h",
    noData: "No data",
    showGraph: "Show graph",
    total: (time: string) => `Total: ${time}`,
    csv: "CSV",
    pdf: "PDF",
    share: "Public link",
    yourLink: "Report's public link:",
    copyToClipboard: "Copy to clipboard",
    all: "All",
    billable: "Billable",
    nonBillable: "Non-billable",
    publicLinkDesc: "Only the billable part of data will be exported.",
};

export const timesheetReportsI18N = {
    de: timesheetReportsI18NDe,
    pl: timesheetReportsI18NPl,
    en: timesheetReportsI18NEn,
};
