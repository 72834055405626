import { holidaysI18NDe } from "./holidays-i18n-de";
import { holidaysI18NPl } from "./holidays-i18n-pl";

const holidaysI18NEn = {
    pageTitle: "Days off",
    addHolidayTitle: "Add days off",
    addHolidays: "Add days off",
    addUsers: "Add Users",
    usersField: "Users",
    findUser: "Find User",
    startDate: "Start: ",
    endDate: "End: ",
    usedIn: "Used",
    days: "days in",
    add: "Add",
    addSelected: "Add selected",
    deleteSelected: "Delete selected",
    deleteWhole: "Delete whole",
    holidays: "Holidays",
    sickDays: "Sick days",
    selectType: "Select type",
};

export const holidaysI18N = {
    de: holidaysI18NDe,
    en: holidaysI18NEn,
    pl: holidaysI18NPl,
};
