import { Component, Input, OnInit } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { DateService } from "services/date/DateService";
import { I18NService } from "services/i18n/I18NService";
import { DateRange } from "models/daterange/DateRange";
import { datePickerI18N } from "../i18n/datepicker-i18n";

@Component({
    selector: "date-picker-range",
    templateUrl: "date-picker-range.html",
    host: {
        "[class.c-date-picker-range]": "true",
        "[class.c-date-picker-range--report]": "this.report",
    },
})
export class DatePickerRangeComponent implements OnInit {
    @Input() public report: boolean;
    @Input() public dateRangeSubject: Subject<DateRange>;
    @Input() public dateRangeObservable: Observable<DateRange>;

    public selectedDate = new Subject<Date>();
    public preselected = new ReplaySubject<Date>(1);
    public startRange = new ReplaySubject<Date>();
    public endRange = new ReplaySubject<Date>();
    public i18n;

    private startDate: Date = new Date();
    private endDate: Date = new Date();

    public startDateString: string;
    public endDateString: string;

    public isVisibleDatepicker = false;
    public dateRangeInputType = DateRangeInputType;
    public currentDateRangeInputType: DateRangeInputType = null;

    constructor(private dateService: DateService, i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(datePickerI18N);
    }

    public ngOnInit() {
        this.dateRangeObservable.subscribe((range) => {
            if (range.from && range.to) {
                this.startDate = range.from;
                this.endDate = range.to;
                this.getDateString();
            } else {
                const today = new Date();
                this.startDate = today;
                this.endDate = today;
                this.startDateString = "";
                this.endDateString = "";
            }
        });

        this.selectedDate.subscribe((newDate) => {
            this.isVisibleDatepicker = false;
            if (this.currentDateRangeInputType === DateRangeInputType.START) {
                let endDate = this.endDate;
                if (this.dateService.isBefore(this.endDate, newDate)) {
                    endDate = newDate;
                }
                this.dateRangeSubject.next({
                    from: newDate,
                    to: endDate,
                });
            } else {
                let startDate = this.startDate;
                if (this.dateService.isBefore(newDate, startDate)) {
                    startDate = newDate;
                }
                this.dateRangeSubject.next({
                    from: startDate,
                    to: newDate,
                });
            }
        });
    }

    public getDateString() {
        this.startDateString = this.startDate
            ? DateService.toISOString(this.startDate)
            : "";
        this.endDateString = this.startDate
            ? DateService.toISOString(this.endDate)
            : "";
    }

    public showDatepicker(type: DateRangeInputType) {
        this.currentDateRangeInputType = type;
        this.isVisibleDatepicker = true;

        if (this.currentDateRangeInputType === DateRangeInputType.START) {
            this.preselected.next(this.startDate);
        } else {
            this.preselected.next(this.endDate);
        }

        this.startRange.next(this.startDate);
        this.endRange.next(this.endDate);
    }

    public hideDatepicker() {
        this.isVisibleDatepicker = false;
    }

    private setDate(from: Date, to: Date) {
        this.dateRangeSubject.next({
            from: from,
            to: to,
        });
        this.startRange.next(from);
        this.endRange.next(to);
        this.isVisibleDatepicker = false;
    }

    private setToday(): void {
        const today = new Date();
        this.setDate(today, today);
    }

    private setYesterday(): void {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        this.setDate(yesterday, yesterday);
    }

    private setThisWeek(): void {
        const firstDay = this.dateService.getMondayOfWeek(new Date());
        const lastDay = new Date();
        lastDay.setMonth(firstDay.getMonth());
        lastDay.setFullYear(firstDay.getFullYear());
        lastDay.setDate(firstDay.getDate() + 6);
        this.setDate(firstDay, lastDay);
    }

    private setLastWeek(): void {
        const firstDay = this.dateService.getMondayOfLastWeek(new Date());
        const lastDay = new Date();
        lastDay.setMonth(firstDay.getMonth());
        lastDay.setFullYear(firstDay.getFullYear());
        lastDay.setDate(firstDay.getDate() + 6);
        this.setDate(firstDay, lastDay);
    }

    private setThisMonth(): void {
        const today = new Date();
        this.setDate(
            new Date(today.getFullYear(), today.getMonth(), 1),
            new Date(today.getFullYear(), today.getMonth() + 1, 0)
        );
    }

    private setLastMonth(): void {
        const firstDay = new Date();
        firstDay.setDate(1);
        firstDay.setMonth(firstDay.getMonth() - 1);
        const lastDay = new Date(
            firstDay.getFullYear(),
            firstDay.getMonth() + 1,
            0
        );
        this.setDate(firstDay, lastDay);
    }

    private setThisYear(): void {
        this.setDate(
            new Date(new Date().getFullYear(), 0, 1),
            new Date(new Date().getFullYear(), 11, 31)
        );
    }

    private setLastYear(): void {
        this.setDate(
            new Date(new Date().getFullYear() - 1, 0, 1),
            new Date(new Date().getFullYear() - 1, 11, 31)
        );
    }
}

enum DateRangeInputType {
    START,
    END,
}
