import { Component, Input } from "@angular/core";

@Component({
    selector: "main-header",
    templateUrl: "main-header.html",
})
export class HeaderComponent {
    @Input() public pageTitle: string;
    @Input() public addLine = true; // holidays view does not use the line, make it optional
}
