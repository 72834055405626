export class CookieService {
    public setCookie(
        name: string,
        value: string,
        expires?: Date,
        path?: string
    ) {
        const expiration = expires ? "; expires=" + expires.toUTCString() : "";
        let cookieStr = `${name}=${value}${expiration}`;
        if (path) {
            cookieStr += ";path=" + path;
        }
        document.cookie = cookieStr;
    }

    public getCookie(name: string): string {
        const cookie: string = document.cookie
            .split(";")
            .map((cookieStr) => cookieStr.trim())
            .find((cookieStr) => cookieStr.startsWith(name + "="));

        return cookie ? cookie.replace(name + "=", "") : null;
    }

    public clearCookie(name: string) {
        this.setCookie(name, "");
    }
}
