export const financialReportsI18NDe = {
    configure: "Konfigurieren",
    userSalaries: "Gehälter der Benutzer",
    and: "und",
    projectRates: "Projektsätze",
    generateReports: "und wir erstellen die Finanzberichte für Sie.",
    pageTitle: "Finanzen",
    income: "Einkünfte",
    expenses: "Ausgaben",
    totalBalance: "Gesamtsaldo",
    project: "Projekt",
    projects: "Projekte",
    noResults: "Keine Ergebnisse für aktuelle Filter",
};
