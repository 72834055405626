import { barChartHorizontalI18NDe } from "./barchart-horizontal-i18n-de";
import { barChartHorizontalI18NPl } from "./barchart-horizontal-i18n-pl";

const barChartHorizontalI18NEn = {
    projectName: "Project name",
    username: "Username",
    time: "Time",
};

export const barChartHorizontalI18N = {
    de: barChartHorizontalI18NDe,
    pl: barChartHorizontalI18NPl,
    en: barChartHorizontalI18NEn,
};
