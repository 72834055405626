import { configuratorUserEntryI18NDe } from "./configurator-user-entry-i18n-de";
import { configuratorUserEntryI18NPl } from "./configurator-user-entry-i18n-pl";
const configuratorUserEntryI18NEn = {
    you: "You",
};

export const configuratorUserEntryI18N = {
    de: configuratorUserEntryI18NDe,
    en: configuratorUserEntryI18NEn,
    pl: configuratorUserEntryI18NPl,
};
