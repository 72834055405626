import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import * as _ from "lodash";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import { ProjectService } from "services/projects/ProjectService";
import { Project } from "models/projects/Project";
import { I18NService } from "services/i18n/I18NService";
import {
    FilterSetting,
    FilterType,
} from "models/reports/filters/FilterSetting";
import { reportFilterI18N } from "../i18n/reportfilter-i18n";
import { Item } from "../../timesheet/tsprojectselector/TSProjectSelectorComponent";
import { TeamService } from "services/teams/TeamService";

@Component({
    selector: "projects-filter",
    templateUrl: "projects-filter.html",
})
export class ProjectsFilterComponent implements OnInit {
    @Input() public filterSubject: Subject<FilterSettings>;

    public projects: Item[] = [];
    public groups: Item[] = [];
    public i18n;
    public selectedItems = new ReplaySubject<Item[]>(1);
    public preselected = new ReplaySubject<{ id: string; name: string }[]>(1);

    private filterSettings: FilterSettings;

    constructor(
        private projectService: ProjectService,
        private teamService: TeamService,
        private i18nService: I18NService
    ) {
        this.i18n =
            this.i18nService.extractCurrentTranslation(reportFilterI18N);
    }

    public ngOnInit(): void {
        this.projectService.getCompanyProjects().subscribe((projects) => {
            this.projects = projects.projects.map(
                (project): Item => ({
                    type: "project",
                    name: project.name,
                    id: project.id,
                    filterType: FilterType.PROJECT,
                })
            );
        });
        this.teamService.getTeams().subscribe((teams) => {
            this.groups = teams.map((t) => ({
                id: t.code,
                name: t.name,
                type: "team",
            }));
        });
        this.filterSubject.subscribe((filterSettings) => {
            this.filterSettings = filterSettings;
            const preselected = _.filter(
                filterSettings.settings,
                (fs) =>
                    fs.filterType === FilterType.PROJECT ||
                    fs.filterType === FilterType.PROJECT_TEAMS
            ).map((fs) => {
                return {
                    id: fs.filterValue,
                    name: fs.filterDisplay,
                    filterType: fs.filterType,
                };
            });
            this.preselected.next(preselected);
        });

        this.selectedItems.subscribe((selectedItems) => {
            if (this.filterSettings) {
                _.remove(
                    this.filterSettings.settings,
                    (fs) =>
                        fs.filterType === FilterType.PROJECT ||
                        fs.filterType === FilterType.PROJECT_TEAMS
                );
            }
            selectedItems.forEach((item) => {
                this.filterSettings.settings.push(
                    this.buildFilterSetting(item)
                );
            });
            this.filterSubject.next(this.filterSettings);
        });
    }

    public getProjectCSS(project: Project) {
        return project && project.color
            ? "c-generic-dropdown__list-element--" +
                  project.color.toLocaleLowerCase() +
                  " c-generic-dropdown__list-element--projects"
            : "";
    }

    private buildFilterSetting(item: Item): FilterSetting {
        if (item.type === "project" || item.filterType === FilterType.PROJECT) {
            return new FilterSetting(FilterType.PROJECT, item.id, item.name);
        }
        return new FilterSetting(
            FilterType.PROJECT_TEAMS,
            item.id,
            item.name.startsWith(this.i18n.groupDisplay)
                ? item.name
                : this.i18n.groupDisplay + item.name
        );
    }
}
