<form
    class="c-invitation-form__form"
    [formGroup]="emailsForm"
    accept-charset="UTF-8"
    (ngSubmit)="onSubmit(emailsForm.value)"
>
    <div class="u-form-group" formArrayName="emails">
        <div
            *ngFor="let email of formData.controls; let i = index"
            class="u-form-group"
            #inputParent
        >
            <input
                id="email-{{i}}"
                type="text"
                class="c-invitation-form__form-input"
                placeholder="{{ i === 0 ? i18n.firstEmailAddressPlaceholder : i18n.nextEmailAddressPlaceholder }}"
                value=""
                formControlName="{{i}}"
                [ngClass]="{'c-invitation-form__form-input--valid': isValid(i)}"
            />
            <label for="email-{{i}}" class="c-invitation-form__validation">
                <span
                    *ngIf="isInvalidEmail(i)"
                    class="c-invitation-form__validation-msg c-invitation-form__validation-msg--error"
                >
                    {{ i18n.invalidEmailAddress }}
                </span>
                <span
                    *ngIf="isAlreadyInvited(i)"
                    class="c-invitation-form__validation-msg c-invitation-form__validation-msg--error"
                >
                    {{ i18n.usedEmailAddress }}
                </span>
                <span
                    *ngIf="isEmpty(i)"
                    class="c-invitation-form__validation-msg c-invitation-form__validation-msg--error"
                >
                    {{ i18n.required }}
                </span>
                <span
                    *ngIf="nonUnique(i)"
                    class="c-invitation-form__validation-msg c-invitation-form__validation-msg--error"
                >
                    {{ i18n.nonUnique }}
                </span>
            </label>
            <span
                class="c-invitation-form__form-remove"
                *ngIf="!(isFirstIdx(i) && isLastIdx(i))"
                (click)="onRemove(i)"
            ></span>
        </div>
        <div *ngIf="isFormWithoutEmptyInputs()" class="u-form-group">
            <input
                class="c-invitation-form__form-input c-invitation-form__form-input--placeholder"
                (input)="onAddInput($event)"
            />
        </div>
    </div>
</form>
