<div class="c-main-header">
    <a class="c-main-header__logo" href="#" title=""></a>
    <h1
        class="c-main-header__title"
        [ngClass]="{'c-main-header__title--with-underline': addLine}"
    >
        {{pageTitle}}
    </h1>
    <div class="c-main-header__add-button">
        <!-- the add item buttons go here -->
        <ng-content></ng-content>
    </div>
</div>
