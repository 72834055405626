import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { Token } from "models/token/Token";
import { Action, OpenModalToken } from "../genericmodal/GenericModalComponent";
import { profileEditI18N } from "./i18n/profile-edit-i18n";
import { map } from "rxjs/operators";

@Component({
    selector: "profile-edit-modal",
    templateUrl: "profile-edit-modal.html",
})
export class ProfileEditModalComponent implements OnInit {
    @Input() public showStream: Subject<Token>;
    @Input() public actionStream: Subject<Action> = new ReplaySubject<Action>(
        1
    );
    @Input() public openModalStream: Subject<OpenModalToken> =
        new ReplaySubject<OpenModalToken>(1);

    public i18n;
    public showModalStream = new Subject<boolean>();
    public okEnabled: Subject<boolean> = new BehaviorSubject<boolean>(false);
    public isBtnOkEnabled: boolean;

    constructor(private i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(profileEditI18N);

        this.okEnabled.subscribe((enable) => {
            this.isBtnOkEnabled = enable;
        });
    }

    public ngOnInit() {
        this.showStream.subscribe(() => this.showModalStream.next(true));
        this.actionStream
            .pipe(map((action) => action === Action.OK))
            .subscribe(() => this.showModalStream.next(false));
    }
}
