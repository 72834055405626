import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import { projectDropdownI18n } from "./i18n/project-dropdown-i18n";
import { ProjectService } from "services/projects/ProjectService";
import { Project } from "models/projects/Project";
import { I18NService } from "services/i18n/I18NService";
import { Token } from "models/token/Token";
import { CurrentCompanyUserViewService } from "services/currentcompanyuser/CurrentCompanyUserViewService";
import { KEY_CODE } from "constants/KeyCode";
import { FilterType } from "models/reports/filters/FilterSetting";

@Component({
    host: {
        "[class.c-ts-project-selector]": "true",
        "[class.c-ts-project-selector--active]": "isActiveSelector",
        "[class.u-tooltip--right]": "!isActiveSelector",
        "[class.u-tooltip--left]": "isActiveSelector",
        "[attr.data-tooltip]":
            "currentSelection ? currentSelection.name : 'Select project'",
    },
    selector: "ts-project-selector",
    templateUrl: "ts-project-selector.html",
})
export class TSProjectSelectorComponent implements OnInit {
    public items: Project[] = [];
    @Input() public selection: Subject<Project>;
    @Input() public activateStream: Observable<Token>;
    @Input() public initialSelectionStream: Observable<Project>;
    @Input() public userIdObservable: Subject<string>;
    @Input() public automaticallySelectTheOnlyProject = false;
    @Input() public userId: string;
    public allowAddingProject = false;
    public initialMultiSelectionStream: Observable<Project[]>;
    public selectedProjects = new ReplaySubject<Project[]>();
    public i18n;
    public isActiveSelector: boolean;
    public currentSelection;
    public searchForm: FormGroup = null;
    public projectModalStream = new ReplaySubject<Project>(1);
    private addProjectOpen = false;
    private projects: Project[] = [];
    private step: number;

    public showAddProjectModal = (event: Event) => {
        this.addProjectOpen = true;
        this.projectModalStream.next(null);
        event.stopPropagation();
        event.preventDefault();
    };

    constructor(
        private projectService: ProjectService,
        public i18nService: I18NService,
        private currentCompanyUserViewService: CurrentCompanyUserViewService,
        protected elementRef: ElementRef
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectDropdownI18n);
        this.allowAddingProject =
            currentCompanyUserViewService.companyUser.manager;
        this.searchForm = new FormGroup({
            search: new FormControl(),
        });
    }

    public ngOnInit(): void {
        this.getMostRecentProjectsForUser(this.userId);

        this.userIdObservable.subscribe((id) =>
            this.getMostRecentProjectsForUser(id)
        );

        this.selectedProjects.subscribe((projects) =>
            this.selection.next(projects.length > 0 ? projects[0] : null)
        );
    }

    public onProjectCreated(project: Project) {
        this.addProjectOpen = false;
        this.projectService
            .getMostRecentProjectsForUser(
                this.currentCompanyUserViewService.userId,
                true
            )
            .subscribe((projects) => {
                this.items = projects.projects;
                this.projects = projects.projects;
            });
        this.select({ id: project.id, type: "project", name: project.name });
    }

    public toggleSelector(state): void {
        this.isActiveSelector = state;
    }

    public select(item: Item): void {
        this.currentSelection = item;
        this.selection.next(this.currentSelection);
        this.close();
        this.setFocusOnNextElement();
    }

    public isSelected(item: Item): boolean {
        return !!item && this.currentSelection.id === item.id;
    }

    public isKeySelected(item: Item): boolean {
        return (
            !!item &&
            this.items[this.step] &&
            item.id === this.items[this.step].id
        );
    }

    public onInputSearch(filterText): void {
        this.items = this.projects.filter(function (entry) {
            return (
                entry.name
                    .toLocaleLowerCase()
                    .indexOf(filterText.toLocaleLowerCase()) !== -1
            );
        });
    }

    public onInputBlur(): void {
        if (!this.addProjectOpen) {
            this.close();
        }
    }

    public onInputFocus(): void {
        this.isActiveSelector = true;
    }

    public getProjectCSS(project: Project) {
        return project && project.color
            ? project.color.toLocaleLowerCase()
            : "";
    }

    public onInputKeyDown(event: KeyboardEvent): void {
        if ((event.which || event.keyCode) === KEY_CODE.KEY_ESCAPE) {
            this.closeSelector(event);
        } else if (
            ((event.which || event.keyCode) === KEY_CODE.KEY_DOWN ||
                (event.which || event.keyCode) === KEY_CODE.KEY_UP) &&
            this.items.length > 0
        ) {
            event.stopPropagation();
            event.preventDefault();

            this.selectItem(
                this.items,
                this.step,
                event.which || event.keyCode
            );
        } else if (
            (event.which || event.keyCode) === KEY_CODE.KEY_RETURN ||
            (event.which || event.keyCode) === KEY_CODE.KEY_TAB
        ) {
            if (this.step >= 0 && this.items[this.step]) {
                const project = this.items[this.step];
                this.select({
                    id: project.id,
                    type: "project",
                    name: project.name,
                });
            }
        }
    }

    public closeSelector(event): void {
        event.stopPropagation();
        this.close();
    }

    private getMostRecentProjectsForUser(userId: string) {
        const initialMultiSelectionStream = new Subject<Project[]>();
        this.projectService
            .getMostRecentProjectsForUser(userId, true)
            .subscribe((projects) => {
                this.items = projects.projects;
                this.projects = projects.projects;
                this.currentSelection =
                    projects.projects.length > 0 ? projects.projects[0] : null;
                this.initialSelectionStream.subscribe((project) => {
                    if (project) {
                        initialMultiSelectionStream.next([project]);
                    } else if (
                        this.automaticallySelectTheOnlyProject &&
                        this.items.length === 1
                    ) {
                        initialMultiSelectionStream.next(this.items);
                    } else {
                        initialMultiSelectionStream.next([]);
                    }
                });
                this.selection.next(this.currentSelection);
            });
        this.initialMultiSelectionStream = initialMultiSelectionStream;
    }

    private setFocusOnNextElement(): void {
        const nextElement = <HTMLElement>(
            document.querySelector(".c-generic-dropdown__activator")
        );
        nextElement.focus();
    }

    private selectItem(items, current, direction): void {
        if (direction === KEY_CODE.KEY_DOWN) {
            if (current >= 0 && current + 1 < items.length) {
                this.step = current + 1;
            } else {
                this.step = 0;
            }
        } else {
            if (current >= 1) {
                this.step = current - 1;
            } else {
                this.step = items.length - 1;
            }
        }
        this.searchForm.patchValue({ search: items[this.step].name });
    }

    private close(): void {
        this.items = this.projects;
        this.step = undefined;
        this.isActiveSelector = false;
        this.searchForm.patchValue({ search: "" });
    }
}

/** String id with string name */
export type Item = {
    id: string;
    name: string;
    filterType?: FilterType;
    type?: "project" | "team";
};
