<generic-dropdown
    [activateStream]="activateStream"
    [items]="items"
    [selection]="selectedProjects"
    [initialSelectionStream]="initialMultiSelectionStream"
    [autocommit]="true"
    [resetAfterCommit]="false"
    [globalSearchOnOpen]="true"
    [getItemStyle]="getProjectCSS"
    [selectMessage]="i18n.projects"
    [searchPlaceholder]="i18n.findProjectPlaceholder"
    class="basic-dropdown"
>
</generic-dropdown>
