<generic-modal
    [showStream]="showStream"
    [actionStream]="actionStream"
    [okEnabled]="ready && isValid"
    [buttonsVisible]="!isShowingHistory"
    [showReset]="!noInitialPaymentType"
>
    <div class="c-user-payment-modal__header">
        <user-avatar
            class="c-user-payment-modal__header-avatar"
            [user]="user"
        ></user-avatar>
        <span class="c-user-payment-modal__header-name">{{user.name}}</span>
    </div>
    <div *ngIf="!ready" class="u-loader u-loader--relative"></div>
    <ng-container *ngIf="ready">
        <ul class="c-user-payment-modal__tabs" *ngIf="isHistoryTabAvailable">
            <li
                class="c-user-payment-modal__tab"
                [ngClass]="{'c-user-payment-modal__tab--active': !isShowingHistory}"
                (click)="isShowingHistory = false"
            >
                {{i18n.currentRate}}
            </li>
            <li
                class="c-user-payment-modal__tab"
                [ngClass]="{'c-user-payment-modal__tab--active': isShowingHistory}"
                *ngIf="isHistoryTabAvailable"
                (click)="isShowingHistory = true"
            >
                {{i18n.history}}
            </li>
        </ul>
        <create-or-add-user-payment
            [userCode]="user.id"
            [payment]="userPayment"
            [noInitialPaymentType]="noInitialPaymentType"
            (valid)="isValid = $event"
            [ngClass]="{'u-hidden': isShowingHistory}"
        ></create-or-add-user-payment>
        <user-payment-history
            *ngIf="isHistoryTabAvailable"
            (edit)="onHistoryEdit()"
            [history]="paymentHistory"
            [currency]="userPayment ? userPayment.currency : 'USD'"
            [ngClass]="{'u-hidden': !isShowingHistory}"
            [userId]="user.id"
            [workingHoursInDay]="userPayment.workingHoursInDay"
        ></user-payment-history>
    </ng-container>
</generic-modal>
