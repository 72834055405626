import { projectsI18NDe } from "./projects-i18n-de";
import { projectsI18NPl } from "./projects-i18n-pl";

const projectsI18NEn = {
    addUser: "Add user",
    addRatesPerUser: "Add rates per user",
    personalizedRates: "Personalized rates",
    pageTitle: "Project list",
    undo: "Undo",
    active: "Active projects",
    inactive: "Archived projects",
    addProject: "Add project",
    archive: "Archive",
    undoArchiveTooltip: "Undo archive",
    edit: "Edit",
    duration: (hours: number, minutes: number) => `${hours}h ${minutes}min`,
    unknownDuration: "unknown duration",
    restore: "Restore",
    value: "Value",
    estimatedWorkload: "Estimated workload in hours",
    hourlyRate: "Hourly rate",
    monthlyRate: "Monthly rate",
    fixedPrice: "Fixed price",
    invalid: "Invalid",
    pricing: "Pricing",
    from: "From",
    to: "To",
    price: "Price",
    history: "History",
    currency: "Currency",
    hourShort: "h",
    currentRate: "Current rate",
    reports: "Reports",
    selectCurrency: "Select currency",
    user: "User",
    of: "of",
    findProjects: "Find projects",
    more: "more",
    add: "Add",
    addTeam: "Add group",
    teamsCountLabel: (count: number) =>
        count === 1 ? "1 group" : `${count} groups`,
    allTeams: "All groups",
    myTeams: "My groups",
    tasksAreNonBillableByDefault: "Tasks are non-billable by default",
};

export const projectsI18N = {
    de: projectsI18NDe,
    pl: projectsI18NPl,
    en: projectsI18NEn,
};
