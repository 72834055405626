<main-header [pageTitle]="i18n.pageTitle"></main-header>
<div
    class="c-reports__container"
    [ngClass]="{'c-reports__container--empty': isEmptyReport()}"
>
    <financial-report-filter
        [filterSubject]="filterSubject"
    ></financial-report-filter>
    <div class="c-reports__content">
        <project-summary
            [reportObservable]="projectIncomeAndExpenseObservable"
        ></project-summary>
        <div
            *ngIf="monthlyBalanceChartLoading"
            class="c-reports__bar-chart-loader-container"
        >
            <div class="u-loader"></div>
        </div>
        <div [hidden]="monthlyBalanceChartLoading">
            <barchart
                [dataItemsObservable]="monthlyBalanceObservable"
                [selectedItemStream]="filterSettingsStream"
                [config]="monthlyBalanceChartConfig"
                [filterSubject]="filterSubject"
            ></barchart>
        </div>
        <div class="c-reports__graphs">
            <div class="c-reports__graphs-tabs">
                <div class="c-reports__graphs-tabs-option">
                    <a
                        class="c-reports__graphs-tabs-option-link c-reports__graphs-tabs-option-link--default active"
                        >{{i18n.projects}}</a
                    >
                </div>
            </div>
            <ul class="c-reports__graphs-desc">
                <li class="c-reports__graphs-desc-element">
                    <span
                        class="c-reports__graphs-desc-bar c-reports__graphs-desc-bar--income"
                    ></span>
                    {{i18n.income}}
                </li>
                <li class="c-reports__graphs-desc-element">
                    <span
                        class="c-reports__graphs-desc-bar c-reports__graphs-desc-bar--expense"
                    ></span>
                    {{i18n.expenses}}
                </li>
            </ul>
            <project-report
                [reportObservable]="projectIncomeAndExpenseObservable"
                [selectedItemStream]="filterSettingsStream"
            ></project-report>
        </div>
    </div>
    <div class="c-reports__alert c-reports__alert--info">
        {{i18n.noResults}}. {{i18n.configure}}
        <a class="c-reports__alert-link" [routerLink]="['/auth/users']"
            >{{i18n.userSalaries}}</a
        >
        {{i18n.and}}
        <a class="c-reports__alert-link" [routerLink]="['/auth/projects']"
            >{{i18n.projectRates}}</a
        >
        {{i18n.generateReports}}
    </div>
</div>
