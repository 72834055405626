<project-entry
    *ngFor="let project of page"
    [project]="project"
    [teamsStream]="teamsStream"
    [activitiesDuration]="projectActivitiesDurations.get(project.id)"
    [projectModalStream]="projectModalStream"
    (showPricing)="onSelectedForPricing(project, $event)"
></project-entry>
<pagination
    [dataStream]="projectsStream"
    (page)="onPageChanged($event)"
></pagination>

<project-pricing-modal
    [showStream]="pricingModalShowStream"
    [projectStream]="projectForPricingStream"
></project-pricing-modal>
