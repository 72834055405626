import { Injectable } from "@angular/core";
import { from, Observable, of, Subject } from "rxjs";
import { ConfigService } from "../config";
import * as SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import { flatMap, publishReplay, refCount, tap } from "rxjs/operators";

@Injectable()
export class WebSocketNotificationService {
    private isDebugMode = false;
    private stompClient: Observable<Stomp.Client>;
    private topicToSubscriptionId = new Map<string, string>();

    constructor(private configService: ConfigService) {
        this.initializeStompClient();
    }

    public getNotificationStreamForTopic(topic: string): Observable<object> {
        const notificationStream = new Subject<object>();
        this.stompClient
            .pipe(
                tap((client) => {
                    const subscriptionId = client.subscribe(topic, (frame) => {
                        const json = JSON.parse(frame.body);
                        notificationStream.next(json);
                    });
                    this.topicToSubscriptionId.set(topic, subscriptionId);
                })
            )
            .subscribe();
        return notificationStream;
    }

    public unsubscribeTopic(topic: string) {
        const subscriptionId = this.topicToSubscriptionId.get(topic);
        this.topicToSubscriptionId.delete(topic);

        this.stompClient
            //incorrect type definition in @types/stompjs
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .pipe(tap((client) => (<any>client).unsubscribe(subscriptionId)))
            .subscribe();
    }

    public initializeStompClient() {
        this.stompClient = this.createStompClient().pipe(
            flatMap(this.connectStompClient),
            publishReplay(1),
            refCount()
        );
    }

    private createStompClient(): Observable<Stomp.Client> {
        return of(
            Stomp.over(
                new SockJS(
                    this.configService.notificationsEndpoint() +
                        "?version=" +
                        this.configService.apiVersion
                ) as WebSocket
            )
        );
    }

    private connectStompClient(client: Stomp.Client): Observable<Stomp.Client> {
        return <Observable<Stomp.Client>>from(
            new Promise((resolve) => {
                client.connect(
                    { login: undefined, passcode: undefined },
                    () => {
                        resolve(client);
                    }
                );
            })
        );
    }
}
