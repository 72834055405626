import { Component, Input, OnChanges } from "@angular/core";
import { DateService } from "services/date/DateService";
import { UserService } from "services/user/UserService";
import { Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { DaysOff } from "models/daysOff/DaysOff";
import { Calendar } from "models/calendar/Calendar";
import { CalendarViewMode } from "models/calendar/CalendarViewMode";
import { User } from "models/users/User";
import { calendarI18N } from "../i18n/calendar-i18n";

@Component({
    selector: "month-full",
    templateUrl: "month-full.html",
    host: {
        "[class.c-month-full]": "true",
        "[class.c-month-full--hovered]": "this.hoveredUser",
    },
})
export class MonthFullComponent implements OnChanges {
    @Input() public month: number; // 0-based
    @Input() public year: number;
    @Input() public daysOff: DaysOff;
    @Input() public currentUser: User;
    @Input() public calendarStateObservable: Subject<Calendar>;

    public dayClassNames = [
        "c-month-full__day--mon",
        "c-month-full__day--tue",
        "c-month-full__day--wed",
        "c-month-full__day--thu",
        "c-month-full__day--fri",
        "c-month-full__day--sat",
        "c-month-full__day--sun",
    ];

    public selectedMonth = [];
    public i18n;
    public selectedWeek: number;
    public hoveredUser: number;

    constructor(
        private dateService: DateService,
        i18nService: I18NService,
        private userService: UserService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(calendarI18N);
    }

    public ngOnChanges() {
        const first = new Date(this.year, this.month, 1);
        let monday = this.dateService.getMondayOfWeek(first);
        this.selectedMonth = [];
        do {
            this.selectedMonth.push(this.dateService.getWeek(monday));
            monday = new Date(monday.getTime());
            monday.setDate(monday.getDate() + 7);
        } while (this.isCurrentMonth(monday));

        if (this.daysOff) {
            this.selectedMonth.forEach((week) => {
                week.forEach((day) => {
                    day.absence = this.getAbsence(day);
                });
            });
        }
    }
    public isCurrentMonth(date: Date) {
        return date.getMonth() === this.month;
    }

    public isPrevMonth(date: Date) {
        return date.getFullYear() < this.year || date.getMonth() < this.month;
    }
    public isNextMonth(date: Date) {
        return date.getFullYear() > this.year || date.getMonth() > this.month;
    }
    public isOtherMonth(date: Date) {
        return this.isPrevMonth(date) || this.isNextMonth(date);
    }
    public isToday(date: Date) {
        return this.dateService.isToday(date);
    }

    public showWeek(monday: Date) {
        this.calendarStateObservable.next({
            year: this.year,
            month: this.month,
            monday: monday,
            viewMode: CalendarViewMode.WEEK,
        });
    }

    public hover(userId) {
        this.hoveredUser = userId;
    }

    public isHoveredUserInMore(userId, absence): boolean {
        if (userId) {
            const absenceTmp = [...absence];
            absenceTmp.splice(0, 2);
            return absenceTmp.find((absent) => absent.userId === userId);
        } else {
            return false;
        }
    }

    private getAbsence(date: Date) {
        if (!this.daysOff || !this.currentUser) {
            return " ";
        }
        let daysOff: { userId: string; begin: Date; end: Date }[] = [];
        for (let i = 0; i < this.daysOff.daysOff.length; i++) {
            const userHolidaysForDay = this.daysOff.daysOff[i].daysOff.filter(
                (dayOff) =>
                    this.dateService.isInRange(date, dayOff.begin, dayOff.end)
            );
            daysOff = daysOff.concat(
                userHolidaysForDay.map((h) => {
                    return {
                        userId: this.daysOff.daysOff[i].user.id,
                        user: this.daysOff.daysOff[i].user,
                        userAvatar: this.userService.getAvatarForUser(
                            this.daysOff.daysOff[i].user
                        ),
                        currentUser:
                            this.currentUser.id ===
                            this.daysOff.daysOff[i].user.id,
                        begin: h.begin,
                        end: h.end,
                        type: h.type,
                    };
                })
            );
        }

        return daysOff;
    }
}
