import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { TeamService } from "services/teams/TeamService";
import { PerfectScrollbarService } from "services/perfectscrollbar/PerfectScrollbarService";
import { DisplayService } from "services/display/DisplayService";

import { CompanyUser } from "models/companyusers/CompanyUser";
import { Team } from "models/teams/Team";
import { TeamViewMode } from "models/teams/TeamViewMode";
import { teamsMenuI18N } from "./i18n/teams-menu-i18n";

@Component({
    selector: "teams-menu",
    templateUrl: "teams-menu.html",
    host: {
        "[class.c-teams-menu]": "true",
        "(window:resize)": "onResize($event)",
    },
})
export class TeamsMenuComponent implements OnInit {
    private static BREAKPOINT_MEDIUM = 992;
    @Output() public selection = new EventEmitter<{
        code?: string;
        predefinedCode?: PredefinedTeamCode;
    }>();
    @Output() public drag = new EventEmitter<{
        elementCode: string;
        teamCode: string;
    }>();
    @Output() public mode = new EventEmitter<TeamViewMode>();
    @Input() public currentUserStream: Observable<CompanyUser>;
    @Input() public elementList: (team: Team) => string[];
    @Input() public showPending = true;
    @Input() public i18n;
    @Input() public teamsStream: Observable<Team[]>;
    @Input() public getViewClasses = false;
    @Input() public holidaysView = false;

    public removeTeamModalShowStream = new ReplaySubject<boolean>(1);
    public currentUser: CompanyUser;
    public teams: Team[] = [];
    public allTeams: Team[] = [];
    public selected = "active";
    public selectedMode: string = null;
    public tI18n;
    public addNewTeamInputVisible = false;
    public addTeamForm: FormGroup = null;
    public removeTeamVisible = false;
    public teamToRemove: Team;
    public dragOver: HTMLElement = null;
    public dragEnd = true;
    public openTeamListFlag = false;
    public navbarOpen = false;
    public initValueFilter = "";
    public oLayoutTopStart: number;
    public wrapperMenuPaddingBottom: string;
    public lastInnerWidth = window.innerWidth;
    private timepotContainer = document.getElementsByClassName("c-timepot")[0];

    public isWidthChangedAndBiggerThanBreakpoint(
        currentWidth,
        lastWidth
    ): boolean {
        this.lastInnerWidth = currentWidth;
        return (
            currentWidth > lastWidth &&
            lastWidth < DisplayService.VIEW_BREAKPOINT
        );
    }

    constructor(
        private teamService: TeamService,
        private i18nService: I18NService,
        private element: ElementRef,
        private perfectScrollbar: PerfectScrollbarService
    ) {
        this.tI18n = this.i18nService.extractCurrentTranslation(teamsMenuI18N);
        this.addTeamForm = new FormGroup({
            newTeam: new FormControl(),
        });
    }

    public ngOnInit() {
        combineLatest([this.teamsStream, this.currentUserStream]).subscribe(
            (data) => {
                const teams = data[0];
                this.currentUser = data[1];
                this.selectedMode =
                    this.selectedMode || this.getModeForUser(this.currentUser);
                this.allTeams = teams;
                if (this.selectedMode === TeamViewMode.MY_TEAMS.toString()) {
                    this.setMyTeams();
                } else {
                    this.setAllTeams();
                }

                this.setHeightOnWrapperMenu();
            }
        );
        this.removeTeamModalShowStream.subscribe((v) => {
            this.removeTeamVisible = v;
            if (!v) {
                if (
                    this.teamToRemove &&
                    this.selected === this.teamToRemove.code
                ) {
                    this.selectActive();
                }
                this.teamToRemove = null;
            }
        });

        this.initValueFilter = this.i18n.active;
        if (this.getViewClasses) {
            /* eslint-disable @typescript-eslint/no-explicit-any */
            this.oLayoutTopStart = <any>(
                document
                    .querySelector(".o-layout__item")
                    .getBoundingClientRect().top
            );
            /* eslint-enable @typescript-eslint/no-explicit-any */
            this.wrapperMenuPaddingBottom = window
                .getComputedStyle(
                    document.querySelector(".c-teams-menu__wrapper-sterch"),
                    null
                )
                .getPropertyValue("padding-bottom");
        }

        if (navigator.userAgent.indexOf("Chrome") !== -1) {
            window.addEventListener("ps-scroll-up", this.stickyTeamList);
            window.addEventListener("ps-scroll-down", this.stickyTeamList);
        }
    }

    public setHeightOnWrapperMenu = () => {
        if (!this.getViewClasses) {
            return;
        }
        setTimeout(() => {
            const cSearchHeight = document
                .querySelector(".c-search")
                .getBoundingClientRect().height;
            /* eslint-disable @typescript-eslint/no-explicit-any */
            const wrapperMenu = <any>(
                document.querySelector(".c-teams-menu__wrapper-sterch")
            );

            /* eslint-enable @typescript-eslint/no-explicit-any */
            if (
                document.querySelector(".c-user-list") &&
                document.body.getBoundingClientRect().width >
                    TeamsMenuComponent.BREAKPOINT_MEDIUM
            ) {
                const cUserListHeight = document
                    .querySelector(".c-user-list")
                    .getBoundingClientRect().height;

                if (
                    document.body.getBoundingClientRect().height >
                    cSearchHeight + cUserListHeight
                ) {
                    wrapperMenu.style.height = `calc(100vh - ${this.oLayoutTopStart}px)`;
                    wrapperMenu.style.paddingBottom = "0";
                } else {
                    wrapperMenu.style.height = "100vh";
                    wrapperMenu.style.paddingBottom =
                        this.wrapperMenuPaddingBottom;
                }
            } else if (
                document.querySelector(".c-projects-list") &&
                document.body.getBoundingClientRect().width >
                    TeamsMenuComponent.BREAKPOINT_MEDIUM
            ) {
                const cUserListHeight = document
                    .querySelector(".c-projects-list")
                    .getBoundingClientRect().height;

                if (
                    document.body.getBoundingClientRect().height >
                        cSearchHeight + cUserListHeight &&
                    document.body.getBoundingClientRect().width >
                        TeamsMenuComponent.BREAKPOINT_MEDIUM
                ) {
                    wrapperMenu.style.height = `calc(100vh - ${this.oLayoutTopStart}px)`;
                    wrapperMenu.style.paddingBottom = "0";
                } else {
                    wrapperMenu.style.height = "100vh";
                    wrapperMenu.style.paddingBottom =
                        this.wrapperMenuPaddingBottom;
                }
            } else {
                wrapperMenu.style.height = "100vh";
                wrapperMenu.style.paddingBottom = "0";
            }
        });
    };

    public stickyTeamList = () => {
        if (!this.getViewClasses) {
            return;
        }

        /* eslint-disable @typescript-eslint/no-explicit-any */
        const oLayout = <any>document.querySelector(".o-layout__item");
        const wrapperMenu = <any>(
            document.querySelector(".c-teams-menu__wrapper-sterch")
        );
        if (!oLayout || !wrapperMenu) {
            return;
        }
        /* eslint-enable @typescript-eslint/no-explicit-any */
        const oLayoutTop = oLayout.getBoundingClientRect().top;
        const wrapperMenuTop = wrapperMenu.getBoundingClientRect().top;
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const option = <any>(
            document.querySelector(".c-teams-menu__wrapper-options")
        );
        /* eslint-enable @typescript-eslint/no-explicit-any */

        if (
            document.body.getBoundingClientRect().width >
            TeamsMenuComponent.BREAKPOINT_MEDIUM
        ) {
            if (oLayoutTop < 0) {
                option.style.marginBottom =
                    (wrapperMenuTop - this.oLayoutTopStart).toString() + "px";
                wrapperMenu.style.marginTop =
                    (wrapperMenuTop - this.oLayoutTopStart).toString() + "px";
            } else if (oLayoutTop > 0) {
                option.style.marginBottom = "0";
                wrapperMenu.style.marginTop = "0";
            }
        } else {
            option.style.marginBottom = "0";
            wrapperMenu.style.marginTop = "0";
        }
    };

    public onResize(event) {
        this.stickyTeamList();
        this.setHeightOnWrapperMenu();

        if (
            this.isWidthChangedAndBiggerThanBreakpoint(
                event.target.innerWidth,
                this.lastInnerWidth
            ) &&
            this.openTeamListFlag
        ) {
            this.openTeamListFlag = false;
            this.timepotContainer.classList.remove("c-timepot--navbar-open");
            this.perfectScrollbar.initScrollbar();
        }
    }

    public selectTeam(team: Team) {
        this.selected = team.code;
        this.selection.emit({
            code: team.code,
        });
    }

    public selectActive() {
        this.selected = "active";
        this.selection.emit({
            predefinedCode: PredefinedTeamCode.ACTIVE,
        });
    }

    public selectInactive() {
        this.selected = "inactive";
        this.selection.emit({
            predefinedCode: PredefinedTeamCode.INACTIVE,
        });
    }

    public selectInvited() {
        this.selected = "invited";
        this.selection.emit({
            predefinedCode: PredefinedTeamCode.PENDING,
        });
    }

    public allowDrop(elem: HTMLElement, event: DragEvent) {
        this.dragOver = elem;
        event.preventDefault();
    }

    public dragLeaveEvent(event) {
        this.dragOver = null;
        event.preventDefault();
    }

    public dropElement(event: DragEvent, team: Team) {
        event.preventDefault();
        this.dragOver = null;

        const elementCode = event.dataTransfer.getData("text/plain");
        if (elementCode) {
            // can be either users or projects
            const elements = this.elementList(team);
            if (!elements.find((u) => u === elementCode)) {
                elements.push(elementCode);
                this.teamService.updateTeam(team).subscribe(() => {
                    this.drag.emit({
                        elementCode: elementCode,
                        teamCode: team.code,
                    });
                });
            }
        }
    }

    public setMyTeams() {
        this.selectedMode = TeamViewMode.MY_TEAMS.toString();
        this.teams = this.allTeams.filter(
            (team) => !!this.currentUser.teams.find((t) => t.code === team.code)
        );
        this.mode.emit(TeamViewMode.MY_TEAMS);
    }

    public setAllTeams() {
        this.selectedMode = TeamViewMode.ALL.toString();
        this.teams = this.allTeams;
        this.mode.emit(TeamViewMode.ALL);
    }

    public addTeam(newTeamName: string) {
        const userCodes: string[] = [];
        if (this.selectedMode === TeamViewMode.MY_TEAMS.toString()) {
            userCodes.push(this.currentUser.id);
        }
        if (newTeamName) {
            this.teamService
                .createTeam({
                    code: null,
                    name: newTeamName,
                    readOnly: false,
                    userCodes,
                    projectCodes: [],
                    id: null,
                })
                .subscribe(
                    () => {
                        this.resetAddTeamForm();
                    },
                    () => {
                        this.resetAddTeamForm();
                    }
                );
        } else {
            this.resetAddTeamForm();
        }
    }

    public resetAddTeamForm() {
        this.addNewTeamInputVisible = false;
        this.addTeamForm.get("newTeam").setValue("");
    }

    public showAndFocusNewTeamInput() {
        this.addNewTeamInputVisible = true;
        setTimeout(() => {
            this.element.nativeElement.querySelector("input").focus();
        }, 0);
    }

    public showRemoveTeamModal(team: Team) {
        this.teamToRemove = team;
        this.removeTeamModalShowStream.next(true);
        return false;
    }

    private getModeForUser(cu: CompanyUser) {
        if (cu.admin) {
            return TeamViewMode.ALL.toString();
        }
        return TeamViewMode.MY_TEAMS.toString();
    }

    public isUnderDrag(elem: HTMLElement) {
        return elem === this.dragOver;
    }

    public openTeamList(addClassToBody = true) {
        this.openTeamListFlag = !this.openTeamListFlag;

        if (this.openTeamListFlag && addClassToBody) {
            this.perfectScrollbar.destroyScrollbar();
            this.timepotContainer.classList.add("c-timepot--navbar-open");
        } else {
            this.timepotContainer.classList.remove("c-timepot--navbar-open");
            this.perfectScrollbar.initScrollbar();
        }
    }

    public getText(elem) {
        this.initValueFilter = elem.outerText;
    }
}

export enum PredefinedTeamCode {
    ACTIVE,
    PENDING,
    INACTIVE,
}
