import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, startWith } from "rxjs/operators";

const DEBOUNCE_TIME = 100;

@Component({
    selector: "search",
    templateUrl: "search.html",
    host: {
        "[class.c-search]": "true",
        "[class.c-search--active]": "isSearchVisible",
    },
})
export class SearchComponent implements OnInit {
    @ViewChild("sinput") public vc: ElementRef;
    @Input() public placeholder: string;
    @Input() public dataStream: Observable<{ name: string }[]>;
    @Output() public match = new EventEmitter<{ name: string }[]>();
    public searchForm: FormGroup = null;
    public isEmptySearchInput = true;
    public isSearchVisible = false;

    constructor() {
        this.searchForm = new FormGroup({
            search: new FormControl(),
        });
    }
    public ngOnInit() {
        const searchChangesStream = this.searchForm.valueChanges.pipe(
            debounceTime(DEBOUNCE_TIME),
            distinctUntilChanged(),
            startWith({ search: "" })
        );
        combineLatest([this.dataStream, searchChangesStream]).subscribe((d) => {
            const data = d[0];
            const form = d[1];
            this.isEmptySearchInput =
                form.search === "" || form.search === null;
            if (this.isEmptySearchInput) {
                this.match.emit(data);
            } else {
                this.match.emit(
                    data.filter((item) => this.matches(item.name, form.search))
                );
            }
        });
    }

    public clear() {
        this.searchForm.reset();
        this.hideSearch();
    }

    public hideSearch() {
        this.isSearchVisible = false;
    }

    public changeSearchState() {
        this.isSearchVisible = !this.isSearchVisible;

        if (this.isSearchVisible) {
            setTimeout(() => this.vc.nativeElement.focus(), 100);
        }
    }

    private matches(name: string, search: string): boolean {
        return name.toLowerCase().includes(search.toLowerCase());
    }
}
