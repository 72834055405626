import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { I18NService } from "../i18n/I18NService";
import { pricingI18N } from "./i18n/pricing-i18n";

@Injectable()
export class PricingDisplayService {
    private i18n;

    constructor(i18nService: I18NService, private datePipe: DatePipe) {
        this.i18n = i18nService.extractCurrentTranslation(pricingI18N);
    }

    public getFormattedAmount(
        isHourlyRate: boolean,
        amount: number,
        currency: string
    ) {
        return isHourlyRate
            ? this.i18n.hourlyRateFormat(amount, currency)
            : this.i18n.monthlyRateFormat(amount, currency);
    }

    public getFormattedDate(isHourlyRate: boolean, date: Date) {
        if (!date) {
            return this.i18n.noValuePlaceholder;
        }
        return isHourlyRate
            ? this.datePipe.transform(date, "dd.MM.y")
            : this.datePipe.transform(date, "MMMM y");
    }
}
