<div id="donutchart-chart" class="c-donutchart__container">
    <div
        id="donutchart-container"
        width="360"
        height="350"
        class="c3"
        style="max-height: 336.094px; position: relative"
    >
        <!-- DonutChart placed here -->
    </div>
    <div class="c-donutchart__donut-inner" id="donut-inner">
        <b *ngIf="duration">{{getDuration(duration)}}</b>
        <div>{{selectedLabel}}</div>
    </div>
</div>
<div class="c-donutchart__labels c-donutchart__labels--left">
    <ul class="c-donutchart__labels-list">
        <li
            *ngFor="let item of itemsOnTheLeft"
            (click)="selectEntry(item.data)"
        >
            <div class="c-donutchart__labels-list-element">
                <span
                    class="c-donutchart__labels-list-element-value"
                    showOverflownTitle
                >
                    {{getDuration(item.minutes)}}
                </span>
                <span
                    class="c-donutchart__labels-list-element-name"
                    [ngStyle]="{ color: item.colorHash }"
                    showOverflownTitle
                    >{{item.label}}</span
                >
            </div>
        </li>
    </ul>
</div>
<div class="c-donutchart__labels c-donutchart__labels--right">
    <ul class="c-donutchart__labels-list">
        <li
            *ngFor="let item of itemsOnTheRight"
            (click)="selectEntry(item.data)"
        >
            <div class="c-donutchart__labels-list-element">
                <span
                    class="c-donutchart__labels-list-element-value"
                    showOverflownTitle
                >
                    {{getDuration(item.minutes)}}
                </span>
                <span
                    class="c-donutchart__labels-list-element-name"
                    [ngStyle]="{ color: item.colorHash }"
                    showOverflownTitle
                    >{{item.label}}</span
                >
            </div>
        </li>
    </ul>
</div>
