<div [@dialog] class="c-generic-modal__modal" role="dialog" *ngIf="visible">
    <div class="c-generic-modal__dialog">
        <div class="o-box o-box--wide">
            <button
                (click)="close()"
                class="o-box__close"
                *ngIf="showClose"
            ></button>

            <div class="c-generic-modal__modal-header">
                <h4 class="c-generic-modal__modal-header-title">{{title}}</h4>
                <p class="c-generic-modal__modal-header-subtitle">
                    {{subtitle}}
                </p>
            </div>

            <div class="c-generic-modal__modal-body">
                <ng-content></ng-content>
            </div>

            <div
                class="c-generic-modal__modal-footer"
                *ngIf="buttonsVisible && !busyIndicator"
            >
                <button
                    *ngIf="showClose"
                    class="o-btn o-btn--large o-btn--link"
                    (click)="close()"
                    data-id="cancel"
                >
                    {{i18n.cancel}}
                </button>
                <button
                    *ngIf="showReset"
                    class="o-btn o-btn--large o-btn--link"
                    (click)="reset()"
                    data-id="reset"
                >
                    {{i18n.reset}}
                </button>
                <button
                    class="o-btn o-btn--large o-btn--success"
                    data-id="ok"
                    (click)="ok()"
                    [ngClass]="{'o-btn--disabled': !okEnabled}"
                >
                    {{confirmLabel || i18n.ok}}
                </button>
            </div>

            <div
                class="c-generic-modal__modal-footer c-generic-modal__modal-footer--loader"
                *ngIf="busyIndicator"
            >
                <div class="u-loader u-loader--relative"></div>
            </div>
        </div>
    </div>
    <div class="c-generic-modal__overlay" (click)="close()"></div>
</div>
