<div class="c-user-day-activity__row">
    <div class="c-user-day-activity__cell c-user-day-activity__cell--first">
        {{userName()}}
    </div>
    <div class="c-user-day-activity__cell c-user-day-activity__cell--wrapper">
        <user-day-activity-details
            *ngFor="let details of dayActivity.dayActivityDetails"
            [details]="details"
        ></user-day-activity-details>
    </div>
</div>
