<main-header [pageTitle]="i18n.pageTitle">
    <a
        data-id="add-project-modal"
        [attr.data-tooltip]="i18n.addProject"
        class="o-add-button u-tooltip--left"
        data-toggle="modal"
        data-target="#invite_users_form"
        (click)="showAddProjectModal()"
    >
        <svg class="o-add-button__main-icon">
            <use [attr.xlink:href]="'images/menu/all.svg#projects'"></use>
        </svg>
        <svg class="o-add-button__plus-icon">
            <use [attr.xlink:href]="'images/menu/all.svg#plus-circle'"></use>
        </svg>
    </a>
</main-header>

<div class="o-layout o-layout__stretch">
    <div class="o-layout__item o-layout__item--teams-menu">
        <teams-menu
            [teamsStream]="teamsStream"
            [currentUserStream]="currentUserStream"
            [elementList]="getProjectCodes"
            [i18n]="i18n"
            (drag)="onProjectDragged($event)"
            (mode)="onModeChanged($event)"
            [showPending]="false"
            (selection)="onTeamSelected($event)"
        ></teams-menu>
    </div>
    <div class="o-layout__item">
        <search
            [dataStream]="projectsInCurrentTeamStream"
            (match)="onSearchMatched($event)"
            [placeholder]="i18n.findProjects"
        ></search>

        <projects-list
            [teamsStream]="teamsStream"
            [projectsStream]="filteredProjectsStream"
            [projectModalStream]="projectModalStream"
        >
        </projects-list>
    </div>
</div>

<project-modal [projectObservable]="projectModalStream"></project-modal>
