<!-- trigger on mobile -->
<div class="c-teams-menu__trigger-on-mobile" (click)="openTeamList()">
    <svg><use [attr.xlink:href]="'images/menu/all.svg#filter'"></use></svg
    ><span class="c-teams-menu__team-text">{{initValueFilter}}</span>
</div>

<div
    class="c-teams-menu__wrapper-sterch"
    [ngClass]="{'c-teams-menu__wrapper-sterch--open-on-mobile': openTeamListFlag}"
>
    <div class="c-teams-menu__wrapper">
        <!-- all teams / my teams toggle -->
        <ul class="c-teams-menu__wrapper-toggle">
            <li
                class="c-teams-menu__item-toggle"
                (click)="setAllTeams()"
                [ngClass]="{'c-teams-menu__item-toggle--selected': selectedMode === '0'}"
            >
                {{i18n.allTeams}}
            </li>
            <li
                class="c-teams-menu__item-toggle"
                *ngIf="currentUser"
                (click)="setMyTeams()"
                [ngClass]="{'c-teams-menu__item-toggle--selected': selectedMode === '1'}"
            >
                {{i18n.myTeams}}
            </li>
        </ul>

        <div
            class="c-teams-menu__header-list c-teams-menu__header-list--desktop"
            #elemActive
            (click)="selectActive(); openTeamList(false); getText(elemActive)"
            [ngClass]="{'c-teams-menu__header-list--active': selected === 'active'}"
        >
            <span class="c-teams-menu__team-text">{{i18n.active}}</span>
        </div>

        <div class="c-teams-menu__wrapper-to-scroll ps-child">
            <div class="c-teams-menu__wrapper-to-scroll--inner">
                <!-- all users / projects pseudo team -->
                <div
                    class="c-teams-menu__header-list c-teams-menu__header-list--mobile"
                    #elemActive
                    (click)="selectActive(); openTeamList(false); getText(elemActive)"
                    [ngClass]="{'c-teams-menu__header-list--active': selected === 'active'}"
                >
                    <span class="c-teams-menu__team-text">{{i18n.active}}</span>
                </div>

                <!-- real teams -->
                <ul class="c-teams-menu__teams">
                    <li
                        *ngFor="let team of teams"
                        (click)="openTeamList(false)"
                        #elem
                        (click)="selectTeam(team); getText(elem)"
                        (dragover)="allowDrop(elem, $event)"
                        (drop)="dropElement($event, team)"
                        (dragleave)="dragLeaveEvent($event)"
                        [ngClass]="{'c-teams-menu__team--selected': selected === team.code, 'c-teams-menu__team--drag': isUnderDrag(elem)}"
                        class="c-teams-menu__team"
                    >
                        <span class="c-teams-menu__team-text"
                            >{{team.name}}</span
                        >
                        <span
                            class="c-teams-menu__remove-team"
                            (click)="showRemoveTeamModal(team)"
                        >
                            <svg>
                                <use
                                    [attr.xlink:href]="'images/menu/all.svg#remove'"
                                ></use>
                            </svg>
                        </span>
                    </li>
                </ul>
                <div *ngIf="!holidaysView">
                    <div
                        [hidden]="!addNewTeamInputVisible"
                        [formGroup]="addTeamForm"
                        (keyup.esc)="resetAddTeamForm()"
                        (keyup.enter)="addTeam(addTeamForm.get('newTeam').value)"
                    >
                        <input
                            class="c-teams-menu__create-new-item-input"
                            formControlName="newTeam"
                            type="text"
                            (blur)="addTeam(addTeamForm.get('newTeam').value)"
                            [placeholder]="i18n.addTeam"
                        />
                    </div>
                    <div
                        class="c-teams-menu__create-new-team"
                        [hidden]="addNewTeamInputVisible"
                        (click)="showAndFocusNewTeamInput()"
                    >
                        {{i18n.addTeam}}
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!holidaysView">
            <!-- invited users (not present for projects at all) -->
            <div class="c-teams-menu__wrapper-options">
                <div
                    class="c-teams-menu__options-item"
                    #elem
                    *ngIf="showPending"
                    (click)="selectInvited(); getText(elem); openTeamList(false)"
                    [ngClass]="{'c-teams-menu__options-item--active': selected === 'invited'}"
                >
                    <span class="c-teams-menu__team-text"
                        >{{i18n.pending}}</span
                    >
                </div>

                <!-- archived projects / deactivated users -->
                <div
                    class="c-teams-menu__options-item"
                    #elem
                    (click)="getText(elem); selectInactive(); openTeamList(false)"
                    [ngClass]="{'c-teams-menu__options-item--active': selected === 'inactive'}"
                >
                    <span class="c-teams-menu__team-text"
                        >{{i18n.inactive}}</span
                    >
                </div>
            </div>
        </div>

        <div *ngIf="removeTeamVisible">
            <remove-team-modal
                [team]="teamToRemove"
                [showStream]="removeTeamModalShowStream"
            ></remove-team-modal>
        </div>
    </div>
</div>

<div
    [ngClass]="{'c-navbar__overflow': openTeamListFlag}"
    (click)="openTeamList(false)"
    [style.display]="openTeamListFlag ? 'block' : 'none'"
></div>
