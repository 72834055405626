export const timesheetI18NPl = {
    cancel: "Aunluj",
    youAreAtTimepotOf: (name) =>
        "Przeglądasz teraz Timepot użytkownika " + name,
    newTaskTitle: "Dodaj zadanie do projektu",
    editTaskTitle: "Edytuj zadanie",
    taskNamePlaceholder: "Nazwa zadania",
    noTasksInProject: "Brak zadań w projekcie",
    add: "Dodaj",
    save: "Zapisz",
    pageTitle: "Twój tydzień",
    findOrAddTask: "Znajdź lub dodaj zadanie",
    selectTaskFromList: "Wybierz zadanie z listy",
    chooseTaskFirst: "Wybierz zadanie",
    selectProject: "Wybierz projekt",
    enterTask: "Wprowadź zadanie",
    startTimer: "Uruchom stoper...",
    orLogTimeManually: "...lub dodaj czas ręcznie",
    logYourTime: "Dodaj czas",
    watchTutorial: "Obejrzyj przewodnik",
    taskSealed: "Edycja zadania jest zablokowana",
    sealTimepot: "Zapieczętuj Timepot",
    seal: "Zapieczętuj",
    timepot: "Timepota",
    sealDateTitle: "Wybierz datę zapieczętowania Timepota",
    sealDateExplanation:
        "Uwaga: użytkownik nie będzie mógł edytować żadnych aktywności w dniach poprzedzających wybraną datę. W szczególności nie będzie " +
        "mógł zmienić ani usunąć zadań.",
    billable: "Płatne",
    nonBillable: "Niepłatne",
    nonBillableTask: "Niepłatne zadanie",
};
