<generic-modal
    [showStream]="showStream"
    [actionStream]="actionStream"
    [okEnabled]="true"
>
    <div class="c-project-teams-modal__header">
        <span class="c-project-teams-modal__header-name"
            >{{i18n.removeTeam(team.name)}}</span
        >
    </div>
    <ng-container> {{i18n.removeTeamNotice(team.name)}} </ng-container>
</generic-modal>
