<div
    class="c-timepot-sealer__container"
    [ngClass]="{'c-timepot-sealer__container--active' : isDatePickerOpened}"
    *ngIf="enableSealing"
    (click)="openDatePicker($event)"
>
    <span class="c-timepot-sealer__label">{{i18n.seal}}</span>
    <svg class="c-timepot-sealer__icon">
        <use [attr.xlink:href]="'images/menu/all.svg#seal'"></use>
    </svg>
    <span class="c-timepot-sealer__label">{{i18n.timepot}}</span>
</div>

<sealer-flyout
    *ngIf="isDatePickerOpened"
    [userId]="userId"
    [flyoutState]="flyoutState"
></sealer-flyout>
