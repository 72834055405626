<div *ngFor="let snapshot of history.snapshots">
    <user-payment-history-entry
        [snapshot]="snapshot"
        [paymentType]="history.type"
        [currency]="currency"
        [userId]="userId"
        [workingHoursInDay]="workingHoursInDay"
        (save)="onEntrySave()"
    >
    </user-payment-history-entry>
</div>
