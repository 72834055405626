import { financialReportsI18NDe } from "./financial-reports-i18n-de";
import { financialReportsI18NPl } from "./financial-reports-i18n-pl";

const financialReportsI18NEn = {
    configure: "Configure",
    userSalaries: "user salaries",
    and: "and",
    projectRates: "project rates",
    generateReports: "and we'll generate the financial reports for you.",
    pageTitle: "Finances",
    income: "Income",
    expenses: "Expenses",
    totalBalance: "Total balance",
    project: "Project",
    projects: "Projects",
    noResults: "No results for current filters",
};

export const financialReportsI18N = {
    de: financialReportsI18NDe,
    pl: financialReportsI18NPl,
    en: financialReportsI18NEn,
};
