<generic-modal
    *ngIf="isDemoMode()"
    [showStream]="showModalStream"
    [buttonsVisible]="false"
    [actionStream]="actionStream"
    [title]="i18n.enjoyedUsingTimepot"
>
    <div class="c-call-to-action-modal__container">
        <a
            href="{{signUpHref()}}"
            target="_blank"
            class="o-btn o-btn--large o-btn--success"
        >
            <span class="c-call-to-action-modal__label"
                >{{i18n.createAccount}}</span
            >
            <span class="c-call-to-action-modal__label-short"
                >{{i18n.createAccountShort}}</span
            >
        </a>
        <div class="c-call-to-action-modal__or">{{i18n.or}}</div>
        <a
            href="mailto:timepot@timepot.io?subject={{encodedMailSubject}}&body={{encodedMailBody}}"
            target="_blank"
            class="o-btn o-btn--large o-btn--danger"
            >{{i18n.sendFeedback}}</a
        >
    </div>
</generic-modal>

<a
    *ngIf="isDemoMode()"
    href="{{signUpHref()}}"
    class="o-btn o-btn--success u-signup"
    >{{i18n.signUpNow}}</a
>
