import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { I18NService } from "services/i18n/I18NService";
import { projectsI18N } from "../../../i18n/projects-i18n";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { UserRateOverride } from "models/projects/UserRateOverride";

@Component({
    selector: "user-rate-overrides",
    templateUrl: "user-rate-overrides.html",
    host: { "[class.c-user-rate-overrides]": "true" },
})
export class UserRateOverridesComponent implements OnInit {
    @Input() public initialOverrides: UserRateOverride[];
    @Input() public users: CompanyUser[];
    @Output() public dataChanged = new EventEmitter<UserRateOverride[]>();
    public i18n;
    public currentOverrides: UserRateOverride[];
    private workingCopy: UserRateOverride[];
    private usersAvailableForOverride: { id: string; name: string }[][] = [];
    constructor(i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(projectsI18N);
    }
    public ngOnInit() {
        this.currentOverrides = this.initialOverrides.slice();
        this.workingCopy = this.currentOverrides.slice();
    }

    public getAvailableUsersForOverride(idx: number) {
        this.recalculateUsersAvailableForOverride();
        return this.usersAvailableForOverride[idx] || [];
    }

    public updateOverride(override: UserRateOverride, idx: number) {
        this.workingCopy[idx] = override;
        this.recalculateUsersAvailableForOverride(true);
        this.dataChanged.emit(this.workingCopy);
    }

    public addOverride() {
        this.currentOverrides = this.workingCopy;
        this.currentOverrides.push({ rate: undefined, userCode: null });
        this.workingCopy = this.currentOverrides.slice();
        this.recalculateUsersAvailableForOverride(true);
        this.dataChanged.emit(this.currentOverrides);
    }

    public removeOverride(override: UserRateOverride, idx: number) {
        this.currentOverrides = this.workingCopy;
        this.currentOverrides.splice(idx, 1);
        this.workingCopy = this.currentOverrides.slice();
        this.recalculateUsersAvailableForOverride(true);
        this.dataChanged.emit(this.currentOverrides);
    }

    private recalculateUsersAvailableForOverride(force = false) {
        if (this.usersAvailableForOverride.length === 0 || force) {
            for (let i = 0; i < this.workingCopy.length; i++) {
                this.usersAvailableForOverride[i] =
                    this.calculateUsersForOverride(this.workingCopy[i]);
            }
        }
    }

    private calculateUsersForOverride(override: UserRateOverride) {
        const usersInOverrides = new Set<string>();
        this.workingCopy.forEach((o) => usersInOverrides.add(o.userCode));
        return this.users
            .filter(
                (user) =>
                    user.userId === override.userCode ||
                    !usersInOverrides.has(user.userId)
            )
            .map((user) => {
                return { id: user.userId, name: user.name };
            });
    }
}
