<div class="o-form__group">
    <span class="o-form__label">{{i18n.currency}}</span>
    <div class="o-form__input-group">
        <generic-dropdown
            [items]="currenciesArray"
            [selection]="selected"
            [disabled]="!noInitialPricing"
            [initialSelectionStream]="preselected"
            [selectMessage]="''"
            [selectOnSwitch]="false"
            [searchPlaceholder]="i18n.selectCurrency"
        >
        </generic-dropdown>
    </div>
</div>

<div class="o-form__group">
    <span class="o-form__label">{{i18n.value}}</span>
    <div class="o-form__input-group">
        <input
            class="o-form__input"
            [ngModel]="currentPricing.rate"
            #rate
            id="rate"
            (keyup)="setRate(rate.value)"
            name="rate"
        />
        <label
            for="rate"
            class="o-form__validation"
            [class.u-hidden]="rateValid || !rateTouch"
        >
            <span
                class="o-form__validation-msg o-form__validation-msg--error"
                [ngClass]="{'u-hidden': rateValid}"
            >
                {{i18n.invalid}}
            </span>
        </label>
    </div>
</div>

<div class="o-form__group">
    <span class="o-form__label">{{i18n.estimatedWorkload}}</span>
    <div class="o-form__input-group">
        <span class="o-form__input-group-addon">{{i18n.hourShort}}</span>
        <input
            class="o-form__input"
            [ngModel]="currentPricing.expectedWorkload"
            #workload
            id="workload"
            (keyup)="setExpectedWorkload(workload.value)"
            name="workload"
        />
        <label
            for="workload"
            class="o-form__validation"
            [class.u-hidden]="workloadValid || !workloadTouch"
        >
            <span
                class="o-form__validation-msg o-form__validation-msg--error"
                [ngClass]="{'u-hidden': workloadValid}"
            >
                {{i18n.invalid}}
            </span>
        </label>
    </div>
</div>
