import { Component, Input, OnInit } from "@angular/core";
import { UserService } from "../../services/user/UserService";
import { User } from "../../models/users/User";

@Component({
    selector: "user-avatar",
    template: `<div class="c-user-avatar__photo">
        <img class="c-user-avatar__photo-img" src="{{ avatarUrl }}" alt="" />
    </div>`,
})
export class UserAvatarComponent implements OnInit {
    @Input()
    public user: User;

    public avatarUrl: string;

    constructor(private userService: UserService) {}

    public ngOnInit(): void {
        this.avatarUrl = this.userService.getAvatarForUser(this.user);
    }
}
