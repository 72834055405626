<div
    class="c-month-picker__popdown"
    [ngClass]="{'c-month-picker__popdown--align-left': alignLeft}"
>
    <div class="c-month-picker__popdown-years">
        <div
            class="c-month-picker__popdown-year c-month-picker__popdown-year--prev"
            (click)="previousYear()"
        >
            <i class="u-icon u-icon-angle-left"></i>
        </div>
        <div class="c-month-picker__popdown-year">{{year}}</div>
        <div
            class="c-month-picker__popdown-year c-month-picker__popdown-year--next"
            (click)="nextYear()"
        >
            <i class="u-icon u-icon-angle-right"></i>
        </div>
    </div>
    <div class="c-month-picker__popdown-months">
        <div
            *ngFor="let m of months"
            class="c-month-picker__popdown-month"
            [ngClass]="{'c-month-picker__popdown-month--selected': isSelected(m),
                    'c-month-picker__popdown-month--disabled': isDisabled(m)}"
            (click)="!isDisabled(m) && select(m)"
        >
            {{i18n.monthNames[m]}}
        </div>
    </div>
</div>
