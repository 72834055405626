import { CompanyUser } from "../../models/companyusers/CompanyUser";
import { Injectable } from "@angular/core";
import { CurrentCompanyUserService } from "./CurrentCompanyUserService";
import { UserService } from "../user/UserService";
import { ConfigService } from "../config";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class CompanyPickerService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private userService: UserService,
        private currentCompanyUserService: CurrentCompanyUserService
    ) {}

    public pickCompany(companyUser: CompanyUser) {
        return this.http
            .post(this.configService.currentCompanyEndpoint(), {
                companyId: companyUser.companyId,
            })
            .subscribe(() => window.location.reload());
    }
}
