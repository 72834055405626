import { navbarI18NPl } from "./navbar-i18n-pl";
import { navbarI18NDe } from "./navbar-i18n-de";

const navbarI18NEn = {
    api: "API",
    introductoryTour: "Introductory tour",
    timesheet: "Your week",
    reports: "Reports",
    finances: "Finances ",
    projects: "Projects",
    holidays: "Holidays",
    users: "Users",
    daysOff: "Days off",
    companyName: (name) => `${name}’s Timepot`,
    createNewCompany: "Create New Timepot",
    editProfile: "Edit Profile",
    settings: "Settings",
    sendUsFeedback: "Send us feedback",
    signOut: "Sign Out",
};

export const navbarI18N = {
    de: navbarI18NDe,
    en: navbarI18NEn,
    pl: navbarI18NPl,
};
