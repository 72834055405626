<message-header></message-header>
<modal-core>
    <div class="c-send-reset-password-email__header">
        <h1 class="c-send-reset-password-email__header-title">
            {{i18n.resetPassword}}
        </h1>
    </div>

    <p class="u-form-group">{{i18n.resetPasswordDescription}}</p>

    <form
        class="c-send-reset-password-email__form"
        [formGroup]="sendLinkForm"
        (ngSubmit)="submit($event)"
    >
        <div class="u-form-group">
            <input
                class="u-form-control"
                placeholder="{{i18n.email}}"
                formControlName="email"
            />
        </div>
        <div
            *ngIf="emailSent"
            class="c-send-reset-password-email__valid u-form-group"
        >
            {{i18n.resetPasswordEmailSent}}
        </div>
        <div class="u-form-group">
            <button
                class="o-btn o-btn--large o-btn--primary c-send-reset-password-email__form-btn"
                [disabled]="!sendLinkForm.valid"
                type="submit"
            >
                {{i18n.send}}
            </button>
        </div>
    </form>
</modal-core>
