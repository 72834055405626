import { Component, Input, OnInit } from "@angular/core";
import { TimesheetService } from "services/timesheet/TimesheetService";
import { ReplaySubject } from "rxjs";
import { FilterSettings } from "models/reports/filters/FilterSettings";
import { UserDayTaskActivities } from "models/reports/userdaytaskactivities/UserDayTaskActivites";
import { I18NService } from "services/i18n/I18NService";
import { userBreakdownReportI18N } from "./i18n/userBreakdownReport-i18n";

@Component({
    selector: "user-breakdown-report",
    templateUrl: "user-breakdown-report.html",
    host: { "[class.c-user-day-activity]": "true" },
})
export class UserBreakdownReportComponent implements OnInit {
    @Input() public filterSubject: ReplaySubject<FilterSettings>;
    @Input() public publicReportId: string;

    public userDayTaskActivities: UserDayTaskActivities[] = null;
    public i18n;

    constructor(
        private activityService: TimesheetService,
        private i18nService: I18NService
    ) {
        this.i18n = this.i18nService.extractCurrentTranslation(
            userBreakdownReportI18N
        );
    }

    public ngOnInit(): void {
        this.filterSubject.subscribe((filters) => {
            this.update(filters);
        });
    }

    private update(filters: FilterSettings) {
        if (this.publicReportId) {
            this.activityService
                .getPublicUserDayTaskActivities(this.publicReportId)
                .subscribe((activities) => {
                    this.userDayTaskActivities = activities;
                });
        } else {
            this.activityService
                .getUserDayTaskActivities(filters)
                .subscribe((activities) => {
                    this.userDayTaskActivities = activities;
                });
        }
    }
}
