import { Pipe, PipeTransform } from "@angular/core";
import { TimeUnitConversionService } from "../services/timeunitconversion/TimeUnitConversionService";

@Pipe({ name: "minutesViewPipe" })
export class MinutesViewPipe implements PipeTransform {
    constructor(private timeUnitConversionService: TimeUnitConversionService) {}

    public transform(value: number): string {
        return this.timeUnitConversionService.minutesToFormattedTextWithDefault(
            value,
            "&nbsp;"
        );
    }
}

@Pipe({ name: "minutesEditPipe" })
export class MinutesEditPipe implements PipeTransform {
    constructor(private timeUnitConversionService: TimeUnitConversionService) {}

    public transform(value: number): string {
        return this.timeUnitConversionService.minutesToFormattedTextWithDefault(
            value,
            ""
        );
    }
}

@Pipe({ name: "secondsViewPipe" })
export class SecondsViewPipe implements PipeTransform {
    constructor(private timeUnitConversionService: TimeUnitConversionService) {}

    public transform(value: number): string {
        return this.timeUnitConversionService.secondsToFormattedTextWithDefault(
            value,
            "00:00:00"
        );
    }
}
