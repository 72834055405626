import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { concat, Observable, ReplaySubject, Subject } from "rxjs";
import * as _ from "lodash";
import { ConfigService } from "../config";
import { Project } from "../../models/projects/Project";
import { Projects } from "../../models/projects/Projects";
import { CurrentCompanyUserViewService } from "../currentcompanyuser/CurrentCompanyUserViewService";
import { flatMap, map } from "rxjs/operators";

@Injectable()
export class ProjectService {
    private refreshSubject = new Subject<RefreshToken>();

    constructor(
        private http: HttpClient,
        private currentCompanyUserViewService: CurrentCompanyUserViewService,
        private configService: ConfigService
    ) {}

    public getCompanyProjects(active?: boolean): Observable<Projects> {
        const getProjectsObservable = () =>
            this.http
                .get(this.configService.projectsEndpoint(active))
                .pipe(map((res) => Projects.parse(res)));

        const refreshedProjects = this.refreshSubject.pipe(
            flatMap(() => getProjectsObservable())
        );
        return concat(getProjectsObservable(), refreshedProjects);
    }

    public getProject(projectId: string): Observable<Project> {
        const getProjectObservable = () =>
            this.http
                .get(this.configService.projectEndpoint(projectId))
                .pipe(map((res) => Project.nullable().parse(res)));

        const refreshedProject = this.refreshSubject.pipe(
            flatMap(() => getProjectObservable())
        );
        return concat(getProjectObservable(), refreshedProject);
    }

    public addProject(project: Project): Observable<Project> {
        const ret = new ReplaySubject<Project>(1);
        this.http
            .post(
                this.configService.projectsEndpoint(),
                _.omit(project, ["id", "active"])
            )
            .subscribe((res) => {
                this.refreshSubject.next(RefreshToken.REFRESH);
                ret.next(Project.parse(res));
            });
        return ret;
    }

    public updateProject(project: Project): Observable<Project> {
        const ret = new ReplaySubject<Project>(1);
        this.http
            .put(
                this.configService.projectEndpoint(project.id),
                _.omit(project, "id")
            )
            .subscribe((res) => {
                this.refreshSubject.next(RefreshToken.REFRESH);
                ret.next(Project.parse(res));
            });
        return ret;
    }

    public getMostRecentProjectForUser(userId: string): Observable<Project> {
        return this.http
            .get(this.configService.mostRecentProjectEndpoint(userId))
            .pipe(map((res) => Project.nullable().parse(res)));
    }

    public getMostRecentProjectsForUser(
        userId: string,
        includeImportedProjects: boolean
    ): Observable<Projects> {
        return this.http
            .get(
                this.configService.mostRecentProjectsEndpoint(
                    userId,
                    includeImportedProjects
                )
            )
            .pipe(map((res) => Projects.parse(res)));
    }

    public getTasksDuration(
        projectId?: string,
        userId?: string,
        query?: string
    ): Observable<number> {
        return this.http
            .get(
                this.configService.tasksDurationEndpoint(
                    projectId,
                    userId,
                    query
                )
            )
            .pipe(map((res) => (res ? (res as number) : 0)));
    }

    public forceRefresh(): void {
        this.refreshSubject.next(RefreshToken.REFRESH);
    }
}

enum RefreshToken {
    REFRESH,
}
