import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { concat, Observable, ReplaySubject, Subject } from "rxjs";
import { ConfigService } from "../config";
import { UserService } from "../user/UserService";
import { ProjectService } from "../projects/ProjectService";
import { Team } from "../../models/teams/Team";
import { Teams } from "../../models/teams/Teams";
import { flatMap, map } from "rxjs/operators";

@Injectable()
export class TeamService {
    private refreshSubject = new Subject<RefreshToken>();

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private userService: UserService,
        private projectService: ProjectService
    ) {}

    public getTeams(): Observable<Team[]> {
        const getTeamsObservable = () =>
            this.http.get(this.configService.teamsEndpoint()).pipe(
                map((res) => Teams.parse(res).teams),
                map((teams) => teams.filter((t) => !t.readOnly))
            );
        const refreshTeams = this.refreshSubject.pipe(
            flatMap(() => getTeamsObservable())
        );
        return concat(getTeamsObservable(), refreshTeams);
    }

    public createTeam(team: Team): Observable<void> {
        const stream = new ReplaySubject<void>(1);
        this.http
            .post(this.configService.teamsEndpoint(), team)
            .subscribe(() => {
                this.userService.forceRefresh();
                this.refreshSubject.next(RefreshToken.REFRESH);
                stream.next(null);
            });
        return stream;
    }

    public deleteTeam(code: string): Observable<void> {
        const stream = new ReplaySubject<void>(1);
        this.http
            .delete(this.configService.teamEndpoint(code))
            .subscribe(() => {
                this.refreshSubject.next(RefreshToken.REFRESH);
                this.userService.forceRefresh();
                this.projectService.forceRefresh();
                stream.next(null);
            });
        return stream;
    }

    public updateTeam(team: Team): Observable<void> {
        const stream = new ReplaySubject<void>(1);
        this.http
            .put(this.configService.teamEndpoint(team.code), team)
            .subscribe(() => {
                this.refreshSubject.next(RefreshToken.REFRESH);
                this.userService.forceRefresh();
                this.projectService.forceRefresh();
                stream.next(null);
            });
        return stream;
    }

    public calculateUpdatedTeamsForUser(
        userCode: string,
        oldTeamCodes: string[],
        newTeamCodes: string[],
        allTeams: Team[]
    ): Team[] {
        const ret: Team[] = [];
        oldTeamCodes.forEach((oldTeamCode) => {
            if (!newTeamCodes.find((newCode) => newCode === oldTeamCode)) {
                // the user was removed from this team
                const team = allTeams.find((t) => t.code === oldTeamCode);
                if (team) {
                    team.userCodes = team.userCodes.filter(
                        (c) => c !== userCode
                    );
                    ret.push(team);
                }
            }
        });

        newTeamCodes.forEach((newTeamCode) => {
            if (!oldTeamCodes.find((oldCode) => oldCode === newTeamCode)) {
                // the user was added to this team
                const team = allTeams.find((t) => t.code === newTeamCode);
                if (team) {
                    if (!team.userCodes.find((c) => c === userCode)) {
                        team.userCodes.push(userCode);
                        ret.push(team);
                    }
                }
            }
        });

        return ret;
    }

    public calculateUpdatedTeamsForProject(
        projectCode: string,
        oldTeamCodes: string[],
        newTeamCodes: string[],
        allTeams: Team[]
    ): Team[] {
        const ret: Team[] = [];
        oldTeamCodes.forEach((oldTeamCode) => {
            if (!newTeamCodes.find((newCode) => newCode === oldTeamCode)) {
                // the user was removed from this team
                const team = allTeams.find((t) => t.code === oldTeamCode);
                if (team) {
                    team.projectCodes = team.projectCodes.filter(
                        (c) => c !== projectCode
                    );
                    ret.push(team);
                }
            }
        });

        newTeamCodes.forEach((newTeamCode) => {
            if (!oldTeamCodes.find((oldCode) => oldCode === newTeamCode)) {
                // the user was added to this team
                const team = allTeams.find((t) => t.code === newTeamCode);
                if (team) {
                    if (!team.projectCodes.find((c) => c === projectCode)) {
                        team.projectCodes.push(projectCode);
                        ret.push(team);
                    }
                }
            }
        });

        return ret;
    }
}

enum RefreshToken {
    REFRESH,
}
