import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import * as _ from "lodash";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { UserPaymentService } from "services/user/UserPaymentService";
import { CurrencyService } from "services/currency/CurrencyService";
import { emptyUserPayment, UserPayment } from "models/users/UserPayment";
import { UserPaymentHistory } from "models/users/UserPaymentHistory";
import { Action } from "../../genericmodal/GenericModalComponent";
import { I18NService } from "services/i18n/I18NService";
import { userPaymentI18N } from "./i18n/user-payment-i18n";
import { CreateOrAddUserPaymentComponent } from "./createoradduserpayment/CreateOrAddUserPaymentComponent";
import { filter, flatMap, map, tap } from "rxjs/operators";

@Component({
    selector: "user-payment-modal",
    templateUrl: "user-payment-modal.html",
    host: {
        "[class.c-user-payment-modal]": "true",
    },
})
export class UserPaymentModalComponent implements OnInit {
    @Input() public user: CompanyUser;
    @Input() public showStream: Subject<boolean>;
    @ViewChild(CreateOrAddUserPaymentComponent)
    public createOrAddUserPaymentComponent: CreateOrAddUserPaymentComponent;

    public actionStream = new Subject<Action>();
    public userPayment: UserPayment = emptyUserPayment();
    public noInitialPaymentType: boolean;
    public paymentHistory: UserPaymentHistory;
    public ready = false;
    public i18n;
    public isValid = false;
    public isShowingHistory: boolean;
    public isHistoryTabAvailable: boolean;

    constructor(
        private userPaymentService: UserPaymentService,
        i18nService: I18NService,
        private currencyService: CurrencyService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(userPaymentI18N);
    }

    public ngOnInit(): void {
        this.showStream
            .pipe(
                filter(_.identity),
                tap(() => (this.ready = false)),
                flatMap(() =>
                    this.userPaymentService.getUserPayment(this.user.id)
                ),
                flatMap((userPayment) =>
                    this.userPaymentService
                        .getUserPaymentHistory(this.user.id)
                        .pipe(map((history) => [userPayment, history]))
                )
            )
            .subscribe(
                ([userPayment, history]: [UserPayment, UserPaymentHistory]) => {
                    this.isShowingHistory = false;
                    this.userPayment =
                        userPayment === undefined
                            ? emptyUserPayment()
                            : userPayment;
                    this.noInitialPaymentType = userPayment === undefined;
                    this.paymentHistory = history;
                    this.isHistoryTabAvailable = !!history;
                    this.ready = true;
                }
            );

        this.actionStream.subscribe((action) => {
            if (action === Action.OK) {
                this.createOrAddUserPaymentComponent.save();
            }
            if (action === Action.RESET) {
                this.userPaymentService
                    .resetPayment(this.user.id)
                    .subscribe(() => {
                        this.userPayment = emptyUserPayment();
                        this.noInitialPaymentType = true;
                        this.isHistoryTabAvailable = false;
                        this.paymentHistory = undefined;
                    });
            }
        });
    }

    public onHistoryEdit() {
        this.userPaymentService
            .getUserPaymentHistory(this.user.id)
            .subscribe((history) => {
                this.paymentHistory = history;
            });
    }
}
