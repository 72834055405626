<div class="c-user-day-activity__subrow">
    <div class="c-user-day-activity__cell c-user-day-activity__cell--second">
        {{details.date}}
    </div>
    <div class="c-user-day-activity__cell c-user-day-activity__cell--third">
        {{timeUnitConversionService.minutesToFormattedText(details.totalMinutes)}}
    </div>
    <div class="c-user-day-activity__cell c-user-day-activity__cell--fourth">
        {{i18n.total(details.taskMinutes.length)}}
        <a
            (click)="toggleDayDetails()"
            class="c-reports__graphs-type-picker-link c-reports__graphs-type-picker-link--day-details"
        ></a>
    </div>
</div>

<div *ngIf="showDetails">
    <div
        *ngFor="let taskMinute of details.taskMinutes"
        class="c-user-day-activity__subrow"
    >
        <div
            class="c-user-day-activity__cell c-user-day-activity__cell--second"
        ></div>
        <div class="c-user-day-activity__cell c-user-day-activity__cell--third">
            {{timeUnitConversionService.minutesToFormattedText(taskMinute.minutes)}}
        </div>
        <div
            class="c-user-day-activity__cell c-user-day-activity__cell--fourth"
        >
            {{taskMinute.name}}
        </div>
    </div>
</div>
