import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { profileEditI18N } from "./i18n/profile-edit-i18n";
import { Action } from "../genericmodal/GenericModalComponent";
import { CurrentCompanyUserService } from "services/currentcompanyuser/CurrentCompanyUserService";
import { UserService } from "services/user/UserService";
import { Token } from "models/token/Token";

/* eslint-disable */
export const Croppie: Croppie = (<any>window).Croppie;
/* eslint-enable */

@Component({
    selector: "profile-edit-form",
    templateUrl: "profile-edit-form.html",
})
export class ProfileEditFormComponent implements AfterViewInit {
    public static VIEWPORT_DIMENSION = 150;
    public static CROPPIE_WIDTH = 310;
    public static CROPPIE_HEIGHT = 220;
    public i18n;
    public profileEditForm: FormGroup;
    public currentUser;
    @Input() public showStream: Subject<Token>;
    @Input() public actionStream: Subject<Action>;
    @Input() public canSkip: boolean;
    @Input() public okEnabled: Subject<boolean>;
    @Input() public isInModal = false;
    @ViewChild("avatarPreview") public avatarPreview: ElementRef;
    @ViewChild("avatarUpload") public avatarUploadInput: ElementRef;

    public croppie: Croppie;

    public get hideButton() {
        return this.isInModal;
    }

    private controls = ["firstName", "lastName"];

    constructor(
        private i18nService: I18NService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private currentCompanyUserService: CurrentCompanyUserService,
        private http: HttpClient
    ) {
        this.i18n = i18nService.extractCurrentTranslation(profileEditI18N);
        this.rebuildForm();
    }

    public ngAfterViewInit() {
        this.showStream.subscribe(() => {
            this.rebuildForm();
            this.resetFileUpload();
        });

        if (this.isInModal) {
            this.actionStream.subscribe((action) => {
                if (action === Action.OK) {
                    this.submit(this.profileEditForm.value);
                }
            });

            this.okEnabled.next(this.isFormValid());
        }
    }

    private rebuildForm() {
        this.currentUser = this.currentCompanyUserService.companyUser;
        const firstName =
            (this.currentUser && this.currentUser.firstName) || "";
        const lastName = (this.currentUser && this.currentUser.lastName) || "";

        this.profileEditForm = this.formBuilder.group({
            firstName: [firstName, Validators.required],
            lastName: [lastName, Validators.required],
        });
    }

    private initializeCroppie() {
        this.croppie = new Croppie(this.avatarPreview.nativeElement, {
            viewport: {
                width: ProfileEditFormComponent.VIEWPORT_DIMENSION,
                height: ProfileEditFormComponent.VIEWPORT_DIMENSION,
                type: "square",
            },
            boundary: {
                width: ProfileEditFormComponent.CROPPIE_WIDTH,
                height: ProfileEditFormComponent.CROPPIE_HEIGHT,
            },
        });
    }

    private resetFileUpload() {
        if (this.croppie) {
            this.croppie.destroy();
            this.croppie = undefined;
        }
        this.avatarUploadInput.nativeElement.value = "";
    }

    public fileChanged(evt) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent) => {
            if (!this.croppie) {
                this.initializeCroppie();
            }
            this.croppie.bind({
                url: (<FileReader>e.target).result as string,
            });
        };
        const files: FileList = evt.target.files;
        if (files.length) {
            reader.readAsDataURL(files.item(0));
        }
    }

    private createUpdateRequest(data: ProfileData, image?: Blob) {
        const updatedUser = Object.assign({}, data, { askForDetails: false });
        const multiPart = new FormData();
        multiPart.append(
            "metadata",
            new Blob([JSON.stringify(updatedUser)], {
                type: "application/json",
            })
        );
        multiPart.append("image", image);
        return multiPart;
    }

    public submit(data: ProfileData): void {
        if (
            this.canSkip &&
            this.isFormEmpty() &&
            !this.avatarUploadInput.nativeElement.value
        ) {
            this.skip();
        } else if (this.isFormValid()) {
            const imagePromise: Promise<Blob> = this.croppie
                    ? this.croppie.result("blob")
                    : Promise.resolve(null),
                updateRequestPromise = imagePromise.then((image) =>
                    this.createUpdateRequest(data, image)
                );

            updateRequestPromise.then((updateRequest) => {
                this.updateProfile(updateRequest).subscribe(() => {
                    if (!this.isInModal) {
                        this.showStream.next(Token.TOKEN);
                    }
                });
            });
        }
    }

    public skip() {
        this.updateProfile(this.createUpdateRequest({})).subscribe(() =>
            this.showStream.next(Token.TOKEN)
        );
    }

    private updateProfile(updateRequest: FormData): Observable<void> {
        return this.userService.updateProfile(updateRequest);
    }

    public isFormValid(): boolean {
        this.controls.forEach((name) => this.touch(name));
        this.profileEditForm.updateValueAndValidity();
        return this.profileEditForm.valid;
    }

    public isFormEmpty(): boolean {
        this.profileEditForm.updateValueAndValidity();
        return this.controls.every((name) => this.isEmpty(name));
    }

    public onInput() {
        if (this.isInModal) {
            this.okEnabled.next(this.isFormValid());
        }
    }

    public onFocus(name: string) {
        this.untouch(name);
    }

    public onBlur() {
        if (this.canSkip && this.isFormEmpty()) {
            this.controls.forEach((controlName) => this.untouch(controlName));
        }
    }

    public getControl(name: string): AbstractControl {
        return this.profileEditForm.controls[name];
    }

    public isEmpty(name: string): boolean {
        return !this.getControl(name).value;
    }

    public valid(name: string): boolean {
        return this.getControl(name).valid;
    }

    public touched(name: string): boolean {
        return this.getControl(name).touched;
    }

    public touch(name: string): void {
        return this.getControl(name).markAsTouched();
    }

    public untouch(name: string): void {
        return this.getControl(name).markAsUntouched();
    }

    public getAvatarUrl(): string {
        return this.currentUser
            ? this.userService.getAvatarForUser(this.currentUser)
            : "./images/no-images.png";
    }
}

export interface ProfileData {
    firstName?: string;
    lastName?: string;
}

export interface Croppie {
    new (
        target: HTMLElement,
        config: {
            viewport: {
                width: number;
                height: number;
                type: string;
            };
            boundary: {
                width: number;
                height: number;
            };
        }
    );
    bind(options: { url: string });
    destroy();
    result(result: string);
}
