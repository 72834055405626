<generic-dropdown
    [items]="holidaysIncludedOptions"
    [selection]="selected"
    [initialSelectionStream]="preselected"
    [allowNoValue]="false"
    [selectMessage]="i18n.projects"
    [selectOnSwitch]="false"
    [searchPlaceholder]="i18n.findProjectPlaceholder"
    class="c-generic-dropdown--contained"
>
</generic-dropdown>
