import { UserPaymentType } from "./UserPaymentType";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const UserPayment = z.object({
    type: z.string().transform((str) => UserPaymentType[str]),
    rate: z.number().transform((value) => value / 100),
    currency: z.string(),
    workingHoursInDay: z.number().optional(),
});

export type UserPayment = z.infer<typeof UserPayment>;

export function emptyUserPayment(): UserPayment {
    return {
        currency: undefined,
        type: undefined,
        rate: undefined,
        workingHoursInDay: undefined,
    };
}
