import { pipesI18NDe } from "./pipes-i18n-de";
import { pipesI18NPl } from "./pipes-i18n-pl";

const pipesI18NEn = {
    today: "Today",
    yesterday: "Yesterday",
};

export const pipesI18N = {
    de: pipesI18NDe,
    pl: pipesI18NPl,
    en: pipesI18NEn,
};
