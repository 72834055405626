import { pagedDropdownI18NDe } from "./pageddropdown-i18n-de";
import { pagedDropdownI18NPl } from "./pageddropdown-i18n-pl";

const pagedDropdownI18NEn = {
    items: (first, last, total) => `${first}–${last} of ${total}`,
    page: (current, total) => `page: ${current} / ${total}`,
};

export const pagedDropdownI18N = {
    de: pagedDropdownI18NDe,
    en: pagedDropdownI18NEn,
    pl: pagedDropdownI18NPl,
};
