import { DaysOffRange } from "./DaysOffRange";
import { DayOffTotals } from "./DayOffTotals";
import { User } from "../users/User";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const UserDaysOff = z.object({
    user: User,
    totals: DayOffTotals,
    daysOff: DaysOffRange.array(),
});
export type UserDaysOff = z.infer<typeof UserDaysOff>;
