import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { ProjectPricingType } from "models/projects/ProjectPricingType";
import { ProjectPricing } from "models/projects/ProjectPricing";
import { ProjectPricingService } from "services/projects/ProjectPricingService";
import { I18NService } from "services/i18n/I18NService";
import { projectsI18N } from "../../i18n/projects-i18n";

@Component({
    selector: "create-or-add-project-rate",
    templateUrl: "create-or-add-project-rate.html",
})
export class CreateOrAddProjectRateComponent {
    @Input() public projectCode: string;
    @Input() public noInitialPricing: boolean;
    @Input() public currentPricing: ProjectPricing;
    @Input() public users: CompanyUser[] = [];
    public isValid: boolean;
    public i18n;

    public get currenPricingTypeStr() {
        if (this.currentPricing) {
            switch (this.currentPricing.type) {
                case ProjectPricingType.HOURLY_RATE:
                    return this.i18n.hourlyRate;
                case ProjectPricingType.MONTHLY_RATE:
                    return this.i18n.monthlyRate;
                case ProjectPricingType.FIXED_PRICE:
                    return this.i18n.fixedPrice;
                default:
                    throw "Unknown project pricing type";
            }
        }
    }
    private newPricing: ProjectPricing;

    constructor(
        private projectPricingService: ProjectPricingService,
        i18nService: I18NService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectsI18N);
    }

    public setHourlyRate() {
        this.currentPricing.type = ProjectPricingType.HOURLY_RATE;
        this.setValid(false);
    }

    public isHourlyRate() {
        return this.currentPricing.type === ProjectPricingType.HOURLY_RATE;
    }

    public setMonthlyRate() {
        this.currentPricing.type = ProjectPricingType.MONTHLY_RATE;
        this.setValid(false);
    }

    public isMonthlyRate() {
        return this.currentPricing.type === ProjectPricingType.MONTHLY_RATE;
    }

    public setFixedPrice() {
        this.currentPricing.type = ProjectPricingType.FIXED_PRICE;
        this.setValid(false);
    }

    public isFixedPrice() {
        return this.currentPricing.type === ProjectPricingType.FIXED_PRICE;
    }

    public setNewPricing(pricing: ProjectPricing) {
        this.newPricing = pricing;
    }

    public setValid(valid: boolean) {
        this.isValid = valid;
    }

    public save(): void {
        if (this.newPricing) {
            let observable: Observable<void>;
            switch (this.newPricing.type) {
                case ProjectPricingType.FIXED_PRICE:
                    observable =
                        this.projectPricingService.createOrUpdateFixedPrice(
                            this.projectCode,
                            this.newPricing.rate,
                            this.newPricing.expectedWorkload,
                            this.newPricing.currency
                        );
                    break;
                case ProjectPricingType.HOURLY_RATE:
                    if (this.noInitialPricing) {
                        observable =
                            this.projectPricingService.createHourlyRate(
                                this.projectCode,
                                this.newPricing.rate,
                                this.newPricing.currency,
                                this.newPricing.userOverrides
                            );
                    } else {
                        observable =
                            this.projectPricingService.addHourlyRatePeriod(
                                this.projectCode,
                                this.newPricing.rate,
                                this.newPricing.userOverrides,
                                new Date()
                            );
                    }
                    break;
                case ProjectPricingType.MONTHLY_RATE:
                    if (this.noInitialPricing) {
                        observable =
                            this.projectPricingService.createMonthlyRate(
                                this.projectCode,
                                this.newPricing.rate,
                                this.newPricing.currency
                            );
                    } else {
                        observable =
                            this.projectPricingService.addMonthlyRatePeriod(
                                this.projectCode,
                                this.newPricing.rate,
                                new Date()
                            );
                    }
                    break;
                default:
                    throw "unexpected projectPricingType";
            }

            observable.subscribe();
        }
    }
}
