export const loginI18NDe = {
    logIn: "Anmeldung",
    withGoogle: "mit Google",
    or: "oder",
    yourEmail: "E-Mail",
    password: "Passwort",
    passwordLoginFailed:
        "Anmeldung fehlgeschlagen Bitte versuchen Sie es erneut oder melden Sie sich mit Google an",
    forgotPassword: "Passwort vergessen?",
    invalidEmailAddress: "Ungültige E-Mail",
    required: "Erforderlich",
    unknownGoogleAccount:
        "Unbekanntes Google Konto. Bitte nochmals versuchen mit einem anderen Konto oder mit E-Mail und Passwort anmelden.",
};
