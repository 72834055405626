import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import * as _ from "lodash";
import { I18NService } from "services/i18n/I18NService";
import { reportFilterI18N } from "../../../i18n/reportfilter-i18n";

@Component({
    selector: "year-month-picker",
    templateUrl: "year-month-picker.html",
})
export class YearMonthPickerComponent implements OnInit {
    @Input() public selectedYearMonthSubject: Subject<Date>;
    @Input() public selectedYearMonth: Date;
    @Input() public alignLeft: boolean;
    public year: number;
    public i18n;

    public months = _.range(0, 12);

    private currentYearMonth: Date;

    constructor(i18nService: I18NService) {
        this.i18n = i18nService.extractCurrentTranslation(reportFilterI18N);
    }

    public ngOnInit() {
        this.year = (
            this.selectedYearMonth || this.getCurrentYearMonth()
        ).getFullYear();
        this.currentYearMonth = this.getCurrentYearMonth();
    }

    public nextYear() {
        this.year++;
    }

    public previousYear() {
        this.year--;
    }

    public select(m: number) {
        this.selectedYearMonth = new Date(this.year, m);
        this.selectedYearMonthSubject.next(this.selectedYearMonth);
    }

    public isSelected(m: number) {
        return (
            this.selectedYearMonth &&
            this.selectedYearMonth.getFullYear() === this.year &&
            this.selectedYearMonth.getMonth() === m
        );
    }

    public isDisabled(m: number) {
        return (
            this.year * 100 + m >=
            this.currentYearMonth.getFullYear() * 100 +
                this.currentYearMonth.getMonth()
        );
    }

    private getCurrentYearMonth() {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth());
    }
}
