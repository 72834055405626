import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";

@Component({
    selector: "color-picker",
    templateUrl: "color-picker.html",
    host: { "[class.c-color-picker]": "true" },
})
export class ColorPickerComponent implements OnInit {
    @Input() public selectionStream: Observable<string>;
    @Output() public change = new EventEmitter<string>();
    public colors = [
        "HOTPINK",
        "VIOLET",
        "PURPLEHEART",
        "CERULAENBLUE",
        "BLUE",
        "TURQUOISE",
        "GREEN",
        "JUNGLEGREEN",
        "YELLOW",
        "ORANGE",
        "RED",
        "BEAVERBROWN",
    ];
    public picked: string;

    public ngOnInit() {
        const defaultColor = this.colors[0];
        this.pick(defaultColor);
        this.selectionStream.subscribe((color) =>
            this.pick(color || defaultColor)
        );
    }

    public pick(color: string) {
        this.picked = color;
        this.change.emit(color);
    }
}
