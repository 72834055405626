import { Component, OnInit } from "@angular/core";
import { combineLatest, ReplaySubject } from "rxjs";
import { I18NService } from "services/i18n/I18NService";
import { ProjectService } from "services/projects/ProjectService";
import { TeamService } from "services/teams/TeamService";
import { CurrentCompanyUserService } from "services/currentcompanyuser/CurrentCompanyUserService";
import { projectsI18N } from "./i18n/projects-i18n";
import { CompanyUser } from "models/companyusers/CompanyUser";
import { Project } from "models/projects/Project";
import { PredefinedTeamCode } from "../teamsmenu/TeamsMenuComponent";
import { Team } from "models/teams/Team";
import { TeamViewMode } from "models/teams/TeamViewMode";

@Component({
    selector: "projects",
    templateUrl: "projects.html",
    host: { "[class.c-projects]": "true" },
})
export class ProjectsComponent implements OnInit {
    public i18n;
    public projectModalStream = new ReplaySubject<Project>(1);
    public projectsInCurrentTeamStream = new ReplaySubject<Project[]>(1);
    public filteredProjectsStream = new ReplaySubject<Project[]>(1);
    public selectedProjects: Project[] = [];
    public teams: Team[] = [];
    public currentUserStream = new ReplaySubject<CompanyUser>(1);
    public currentUser: CompanyUser;
    public teamsStream = new ReplaySubject<Team[]>(1);
    private allProjects: Project[] = [];
    private selectedTeam: { code?: string; predefinedCode: PredefinedTeamCode };
    private mode: TeamViewMode;

    constructor(
        private i18nService: I18NService,
        private projectService: ProjectService,
        private currentCompanyUserService: CurrentCompanyUserService,
        private teamService: TeamService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(projectsI18N);
    }

    public ngOnInit() {
        this.mode = TeamViewMode.ALL;
        this.selectedTeam = { predefinedCode: PredefinedTeamCode.ACTIVE };
        this.currentCompanyUserService
            .reloadCurrentCompanyUser()
            .subscribe((cu) => {
                this.currentUser = cu;
                this.currentUserStream.next(cu);
            });
        const ps = this.projectService.getCompanyProjects();
        const ts = this.teamService.getTeams();
        combineLatest([ps, ts]).subscribe((data) => {
            const projects = data[0];
            const teams = data[1];
            this.allProjects = projects.projects;
            this.projectsInCurrentTeamStream.next(
                this.allProjects
                    .filter(this.teamModeFilter)
                    .filter((p) => p.active === true)
            );
            this.onTeamSelected(this.selectedTeam);
            this.teamsStream.next(teams);
            this.teams = teams;
        });
    }
    public showAddProjectModal() {
        this.projectModalStream.next(null);
    }

    public onTeamSelected(e: {
        code?: string;
        predefinedCode: PredefinedTeamCode;
    }) {
        this.selectedTeam = e;
        if (e.code) {
            this.projectsInCurrentTeamStream.next(
                this.allProjects.filter(
                    (p) => p.active && p.teams.find((t) => t.code === e.code)
                )
            );
        } else if (e.predefinedCode === PredefinedTeamCode.ACTIVE) {
            this.projectsInCurrentTeamStream.next(
                this.allProjects
                    .filter(this.teamModeFilter)
                    .filter((p) => p.active)
            );
        } else {
            this.projectsInCurrentTeamStream.next(
                this.allProjects.filter((p) => !p.active)
            );
        }
    }

    public onProjectDragged(e: { elementCode: string; teamCode: string }) {
        const project = this.allProjects.find((p) => p.id === e.elementCode);
        if (project) {
            const team = this.teams.find((t) => t.code === e.teamCode);
            project.teams.push(team);
        }
    }

    public onSearchMatched(projects: Project[]) {
        this.filteredProjectsStream.next(projects);
    }

    public onModeChanged(mode: TeamViewMode) {
        this.mode = mode;
        if (this.selectedTeam) {
            this.onTeamSelected(this.selectedTeam);
        }
    }

    private teamModeFilter = (p: Project) => {
        // the given project has at least one team common with the current user
        if (this.mode === TeamViewMode.MY_TEAMS) {
            return (
                p.teams.filter(
                    (team) =>
                        !!this.currentUser.teams.find(
                            (t) => t.code === team.code
                        )
                ).length > 0
            );
        }
        return true;
    };

    public getProjectCodes(team: Team): string[] {
        return team.projectCodes;
    }
}
