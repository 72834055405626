import { Component, Input, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as _ from "lodash";
import { I18NService } from "services/i18n/I18NService";
import { financialReportsI18N } from "../i18n/financial-reports-i18n";
import { ChartScaleService } from "services/chartscale/ChartScaleService";
import {
    FilterSetting,
    FilterType,
} from "models/reports/filters/FilterSetting";
import {
    incomeAndExpenseReportMaxValue,
    ProjectIncomeAndExpenseReport,
} from "models/financialreport/ProjectIncomeAndExpenseReport";
import { map } from "rxjs/operators";

@Component({
    selector: "project-report",
    templateUrl: "project-report.html",
    host: { "[class.c-project-report]": "true" },
})
export class ProjectReportComponent implements OnInit {
    public static NUMBER_OF_SCALE_UNITS = 5;
    public static DEFAULT_REPORTS_MAX_VALUE = 1000;
    @Input() public reportObservable: Observable<ProjectIncomeAndExpenseReport>;
    @Input() public selectedItemStream = new Subject<FilterSetting>();
    public chartDataObservable: Observable<ProjectData[]>;
    public scaleObservable: Observable<number[]>;
    public i18n;
    public currency = "";

    constructor(
        i18nService: I18NService,
        private chartScaleService: ChartScaleService
    ) {
        this.i18n = i18nService.extractCurrentTranslation(financialReportsI18N);
    }

    public ngOnInit() {
        const chartAndScaleObservable = this.reportObservable.pipe(
            map((report) => {
                this.currency = report.currency;
                const scaleUnitSize = this.chartScaleService.getScale(
                    incomeAndExpenseReportMaxValue(report) ||
                        ProjectReportComponent.DEFAULT_REPORTS_MAX_VALUE,
                    ProjectReportComponent.NUMBER_OF_SCALE_UNITS
                );
                const scale = _.range(
                    0,
                    ProjectReportComponent.NUMBER_OF_SCALE_UNITS
                ).map((i) => i * scaleUnitSize);
                const projectData = report.incomesAndExpenses.map(
                    (iAndE) =>
                        new ProjectData(
                            iAndE.projectCode,
                            iAndE.projectName,
                            iAndE.projectColor,
                            iAndE.income,
                            iAndE.expense,
                            scaleUnitSize *
                                ProjectReportComponent.NUMBER_OF_SCALE_UNITS
                        )
                );
                return [scale, projectData];
            })
        );

        this.chartDataObservable = chartAndScaleObservable.pipe(
            map(([, projectData]) => <ProjectData[]>projectData)
        );
        this.scaleObservable = chartAndScaleObservable.pipe(
            map(([scale]) => <number[]>scale)
        );
    }
    public onProjectSelected(project: ProjectData): void {
        this.selectedItemStream.next(
            new FilterSetting(FilterType.PROJECT, project.code, project.name)
        );
    }
}

export class ProjectData {
    public incomeBarLength: number;
    public expenseBarLength: number;

    constructor(
        public code: string,
        public name: string,
        public color: string,
        public income: number,
        public expense: number,
        scaleLength: number
    ) {
        this.incomeBarLength = (income * 90) / scaleLength;
        this.expenseBarLength = (expense * 90) / scaleLength;
    }
}
