import { RouterModule, Routes } from "@angular/router";

import {
    AuthenticatedGuard,
    NotAuthenticatedGuard,
    AdminGuard,
    ManagerGuard,
    ProfileEditGuard,
    TimesheetAccessGuard,
} from "services/auth/ActivationGuards";

import { LoginComponent } from "components/login/LoginComponent";

import { InviteComponent } from "./components/invite/InviteComponent";
import { SendResetPasswordEmailComponent } from "./components/password/SendResetPasswordEmailComponent";
import { ResetPasswordComponent } from "./components/password/ResetPasswordComponent";
import { AuthComponent } from "./AuthComponent";
import { SignupInvitationComponent } from "./components/signup/SignupInvitationComponent";
import { TimesheetComponent } from "./components/timesheet/TimesheetComponent";
import { ProjectsComponent } from "./components/projects/ProjectsComponent";
import { TimesheetReportsComponent } from "./components/timesheetreports/TimesheetReportsComponent";
import { FinancialReportsComponent } from "./components/finances/financialreports/FinancialReportsComponent";
import { UsersComponent } from "./components/users/UsersComponent";
import { HolidaysComponent } from "./components/holidays/HolidaysComponent";
import { ProfileEditComponent } from "./components/profileedit/ProfileEditComponent";
import { SettingsComponent } from "./components/settings/SettingsComponent";

const appRoutes: Routes = [
    { path: "", redirectTo: "auth", pathMatch: "full" },
    {
        path: "auth/invite",
        component: InviteComponent,
        canActivate: [ManagerGuard],
    },
    {
        path: "auth",
        component: AuthComponent,
        canActivate: [AuthenticatedGuard],
        children: [
            { path: "", redirectTo: "ts", pathMatch: "full" },
            {
                path: "ts",
                component: TimesheetComponent,
                canActivate: [TimesheetAccessGuard],
            },
            {
                path: "ts/:userId",
                component: TimesheetComponent,
                canActivate: [TimesheetAccessGuard],
            },
            {
                path: "ts/:userId/:from",
                component: TimesheetComponent,
                canActivate: [TimesheetAccessGuard],
            },
            {
                path: "ts/:userId/:from/:to",
                component: TimesheetComponent,
                canActivate: [TimesheetAccessGuard],
            },
            {
                path: "projects",
                component: ProjectsComponent,
                canActivate: [ManagerGuard],
            },
            { path: "reports", component: TimesheetReportsComponent },
            {
                path: "finances",
                component: FinancialReportsComponent,
                canActivate: [AdminGuard],
            },
            {
                path: "users",
                component: UsersComponent,
                canActivate: [ManagerGuard],
            },
            {
                path: "settings",
                component: SettingsComponent,
                canActivate: [ManagerGuard],
            },
            { path: "holidays", component: HolidaysComponent },
            {
                path: "profile",
                component: ProfileEditComponent,
                canActivate: [ProfileEditGuard],
            },
        ],
    },
    { path: "publicReport/:reportId", component: TimesheetReportsComponent },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [NotAuthenticatedGuard],
    },
    {
        path: "sendResetPasswordEmail",
        component: SendResetPasswordEmailComponent,
    },
    { path: "resetPassword/:token", component: ResetPasswordComponent },
    {
        path: `signup/:` + SignupInvitationComponent.INVITATION_TOKEN_PARAM,
        component: SignupInvitationComponent,
        canActivate: [NotAuthenticatedGuard],
    },
    { path: "**", redirectTo: "", pathMatch: "full" },
];

export const routing = RouterModule.forRoot(appRoutes, { useHash: true });
