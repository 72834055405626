<div
    *ngFor="let day of days"
    class="o-date-picker__month-week-day"
    [ngClass]="{
             'o-date-picker__month-week-day--selected': isSelected(day),
             'o-date-picker__month-week-day--sealed': sealDay && isBeforeSealed(day),
             'o-date-picker__month-week-day--in-range': (startDay || endDay) && isInRange(day),
             'o-date-picker__month-week-day--first-in-range': startDay && isEqual(day, startDay),
             'o-date-picker__month-week-day--last-in-range': endDay && isEqual(day, endDay),
             'o-date-picker__month-week-day--weekend': !isWeekDay(day),
             'o-date-picker__month-week-day--today': isToday(day)}"
    (mousedown)="pickDay(day)"
>
    <span class="o-date-picker__month-week-day-label" *ngIf="!isOtherMonth(day)"
        >{{day.getDate()}}</span
    >
</div>
