import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const UserActivitiesDuration = z.object({
    code: z.string(),
    name: z.string(),
    duration: z.number(),
});

export type UserActivitiesDuration = z.infer<typeof UserActivitiesDuration>;
