import { DateService } from "../../../services/date/DateService";

export class FilterSetting {
    public static displayOnly(display: string): FilterSetting {
        return new FilterSetting(FilterType.DISPLAY_ONLY, "", display, true);
    }

    constructor(
        public filterType: FilterType,
        public filterValue: string,
        public filterDisplay?: string,
        public readOnly = false
    ) {
        this.filterDisplay = filterDisplay || filterValue;
    }
}

export enum FilterType {
    KEYWORD,
    PROJECT,
    USER,
    IGNORED,
    DATE,
    YEAR_MONTH_PERIOD,
    HOLIDAYS_INCLUDED,
    CURRENCY,
    PROJECT_TEAMS,
    USER_TEAMS,
    DISPLAY_ONLY,
    BILLABLE,
}

export class DateFilterSetting extends FilterSetting {
    constructor(public from: Date, public to: Date) {
        super(
            FilterType.DATE,
            DateService.toISOString(from) + " " + DateService.toISOString(to),
            DateService.toISOString(from) + " – " + DateService.toISOString(to)
        );
    }
}

export class YearMonthFilterSetting extends FilterSetting {
    constructor(public from: Date, public to: Date) {
        super(
            FilterType.YEAR_MONTH_PERIOD,
            YearMonthFilterSetting.toYearMonthString(from) +
                " " +
                YearMonthFilterSetting.toYearMonthString(to),
            YearMonthFilterSetting.toYearMonthString(from) +
                " - " +
                YearMonthFilterSetting.toYearMonthString(to),
            true
        );
    }

    private static toYearMonthString(date: Date) {
        return date ? DateService.toYearMonthString(date) : "";
    }
}
