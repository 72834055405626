<generic-modal
    [title]="i18n.yourLink"
    [buttonsVisible]="true"
    [showStream]="showLinkStream"
    [showClose]="false"
    [closeOnOk]="true"
    [actionStream]="actionStream"
    [showReset]="false"
    [confirmLabel]="i18n.copyToClipboard"
    [okEnabled]="true"
>
    <ng-content></ng-content>
</generic-modal>
