<div class="c-year__container" id="holidays-calendar-year">
    <month
        *ngFor="let monthNum of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
        [month]="monthNum"
        [year]="[year]"
        [daysOff]="daysOff"
        [currentUser]="currentUser"
        [currentSelection]="currentSelection"
        [calendarStateObservable]="calendarStateObservable"
    ></month>
</div>
