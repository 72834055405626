export const holidaysI18NDe = {
    pageTitle: "Freie Tage",
    addHolidayTitle: "Urlaub hinzufügen",
    addHolidays: "Urlaub hinzufügen",
    usersField: "Benutzer",
    findUser: "Benutzer finden",
    startDate: "Start: ",
    endDate: "Ende: ",
    usedIn: "Genutzt",
    days: "Tage im",
    add: "Hinzufügen",
    addSelected: "Auswahl hinzufügen",
    deleteSelected: "Ausgewählte Tage löschen",
    deleteWhole: "Ganzen Urlaub löschen",
    holidays: "Ferien",
    sickDays: "Krankheitstage",
    selectType: "Art auswählen",
};
