import { z } from "zod";
import { MergeFunctions } from "models/timesheet/Merger";
import { TimepotDate } from "models/TimepotDate";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const TSActivity = z.object({
    date: TimepotDate,
    sealed: z.boolean(),
    id: z.string().optional(),
    minutes: z.number().optional(),
    updateTimestamp: z.number().optional(),
});
export type TSActivity = z.infer<typeof TSActivity>;

export const tsActivityMergeFunctions: MergeFunctions<TSActivity> = {
    match: (a, b) => b && a.date.getTime() === b.date.getTime(),
    merge: (a, b) => ({
        ...a,
        id: b.id,
        minutes: b.minutes,
    }),
};

export function tsActivityEmpty(date: Date): TSActivity {
    return { date, sealed: false };
}
