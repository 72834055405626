import { Injectable } from "@angular/core";
import { ConfigService } from "../config";
import { FilterSettings } from "../../models/reports/filters/FilterSettings";
import { FilterSettingsQueryHelper } from "../report/FilterSettingsQueryHelper";

@Injectable()
export class PdfReportService {
    constructor(
        private filterSettingQueryHelper: FilterSettingsQueryHelper,
        private configService: ConfigService
    ) {}

    public getPdfReportLink(filterSettings: FilterSettings) {
        return (
            this.configService.pdfReportEndpoint() +
            this.filterSettingQueryHelper.getQueryString(filterSettings, true)
        );
    }
}
