import { z } from "zod";
import { TimepotDate } from "models/TimepotDate";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const MonthlyBalance = z.object({
    yearMonth: TimepotDate,
    balance: z.number().transform((value) => value / 100),
});

export type MonthlyBalance = z.infer<typeof MonthlyBalance>;
