import { Injectable } from "@angular/core";
import {
    HttpClient,
    HttpErrorResponse,
    HttpResponse,
} from "@angular/common/http";
import { EMPTY, mergeMap, Observable, of, shareReplay } from "rxjs";

import { ConfigService } from "../config";
import { BACKEND_ERROR_CODES } from "constants/BackendErrorCode";
import { UserService } from "../user/UserService";
import { CookieService } from "../cookie/CookieService";
import { User } from "models/users/User";

import { CurrentCompanyUserService } from "../currentcompanyuser/CurrentCompanyUserService";
import { catchError, flatMap, tap } from "rxjs/operators";
import { SocialAuthService } from "@abacritt/angularx-social-login";

@Injectable()
export class AuthService {
    private static isAuthenticatedCookieName = "isAuthenticated";

    public googleIdToken: string | null = null;
    public loggedInWithGoogle = false;
    public currentUser = this.authService.authState.pipe(
        flatMap((user) => {
            if (!user) {
                return EMPTY;
            }
            this.setGoogleIdToken(user.idToken);
            return this.confirmSignIn();
        }),
        shareReplay(1)
    );

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private cookieService: CookieService,
        private currentCompanyUserService: CurrentCompanyUserService,
        private configService: ConfigService,
        private authService: SocialAuthService
    ) {}

    public isAuthenticated(): boolean {
        return !!this.cookieService.getCookie(
            AuthService.isAuthenticatedCookieName
        );
    }

    public signInWithEmail(
        email: string,
        password: string
    ): Observable<object> {
        const payload = { email: email, password: password };
        return this.http.post(this.configService.signInEndpoint(), payload);
    }

    public signUpWithInvitationAndEmail(
        invitationToken: string,
        password: string
    ): Observable<object> {
        const payload = {
            invitationToken: invitationToken,
            password: password,
        };

        return this.http.post(
            this.configService.signUpWithInvitationEndpoint(),
            payload
        );
    }

    public signOut(): Observable<void> {
        return this.invalidateToken().pipe(
            mergeMap(async (response) => {
                if (this.loggedInWithGoogle) {
                    await this.authService.signOut();
                    this.loggedInWithGoogle = false;
                }
                this.currentCompanyUserService.companyUser = null;
                this.clearJwtTokens();

                const location =
                    "location" in response &&
                    typeof response.location === "string"
                        ? response.location
                        : null;
                if (location) {
                    window.location.replace(location);
                    //I'm sorry for this
                    throw new Error(
                        "Not really an error, just prevent execution of subscribers!"
                    );
                }
            })
        );
    }

    public setGoogleIdToken(token: string): void {
        this.googleIdToken = token;
        this.loggedInWithGoogle = true;
    }

    private clearJwtTokens(): void {
        this.googleIdToken = null;
    }

    private invalidateToken(): Observable<HttpResponse<object>> {
        return this.http.delete(this.configService.signOutEndpoint(), {
            observe: "response",
        });
    }

    public confirmSignIn(): Observable<User> {
        return this.userService
            .getProfile()
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this.onSignInConfirmError(error)
                )
            );
    }

    private onSignInConfirmError(error: HttpErrorResponse): Observable<User> {
        if (
            error.error &&
            error.error.errorCode === BACKEND_ERROR_CODES.NOT_AUTHENTICATED
        ) {
            return of(null);
        }
        throw error;
    }

    public withoutJwtToken<T>(handler: () => Observable<T>): Observable<T> {
        const googleIdToken = this.googleIdToken;
        this.clearJwtTokens();
        return handler().pipe(
            tap(() => {
                this.setGoogleIdToken(googleIdToken);
            })
        );
    }
}
