import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { TSActivity } from "models/timesheet/TSActivity";
import { TSTask } from "models/timesheet/TSTask";
import { TSProject } from "models/timesheet/TSProject";
import { DateService } from "services/date/DateService";
import { TSActivityComponent } from "../activity/TSActivityComponent";
import { TimesheetService } from "services/timesheet/TimesheetService";
import { NavigableRowOfActivities } from "../navigation/NavigableRowOfActivities";
import { Timer } from "models/timesheet/Timer";
import { FeatureToggleService } from "services/featuretoggle/FeatureToggleService";
import { I18NService } from "services/i18n/I18NService";
import { timesheetI18N } from "../i18n/timesheet-i18n";

@Component({
    selector: "ts-task",
    templateUrl: "ts-task.html",
    host: { "[class.c-ts-task]": "true" },
})
export class TSTaskComponent
    extends NavigableRowOfActivities
    implements OnInit
{
    @Input() public userId: string;
    @Input() public task: TSTask;
    @Input() public project: TSProject;
    @Input() public highlightedObservable: Observable<TSTask>;
    @Input() public onTaskEdit: (
        task: TSTask,
        project: { id: string; name: string }
    ) => void;
    @Input() public userHolidaysDays: Date[];
    @Input() public userSealDate: Date;
    @Input() public firstTaskInTable: boolean;
    @Input() public lastTaskInTable: boolean;
    @Input() public focusedActivityObservable: BehaviorSubject<{
        taskId: string;
        date: Date;
    }>;
    @Input() public timerObservable: Observable<Timer>;
    @Input() public timerSeconds: number;

    @Output() private navigateUp = new EventEmitter<Date>();
    @Output() private navigateDown = new EventEmitter<Date>();

    public highlightedTask: TSTask;
    public active = false;
    public isOnTimer = false;
    public timer: Observable<number>;
    public higherPriorityTooltip = false;
    public i18n;

    @ViewChildren(TSActivityComponent)
    public activityComponents: QueryList<TSActivityComponent>;

    constructor(
        private dateService: DateService,
        private timesheetService: TimesheetService,
        public featureToggleService: FeatureToggleService,
        public i18nService: I18NService
    ) {
        super();
        this.i18n = i18nService.extractCurrentTranslation(timesheetI18N);
    }

    public ngOnInit() {
        this.highlightedObservable.subscribe(
            (highlightedTask) => (this.highlightedTask = highlightedTask)
        );
        this.timerObservable.subscribe((timer) => {
            this.isOnTimer =
                timer &&
                timer.taskCode === this.task.id &&
                this.hasCurrentDay();
        });
    }

    public getSecondsWithTimer() {
        return this.task.summary * 60 + this.timerSeconds;
    }

    public editTask() {
        this.onTaskEdit(this.task, {
            id: this.project.id,
            name: this.project.name,
        });
    }

    public isHolidaysActivity(activity: TSActivity): boolean {
        return this.timesheetService.isHolidaysActivity(
            this.userHolidaysDays,
            activity
        );
    }

    public isTodaySealed(): boolean {
        return (
            this.userSealDate != null &&
            (this.dateService.isAfter(this.userSealDate, new Date()) ||
                this.dateService.isToday(this.userSealDate))
        );
    }

    public isSealedActivity(activity: TSActivity): boolean {
        return (
            this.userSealDate != null &&
            (this.dateService.isBefore(activity.date, this.userSealDate) ||
                this.dateService.isEqual(activity.date, this.userSealDate))
        );
    }

    protected getActivities(): TSActivityComponent[] {
        return this.activityComponents.toArray();
    }

    public navigateUpOf(focusedActivity: TSActivityComponent) {
        if (!this.firstTaskInTable) {
            focusedActivity.saveDurationAndClose();
            this.navigateUp.emit(focusedActivity.activity.date);
        }
    }

    public navigateDownOf(focusedActivity: TSActivityComponent) {
        if (!this.lastTaskInTable) {
            focusedActivity.saveDurationAndClose();
            this.navigateDown.emit(focusedActivity.activity.date);
        }
    }

    public setOpen(date: Date) {
        const targetActivity = this.activityComponents.find(
            (tsActivityComponent) =>
                tsActivityComponent.activity.date.getTime() === date.getTime()
        );
        targetActivity.setOpen(true);
    }

    public hasCurrentDay(): boolean {
        return this.task.activities.some((a) =>
            this.dateService.isToday(a.date)
        );
    }
}
