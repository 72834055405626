import { Team } from "../teams/Team";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const RawProject = z.object({
    id: z.string(),
    name: z.string().optional(),
    color: z.string(),
    active: z.boolean(),
    billableByDefault: z.boolean(),
    source: z.string().optional(),
    teams: Team.array()
        .default([])
        .transform((teams) => teams.filter((t) => !t.readOnly)),
});
export type RawProject = z.infer<typeof RawProject>;
export const createProject = (result: RawProject) => ({
    ...result,
    imported: !!result.source,
});

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const Project = RawProject.transform(createProject);
export type Project = z.infer<typeof Project>;
