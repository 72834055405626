export const projectsI18NPl = {
    addUser: "Dodaj Użytkownika",
    addRatesPerUser: "Dodaj stawki per użytkownik",
    personalizedRates: "spersonalizowane stawki",
    pageTitle: "Lista projektów",
    undo: "Cofnij",
    active: "Projekty aktywne",
    inactive: "Projekty zarchiwizowane",
    addProject: "Dodaj projekt",
    archive: "Archiwizuj",
    undoArchiveTooltip: "Cofnij archiwizację",
    edit: "Edytuj",
    duration: (hours: number, minutes: number) => `${hours}g ${minutes}min`,
    unknownDuration: "nieznana długość",
    restore: "Przywróć",
    value: "Stawka",
    estimatedWorkload: "Szacowana parcochłonność w godzinach",
    hourlyRate: "Stawka godzinowa",
    monthlyRate: "Stawka miesięczna",
    fixedPrice: "Stały koszt",
    invalid: "Niepoprawny",
    pricing: "Wycena",
    from: "Od",
    to: "Do",
    price: "Stawka",
    history: "Historia",
    currency: "Waluta",
    hourShort: "h",
    currentRate: "Aktualne stawka",
    reports: "Raporty",
    selectCurrency: "Wybierz walutę",
    user: "Użytkownik",
    of: "z",
    findProjects: "Znajdź projekt",
    more: "więcej",
    add: "Dodaj",
    addTeam: "Dodaj grupę",
    teamsCountLabel: (count: number) => {
        if (count === 1) {
            return "1 grupa";
        } else {
            const r = count % 100;
            if (r >= 10 && r <= 20) {
                return `${count} grup`;
            }
            const lastDigit = count % 10;
            if (lastDigit > 0 && lastDigit < 5) {
                return `${count} grup`;
            }
            return `${count} grupy`;
        }
    },
    allTeams: "Wszystkie grupy",
    myTeams: "Moje",
    tasksAreNonBillableByDefault: "Zadanie jest domyślnie nie opłacane",
};
